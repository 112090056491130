import React from "react";
import { useState, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";

import { GameCenterViewContext } from "../../../utility/context";

import { faSquareCheck } from "@fortawesome/free-solid-svg-icons";

import ThemeImg from "../../../static/theme_preview.png";
import FontAwesome from "../../../components/Icons/fontawesome";
import InputControl from "../../../components/Controls/InputControl";

import { getThemesDto } from "../../../modules/GameCenter/selector";
import { URLS } from "../../../configs/api_urls";
import { httpPost } from "../../../utility/request";
import { addAlert } from "../../../modules/App/actions";
import { setThemeColors } from "../../../utility/common";

import "./appearence-update.styles.scss";

const AppearenceUpdate = () => {
  const dispatch = useDispatch();
  const themes = useSelector(getThemesDto());
  const gameCenterDetails = useContext(GameCenterViewContext);
  const [applicationTheme, setApplicationTheme] = useState(
    gameCenterDetails.GameCenter.applicationTheme
  );
  const [futureActiveDays, setFutureActiveDays] = useState(
    gameCenterDetails.GameCenter.futureActiveDays
  );
  const [timeZone, setTimeZone] = useState(
    gameCenterDetails.GameCenter.timeZone
  );

  const setTheme = async (id) => {
    var selectedTheme = themes.find((t) => t.applicationThemeId == id);
    await httpPost(
      URLS.APPLICATION_INSTANCE_SETTINGS.UPDATE_INSTANCE_THEME.replace(
        "#ID#",
        id
      )
    )
      .then((response) => {
        if (response.data) {
          dispatch(
            addAlert({
              type: "success",
              message: "Application theme updated succesfully",
            })
          );
          setApplicationTheme(selectedTheme);
          setThemeColors(selectedTheme);
          gameCenterDetails.setGameCenter({
            ...gameCenterDetails.GameCenter,
            applicationTheme: selectedTheme,
          });
        }
      })
      .catch((error) => {
        dispatch(
          addAlert({
            type: "error",
            message: error,
          })
        );
      })
      .finally(() => {
        //setIsSaving(false);
      });
  };

  const isThemeSelected = (id) => {
    return applicationTheme.applicationThemeId == id;
  };

  const themeSelectBox = (theme) => {
    if (!theme) return "";

    return (
      <div
        className="theme_preview"
        onClick={() => {
          setTheme(theme.applicationThemeId);
        }}
      >
        <div
          className="forground"
          style={{ background: theme.foreGroundColor }}
        ></div>
        <div
          className="background"
          style={{ background: theme.backGroundColor }}
        >
          {isThemeSelected(theme.applicationThemeId) && (
            <FontAwesome icon={faSquareCheck} />
          )}
        </div>
      </div>
    );
  };

  return (
    <div className="my-2">
      <div className="container">
        <div className="row">
          <div className="col-md-6 border-right border-light">
            <div className="container theme_show_case">
              <h5 className="font-family-unset">Theme</h5>
              <img src={ThemeImg} className="w-100" alt="" />
            </div>
          </div>
          <div className="col-md-6">
            <div className="container">
              {[...Array(Math.ceil(themes.length / 2))].map((theme, index) => {
                return (
                  <div className="d-flex" key={`theme-index-${index}`}>
                    {themeSelectBox(themes[index * 2])}
                    {themeSelectBox(themes[index * 2 + 1])}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="mt-20">
          <label className="field-title">Future Active days</label>
          <InputControl
            name="timeZone"
            style={{ width: "100%", marginTop: "5px" }}
            value={futureActiveDays}
            disabled={true}
          ></InputControl>
        </div>
        <div className="mt-20">
          <label className="field-title">Time Zone</label>
          <InputControl
            name="timeZone"
            style={{ width: "100%", marginTop: "5px" }}
            value={timeZone}
            disabled={true}
          ></InputControl>
        </div>
      </div>
    </div>
  );
};

export default AppearenceUpdate;
