import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faPlus,
  faTimes,
  faSquareCheck,
} from "@fortawesome/free-solid-svg-icons";
import { faSquareCheck as faCk2 } from "@fortawesome/free-regular-svg-icons";
import { CircularProgress } from "@mui/material";

const SelectImage = (props) => {
  const {
    isEmpty,
    classNames,
    imageData,
    onClickClose,
    onSetThumbnail,
    handleImageChange,
    label,
    keySelect,
    isLoading = false,
  } = props;
  const { image } = imageData;

  /**
   * handle click upload
   *
   * @param {Event} e
   */
  const handleUploadImages = (e) => {
    try {
      const id = e.target.attributes["data-target"].value;
      const input_el = document.getElementById(id);
      input_el.click();
    } catch (err) {
      const target_el = e.target.parentElement;
      console.log(target_el);
      const id = target_el.attributes["data-target"].value;
      const input_el = document.getElementById(id);
      input_el.click();
    }
  };

  return (
    <div className={`${classNames} p-1`}>
      <div className="p-1">
        {isEmpty ? (
          <div
            data-target={keySelect}
            className="img_empty"
            onClick={handleUploadImages}
          >
            {isLoading ? (
              <div className="position-center">
                <CircularProgress />
              </div>
            ) : (
              <FontAwesomeIcon icon={faPlus} data-target={keySelect} />
            )}
          </div>
        ) : (
          <div className={"preview_image"}>
            <div className={"wrapper"}></div>
            <img src={image.imageFullUrl} alt=""></img>
            <span
              onClick={() => {
                onClickClose(keySelect);
              }}
              className="close"
            >
              <FontAwesomeIcon icon={faTimes} />
            </span>
            {onSetThumbnail && (
              <span
                onClick={() => {
                  onSetThumbnail(keySelect, !image.isThumbnailImage);
                }}
                className={"is_thumbnail"}
              >
                {image.isThumbnailImage ? (
                  <FontAwesomeIcon icon={faSquareCheck} />
                ) : (
                  <FontAwesomeIcon icon={faCk2} />
                )}
              </span>
            )}
          </div>
        )}
        <input
          type="file"
          name={keySelect}
          id={keySelect}
          className="d-none"
          accept="image/*"
          onChange={handleImageChange}
        />
        {label}
      </div>
    </div>
  );
};

export default SelectImage;
