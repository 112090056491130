import React from "react";

export default function NoContentMessage({message}) {

  return (
    <div className="card my-2" style={{ minHeight: "80vh" }}>
      <div className="p-2">
        <h6 className="text-muted position-center text-align-center">
          {message || "Sorry!! No data found."}
        </h6>
      </div>
    </div>
  );
}
