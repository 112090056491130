const GuideTaskOperations = () => {
  return (
    <div className="edit-container">
      <div style={{ display: "inherit" }} className="operations-title">
        <div style={{ margin: "auto", textAlign: "center", marginTop: "30px" }}>
          Create Tasks and reminders for yourself on increase productivity
        </div>
      </div>
      <div className="mt-50 p-20">
        <div className="row">
          <div className="col-md-6">
            <h5 className="text-theme font-600 cursor-pointer">
              Create a Task
            </h5>
            <span>create a task or reminder for your daily operations.</span>
          </div>
          <div className="col-md-6">
            <h5 className="text-theme font-600 cursor-pointer">
              Receive alerts
            </h5>
            <span>
              Receive alerts by setting deadline for each created task.
            </span>
          </div>
        </div>
        <div className="row mt-20">
          <div className="col-md-6">
            <h5 className="text-theme font-600 cursor-pointer">
              Track on dashboard
            </h5>
            <span>Task widget is added on dashboard to keep your tasks on focus.</span>
          </div>
          <div className="col-md-6">
          
          </div>
        </div>
      </div>
    </div>
  );
};

export default GuideTaskOperations;
