import React from "react";
import { Box, Typography } from "@mui/material";
import Logo from "../../../static/logo.png";

const RefundPolicy = () => {
  return (
    <Box sx={{ overflowY: "auto", height: "100vh" }}>
      <Box sx={{ display: "flex", ml: 3, p: 2 }}>
        <img style={{ height: "80px" }} src={Logo} alt="LOGO" />
        <Typography variant="h2" sx={{ ml: "10px" }}>
          Playnoot
        </Typography>
      </Box>
      <div className="privacy">
        <div className="container">
          <h1> REFUND POLICY</h1>
          <br />
          <p>
            At Playnoot, we take pride in the online sports services provided by
            us and endeavour to guarantee your satisfaction with our services.
            However, in case you are not satisfied with our solutions, we shall
            do our best to correct the situation, which may, subject to the
            discretion of our grievance redressal team, include corrective
            action, providing a refund or offering credit that can be used for
            future payments on Playnoot platform (Mobile app and Web
            application).{" "}
          </p>
          <p>
            If our solutions do not meet your satisfaction, please don't
            hesitate to contact our customer support team. Alternatively, you
            can send us an email at <b>info@playnoot.in</b> using the same email
            ID associated with your Playnoot account. We value your feedback and
            are here to assist you. Any refund, if called for, shall be provided
            as per and subject to the terms of this refund policy (“Refund
            Policy”)
          </p>
          <ul>
            <li>
              Before processing any refund, we reserve the right to make a best
              effort to complete the service and remedy your grievance.
            </li>
            <li>
              Playnoot spends considerable time and effort in catering to your
              requirements, hence any fees or resources that Playnoot may have
              spent towards your engagement shall be deducted on best judgment
              basis by Playnoot, before processing/finalising the refund amount.
              Any bank charge levied on the refund transaction shall also be
              deducted while processing the final refund amount.
            </li>
            <li>
              Playnoot shall process a full refund where any proven grievance is
              caused due to any act or omission by Playnoot or any of its
              Playnoot sports facility partner
            </li>
            . For the purposes of this Refund Policy, the refund amount shall
            include the refund of only Cancelled bookings where cancellation is
            adhered by Cancellation policy of sports facility. Also for the
            cancellation made by the sports facilty owner before the booking
            start time.
            <li> The refund amount shall be free of interest.</li>{" "}
            <li>
              No refund request shall be entertained after the Cancellation
              Policy time stated by the play facility has passed.
            </li>
            <li>
              The decision of our grievance redressal team shall be final in
              case of processing any refund amount. Once the refund is
              processed, it will be deemed to be the final settlement of your
              dispute with respect to our services. You shall not be entitled to
              any further claim or relief against us in respect thereof.
            </li>
            <li>
              Playnoot shall not be liable to anyone for any cessation,
              interruption, or delay in the performance of its obligations by
              reason of earthquake, flood, fire, storm, lightning, drought,
              landslide, hurricane, cyclone, typhoon, tornado, natural disaster,
              act of God or the public enemy, epidemic, famine or plague, action
              of a court or public authority, change in law, explosion, war,
              terrorism, armed conflict, labor strike, lockout, boycott or any
              other event beyond our reasonable control, whether foreseen or
              unforeseen (each a "Force Majeure Event").
            </li>
            <li>
              All refunds, once initiated, would be processed in 4-7 working
              days. Provided that Playnoot shall not be liable for any delay or
              interruption caused by third parties, including but not limited to
              the banks.
            </li>
          </ul>
        </div>
      </div>
    </Box>
  );
};

export default RefundPolicy;
