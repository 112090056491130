import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Button,
  CircularProgress,
  LinearProgress,
  IconButton,
  Table,
  Typography,
} from "@mui/material";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import InputControl from "../../../components/Controls/InputControl";
import ModalPopup from "../../../components/ModalPopup/modalpopup.component";

import { URLS } from "../../../configs/api_urls";
import {
  httpGet,
  httpPost,
  httpDelete,
  httpPut,
} from "../../../utility/request";
import FontAwesome from "../../../components/Icons/fontawesome";
import {
  faCheckSquare,
  faTimesRectangle,
} from "@fortawesome/free-regular-svg-icons";

import {
  setIsRenderedState,
  setData,
} from "../../../modules/ModalPopup/modalPopupSlice";
import { getModalDataState } from "../../../modules/ModalPopup/modalPopupSlice";
import { faSave, faTrash, faTrashCan } from "@fortawesome/free-solid-svg-icons";

const PlayerDetails = ({ player, onCreditChange }) => {
  const dispatch = useDispatch();
  const removingCreditId = useSelector(getModalDataState);

  const [playerUser, setPlayerUser] = useState(null);
  const [isLoadingPlayerUser, setIsLoadingPlayerUser] = useState(false);

  const [playerCredits, setPlayerCredits] = useState([]);
  const [isLoadingPlayerCredits, setIsLoadingPlayerCredits] = useState(false);

  const fetchPlayerDetails = async () => {
    setIsLoadingPlayerUser(true);
    const { data } = await httpGet(
      URLS.PLAYERS.GETPLAYERBYID.replace("#ID#", player.playerId)
    );
    setPlayerUser(data);
    console.log(data);
    setIsLoadingPlayerUser(false);
  };

  const fetchPlayerCredits = async () => {
    setIsLoadingPlayerCredits(true);
    const { data } = await httpGet(
      URLS.PLAYER_CREDITS.GETPLAYERCREDITS.replace("#ID#", player.playerId)
    );
    setPlayerCredits(data);
    setIsLoadingPlayerCredits(false);
  };

  const addNewCredit = () => {
    var anyNewCredit = playerCredits.find((x) => x.creditId == 0);
    if (anyNewCredit == undefined) {
      var newCredit = {
        creditId: 0,
        playerId: player.playerId,
        creditBalance: 0,
        creditDescription: "",
        isLoaderVisible: false,
      };
      setPlayerCredits([...playerCredits, newCredit]);
    }
  };

  const handleDescriptionChange = (description, creditId) => {
      var credits = playerCredits.map((credit) =>
        credit.creditId === creditId
          ? {
              ...credit,
              creditDescription: description
            }
          : credit
      );
    setPlayerCredits(credits);
  };

  const handlePriceChange = (price, creditId) => {
      var credits = playerCredits.map((credit) =>
        credit.creditId === creditId
          ? {
              ...credit,
              creditBalance: price
            }
          : credit
      );
    setPlayerCredits(credits);
  };

  const handleLoading = (creditId, value) => {
    // set loader ture
    var credits = playerCredits.map((credit) =>
      credit.creditId === creditId
        ? {
            ...credit,
            isLoaderVisible: value,
          }
        : credit
    );
    setPlayerCredits(credits);
  };

  const saveCredit = async (creditId) => {
    var credit = playerCredits.find((x) => x.creditId == creditId);
    if (credit.creditBalance != 0 && credit.creditDescription != "") {
      handleLoading(creditId, true);
      if (creditId > 0) {
        await httpPut(
          URLS.PLAYER_CREDITS.EDITPLAYERCREDIT,
          credit
        ).finally(() => {
          onCreditChange();
          handleLoading(creditId, false);
        });
      } else {
        await httpPost(
          URLS.PLAYER_CREDITS.ADDPLAYERCREDIT,
          credit
        )
          .then(({ data }) => {
            if (data == true) {
              fetchPlayerCredits();
              onCreditChange();
            }
          })
          .finally(() => {
            handleLoading(creditId, false);
          });
      }
    }
  };

  const removeCredit = async () => {
    handleLoading(removingCreditId, true);
    await httpDelete(
      URLS.PLAYER_CREDITS.REMOVEPLAYERCREDIT.replace("#ID#", removingCreditId)
    ).then(({ data }) => {
      if (data == true) {
        fetchPlayerCredits();
      }
    });
    dispatch(setIsRenderedState(false));
    handleLoading(removingCreditId, false);
  };

  useEffect(() => {
    fetchPlayerDetails();
    fetchPlayerCredits();
  }, []);

  return (
    <div>
      <ModalPopup
        heading={"Are you sure  you want to Remove this Credit?"}
        subHeadhing="Removing this credit will reduce the credit amount"
        isConfirmButton={true}
        confirmButtonText={"Yes"}
        onConfirmClick={removeCredit}
        isCancelButton={true}
        cancelButtontext={"No"}
        onCancelClick={() => dispatch(setIsRenderedState(false))}
      ></ModalPopup>
      <div className="mt-20">
        <Typography variant="h6" sx={{ textAlign: "center" }}>
          Player Details
        </Typography>
        <Table sx={{ minWidth: "500px", fontFamily: "Kanit" }}>
          <TableRow>
            <TableCell sx={{ border: "none" }}>Player Id</TableCell>
            <TableCell sx={{ float: "right", border: "none" }}>
              {"#" + player.playerId}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ border: "none" }}>User Name</TableCell>
            <TableCell sx={{ float: "right", border: "none" }}>
              {player.userName}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ border: "none" }}>User Name</TableCell>
            <TableCell sx={{ float: "right", border: "none" }}>
              {player.firstName || ""}
              {player.middleName || ""}
              {player.lastName || ""}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ border: "none" }}>Total Expenditure</TableCell>
            <TableCell sx={{ float: "right", border: "none" }}>
              {player.totalExpenditureHere}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ border: "none" }}>Total Bookings</TableCell>
            <TableCell sx={{ float: "right", border: "none" }}>
              {player.totalGamesHere}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ border: "none" }}>Total Credits</TableCell>
            <TableCell sx={{ float: "right", border: "none" }}>
              {player.totalCredits}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ border: "none" }}>Note</TableCell>
            <TableCell
              sx={{ float: "right", border: "none", maxWidth: "400px" }}
            >
              {player.note || ""}
            </TableCell>
          </TableRow>
        </Table>
      </div>
      <div className="mt-20">
        <Typography variant="h6" sx={{ textAlign: "center" }}>
          Player Contacts
        </Typography>
        {isLoadingPlayerUser && <CircularProgress></CircularProgress>}
        {!isLoadingPlayerUser && playerUser != null && (
          <Table sx={{ minWidth: "500px", fontFamily: "Kanit" }}>
            <TableRow>
              <TableCell sx={{ border: "none" }}>Email</TableCell>
              <TableCell sx={{ float: "right", border: "none" }}>
                {playerUser.user.emailId}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ border: "none" }}>Primary Phone</TableCell>
              <TableCell sx={{ float: "right", border: "none" }}>
                {playerUser.user.primaryPhoneNumber}
              </TableCell>
            </TableRow>
          </Table>
        )}
      </div>
      <div className="mt-20">
        <Typography variant="h6" sx={{ textAlign: "center" }}>
          Player Credits
        </Typography>
        {isLoadingPlayerCredits && <CircularProgress></CircularProgress>}
        {!isLoadingPlayerCredits && (
          <div className="mt-20">
            {
              <span className="font-italic text-sub-info">
                Add new Player credi.
                <label
                  onClick={(e) => addNewCredit()}
                  className="add-pricing-point"
                  href=""
                  style={{ marginLeft: "4px" }}
                >
                  Add Credit
                </label>
              </span>
            }
            {playerCredits.map((credit) => (
              <div key={credit.creditId}>
                <div className="display-flex mt-20">
                  <InputControl
                    name="description"
                    label="description"
                    other={{
                      type: "text",
                      placeholder: "Description",
                    }}
                    style={{ width: "90%" }}
                    onChange={(e) => {
                      handleDescriptionChange(e.target.value, credit.creditId);
                    }}
                    value={credit.creditDescription}
                    error={
                      credit.creditDescription == ""
                        ? "Description is required"
                        : null
                    }
                  ></InputControl>
                  <InputControl
                    name="price"
                    label="price"
                    other={{
                      type: "number",
                      placeholder: "Amount (Rs.)",
                    }}
                    minNumber={0}
                    style={{ width: "90%" }}
                    onChange={(e) => {
                      handlePriceChange(e.target.value, credit.creditId);
                    }}
                    value={credit.creditBalance}
                    error={
                      credit.creditBalance == 0 ? "Balance can't be 0" : null
                    }
                  ></InputControl>
                  <IconButton
                    aria-label="save"
                    size="small"
                    onClick={() => saveCredit(credit.creditId)}
                  >
                    <FontAwesome
                      icon={faSave}
                      iconSize={"30px"}
                      iconcolor={"green"}
                    ></FontAwesome>
                  </IconButton>
                  {credit.creditId == 0 && (
                    <IconButton
                      aria-label="cancel"
                      size="small"
                      onClick={() => {
                        fetchPlayerCredits();
                      }}
                    >
                      <FontAwesome
                        icon={faTimesRectangle}
                        iconSize={"30px"}
                        iconcolor={"red"}
                      ></FontAwesome>
                    </IconButton>
                  )}
                  {credit.creditId > 0 && (
                    <IconButton
                      aria-label="cancel"
                      size="small"
                      onClick={() => {
                        dispatch(setIsRenderedState(true));
                        dispatch(setData(credit.creditId));
                      }}
                    >
                      <FontAwesome
                        icon={faTrash}
                        iconSize={"25px"}
                        iconcolor={"#921d2b"}
                      ></FontAwesome>
                    </IconButton>
                  )}
                </div>
                {credit.isLoaderVisible && (
                  <div className="mt-20">
                    <LinearProgress />
                  </div>
                )}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default PlayerDetails;
