import { Box } from "@mui/material";
import PlatformChargesList from "./PlatformChargesList/platform-charges-list.component";

const PlatformChargesDashboard = () => {
  return (
    <Box>
      <PlatformChargesList />
    </Box>
  );
};

export default PlatformChargesDashboard;
