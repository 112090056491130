import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHouse,
  faReceipt,
  faBank,
  faUser,
  faGaugeHigh,
  faCheckToSlot,
  faPersonRunning,
  faMoneyCheckDollar,
  faMoneyBills,
  faMartiniGlassCitrus,
  faCreditCard,
  faBusinessTime,
  faStarHalfStroke,
  faCogs,
  faBoxesPacking,
  faBullhorn,
  faTags,
  faTasks,
  faMobile,
  faAd,
  faLocationDot,
  faStopwatch
} from "@fortawesome/free-solid-svg-icons";

export const DEFAULT_REDIRECT_URL = `/admin/home`;

export const APPLICATION_TYPES = { ADMIN: "2" };

export const ADMIN_NAV_ITEMS = [
  {
    id: 1,
    name: "MyCenters",
    icon: <FontAwesomeIcon icon={faLocationDot} />,
    label: "My Centers",
    link: `/admin`,
  },
  {
    id: 2,
    name: "Organisation",
    icon: <FontAwesomeIcon icon={faUser} />,
    label: "Organisation",
    link: `/admin/admin-users`,
  },
  {
    id: 3,
    name: "Account",
    icon: <FontAwesomeIcon icon={faReceipt} />,
    label: "Account",
    link: `/admin/admin-account`,
  },
  {
    id: 4,
    name: "AdminOrders",
    icon: <FontAwesomeIcon icon={faBoxesPacking} />,
    label: "Admin Orders",
    link: "/admin/admin-orders",
  },
  {
    id: 5,
    name: "AdminBankDetails",
    icon: <FontAwesomeIcon icon={faBank} />,
    label: "Admin Bank Details",
    link: "/admin/admin-bank-details",
  },
];

const GAME_CENTER_APP_ROUTE = "/admin/center/:applicationId";

const SUPER_ADMIN_ROUTE = "/super-admin";

export const GAME_CENTER_NAV_ITEMS = [
  {
    id: 1,
    name: "Dashboard",
    icon: <FontAwesomeIcon icon={faGaugeHigh} />,
    label: "Dashboard",
    link: `${GAME_CENTER_APP_ROUTE}`,
  },
  {
    id: 2,
    name: "Bookings",
    icon: <FontAwesomeIcon icon={faCheckToSlot} />,
    label: "Bookings",
    link: `${GAME_CENTER_APP_ROUTE}/bookings`,
  },
  {
    id: 3,
    name: "Players",
    icon: <FontAwesomeIcon icon={faPersonRunning} />,
    label: "Players",
    link: `${GAME_CENTER_APP_ROUTE}/players`,
  },
  {
    id: 4,
    name: "Assets",
    icon: <FontAwesomeIcon icon={faMoneyCheckDollar} />,
    label: "Assets",
    link: `${GAME_CENTER_APP_ROUTE}/assets`,
  },
  {
    id: 5,
    name: "Subscription plans",
    icon: <FontAwesomeIcon icon={faReceipt} />,
    label: "Subscription plans",
    link: `${GAME_CENTER_APP_ROUTE}/subscription-plans`,
  },
  {
    id: 6,
    name: "External Products",
    icon: <FontAwesomeIcon icon={faMartiniGlassCitrus} />,
    label: "External Products",
    link: `${GAME_CENTER_APP_ROUTE}/external_products`,
  },
  {
    id: 7,
    name: "Coaches",
    icon: <FontAwesomeIcon icon={faUser} />,
    label: "Coaches",
    link: `${GAME_CENTER_APP_ROUTE}/coaches`,
  },
  {
    id: 8,
    name: "My Reviews",
    icon: <FontAwesomeIcon icon={faStarHalfStroke} />,
    label: "My Reviews",
    link: `${GAME_CENTER_APP_ROUTE}/my_reviews`,
  },
  {
    id: 9,
    name: "Payments",
    icon: <FontAwesomeIcon icon={faCreditCard} />,
    label: "Payments",
    link: `${GAME_CENTER_APP_ROUTE}/payments`,
  },
  {
    id: 10,
    name: "Announcements",
    icon: <FontAwesomeIcon icon={faBullhorn} />,
    label: "Announcements",
    link: `${GAME_CENTER_APP_ROUTE}/announcements`,
  },
  {
    id: 11,
    name: "Branding",
    icon: <FontAwesomeIcon icon={faTags} />,
    label: "Branding",
    link: `${GAME_CENTER_APP_ROUTE}/brandings`,
  },
  {
    id: 12,
    name: "Tasks",
    icon: <FontAwesomeIcon icon={faTasks} />,
    label: "Tasks",
    link: `${GAME_CENTER_APP_ROUTE}/tasks`,
  },
  {
    id: 13,
    name: "Game Sessions",
    icon: <FontAwesomeIcon icon={faStopwatch} />,
    label: "Game Sessions",
    link: `${GAME_CENTER_APP_ROUTE}/game_sessions`,
  },
  {
    id: 14,
    name: "Settings",
    icon: <FontAwesomeIcon icon={faCogs} />,
    label: "Settings",
    link: `${GAME_CENTER_APP_ROUTE}/settings`,
  },
];


// super admin layout
export const SUPER_ADMIN_NAV_ITEMS = [
  {
    id: 1,
    name: "Apps",
    icon: <FontAwesomeIcon icon={faMobile} />,
    label: "All Apps",
    link: `${SUPER_ADMIN_ROUTE}`,
  },
  {
    id: 2,
    name: "Apps",
    icon: <FontAwesomeIcon icon={faAd} />,
    label: "Manage Ads",
    link: `${SUPER_ADMIN_ROUTE}/ads`,
  },
  {
    id: 3,
    name: "Apps",
    icon: <FontAwesomeIcon icon={faBank} />,
    label: "Manage Bank Details",
    link: `${SUPER_ADMIN_ROUTE}/bank-details`,
  },
  {
    id: 4,
    name: "Apps",
    icon: <FontAwesomeIcon icon={faBullhorn} />,
    label: "Notifications",
    link: `${SUPER_ADMIN_ROUTE}/announcements`,
  },
  {
    id: 5,
    name: "Bookings",
    icon: <FontAwesomeIcon icon={faCheckToSlot} />,
    label: "Bookings",
    link: `${SUPER_ADMIN_ROUTE}/bookings`,
  },
  {
    id: 6,
    name: "Payments",
    icon: <FontAwesomeIcon icon={faCreditCard} />,
    label: "Payments",
    link: `${SUPER_ADMIN_ROUTE}/payments`,
  },
  {
    id: 7,
    name: "Online Leads",
    icon: <FontAwesomeIcon icon={faBusinessTime} />,
    label: "Online Leads",
    link: `${SUPER_ADMIN_ROUTE}/online-leads`,
  },
  {
    id: 8,
    name: "Licenses",
    icon: <FontAwesomeIcon icon={faMoneyBills} />,
    label: "Licenses",
    link: `${SUPER_ADMIN_ROUTE}/licenses`,
  },
];