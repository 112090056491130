import { useState, useContext } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

import { getStandardGamesDto } from "../../../modules/GameCenter/selector";
import { GameCenterViewContext } from "../../../utility/context";

import ToggleCardSwitch from "../../../components/ToggleCardSwitch";
import Icon from "../../../components/Icons";

import { URLS } from "../../../configs/api_urls";
import { httpPost, httpDelete } from "../../../utility/request";
import { addAlert } from "../../../modules/App/actions";

const StandardGameUpdate = () => {
  const dispatch = useDispatch();
  const games = useSelector(getStandardGamesDto());
    const gameCenterDetails = useContext(GameCenterViewContext);
    const [selectedStandardGameList, setSelectedStandardGameList] = useState(
      gameCenterDetails.GameCenter.applicationInstanceStandardGames.map(mapItem => mapItem.standardGame)
    );

    const isGameChecked = (standardGameId) => 
    {
      return selectedStandardGameList
         .map((game) => game.standardGameId)
         .includes(standardGameId);
    }

    const selectGame = async (standardGameId) => 
    {
      var newList = [];
       if(isGameChecked(standardGameId)) 
       {
        var mapId =
          gameCenterDetails.GameCenter.applicationInstanceStandardGames.find(
            (g) => g.standardGame.standardGameId == standardGameId
          ).applicationInstanceStandardMapId;
        await httpDelete(
          URLS.APPLICATION_INSTANCE_SETTINGS.DELETE_STANDARD_GAME.replace(
            "#ID#",
            mapId
          )
        )
          .then((response) => {
            if (response.data == true) {
              dispatch(
                addAlert({
                  type: "success",
                  message: "Standard game deleted successfully",
                })
              );
              newList = selectedStandardGameList.filter(
                (game) => game.standardGameId !== standardGameId
              );
              gameCenterDetails.setGameCenter({
                ...gameCenterDetails.GameCenter,
                applicationInstanceStandardGames:
                  gameCenterDetails.GameCenter.applicationInstanceStandardGames.filter(
                    (g) => g.applicationInstanceStandardMapId !== mapId
                  ),
              });
            }
          })
          .catch((error) => {
            dispatch(
              addAlert({
                type: "error",
                message: "Error while deleting Image",
              })
            );
          });
          //remove api
       } else 
       {
        var selectedGame = games.find(
          (g) => g.standardGameId == standardGameId
        );
        await httpPost(
          URLS.APPLICATION_INSTANCE_SETTINGS.ADD_STANDARD_GAME.replace(
        "#ID#",
        standardGameId)
        )
          .then((response) => {
            if (response.data) {
              newList = [...selectedStandardGameList, selectedGame];
              gameCenterDetails.setGameCenter({
                ...gameCenterDetails.GameCenter,
                applicationInstanceStandardGames:
                  [...gameCenterDetails.GameCenter.applicationInstanceStandardGames, response.data]
              });
              dispatch(
                addAlert({
                  type: "success",
                  message: "New Game added succesfully",
                })
              );
            }
          })
          .catch((error) => {
            dispatch(
              addAlert({
                type: "error",
                message: error,
              })
            );
          })
          .finally(() => {
            //setIsSaving(false);
          });
          // add api
       }

       setSelectedStandardGameList(newList);
    }

  return (
    <div className="mt-2">
      <h6 className="font-family-unset">Games</h6>
      <div className="row">
        {games.map((game) => {
          return (
            <div className="col-md-2">
              <ToggleCardSwitch
                key={game.standardGameId}
                id={game.standardGameId}
                isSelected={isGameChecked(game.standardGameId)}
                onClick={() => {
                  selectGame(game.standardGameId);
                }}
                iconOn={
                  <Icon
                    color="white"
                    category="games"
                    icon={game.standardGameName}
                  ></Icon>
                }
                iconOff={
                  <Icon
                    color="black"
                    category="games"
                    icon={game.standardGameName}
                  ></Icon>
                }
                title={game.standardGameName}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default StandardGameUpdate;
