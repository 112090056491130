export const iconList = [
  "fa0",
  "fa1",
  "fa2",
  "fa3",
  "fa4",
  "fa5",
  "fa6",
  "fa7",
  "fa8",
  "fa9",
  "faFillDrip",
  "faArrowsToCircle",
  "faCircleChevronRight",
  "faChevronCircleRight",
  "faAt",
  "faTrashCan",
  "faTrashAlt",
  "faTextHeight",
  "faUserXmark",
  "faUserTimes",
  "faStethoscope",
  "faMessage",
  "faCommentAlt",
  "faInfo",
  "faDownLeftAndUpRightToCenter",
  "faCompressAlt",
  "faExplosion",
  "faFileLines",
  "faFileAlt",
  "faFileText",
  "faWaveSquare",
  "faRing",
  "faBuildingUn",
  "faDiceThree",
  "faCalendarDays",
  "faCalendarAlt",
  "faAnchorCircleCheck",
  "faBuildingCircleArrowRight",
  "faVolleyball",
  "faVolleyballBall",
  "faArrowsUpToLine",
  "faSortDown",
  "faSortDesc",
  "faCircleMinus",
  "faMinusCircle",
  "faDoorOpen",
  "faRightFromBracket",
  "faSignOutAlt",
  "faAtom",
  "faIcons",
  "faHeartMusicCameraBolt",
  "faMicrophoneLinesSlash",
  "faMicrophoneAltSlash",
  "faBridgeCircleCheck",
  "faPumpMedical",
  "faFingerprint",
  "faHandPointRight",
  "faMagnifyingGlassLocation",
  "faSearchLocation",
  "faForwardStep",
  "faStepForward",
  "faFaceSmileBeam",
  "faSmileBeam",
  "faFlagCheckered",
  "faFootball",
  "faFootballBall",
  "faSchoolCircleExclamation",
  "faCrop",
  "faAnglesDown",
  "faAngleDoubleDown",
  "faUsersRectangle",
  "faPeopleRoof",
  "faPeopleLine",
  "faBeerMugEmpty",
  "faBeer",
  "faDiagramPredecessor",
  "faArrowUpLong",
  "faLongArrowUp",
  "faFireFlameSimple",
  "faBurn",
  "faPerson",
  "faMale",
  "faLaptop",
  "faFileCsv",
  "faMenorah",
  "faRecordVinyl",
  "faFaceGrinStars",
  "faGrinStars",
  "faBong",
  "faSpaghettiMonsterFlying",
  "faArrowDownUpAcrossLine",
  "faSpoon",
  "faUtensilSpoon",
  "faJarWheat",
  "faEnvelopesBulk",
  "faMailBulk",
  "faFileCircleExclamation",
  "faCircleH",
  "faHospitalSymbol",
  "faPager",
  "faAddressBook",
  "faContactBook",
  "faStrikethrough",
  "faK",
  "faLandmarkFlag",
  "faPencil",
  "faPencilAlt",
  "faBackward",
  "faCaretRight",
  "faComments",
  "faPaste",
  "faFileClipboard",
  "faCodePullRequest",
  "faClipboardList",
  "faUserCheck",
  "faVialVirus",
  "faSheetPlastic",
  "faBlog",
  "faUserNinja",
  "faPersonArrowUpFromLine",
  "faScrollTorah",
  "faBroomBall",
  "faQuidditch",
  "faQuidditchBroomBall",
  "faToggleOff",
  "faBoxArchive",
  "faArchive",
  "faPersonDrowning",
  "faArrowDown91",
  "faSortNumericDesc",
  "faSortNumericDownAlt",
  "faFaceGrinTongueSquint",
  "faGrinTongueSquint",
  "faSprayCan",
  "faTruckMonster",
  "faW",
  "faEarthAfrica",
  "faGlobeAfrica",
  "faRainbow",
  "faCircleNotch",
  "faTabletScreenButton",
  "faTabletAlt",
  "faPaw",
  "faCloud",
  "faTrowelBricks",
  "faFaceFlushed",
  "faFlushed",
  "faHospitalUser",
  "faTentArrowLeftRight",
  "faLegal",
  "faBinoculars",
  "faMicrophoneSlash",
  "faBoxTissue",
  "faMotorcycle",
  "faBellConcierge",
  "faConciergeBell",
  "faPenRuler",
  "faPencilRuler",
  "faPeopleArrows",
  "faPeopleArrowsLeftRight",
  "faMarsAndVenusBurst",
  "faSquareCaretRight",
  "faCaretSquareRight",
  "faScissors",
  "faCut",
  "faSunPlantWilt",
  "faToiletsPortable",
  "faHockeyPuck",
  "faTable",
  "faMagnifyingGlassArrowRight",
  "faTachographDigital",
  "faDigitalTachograph",
  "faUsersSlash",
  "faClover",
  "faReply",
  "faMailReply",
  "faStarAndCrescent",
  "faHouseFire",
  "faSquareMinus",
  "faMinusSquare",
  "faHelicopter",
  "faCompass",
  "faSquareCaretDown",
  "faCaretSquareDown",
  "faFileCircleQuestion",
  "faLaptopCode",
  "faSwatchbook",
  "faPrescriptionBottle",
  "faBars",
  "faNavicon",
  "faPeopleGroup",
  "faHourglassEnd",
  "faHourglass3",
  "faHeartCrack",
  "faHeartBroken",
  "faSquareUpRight",
  "faExternalLinkSquareAlt",
  "faFaceKissBeam",
  "faKissBeam",
  "faFilm",
  "faRulerHorizontal",
  "faPeopleRobbery",
  "faLightbulb",
  "faCaretLeft",
  "faCircleExclamation",
  "faExclamationCircle",
  "faSchoolCircleXmark",
  "faArrowRightFromBracket",
  "faSignOut",
  "faCircleChevronDown",
  "faChevronCircleDown",
  "faUnlockKeyhole",
  "faUnlockAlt",
  "faCloudShowersHeavy",
  "faHeadphonesSimple",
  "faHeadphonesAlt",
  "faSitemap",
  "faCircleDollarToSlot",
  "faDonate",
  "faMemory",
  "faRoadSpikes",
  "faFireBurner",
  "faFlag",
  "faHanukiah",
  "faFeather",
  "faVolumeLow",
  "faVolumeDown",
  "faCommentSlash",
  "faCloudSunRain",
  "faCompress",
  "faWheatAwn",
  "faWheatAlt",
  "faAnkh",
  "faHandsHoldingChild",
  "faAsterisk",
  "faSquareCheck",
  "faCheckSquare",
  "faPesetaSign",
  "faHeading",
  "faHeader",
  "faGhost",
  "faList",
  "faListSquares",
  "faSquarePhoneFlip",
  "faPhoneSquareAlt",
  "faCartPlus",
  "faGamepad",
  "faCircleDot",
  "faDotCircle",
  "faFaceDizzy",
  "faDizzy",
  "faEgg",
  "faHouseMedicalCircleXmark",
  "faCampground",
  "faFolderPlus",
  "faFutbol",
  "faFutbolBall",
  "faSoccerBall",
  "faPaintbrush",
  "faPaintBrush",
  "faLock",
  "faGasPump",
  "faHotTubPerson",
  "faHotTub",
  "faMapLocation",
  "faMapMarked",
  "faHouseFloodWater",
  "faTree",
  "faBridgeLock",
  "faSackDollar",
  "faPenToSquare",
  "faEdit",
  "faCarSide",
  "faShareNodes",
  "faShareAlt",
  "faHeartCircleMinus",
  "faHourglassHalf",
  "faHourglass2",
  "faMicroscope",
  "faSink",
  "faBagShopping",
  "faShoppingBag",
  "faArrowDownZA",
  "faSortAlphaDesc",
  "faSortAlphaDownAlt",
  "faMitten",
  "faPersonRays",
  "faUsers",
  "faEyeSlash",
  "faFlaskVial",
  "faHand",
  "faHandPaper",
  "faOm",
  "faWorm",
  "faHouseCircleXmark",
  "faPlug",
  "faChevronUp",
  "faHandSpock",
  "faStopwatch",
  "faFaceKiss",
  "faKiss",
  "faBridgeCircleXmark",
  "faFaceGrinTongue",
  "faGrinTongue",
  "faChessBishop",
  "faFaceGrinWink",
  "faGrinWink",
  "faEarDeaf",
  "faDeaf",
  "faDeafness",
  "faHardOfHearing",
  "faRoadCircleCheck",
  "faDiceFive",
  "faSquareRss",
  "faRssSquare",
  "faLandMineOn",
  "faICursor",
  "faStamp",
  "faStairs",
  "faI",
  "faHryvniaSign",
  "faHryvnia",
  "faPills",
  "faFaceGrinWide",
  "faGrinAlt",
  "faTooth",
  "faV",
  "faBicycle",
  "faStaffSnake",
  "faRodAsclepius",
  "faRodSnake",
  "faStaffAesculapius",
  "faHeadSideCoughSlash",
  "faTruckMedical",
  "faAmbulance",
  "faWheatAwnCircleExclamation",
  "faSnowman",
  "faMortarPestle",
  "faRoadBarrier",
  "faSchool",
  "faIgloo",
  "faJoint",
  "faAngleRight",
  "faHorse",
  "faQ",
  "faG",
  "faNotesMedical",
  "faTemperatureHalf",
  "faTemperature2",
  "faThermometer2",
  "faThermometerHalf",
  "faDongSign",
  "faCapsules",
  "faPooStorm",
  "faPooBolt",
  "faFaceFrownOpen",
  "faFrownOpen",
  "faHandPointUp",
  "faMoneyBill",
  "faBookmark",
  "faAlignJustify",
  "faUmbrellaBeach",
  "faHelmetUn",
  "faBullseye",
  "faBacon",
  "faHandPointDown",
  "faArrowUpFromBracket",
  "faFolder",
  "faFolderBlank",
  "faFileWaveform",
  "faFileMedicalAlt",
  "faRadiation",
  "faChartSimple",
  "faMarsStroke",
  "faVial",
  "faGauge",
  "faDashboard",
  "faGaugeMed",
  "faTachometerAltAverage",
  "faWandMagicSparkles",
  "faMagicWandSparkles",
  "faE",
  "faPenClip",
  "faPenAlt",
  "faBridgeCircleExclamation",
  "faUser",
  "faSchoolCircleCheck",
  "faDumpster",
  "faVanShuttle",
  "faShuttleVan",
  "faBuildingUser",
  "faSquareCaretLeft",
  "faCaretSquareLeft",
  "faHighlighter",
  "faKey",
  "faBullhorn",
  "faGlobe",
  "faSynagogue",
  "faPersonHalfDress",
  "faRoadBridge",
  "faLocationArrow",
  "faC",
  "faTabletButton",
  "faBuildingLock",
  "faPizzaSlice",
  "faMoneyBillWave",
  "faChartArea",
  "faAreaChart",
  "faHouseFlag",
  "faPersonCircleMinus",
  "faBan",
  "faCancel",
  "faCameraRotate",
  "faSprayCanSparkles",
  "faAirFreshener",
  "faStar",
  "faRepeat",
  "faCross",
  "faBox",
  "faVenusMars",
  "faArrowPointer",
  "faMousePointer",
  "faMaximize",
  "faExpandArrowsAlt",
  "faChargingStation",
  "faShapes",
  "faTriangleCircleSquare",
  "faShuffle",
  "faRandom",
  "faPersonRunning",
  "faRunning",
  "faMobileRetro",
  "faGripLinesVertical",
  "faSpider",
  "faHandsBound",
  "faFileInvoiceDollar",
  "faPlaneCircleExclamation",
  "faXRay",
  "faSpellCheck",
  "faSlash",
  "faComputerMouse",
  "faMouse",
  "faArrowRightToBracket",
  "faSignIn",
  "faShopSlash",
  "faStoreAltSlash",
  "faServer",
  "faVirusCovidSlash",
  "faShopLock",
  "faHourglassStart",
  "faHourglass1",
  "faBlenderPhone",
  "faBuildingWheat",
  "faPersonBreastfeeding",
  "faRightToBracket",
  "faSignInAlt",
  "faVenus",
  "faPassport",
  "faHeartPulse",
  "faHeartbeat",
  "faPeopleCarryBox",
  "faPeopleCarry",
  "faTemperatureHigh",
  "faMicrochip",
  "faCrown",
  "faWeightHanging",
  "faXmarksLines",
  "faFilePrescription",
  "faWeightScale",
  "faWeight",
  "faUserGroup",
  "faUserFriends",
  "faArrowUpAZ",
  "faSortAlphaUp",
  "faChessKnight",
  "faFaceLaughSquint",
  "faLaughSquint",
  "faWheelchair",
  "faCircleArrowUp",
  "faArrowCircleUp",
  "faToggleOn",
  "faPersonWalking",
  "faWalking",
  "faL",
  "faFire",
  "faBedPulse",
  "faProcedures",
  "faShuttleSpace",
  "faSpaceShuttle",
  "faFaceLaugh",
  "faLaugh",
  "faFolderOpen",
  "faHeartCirclePlus",
  "faCodeFork",
  "faCity",
  "faMicrophoneLines",
  "faMicrophoneAlt",
  "faPepperHot",
  "faUnlock",
  "faColonSign",
  "faHeadset",
  "faStoreSlash",
  "faRoadCircleXmark",
  "faUserMinus",
  "faMarsStrokeUp",
  "faMarsStrokeV",
  "faChampagneGlasses",
  "faGlassCheers",
  "faClipboard",
  "faHouseCircleExclamation",
  "faFileArrowUp",
  "faFileUpload",
  "faWifi",
  "faWifi3",
  "faWifiStrong",
  "faBath",
  "faBathtub",
  "faUnderline",
  "faUserPen",
  "faUserEdit",
  "faSignature",
  "faStroopwafel",
  "faBold",
  "faAnchorLock",
  "faBuildingNgo",
  "faManatSign",
  "faNotEqual",
  "faBorderTopLeft",
  "faBorderStyle",
  "faMapLocationDot",
  "faMapMarkedAlt",
  "faJedi",
  "faSquarePollVertical",
  "faPoll",
  "faMugHot",
  "faCarBattery",
  "faBatteryCar",
  "faGift",
  "faDiceTwo",
  "faChessQueen",
  "faGlasses",
  "faChessBoard",
  "faBuildingCircleCheck",
  "faPersonChalkboard",
  "faMarsStrokeRight",
  "faMarsStrokeH",
  "faHandBackFist",
  "faHandRock",
  "faSquareCaretUp",
  "faCaretSquareUp",
  "faCloudShowersWater",
  "faChartBar",
  "faBarChart",
  "faHandsBubbles",
  "faHandsWash",
  "faLessThanEqual",
  "faTrain",
  "faEyeLowVision",
  "faLowVision",
  "faCrow",
  "faSailboat",
  "faWindowRestore",
  "faSquarePlus",
  "faPlusSquare",
  "faToriiGate",
  "faFrog",
  "faBucket",
  "faImage",
  "faMicrophone",
  "faCow",
  "faCaretUp",
  "faScrewdriver",
  "faFolderClosed",
  "faHouseTsunami",
  "faSquareNfi",
  "faArrowUpFromGroundWater",
  "faMartiniGlass",
  "faGlassMartiniAlt",
  "faRotateLeft",
  "faRotateBack",
  "faRotateBackward",
  "faUndoAlt",
  "faTableColumns",
  "faColumns",
  "faLemon",
  "faHeadSideMask",
  "faHandshake",
  "faGem",
  "faDolly",
  "faDollyBox",
  "faSmoking",
  "faMinimize",
  "faCompressArrowsAlt",
  "faMonument",
  "faSnowplow",
  "faAnglesRight",
  "faAngleDoubleRight",
  "faCannabis",
  "faCirclePlay",
  "faPlayCircle",
  "faTablets",
  "faEthernet",
  "faEuroSign",
  "faEur",
  "faEuro",
  "faChair",
  "faCircleCheck",
  "faCheckCircle",
  "faCircleStop",
  "faStopCircle",
  "faCompassDrafting",
  "faDraftingCompass",
  "faPlateWheat",
  "faIcicles",
  "faPersonShelter",
  "faNeuter",
  "faIdBadge",
  "faMarker",
  "faFaceLaughBeam",
  "faLaughBeam",
  "faHelicopterSymbol",
  "faUniversalAccess",
  "faCircleChevronUp",
  "faChevronCircleUp",
  "faLariSign",
  "faVolcano",
  "faPersonWalkingDashedLineArrowRight",
  "faSterlingSign",
  "faGbp",
  "faPoundSign",
  "faViruses",
  "faSquarePersonConfined",
  "faUserTie",
  "faArrowDownLong",
  "faLongArrowDown",
  "faTentArrowDownToLine",
  "faCertificate",
  "faReplyAll",
  "faMailReplyAll",
  "faSuitcase",
  "faPersonSkating",
  "faSkating",
  "faFilterCircleDollar",
  "faFunnelDollar",
  "faCameraRetro",
  "faCircleArrowDown",
  "faArrowCircleDown",
  "faFileImport",
  "faArrowRightToFile",
  "faSquareArrowUpRight",
  "faExternalLinkSquare",
  "faBoxOpen",
  "faScroll",
  "faSpa",
  "faLocationPinLock",
  "faPause",
  "faHillAvalanche",
  "faTemperatureEmpty",
  "faTemperature0",
  "faThermometer0",
  "faThermometerEmpty",
  "faBomb",
  "faRegistered",
  "faAddressCard",
  "faContactCard",
  "faVcard",
  "faScaleUnbalancedFlip",
  "faBalanceScaleRight",
  "faSubscript",
  "faDiamondTurnRight",
  "faDirections",
  "faBurst",
  "faHouseLaptop",
  "faLaptopHouse",
  "faFaceTired",
  "faTired",
  "faMoneyBills",
  "faSmog",
  "faCrutch",
  "faFontAwesome",
  "faFontAwesomeFlag",
  "faFontAwesomeLogoFull",
  "faCloudArrowUp",
  "faCloudUpload",
  "faCloudUploadAlt",
  "faPalette",
  "faArrowsTurnRight",
  "faVest",
  "faFerry",
  "faArrowsDownToPeople",
  "faSeedling",
  "faSprout",
  "faLeftRight",
  "faArrowsAltH",
  "faBoxesPacking",
  "faCircleArrowLeft",
  "faArrowCircleLeft",
  "faGroupArrowsRotate",
  "faBowlFood",
  "faCandyCane",
  "faArrowDownWideShort",
  "faSortAmountAsc",
  "faSortAmountDown",
  "faCloudBolt",
  "faThunderstorm",
  "faTextSlash",
  "faRemoveFormat",
  "faFaceSmileWink",
  "faSmileWink",
  "faFileWord",
  "faFilePowerpoint",
  "faArrowsLeftRight",
  "faArrowsH",
  "faHouseLock",
  "faCloudArrowDown",
  "faCloudDownload",
  "faCloudDownloadAlt",
  "faChildren",
  "faChalkboard",
  "faBlackboard",
  "faUserLargeSlash",
  "faUserAltSlash",
  "faEnvelopeOpen",
  "faHandshakeSimpleSlash",
  "faHandshakeAltSlash",
  "faMattressPillow",
  "faGuaraniSign",
  "faArrowsRotate",
  "faRefresh",
  "faSync",
  "faFireExtinguisher",
  "faCruzeiroSign",
  "faGreaterThanEqual",
  "faShieldHalved",
  "faShieldAlt",
  "faBookAtlas",
  "faAtlas",
  "faVirus",
  "faEnvelopeCircleCheck",
  "faLayerGroup",
  "faArrowsToDot",
  "faArchway",
  "faHeartCircleCheck",
  "faHouseChimneyCrack",
  "faHouseDamage",
  "faFileZipper",
  "faFileArchive",
  "faSquare",
  "faMartiniGlassEmpty",
  "faGlassMartini",
  "faCouch",
  "faCediSign",
  "faItalic",
  "faChurch",
  "faCommentsDollar",
  "faDemocrat",
  "faZ",
  "faPersonSkiing",
  "faSkiing",
  "faRoadLock",
  "faA",
  "faTemperatureArrowDown",
  "faTemperatureDown",
  "faFeatherPointed",
  "faFeatherAlt",
  "faP",
  "faSnowflake",
  "faNewspaper",
  "faRectangleAd",
  "faAd",
  "faCircleArrowRight",
  "faArrowCircleRight",
  "faFilterCircleXmark",
  "faLocust",
  "faSort",
  "faUnsorted",
  "faListOl",
  "faList12",
  "faListNumeric",
  "faPersonDressBurst",
  "faMoneyCheckDollar",
  "faMoneyCheckAlt",
  "faVectorSquare",
  "faBreadSlice",
  "faLanguage",
  "faFaceKissWinkHeart",
  "faKissWinkHeart",
  "faFilter",
  "faQuestion",
  "faFileSignature",
  "faUpDownLeftRight",
  "faArrowsAlt",
  "faHouseChimneyUser",
  "faHandHoldingHeart",
  "faPuzzlePiece",
  "faMoneyCheck",
  "faStarHalfStroke",
  "faStarHalfAlt",
  "faCode",
  "faWhiskeyGlass",
  "faGlassWhiskey",
  "faBuildingCircleExclamation",
  "faMagnifyingGlassChart",
  "faArrowUpRightFromSquare",
  "faExternalLink",
  "faCubesStacked",
  "faWonSign",
  "faKrw",
  "faWon",
  "faVirusCovid",
  "faAustralSign",
  "faF",
  "faLeaf",
  "faRoad",
  "faTaxi",
  "faCab",
  "faPersonCirclePlus",
  "faChartPie",
  "faPieChart",
  "faBoltLightning",
  "faSackXmark",
  "faFileExcel",
  "faFileContract",
  "faFishFins",
  "faBuildingFlag",
  "faFaceGrinBeam",
  "faGrinBeam",
  "faObjectUngroup",
  "faPoop",
  "faLocationPin",
  "faMapMarker",
  "faKaaba",
  "faToiletPaper",
  "faHelmetSafety",
  "faHardHat",
  "faHatHard",
  "faEject",
  "faCircleRight",
  "faArrowAltCircleRight",
  "faPlaneCircleCheck",
  "faFaceRollingEyes",
  "faMehRollingEyes",
  "faObjectGroup",
  "faChartLine",
  "faLineChart",
  "faMaskVentilator",
  "faArrowRight",
  "faSignsPost",
  "faMapSigns",
  "faCashRegister",
  "faPersonCircleQuestion",
  "faH",
  "faTarp",
  "faScrewdriverWrench",
  "faTools",
  "faArrowsToEye",
  "faPlugCircleBolt",
  "faHeart",
  "faMarsAndVenus",
  "faHouseUser",
  "faHomeUser",
  "faDumpsterFire",
  "faHouseCrack",
  "faMartiniGlassCitrus",
  "faCocktail",
  "faFaceSurprise",
  "faSurprise",
  "faBottleWater",
  "faCirclePause",
  "faPauseCircle",
  "faToiletPaperSlash",
  "faAppleWhole",
  "faAppleAlt",
  "faKitchenSet",
  "faR",
  "faTemperatureQuarter",
  "faTemperature1",
  "faThermometer1",
  "faThermometerQuarter",
  "faCube",
  "faBitcoinSign",
  "faShieldDog",
  "faSolarPanel",
  "faLockOpen",
  "faElevator",
  "faMoneyBillTransfer",
  "faMoneyBillTrendUp",
  "faHouseFloodWaterCircleArrowRight",
  "faSquarePollHorizontal",
  "faPollH",
  "faCircle",
  "faBackwardFast",
  "faFastBackward",
  "faRecycle",
  "faUserAstronaut",
  "faPlaneSlash",
  "faTrademark",
  "faBasketball",
  "faBasketballBall",
  "faSatelliteDish",
  "faCircleUp",
  "faArrowAltCircleUp",
  "faMobileScreenButton",
  "faMobileAlt",
  "faVolumeHigh",
  "faVolumeUp",
  "faUsersRays",
  "faWallet",
  "faClipboardCheck",
  "faFileAudio",
  "faBurger",
  "faHamburger",
  "faWrench",
  "faBugs",
  "faRupeeSign",
  "faRupee",
  "faFileImage",
  "faCircleQuestion",
  "faQuestionCircle",
  "faPlaneDeparture",
  "faHandshakeSlash",
  "faBookBookmark",
  "faCodeBranch",
  "faHatCowboy",
  "faBridge",
  "faPhoneFlip",
  "faPhoneAlt",
  "faTruckFront",
  "faCat",
  "faAnchorCircleExclamation",
  "faTruckField",
  "faRoute",
  "faClipboardQuestion",
  "faPanorama",
  "faCommentMedical",
  "faTeethOpen",
  "faFileCircleMinus",
  "faTags",
  "faWineGlass",
  "faForwardFast",
  "faFastForward",
  "faFaceMehBlank",
  "faMehBlank",
  "faSquareParking",
  "faParking",
  "faHouseSignal",
  "faBarsProgress",
  "faTasksAlt",
  "faFaucetDrip",
  "faCartFlatbed",
  "faDollyFlatbed",
  "faBanSmoking",
  "faSmokingBan",
  "faTerminal",
  "faMobileButton",
  "faHouseMedicalFlag",
  "faBasketShopping",
  "faShoppingBasket",
  "faTape",
  "faBusSimple",
  "faBusAlt",
  "faEye",
  "faFaceSadCry",
  "faSadCry",
  "faAudioDescription",
  "faPersonMilitaryToPerson",
  "faFileShield",
  "faUserSlash",
  "faPen",
  "faTowerObservation",
  "faFileCode",
  "faSignal",
  "faSignal5",
  "faSignalPerfect",
  "faBus",
  "faHeartCircleXmark",
  "faHouseChimney",
  "faHomeLg",
  "faWindowMaximize",
  "faFaceFrown",
  "faFrown",
  "faPrescription",
  "faShop",
  "faStoreAlt",
  "faFloppyDisk",
  "faSave",
  "faVihara",
  "faScaleUnbalanced",
  "faBalanceScaleLeft",
  "faSortUp",
  "faSortAsc",
  "faCommentDots",
  "faCommenting",
  "faPlantWilt",
  "faDiamond",
  "faFaceGrinSquint",
  "faGrinSquint",
  "faHandHoldingDollar",
  "faHandHoldingUsd",
  "faBacterium",
  "faHandPointer",
  "faDrumSteelpan",
  "faHandScissors",
  "faHandsPraying",
  "faPrayingHands",
  "faArrowRotateRight",
  "faArrowRightRotate",
  "faArrowRotateForward",
  "faRedo",
  "faBiohazard",
  "faLocationCrosshairs",
  "faLocation",
  "faMarsDouble",
  "faChildDress",
  "faUsersBetweenLines",
  "faLungsVirus",
  "faFaceGrinTears",
  "faGrinTears",
  "faPhone",
  "faCalendarXmark",
  "faCalendarTimes",
  "faChildReaching",
  "faHeadSideVirus",
  "faUserGear",
  "faUserCog",
  "faArrowUp19",
  "faSortNumericUp",
  "faDoorClosed",
  "faShieldVirus",
  "faDiceSix",
  "faMosquitoNet",
  "faBridgeWater",
  "faPersonBooth",
  "faTextWidth",
  "faHatWizard",
  "faPenFancy",
  "faPersonDigging",
  "faDigging",
  "faTrash",
  "faGaugeSimple",
  "faGaugeSimpleMed",
  "faTachometerAverage",
  "faBookMedical",
  "faPoo",
  "faQuoteRight",
  "faQuoteRightAlt",
  "faShirt",
  "faTShirt",
  "faTshirt",
  "faCubes",
  "faDivide",
  "faTengeSign",
  "faTenge",
  "faHeadphones",
  "faHandsHolding",
  "faHandsClapping",
  "faRepublican",
  "faArrowLeft",
  "faPersonCircleXmark",
  "faRuler",
  "faAlignLeft",
  "faDiceD6",
  "faRestroom",
  "faJ",
  "faUsersViewfinder",
  "faFileVideo",
  "faUpRightFromSquare",
  "faExternalLinkAlt",
  "faTableCells",
  "faTh",
  "faFilePdf",
  "faBookBible",
  "faBible",
  "faO",
  "faSuitcaseMedical",
  "faMedkit",
  "faUserSecret",
  "faOtter",
  "faPersonDress",
  "faFemale",
  "faCommentDollar",
  "faBusinessTime",
  "faBriefcaseClock",
  "faTableCellsLarge",
  "faThLarge",
  "faBookTanakh",
  "faTanakh",
  "faPhoneVolume",
  "faVolumeControlPhone",
  "faHatCowboySide",
  "faClipboardUser",
  "faChild",
  "faLiraSign",
  "faSatellite",
  "faPlaneLock",
  "faTag",
  "faComment",
  "faCakeCandles",
  "faBirthdayCake",
  "faCake",
  "faEnvelope",
  "faAnglesUp",
  "faAngleDoubleUp",
  "faPaperclip",
  "faArrowRightToCity",
  "faRibbon",
  "faLungs",
  "faArrowUp91",
  "faSortNumericUpAlt",
  "faLitecoinSign",
  "faBorderNone",
  "faCircleNodes",
  "faParachuteBox",
  "faIndent",
  "faTruckFieldUn",
  "faHourglass",
  "faHourglassEmpty",
  "faMountain",
  "faUserDoctor",
  "faUserMd",
  "faCircleInfo",
  "faInfoCircle",
  "faCloudMeatball",
  "faCamera",
  "faCameraAlt",
  "faSquareVirus",
  "faMeteor",
  "faCarOn",
  "faSleigh",
  "faArrowDown19",
  "faSortNumericAsc",
  "faSortNumericDown",
  "faHandHoldingDroplet",
  "faHandHoldingWater",
  "faWater",
  "faCalendarCheck",
  "faBraille",
  "faPrescriptionBottleMedical",
  "faPrescriptionBottleAlt",
  "faLandmark",
  "faTruck",
  "faCrosshairs",
  "faPersonCane",
  "faTent",
  "faVestPatches",
  "faCheckDouble",
  "faArrowDownAZ",
  "faSortAlphaAsc",
  "faSortAlphaDown",
  "faMoneyBillWheat",
  "faCookie",
  "faArrowRotateLeft",
  "faArrowLeftRotate",
  "faArrowRotateBack",
  "faArrowRotateBackward",
  "faUndo",
  "faHardDrive",
  "faHdd",
  "faFaceGrinSquintTears",
  "faGrinSquintTears",
  "faDumbbell",
  "faRectangleList",
  "faListAlt",
  "faTarpDroplet",
  "faHouseMedicalCircleCheck",
  "faPersonSkiingNordic",
  "faSkiingNordic",
  "faCalendarPlus",
  "faPlaneArrival",
  "faCircleLeft",
  "faArrowAltCircleLeft",
  "faTrainSubway",
  "faSubway",
  "faChartGantt",
  "faIndianRupeeSign",
  "faIndianRupee",
  "faInr",
  "faCropSimple",
  "faCropAlt",
  "faMoneyBill1",
  "faMoneyBillAlt",
  "faLeftLong",
  "faLongArrowAltLeft",
  "faDna",
  "faVirusSlash",
  "faMinus",
  "faSubtract",
  "faChildRifle",
  "faChess",
  "faArrowLeftLong",
  "faLongArrowLeft",
  "faPlugCircleCheck",
  "faStreetView",
  "faFrancSign",
  "faVolumeOff",
  "faHandsAslInterpreting",
  "faAmericanSignLanguageInterpreting",
  "faAslInterpreting",
  "faHandsAmericanSignLanguageInterpreting",
  "faGear",
  "faCog",
  "faDropletSlash",
  "faTintSlash",
  "faMosque",
  "faMosquito",
  "faStarOfDavid",
  "faPersonMilitaryRifle",
  "faCartShopping",
  "faShoppingCart",
  "faVials",
  "faPlugCirclePlus",
  "faPlaceOfWorship",
  "faGripVertical",
  "faArrowTurnUp",
  "faLevelUp",
  "faU",
  "faSquareRootVariable",
  "faSquareRootAlt",
  "faClock",
  "faClockFour",
  "faBackwardStep",
  "faStepBackward",
  "faPallet",
  "faFaucet",
  "faBaseballBatBall",
  "faS",
  "faTimeline",
  "faKeyboard",
  "faCaretDown",
  "faHouseChimneyMedical",
  "faClinicMedical",
  "faTemperatureThreeQuarters",
  "faTemperature3",
  "faThermometer3",
  "faThermometerThreeQuarters",
  "faMobileScreen",
  "faMobileAndroidAlt",
  "faPlaneUp",
  "faPiggyBank",
  "faBatteryHalf",
  "faBattery3",
  "faMountainCity",
  "faCoins",
  "faKhanda",
  "faSliders",
  "faSlidersH",
  "faFolderTree",
  "faNetworkWired",
  "faMapPin",
  "faHamsa",
  "faCentSign",
  "faFlask",
  "faPersonPregnant",
  "faWandSparkles",
  "faEllipsisVertical",
  "faEllipsisV",
  "faTicket",
  "faPowerOff",
  "faRightLong",
  "faLongArrowAltRight",
  "faFlagUsa",
  "faLaptopFile",
  "faTty",
  "faTeletype",
  "faDiagramNext",
  "faPersonRifle",
  "faHouseMedicalCircleExclamation",
  "faClosedCaptioning",
  "faPersonHiking",
  "faHiking",
  "faVenusDouble",
  "faImages",
  "faCalculator",
  "faPeoplePulling",
  "faN",
  "faCableCar",
  "faTram",
  "faCloudRain",
  "faBuildingCircleXmark",
  "faShip",
  "faArrowsDownToLine",
  "faDownload",
  "faFaceGrin",
  "faGrin",
  "faDeleteLeft",
  "faBackspace",
  "faEyeDropper",
  "faEyeDropperEmpty",
  "faEyedropper",
  "faFileCircleCheck",
  "faForward",
  "faMobile",
  "faMobileAndroid",
  "faMobilePhone",
  "faFaceMeh",
  "faMeh",
  "faAlignCenter",
  "faBookSkull",
  "faBookDead",
  "faIdCard",
  "faDriversLicense",
  "faOutdent",
  "faDedent",
  "faHeartCircleExclamation",
  "faHouse",
  "faHome",
  "faHomeAlt",
  "faHomeLgAlt",
  "faCalendarWeek",
  "faLaptopMedical",
  "faB",
  "faFileMedical",
  "faDiceOne",
  "faKiwiBird",
  "faArrowRightArrowLeft",
  "faExchange",
  "faRotateRight",
  "faRedoAlt",
  "faRotateForward",
  "faUtensils",
  "faCutlery",
  "faArrowUpWideShort",
  "faSortAmountUp",
  "faMillSign",
  "faBowlRice",
  "faSkull",
  "faTowerBroadcast",
  "faBroadcastTower",
  "faTruckPickup",
  "faUpLong",
  "faLongArrowAltUp",
  "faStop",
  "faCodeMerge",
  "faUpload",
  "faHurricane",
  "faMound",
  "faToiletPortable",
  "faCompactDisc",
  "faFileArrowDown",
  "faFileDownload",
  "faCaravan",
  "faShieldCat",
  "faBolt",
  "faZap",
  "faGlassWater",
  "faOilWell",
  "faVault",
  "faMars",
  "faToilet",
  "faPlaneCircleXmark",
  "faYenSign",
  "faCny",
  "faJpy",
  "faRmb",
  "faYen",
  "faRubleSign",
  "faRouble",
  "faRub",
  "faRuble",
  "faSun",
  "faGuitar",
  "faFaceLaughWink",
  "faLaughWink",
  "faHorseHead",
  "faBoreHole",
  "faIndustry",
  "faCircleDown",
  "faArrowAltCircleDown",
  "faArrowsTurnToDots",
  "faFlorinSign",
  "faArrowDownShortWide",
  "faSortAmountDesc",
  "faSortAmountDownAlt",
  "faLessThan",
  "faAngleDown",
  "faCarTunnel",
  "faHeadSideCough",
  "faGripLines",
  "faThumbsDown",
  "faUserLock",
  "faArrowRightLong",
  "faLongArrowRight",
  "faAnchorCircleXmark",
  "faEllipsis",
  "faEllipsisH",
  "faChessPawn",
  "faKitMedical",
  "faFirstAid",
  "faPersonThroughWindow",
  "faToolbox",
  "faHandsHoldingCircle",
  "faBug",
  "faCreditCard",
  "faCreditCardAlt",
  "faCar",
  "faAutomobile",
  "faHandHoldingHand",
  "faBookOpenReader",
  "faBookReader",
  "faMountainSun",
  "faArrowsLeftRightToLine",
  "faDiceD20",
  "faTruckDroplet",
  "faFileCircleXmark",
  "faTemperatureArrowUp",
  "faTemperatureUp",
  "faMedal",
  "faBed",
  "faSquareH",
  "faHSquare",
  "faPodcast",
  "faTemperatureFull",
  "faTemperature4",
  "faThermometer4",
  "faThermometerFull",
  "faBell",
  "faSuperscript",
  "faPlugCircleXmark",
  "faStarOfLife",
  "faPhoneSlash",
  "faPaintRoller",
  "faHandshakeAngle",
  "faHandsHelping",
  "faLocationDot",
  "faMapMarkerAlt",
  "faFile",
  "faGreaterThan",
  "faPersonSwimming",
  "faSwimmer",
  "faArrowDown",
  "faDroplet",
  "faTint",
  "faEraser",
  "faEarthAmericas",
  "faEarth",
  "faEarthAmerica",
  "faGlobeAmericas",
  "faPersonBurst",
  "faDove",
  "faBatteryEmpty",
  "faBattery0",
  "faSocks",
  "faInbox",
  "faSection",
  "faGaugeHigh",
  "faTachometerAlt",
  "faTachometerAltFast",
  "faEnvelopeOpenText",
  "faHospital",
  "faHospitalAlt",
  "faHospitalWide",
  "faWineBottle",
  "faChessRook",
  "faBarsStaggered",
  "faReorder",
  "faStream",
  "faDharmachakra",
  "faHotdog",
  "faPersonWalkingWithCane",
  "faBlind",
  "faDrum",
  "faIceCream",
  "faHeartCircleBolt",
  "faFax",
  "faParagraph",
  "faCheckToSlot",
  "faVoteYea",
  "faStarHalf",
  "faBoxesStacked",
  "faBoxes",
  "faBoxesAlt",
  "faLink",
  "faChain",
  "faEarListen",
  "faAssistiveListeningSystems",
  "faTreeCity",
  "faPlay",
  "faFont",
  "faRupiahSign",
  "faMagnifyingGlass",
  "faSearch",
  "faTableTennisPaddleBall",
  "faPingPongPaddleBall",
  "faTableTennis",
  "faPersonDotsFromLine",
  "faDiagnoses",
  "faTrashCanArrowUp",
  "faTrashRestoreAlt",
  "faNairaSign",
  "faCartArrowDown",
  "faWalkieTalkie",
  "faFilePen",
  "faFileEdit",
  "faReceipt",
  "faSquarePen",
  "faPenSquare",
  "faPencilSquare",
  "faSuitcaseRolling",
  "faPersonCircleExclamation",
  "faChevronDown",
  "faBatteryFull",
  "faBattery",
  "faBattery5",
  "faSkullCrossbones",
  "faCodeCompare",
  "faListUl",
  "faListDots",
  "faSchoolLock",
  "faTowerCell",
  "faDownLong",
  "faLongArrowAltDown",
  "faRankingStar",
  "faChessKing",
  "faPersonHarassing",
  "faBrazilianRealSign",
  "faLandmarkDome",
  "faLandmarkAlt",
  "faArrowUp",
  "faTv",
  "faTelevision",
  "faTvAlt",
  "faShrimp",
  "faListCheck",
  "faTasks",
  "faJugDetergent",
  "faCircleUser",
  "faUserCircle",
  "faUserShield",
  "faWind",
  "faCarBurst",
  "faCarCrash",
  "faY",
  "faPersonSnowboarding",
  "faSnowboarding",
  "faTruckFast",
  "faShippingFast",
  "faFish",
  "faUserGraduate",
  "faCircleHalfStroke",
  "faAdjust",
  "faClapperboard",
  "faCircleRadiation",
  "faRadiationAlt",
  "faBaseball",
  "faBaseballBall",
  "faJetFighterUp",
  "faDiagramProject",
  "faProjectDiagram",
  "faCopy",
  "faVolumeXmark",
  "faVolumeMute",
  "faVolumeTimes",
  "faHandSparkles",
  "faGrip",
  "faGripHorizontal",
  "faShareFromSquare",
  "faShareSquare",
  "faGun",
  "faSquarePhone",
  "faPhoneSquare",
  "faPlus",
  "faAdd",
  "faExpand",
  "faComputer",
  "faXmark",
  "faClose",
  "faMultiply",
  "faRemove",
  "faTimes",
  "faArrowsUpDownLeftRight",
  "faArrows",
  "faChalkboardUser",
  "faChalkboardTeacher",
  "faPesoSign",
  "faBuildingShield",
  "faBaby",
  "faUsersLine",
  "faQuoteLeft",
  "faQuoteLeftAlt",
  "faTractor",
  "faTrashArrowUp",
  "faTrashRestore",
  "faArrowDownUpLock",
  "faLinesLeaning",
  "faRulerCombined",
  "faCopyright",
  "faEquals",
  "faBlender",
  "faTeeth",
  "faShekelSign",
  "faIls",
  "faShekel",
  "faSheqel",
  "faSheqelSign",
  "faMap",
  "faRocket",
  "faPhotoFilm",
  "faPhotoVideo",
  "faFolderMinus",
  "faStore",
  "faArrowTrendUp",
  "faPlugCircleMinus",
  "faSignHanging",
  "faSign",
  "faBezierCurve",
  "faBellSlash",
  "faTablet",
  "faTabletAndroid",
  "faSchoolFlag",
  "faFill",
  "faAngleUp",
  "faDrumstickBite",
  "faHollyBerry",
  "faChevronLeft",
  "faBacteria",
  "faHandLizard",
  "faDisease",
  "faBriefcaseMedical",
  "faGenderless",
  "faChevronRight",
  "faRetweet",
  "faCarRear",
  "faCarAlt",
  "faPumpSoap",
  "faVideoSlash",
  "faBatteryQuarter",
  "faBattery2",
  "faRadio",
  "faBabyCarriage",
  "faCarriageBaby",
  "faTrafficLight",
  "faThermometer",
  "faVrCardboard",
  "faHandMiddleFinger",
  "faPercent",
  "faPercentage",
  "faTruckMoving",
  "faGlassWaterDroplet",
  "faDisplay",
  "faFaceSmile",
  "faSmile",
  "faThumbtack",
  "faThumbTack",
  "faTrophy",
  "faPersonPraying",
  "faPray",
  "faHammer",
  "faHandPeace",
  "faRotate",
  "faSyncAlt",
  "faSpinner",
  "faRobot",
  "faPeace",
  "faGears",
  "faCogs",
  "faWarehouse",
  "faArrowUpRightDots",
  "faSplotch",
  "faFaceGrinHearts",
  "faGrinHearts",
  "faDiceFour",
  "faSimCard",
  "faTransgender",
  "faTransgenderAlt",
  "faMercury",
  "faArrowTurnDown",
  "faLevelDown",
  "faPersonFallingBurst",
  "faAward",
  "faTicketSimple",
  "faTicketAlt",
  "faBuilding",
  "faAnglesLeft",
  "faAngleDoubleLeft",
  "faQrcode",
  "faClockRotateLeft",
  "faHistory",
  "faFaceGrinBeamSweat",
  "faGrinBeamSweat",
  "faFileExport",
  "faArrowRightFromFile",
  "faShield",
  "faShieldBlank",
  "faArrowUpShortWide",
  "faSortAmountUpAlt",
  "faHouseMedical",
  "faGolfBallTee",
  "faGolfBall",
  "faCircleChevronLeft",
  "faChevronCircleLeft",
  "faHouseChimneyWindow",
  "faPenNib",
  "faTentArrowTurnLeft",
  "faTents",
  "faWandMagic",
  "faMagic",
  "faDog",
  "faCarrot",
  "faMoon",
  "faWineGlassEmpty",
  "faWineGlassAlt",
  "faCheese",
  "faYinYang",
  "faMusic",
  "faCodeCommit",
  "faTemperatureLow",
  "faPersonBiking",
  "faBiking",
  "faBroom",
  "faShieldHeart",
  "faGopuram",
  "faEarthOceania",
  "faGlobeOceania",
  "faSquareXmark",
  "faTimesSquare",
  "faXmarkSquare",
  "faHashtag",
  "faUpRightAndDownLeftFromCenter",
  "faExpandAlt",
  "faOilCan",
  "faT",
  "faHippo",
  "faChartColumn",
  "faInfinity",
  "faVialCircleCheck",
  "faPersonArrowDownToLine",
  "faVoicemail",
  "faFan",
  "faPersonWalkingLuggage",
  "faUpDown",
  "faArrowsAltV",
  "faCloudMoonRain",
  "faCalendar",
  "faTrailer",
  "faBahai",
  "faHaykal",
  "faSdCard",
  "faDragon",
  "faShoePrints",
  "faCirclePlus",
  "faPlusCircle",
  "faFaceGrinTongueWink",
  "faGrinTongueWink",
  "faHandHolding",
  "faPlugCircleExclamation",
  "faLinkSlash",
  "faChainBroken",
  "faChainSlash",
  "faUnlink",
  "faClone",
  "faPersonWalkingArrowLoopLeft",
  "faArrowUpZA",
  "faSortAlphaUpAlt",
  "faFireFlameCurved",
  "faFireAlt",
  "faTornado",
  "faFileCirclePlus",
  "faBookQuran",
  "faQuran",
  "faAnchor",
  "faBorderAll",
  "faFaceAngry",
  "faAngry",
  "faCookieBite",
  "faArrowTrendDown",
  "faRss",
  "faFeed",
  "faDrawPolygon",
  "faScaleBalanced",
  "faBalanceScale",
  "faGaugeSimpleHigh",
  "faTachometer",
  "faTachometerFast",
  "faShower",
  "faDesktop",
  "faDesktopAlt",
  "faM",
  "faTableList",
  "faThList",
  "faCommentSms",
  "faSms",
  "faBook",
  "faUserPlus",
  "faCheck",
  "faBatteryThreeQuarters",
  "faBattery4",
  "faHouseCircleCheck",
  "faAngleLeft",
  "faDiagramSuccessor",
  "faTruckArrowRight",
  "faArrowsSplitUpAndLeft",
  "faHandFist",
  "faFistRaised",
  "faCloudMoon",
  "faBriefcase",
  "faPersonFalling",
  "faImagePortrait",
  "faPortrait",
  "faUserTag",
  "faRug",
  "faEarthEurope",
  "faGlobeEurope",
  "faCartFlatbedSuitcase",
  "faLuggageCart",
  "faRectangleXmark",
  "faRectangleTimes",
  "faTimesRectangle",
  "faWindowClose",
  "faBahtSign",
  "faBookOpen",
  "faBookJournalWhills",
  "faJournalWhills",
  "faHandcuffs",
  "faTriangleExclamation",
  "faExclamationTriangle",
  "faWarning",
  "faDatabase",
  "faShare",
  "faArrowTurnRight",
  "faMailForward",
  "faBottleDroplet",
  "faMaskFace",
  "faHillRockslide",
  "faRightLeft",
  "faExchangeAlt",
  "faPaperPlane",
  "faRoadCircleExclamation",
  "faDungeon",
  "faAlignRight",
  "faMoneyBill1Wave",
  "faMoneyBillWaveAlt",
  "faLifeRing",
  "faHands",
  "faSignLanguage",
  "faSigning",
  "faCalendarDay",
  "faWaterLadder",
  "faLadderWater",
  "faSwimmingPool",
  "faArrowsUpDown",
  "faArrowsV",
  "faFaceGrimace",
  "faGrimace",
  "faWheelchairMove",
  "faWheelchairAlt",
  "faTurnDown",
  "faLevelDownAlt",
  "faPersonWalkingArrowRight",
  "faSquareEnvelope",
  "faEnvelopeSquare",
  "faDice",
  "faBowlingBall",
  "faBrain",
  "faBandage",
  "faBandAid",
  "faCalendarMinus",
  "faCircleXmark",
  "faTimesCircle",
  "faXmarkCircle",
  "faGifts",
  "faHotel",
  "faEarthAsia",
  "faGlobeAsia",
  "faIdCardClip",
  "faIdCardAlt",
  "faMagnifyingGlassPlus",
  "faSearchPlus",
  "faThumbsUp",
  "faUserClock",
  "faHandDots",
  "faAllergies",
  "faFileInvoice",
  "faWindowMinimize",
  "faMugSaucer",
  "faCoffee",
  "faBrush",
  "faMask",
  "faMagnifyingGlassMinus",
  "faSearchMinus",
  "faRulerVertical",
  "faUserLarge",
  "faUserAlt",
  "faTrainTram",
  "faUserNurse",
  "faSyringe",
  "faCloudSun",
  "faStopwatch20",
  "faSquareFull",
  "faMagnet",
  "faJar",
  "faNoteSticky",
  "faStickyNote",
  "faBugSlash",
  "faArrowUpFromWaterPump",
  "faBone",
  "faUserInjured",
  "faFaceSadTear",
  "faSadTear",
  "faPlane",
  "faTentArrowsDown",
  "faExclamation",
  "faArrowsSpin",
  "faPrint",
  "faTurkishLiraSign",
  "faTry",
  "faTurkishLira",
  "faDollarSign",
  "faDollar",
  "faUsd",
  "faX",
  "faMagnifyingGlassDollar",
  "faSearchDollar",
  "faUsersGear",
  "faUsersCog",
  "faPersonMilitaryPointing",
  "faBuildingColumns",
  "faBank",
  "faInstitution",
  "faMuseum",
  "faUniversity",
  "faUmbrella",
  "faTrowel",
  "faD",
  "faStapler",
  "faMasksTheater",
  "faTheaterMasks",
  "faKipSign",
  "faHandPointLeft",
  "faHandshakeSimple",
  "faHandshakeAlt",
  "faJetFighter",
  "faFighterJet",
  "faSquareShareNodes",
  "faShareAltSquare",
  "faBarcode",
  "faPlusMinus",
  "faVideo",
  "faVideoCamera",
  "faGraduationCap",
  "faMortarBoard",
  "faHandHoldingMedical",
  "faPersonCircleCheck",
  "faTurnUp",
  "faLevelUpAlt",
];
