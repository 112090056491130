import { Avatar, Checkbox } from "@mui/material";

const ExternalProductItem = ({
  externalProduct,
  clickSelectExternalProduct,
  isSelected,
  isDisabled,
  addToSelectedList,
}) => {
  return (
    <div>
      <div
        className={
          "asset-item cursor-pointer " +
          (isSelected ? "seleted-asset-background" : "")
        }
        onClick={clickSelectExternalProduct}
      >
        <div className="w-100 display-inline-flex">
          <div style={{ margin: "auto" }}>
            <Checkbox
              id={externalProduct.externalProductId}
              checked={isSelected}
              onChange={addToSelectedList}
            />
          </div>
          <div className="w-10" style={{ margin: "auto" }}>
            <Avatar
              src={externalProduct.productPictureUrl}
              style={{ borderRadius: "50%", height: "70px", width: "70px" }}
              className="external-product-image"
            />
          </div>
          <div className="w-80 m-auto">
            <label>{externalProduct.productName}</label>
            <br />
            <span style={{ color: "dimgray" }}>
              {externalProduct.productDescription}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExternalProductItem;
