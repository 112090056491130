import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";

import axios from "axios";

import FontAwesome from "../../../../components/Icons/fontawesome";
import Loader from "../../../../components/Loader";
import { faWarning, faCheckCircle, faTimesCircle } from "@fortawesome/free-solid-svg-icons";

const InstaMojoPaymentStatus = () => {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);
  const [paymentDetails, setPaymentDetails] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();

  var paymentId = searchParams.get("paymentId");
  var payment_for = searchParams.get("payment_for");
  var token = searchParams.get("token");

  useEffect(() => {
    fetchPaymentDetails();
  }, []);

  const fetchPaymentDetails = () => {
    setIsLoading(true);
    axios
      .get(
        "https://playpool.azurewebsites.net/Payment/GetInstaMojoPaymentDetails?instaMojoPaymentId=" +
          paymentId +
          "&payment_for=" +
          payment_for +
          "&token=" +
          token
      )
      .then((response) => {
        setPaymentDetails(response.data);
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <div className="container payment-status-container">
      {isLoading == false ? (
        <div className="status-details">
          {paymentDetails.status == "Credit" && (
            <div>
              <FontAwesome
                icon={faCheckCircle}
                iconSize={"60px"}
                iconcolor={"green"}
              ></FontAwesome>
              <h2>Order completed Successfully!!</h2>
            </div>
          )}
          {paymentDetails.status == "Failure" && (
            <div>
              <FontAwesome
                icon={faTimesCircle}
                iconSize={"60px"}
                iconcolor={"red"}
              ></FontAwesome>
              <h2>Order Failed!!</h2>
            </div>
          )}
          {paymentDetails.status == "Failed" && (
            <div>
              <FontAwesome
                icon={faWarning}
                iconSize={"60px"}
                iconcolor={"red"}
              ></FontAwesome>
              <h2>Order Failed!!</h2>
            </div>
          )}
          <div style={{ marginTop: "40px" }}>
            <h5>
              <b>Order Id</b> : {paymentDetails.paymentId}
            </h5>
            <h5>
              <b>Amount</b> : {paymentDetails.amount} Rs.
            </h5>
          </div>
          <button
            className="theme-btn my-2 p-2 ml-auto"
            onClick={() => {
              navigate("/admin/admin-orders");
            }}
          >
            See Details
          </button>
        </div>
      ) : (
        <Loader isLoading={isLoading}></Loader>
      )}
    </div>
  );
};

export default InstaMojoPaymentStatus;
