import axios from "axios";
import { getAccessToken } from "../hoc/helper";

export const httpGet = async (url, configs, callback = () => {}) => {
  const access_token = await getAccessToken();
  let headers = { ...configs };

  if (access_token) {
    headers = {
      Authorization: `Bearer ${access_token}`,
      ...configs,
    };
  }

  return axios.get(url, { headers });
};

export const httpPost = async (url, payload, configs = {}) => {
  const access_token = await getAccessToken();

  let headers = {};

  if (access_token) {
    headers = {
      Authorization: `Bearer ${access_token}`,
      ...configs,
    };
  }
  return axios.post(url, payload, { headers });
};

export const httpPut = async (url, payload, configs = {}) => {
  const access_token = await getAccessToken();

  let headers = {};

  if (access_token) {
    headers = {
      Authorization: `Bearer ${access_token}`,
      ...configs,
    };
  }
  return axios.put(url, payload, { headers });
};

export const httpPatch = async (url, payload, configs = {}) => {
  const access_token = await getAccessToken();

  let headers = {};

  if (access_token) {
    headers = {
      Authorization: `Bearer ${access_token}`,
      ...configs,
    };
  }
  return axios.patch(url, payload, { headers });
};

export const httpDelete = async (url, configs = {}) => {
  const access_token = await getAccessToken();

  let headers = {};

  if (access_token) {
    headers = {
      Authorization: `Bearer ${access_token}`,
      ...configs,
    };
  }

  return axios.delete(url, { headers });
};
