import * as React from "react";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import { Avatar, Chip, Typography } from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import { IconButton } from "@mui/material";
import { Menu } from "@mui/material";
import { MenuItem } from "@mui/material";
import FontAwesome from "../../../components/Icons/fontawesome";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import OfflineBoltIcon from "@mui/icons-material/OfflineBolt";
import { Pagination } from "@mui/material";
import Controls from "../../../components/Controls/Controls";
import TableSkeleton from "../../../components/Skeletons/table-skeleton.component";
import ModalPopup from "../../../components/ModalPopup/modalpopup.component";

import { URLS } from "../../../configs/api_urls";
import { httpGet, httpDelete } from "../../../utility/request";
import { fireEvent } from "../../../modules/Events/eventSlice";

import moment from "moment-timezone";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "gameSessionId",
    numeric: false,
    disablePadding: true,
    label: "Session Id",
  },
  {
    id: "originalStartTime",
    numeric: false,
    disablePadding: false,
    label: "Date",
  },
  {
    id: "asset",
    numeric: false,
    disablePadding: false,
    label: "Asset",
  },
  {
    id: "originalStartTime",
    numeric: false,
    disablePadding: false,
    label: "Start time",
  },
  {
    id: "duration",
    numeric: false,
    disablePadding: false,
    label: "Duration",
  },
  {
    id: "numberOfPlayers",
    numeric: false,
    disablePadding: false,
    label: "No. of Players",
  },
  {
    id: "comment",
    numeric: false,
    disablePadding: false,
    label: "Comment",
  },
  {
    id: "isInProgress",
    numeric: false,
    disablePadding: false,
    label: "In progress",
  },
//   {
//     id: "action",
//     numeric: true,
//     disablePadding: false,
//     label: "Action",
//   },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all payments",
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default function AllGameSessions() {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const [gameSessionData, setGameSessionData] = useState({});
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [selectedForRemove, setSelectedForRemove] = React.useState(null);
  const [page, setPage] = React.useState(1);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const fetchData = {
      pageNumber: page,
      pageSize: rowsPerPage,
  };

  const fetchGameSessions = async () => {
    setIsLoading(true);
    const { data } = await httpGet(
      URLS.GAME_SESSIONS.GET_GAME_SESSIONS.replace(
        "#PAGE_SIZE#",
        rowsPerPage
      ).replace("#PAGE_NUMBER#", page)
    );
    setGameSessionData(data);
    setRows(data.items);
    setIsLoading(false);
  };

  const event = useSelector((onEvent) => {
    var eventName = onEvent.events.eventName;
    if (eventName === "refresh-payments-list") {
      fetchGameSessions();
      dispatch(fireEvent({ eventName: "" }));
    }
    //reset event listner
  });

  const getTimeDuration = (startTime, endTime, isInProgress) => {
    if (!isInProgress) {
      var totalmins = moment(endTime).diff(moment(startTime), "minutes");
      var hours = Math.floor(totalmins / 60);
      var minutes = totalmins % 60;
      return hours + " hr " + minutes + " min";
    } else {
        return "Ongoing"
    }
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.phonepePaymentId);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, gameSessionId) => {
    const selectedIndex = selected.indexOf(gameSessionId);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, gameSessionId);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (rows) => {
    setRowsPerPage(parseInt(rows, 10));
    setPage(1);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (phonepePaymentId) =>
    selected.indexOf(phonepePaymentId) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = 1;

  const visibleRows = React.useMemo(
    () =>
      stableSort(rows, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [order, orderBy, page, rowsPerPage]
  );

  const handleMenuClick = (event) => {
    setAnchorEl(event);
    CheckOpen(event.split("_")[1]);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const CheckOpen = (phonepePaymentId) => {
    if (anchorEl !== undefined && anchorEl !== null) {
      if (anchorEl.split("_")[1] == phonepePaymentId) return true;
    }
    return false;
  };

  useEffect(() => {
    fetchGameSessions();
  }, [page, rowsPerPage]);

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        {isLoading && (
          <TableSkeleton
            pageNumber={page}
            pageSize={rowsPerPage}
          ></TableSkeleton>
        )}
        {!isLoading && (
          <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={dense ? "small" : "medium"}
            >
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
              />
              <TableBody>
                {rows.map((row, index) => {
                  const isItemSelected = isSelected(row.gameSessionId);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      onClick={(event) => handleClick(event, row.gameSessionId)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.gameSessionId}
                      selected={isItemSelected}
                      sx={{ cursor: "pointer" }}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          inputProps={{
                            "aria-labelledby": labelId,
                          }}
                        />
                      </TableCell>
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                      >
                        {"#" + row.gameSessionId}
                      </TableCell>
                      <TableCell align="left">
                        {moment
                          .utc(row.originalStartTime)
                          .local()
                          .format("MMM DD, yyyy")}
                      </TableCell>
                      <TableCell align="left">
                        <Typography>{row.asset.assetName}</Typography>
                      </TableCell>
                      <TableCell align="left" padding="none">
                        {moment
                          .utc(row.originalStartTime)
                          .local()
                          .format("hh:mm A")}
                      </TableCell>
                      <TableCell align="left">
                        <Typography color={row.isInProgress ? "green" : ""}>
                          {getTimeDuration(
                            row.startTime,
                            row.endTime,
                            row.isInProgress
                          )}
                        </Typography>
                      </TableCell>
                      <TableCell align="left">{row.numberOfPlayers}</TableCell>
                      <TableCell align="left" sx={{ maxWidth: "300px" }}>
                        {row.comment}
                      </TableCell>
                      <TableCell align="left">
                        {row.isInProgress === true ? "Yes" : "No"}
                      </TableCell>
                      {/* <TableCell align="right">
                        <IconButton
                          id={"button_" + row.gameSessionId}
                          aria-label={"button_" + row.gameSessionId}
                          aria-controls={
                            CheckOpen(row.gameSessionId)
                              ? "menu_" + row.gameSessionId
                              : undefined
                          }
                          aria-haspopup="true"
                          aria-expanded={
                            CheckOpen(row.gameSessionId) ? "true" : undefined
                          }
                          onClick={(e) => {
                            handleMenuClick(e.currentTarget.ariaLabel);
                            e.stopPropagation();
                          }}
                        >
                          <FontAwesome
                            icon={faEllipsisV}
                            iconSize={"20px"}
                            className={"info-icon-margin"}
                          ></FontAwesome>
                        </IconButton>
                        <Menu
                          aria-label={"menu_" + row.gameSessionId}
                          id={"menu_" + row.gameSessionId}
                          anchorEl={document.getElementById(
                            "button_" + row.gameSessionId
                          )}
                          open={CheckOpen(row.gameSessionId)}
                          onClose={handleMenuClose}
                          sx={{ boxShadow: 10, color: "gray" }}
                          MenuListProps={{
                            "aria-labelledby": "button_" + row.gameSessionId,
                          }}
                        >
                          <MenuItem
                            onClick={(e) => {
                              handleMenuClose(e);
                              setSelectedForRemove(row.gameSessionId);
                            }}
                          >
                            Delete
                          </MenuItem>
                        </Menu>
                      </TableCell> */}
                    </TableRow>
                  );
                })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: (dense ? 33 : 53) * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        {!isLoading && (
          <div className="display-flex" style={{ padding: "10px" }}>
            <Pagination
              count={gameSessionData.totalPages}
              page={page}
              variant="outlined"
              shape="rounded"
              showFirstButton={gameSessionData.hasPreviousPage}
              showLastButton={gameSessionData.hasNextPage}
              color="secondary"
              style={{ marginLeft: "auto" }}
              onChange={(event, value) => {
                handleChangePage(value);
              }}
            />
            <Controls.SelectControl
              name="pageSize"
              style={{ width: "100%", marginLeft: "20px" }}
              options={[
                { id: 5, title: "5" },
                { id: 10, title: "10" },
                { id: 50, title: "50" },
              ]}
              value={rowsPerPage}
              onChange={(e) => {
                handleChangeRowsPerPage(Number(e.target.value));
              }}
            ></Controls.SelectControl>
          </div>
        )}
      </Paper>
    </Box>
  );
}
