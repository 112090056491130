import React from "react";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import { Alert, Snackbar } from "@mui/material";

import { selectAppAlert } from "../../modules/App/selectors";

import "./notify.scss";

const Notify = () => {
  const [open, setOpen] = useState(false);
  const alertData = useSelector(selectAppAlert);

  useEffect(() => {
    if(alertData !== null || alertData !== {})
    {setOpen(true)}
  }, [alertData]);

  return (
    <div className="notify">
      {open && (
        <Snackbar
          open={open}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          autoHideDuration={5000}
          onClose={() => setOpen(false)}
        >
          <Alert
            onClose={null}
            severity={alertData.type}
            sx={{ width: "100%" }}
            variant="filled"
          >
            {alertData.message}
          </Alert>
        </Snackbar>
      )}
    </div>
  );
};

export default Notify;
