import { useState } from "react";

import ButtonControl from "../../components/Controls/ButtonControl";
import TaskList from "./TaskList/task-list.component";
import GuideTaskOperations from "./GuideTaskOperations/guide-task-operations.component";
import AddEditTask from "./AddEditTask/addedit-task.component";

const TaskDashboard = () => {
  const [selectedTask, setSelectedTask] = useState(null);
  const [selectedView, setSelectedView] = useState("");
  const [selectedTasksList, setSelectedTasksList] = useState([]);

  var newTask = {
    taskId: 0,
    taskTitle: "",
    taskDescription: "",
    isActive: true,
  };

  const handleTaskSelected = (task) => {
    setSelectedTask(task);
    setSelectedView("addeditview");
    setSelectedTasksList([task.taskId]);
  };

  const cancelClick = () => {
    setSelectedView("");
    setSelectedTasksList([]);
  }

  return (
    <div>
      <div style={{ float: "right" }}>
        <ButtonControl
          text={"Create New Task"}
          style={{ width: "200px", margin: "0px 5px" }}
          onClick={() => handleTaskSelected(newTask)}
        ></ButtonControl>
      </div>
      <div className="display-inline-flex w-100">
        <div className="items-list-container w-50">
          <div className="items-list">
            <TaskList
              selectedTaskList={selectedTasksList}
              clickSelectTask={(task) => {
                handleTaskSelected(task);
              }}
              cancelClick={cancelClick}
            ></TaskList>
          </div>
        </div>
        <div className="items-edit-container  w-50">
          {selectedView == "" && <GuideTaskOperations></GuideTaskOperations>}
          {selectedView == "addeditview" && (
            <AddEditTask
              selectedTask={selectedTask}
              cancelClick={cancelClick}
            ></AddEditTask>
          )}
        </div>
      </div>
    </div>
  );
};

export default TaskDashboard;
