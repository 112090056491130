import AddEditCoach from "./AddEditCoache/addedit-coach.component";
import CoachList from "./CoachList/coach-list.component";
import { useState } from "react";

import CoachNavigation from "./CoachNavigation/coach-navigation.component";
import GuideCoachOperations from "./GuideCoachOperation/guide-coach-opertions.component";

var newCoach = {
  coachMasterId: 0,
  coachDesignation: "",
  coachBio: "",
  instagramHandle: "",
  twitterHandle: "",
  facebookHandle: "",
  linkedInHandle: "",
  experience: "",
  user: {
    userId: "",
    userName: "",
    emailId: "",
    primaryPhoneNumber: "",
    secondaryPhoneNumber: "",
    gender: "Male",
    firstName: "",
    middleName: "",
    lastName: "",
    profilePictureUrl: "",
    dateOfBirth: new Date(),
  },
};

const CoachesDashboard = () => {
  const [selectedCoach, setSelectedCoach] = useState(null);
  const [selectedView, setSelectedView] = useState("");
  const [selectedCoachList, setSelectedCoachList] = useState([]);

  const handleCoachSelected = (coach) => {
    setSelectedCoach(coach);
    setSelectedView("addeditview");
    setSelectedCoachList([coach.coachMasterId]);
  };

  return (
    <div>
      <div>
        <CoachNavigation
          createNewCoachClick={() => handleCoachSelected(newCoach)}
        ></CoachNavigation>
      </div>
      <div>
        <div className="display-inline-flex w-100">
          <div className="items-list-container w-50">
            <div className="items-list">
              <CoachList
                selectedCoachList={selectedCoachList}
                clickSelectCoach={(coach) => {
                  handleCoachSelected(coach);
                }}
              ></CoachList>
            </div>
          </div>
          <div className="items-edit-container  w-50">
            {selectedView == "" && (
              <GuideCoachOperations></GuideCoachOperations>
            )}
            {selectedView == "addeditview" && (
              <AddEditCoach
                selectedCoach={selectedCoach}
                cancelClick={() => {
                  setSelectedView("");
                  setSelectedCoachList([]);
                }}
              ></AddEditCoach>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CoachesDashboard;
