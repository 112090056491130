import {
  RESET_ASSET_CREATION_DATA,
  SET_ASSET_CREATION_DETAILS,
  SET_ASSET_CREATION_PRICING,
  SET_ASSET_CREATION_SETTINGS,
  SET_ASSET_CREATION_SLOTS,
  SET_ASSET_LIST_BY_INSTANCE,
  SET_ASSET_OFFERS_BY_INSTANCE,
  SET_ASSET_PRICING_TYPE,
  SET_ASSET_TYPES_BY_INSTANCE,
  SET_ASSET_TYPES_LIST_BY_INSTANCE,
  SET_ASSET_UPDATE_DATA,
  SET_INSTANCE_STANDARD_GAMES,
  SET_IS_REQUESTING,
  SET_SLOT_UPDATE_METHOD,
} from "./constants";

import moment from "moment";

const DEFAULT_ASSET_CREATION_DATA = {
  assetDetails: {
    assetType: "",
    name: "",
    standardGame: "",
    lastService: "2020-10-01",
    Desciption: "",
    imageData: "",
  },
  assetSlots: {
    times: {
      startTime: "",
      endTime: "",
      duration: 45,
    },
    slots: [...Array(10)],
  },
  assetPricing: {
    fixed: { price: 0, duration: 0 },
    interval: [{ price: 0, duration: 0 }],
  },
  assetSettings: {
    allowMultipleBooking: false,
    includingBookingCharges: false,
    maximumBookingsAllowed: 0,
    minimumBookingsAllowed: 0,
    bookingCharges: 0,
    offers: [],
  },
  assetPricingType: "fixed",
  slotUpdateOption: 1,
};

const INITIAL_ASSETS = {
  assetTypesByInstance: [],
  assetTypesList: [],
  standardGamesByInstance: [],
  assetCreationData: { ...DEFAULT_ASSET_CREATION_DATA },
  assetOffersByInstance: [],
  instanceAssets: [],
  isRequesting: false,
  assetUpdateData: {},
};

function AssetReducer(state = { ...INITIAL_ASSETS }, action) {
  switch (action.type) {
    case SET_ASSET_TYPES_BY_INSTANCE:
      return { ...state, assetTypesByInstance: action.data };

    case SET_INSTANCE_STANDARD_GAMES:
      return { ...state, standardGamesByInstance: action.data };

    case SET_ASSET_CREATION_DETAILS:
      return {
        ...state,
        assetCreationData: {
          ...state.assetCreationData,
          assetDetails: action.data,
        },
      };

    case SET_ASSET_CREATION_SLOTS:
      return {
        ...state,
        assetCreationData: {
          ...state.assetCreationData,
          assetSlots: action.data,
        },
      };

    case SET_ASSET_CREATION_PRICING:
      return {
        ...state,
        assetCreationData: {
          ...state.assetCreationData,
          assetPricing: action.data,
        },
      };

    case SET_ASSET_CREATION_SETTINGS:
      return {
        ...state,
        assetCreationData: {
          ...state.assetCreationData,
          assetSettings: action.data,
        },
      };

    case SET_ASSET_PRICING_TYPE:
      return {
        ...state,
        assetCreationData: {
          ...state.assetCreationData,
          assetPricingType: action.data,
        },
      };

    case SET_ASSET_OFFERS_BY_INSTANCE:
      return {
        ...state,
        assetOffersByInstance: action.data,
      };

    case RESET_ASSET_CREATION_DATA:
      return {
        ...state,
        assetCreationData: { ...DEFAULT_ASSET_CREATION_DATA },
      };

    case SET_ASSET_LIST_BY_INSTANCE:
      return {
        ...state,
        instanceAssets: action.data,
      };

    case SET_ASSET_TYPES_LIST_BY_INSTANCE:
      return {
        ...state,
        assetTypesList: action.data,
      };

    case SET_IS_REQUESTING:
      return {
        ...state,
        isRequesting: action.data,
      };

    case SET_ASSET_UPDATE_DATA:
      return {
        ...state,
        assetCreationData: action.data,
      };

    case SET_SLOT_UPDATE_METHOD:
      return {
        ...state,
        assetCreationData: {
          ...state.assetCreationData,
          slotUpdateOption: action.data,
        },
      };

    default:
      return state;
  }
}

export default AssetReducer;
