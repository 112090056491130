export const ADD_ALERT = "alert/add-alert"

const APP = "MAIN_APP";

export const MAIN_APP_ACTION_CONSTANTS = {
  SET_APPLICATION_INFO: `${APP}/set-application-info`,
  SET_APPLICATION_LICENSE: `${APP}/set-application-license`,
  SET_APPLICATION_LICENSE_CONSUMPTION: `${APP}/set-application-license-consumption`,
  MAIN_APP_REQUEST_START: `${APP}/set-is-requesting`,
  MAIN_APP_REQUEST_SUCCESS: `${APP}/request-success`,
  MAIN_APP_REQUEST_FAILURE: `${APP}/request-failure`,
};
