import { useState, useEffect } from "react";
import React from "react";
import { useDispatch } from "react-redux";

import {
  Box,
  Link,
  Typography,
  Accordion,
  AccordionSummary,
  Badge,
  AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Controls from "../../../../../components/Controls/Controls";
import FontAwesome from "../../../../../components/Icons/fontawesome";
import { faFileArchive } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMinus,
  faTrash,
  faCircleInfo,
} from "@fortawesome/free-solid-svg-icons";
import ModalPopup from "../../../../../components/ModalPopup/modalpopup.component";

import { URLS } from "../../../../../configs/api_urls";
import { httpPost, httpPut } from "../../../../../utility/request";
import { fireEvent } from "../../../../../modules/Events/eventSlice";
import { setIsRenderedState } from "../../../../../modules/ModalPopup/modalPopupSlice";

const planTypeItems = [
  { id: 1, title: "Yearly" },
  { id: 2, title: "Monthly" },
  { id: 3, title: "Weekly" },
];

const EditLicense = React.forwardRef(({ selectedLicense, closeEdit }, ref) => {
  const dispatch = useDispatch();

  //local state
  const [selectedLicenseItem, setSelectedLicenseItem] =
    useState(selectedLicense);
  const [newItemId, setNewItemId] = useState(-1);
  const [isSetDefaultPopupOpen, setIsSetDefaultPopupOpen] = useState(false);
  const [expendedAccordian, setExpendedAccordian] = useState("effective");

  //API handlers
  const updateLicensePlan = async () => {
    debugger;
    if (selectedLicense.licenseId === selectedLicenseItem.licenseId) {
      // API CAll here
      await httpPut(
        URLS.SUPER_ADMIN.LICENSE.UPDATE_LICENSE,
        selectedLicenseItem
      )
        .then((response) => {
          if (response.data == true) {
            dispatch(fireEvent({ eventName: "refresh-licenses-list" }));
            closeEdit();
          }
        })
        .catch((error) => {
          //  setFormSubmitError(
          //    error.response.data.Message || "Some error occuered!"
          //  );
        });
      // close after saving
    }
  };

  const createLicensePlan = async () => {
    if (selectedLicense.licenseId === selectedLicenseItem.licenseId) {
      // API CAll here
      selectedLicenseItem.planFeatures.map((p) => (p.licensePlanFeatureId = 0));
      selectedLicenseItem.planPricings.map((p) => (p.licensePlanPricingId = 0));
      selectedLicenseItem.isActive = true;
      await httpPost(
        URLS.SUPER_ADMIN.LICENSE.CREATE_LICENSE,
        selectedLicenseItem
      )
        .then((response) => {
          if (response.data == true) {
            dispatch(fireEvent({ eventName: "refresh-licenses-list" }));
          }
        })
        .catch((error) => {
          //  setFormSubmitError(
          //    error.response.data.Message || "Some error occuered!"
          //  );
        });
      // close after saving
    }
  };

  const handelSetDefaultClick = async () => {
    if (selectedLicense.licenseId === selectedLicenseItem.licenseId) {
      // API CAll here
      await httpPut(
        URLS.SUPER_ADMIN.LICENSE.SET_LICENSE_DEFAULT.replace(
          "#ID#",
          selectedLicenseItem.licenseId
        )
      )
        .then((response) => {
          if (response.data == true) {
            dispatch(setIsRenderedState(false));
            dispatch(fireEvent({ eventName: "refresh-licenses-list" }));
            setIsSetDefaultPopupOpen(false);
            setSelectedLicenseItem({
              ...selectedLicenseItem,
              isDefault: true,
            });
          }
        })
        .catch((error) => {
          //  setFormSubmitError(
          //    error.response.data.Message || "Some error occuered!"
          //  );
        });
      // close after saving
    }
  };

  React.useImperativeHandle(ref, () => ({
    saveLicensePlan() {
      if (selectedLicense.licenseId === 0) {
        createLicensePlan();
      } else {
        updateLicensePlan();
      }
    },
  }));

  // Change handlers
  const handleFeatureChange = (value, featureId) => {
    var planFeaturesVal = selectedLicenseItem.planFeatures.map((feature) =>
      feature.licensePlanFeatureId === featureId
        ? { ...feature, licensePlanFeatureName: value }
        : feature
    );
    setSelectedLicenseItem({
      ...selectedLicenseItem,
      planFeatures: planFeaturesVal,
    });
  };

  const removeFeature = (featureId) => {
    var planPricingsVal = selectedLicenseItem.planFeatures.filter(
      (feature) => feature.licensePlanFeatureId != featureId
    );
    setSelectedLicenseItem({
      ...selectedLicenseItem,
      planFeatures: planPricingsVal,
    });
  };

  const addFeature = () => {
    var newFeature = {
      licensePlanFeatureId: newItemId,
      licensePlanFeatureName: "",
    };
    var planFeaturessVal = [
      ...selectedLicenseItem.planFeatures,
      { ...newFeature },
    ];
    setSelectedLicenseItem({
      ...selectedLicenseItem,
      planFeatures: planFeaturessVal,
    });
    setNewItemId(newItemId - 1);
  };

  const handlePricingTypeChange = (pricingType, licensePlanPricingId) => {
    var planPricingsVal = selectedLicenseItem.planPricings.map((pricing) =>
      pricing.licensePlanPricingId === licensePlanPricingId
        ? { ...pricing, licensePlanPricingType: pricingType }
        : pricing
    );
    setSelectedLicenseItem({
      ...selectedLicenseItem,
      planPricings: planPricingsVal,
    });
  };

  const handlePricingDurationChange = (
    pricingDuration,
    licensePlanPricingId
  ) => {
    var planPricingsVal = selectedLicenseItem.planPricings.map((pricing) =>
      pricing.licensePlanPricingId === licensePlanPricingId
        ? { ...pricing, planDuration: pricingDuration }
        : pricing
    );
    setSelectedLicenseItem({
      ...selectedLicenseItem,
      planPricings: planPricingsVal,
    });
  };

  const handlePricingPriceChange = (price, licensePlanPricingId) => {
    var planPricingsVal = selectedLicenseItem.planPricings.map((pricing) =>
      pricing.licensePlanPricingId === licensePlanPricingId
        ? { ...pricing, planPrice: price }
        : pricing
    );
    setSelectedLicenseItem({
      ...selectedLicenseItem,
      planPricings: planPricingsVal,
    });
  };

  const removePricing = (licensePlanPricingId) => {
    var planPricingsVal = selectedLicenseItem.planPricings.filter(
      (pricing) => pricing.licensePlanPricingId != licensePlanPricingId
    );
    setSelectedLicenseItem({
      ...selectedLicenseItem,
      planPricings: planPricingsVal,
    });
  };

  const archivePricing = (event) => {
    debugger;
    var pricingId = event.currentTarget.id.split("_")[1];
    var planPricingsVal = selectedLicenseItem.planPricings.map((pricing) =>
      pricing.licensePlanPricingId == pricingId
        ? { ...pricing, isActive: false }
        : pricing
    );
    setSelectedLicenseItem({
      ...selectedLicenseItem,
      planPricings: planPricingsVal,
    });
  };

  const handleExpendedAccordianChange = (panel) => {
    setExpendedAccordian(panel);
  };

  const addPricing = () => {
    var newPricing = {
      licensePlanPricingId: newItemId,
      licensePlanPricingType: 1,
      planDuration: 0,
      planPrice: 0,
      isNew: true,
      isActive: true,
    };
    var planPricingsVal = [
      ...selectedLicenseItem.planPricings,
      { ...newPricing },
    ];
    setSelectedLicenseItem({
      ...selectedLicenseItem,
      planPricings: planPricingsVal,
    });
    setNewItemId(newItemId - 1);
  };

  // Hook
  useEffect(() => {
    setSelectedLicenseItem(selectedLicense);
    setExpendedAccordian("effective");
  }, [selectedLicense]);

  return (
    <Box>
      {isSetDefaultPopupOpen && (
        <ModalPopup
          heading={"Are you sure that you Make this license default?"}
          subHeadhing={
            "Making this license as default will set this licese default for new onboarded users and fallback for used for which any other active licese has expired"
          }
          isConfirmButton={true}
          confirmButtonText={"Yes"}
          onConfirmClick={handelSetDefaultClick}
          isCancelButton={true}
          cancelButtontext={"No"}
          onCancelClick={() => dispatch(setIsRenderedState(false))}
        ></ModalPopup>
      )}
      <Box>
        {selectedLicenseItem.isDefault && (
          <Box className="display-flex alert alert-success">
            <FontAwesome
              icon={faCircleInfo}
              iconSize={"20px"}
              className={"info-icon-margin"}
            ></FontAwesome>
            <strong>
              This is a default license plan. In case of any admin's license
              plan expires thay will be automatically subscribed to this plan
            </strong>
          </Box>
        )}
        {selectedLicenseItem.isDefault &&
          selectedLicenseItem.planPricings.filter((p) => p.isActive == true)
            .length > 1 && (
            <Box className="display-flex alert alert-danger">
              <FontAwesome
                icon={faCircleInfo}
                iconSize={"20px"}
                className={"info-icon-margin"}
              ></FontAwesome>
              <strong>
                You have more than 1 active pricings under deafult license. Make
                Sure you have only one pricing uder default license so that
                System can update it with deault subscription expiry date
              </strong>
            </Box>
          )}
        {!selectedLicenseItem.isDefault &&
          selectedLicenseItem.licenseId > 0 && (
            <Box className="display-flex m-2">
              <Controls.CheckboxControl
                value={selectedLicenseItem.isDefault}
                onChange={() => {
                  dispatch(setIsRenderedState(true));
                  setIsSetDefaultPopupOpen(true);
                }}
              />
              <Typography
                style={{ fontWeight: "bold", fontStyle: "italic" }}
                className="field-title"
              >
                Make this plan the default license plan. In case of any admin's
                license plan expires thay will be automatically subscribed to
                this plan
              </Typography>
            </Box>
          )}
      </Box>
      <Box>
        <label className="field-title">License Name</label>
        <Controls.InputControl
          name="licenseName"
          other={{ placeholder: "Enter title of the license" }}
          style={{ width: "100%", marginTop: "5px" }}
          onChange={(e) =>
            setSelectedLicenseItem({
              ...selectedLicenseItem,
              licenseName: e.target.value,
            })
          }
          value={selectedLicenseItem.licenseName}
          //error={validation.planName}
        ></Controls.InputControl>
      </Box>
      <Box className="row mt-10">
        <Box className="col-md-6">
          <label className="field-title">No. of Centers</label>
          <Controls.InputControl
            name="maximumInstancesAllowed"
            other={{
              type: "number",
              placeholder: "Enter number of centers allowed",
            }}
            minNumber={0}
            style={{ width: "100%", marginTop: "5px" }}
            onChange={(e) =>
              setSelectedLicenseItem({
                ...selectedLicenseItem,
                maximumInstancesAllowed: e.target.value,
              })
            }
            value={selectedLicenseItem.maximumInstancesAllowed}
            //error={validation.planName}
          ></Controls.InputControl>
        </Box>
        <Box className="col-md-6">
          <label className="field-title">No. of Sub Admins</label>
          <Controls.InputControl
            name="maximumAdminsAllowed"
            other={{
              type: "number",
              placeholder: "Enter number of admins",
            }}
            minNumber={0}
            style={{ width: "100%", marginTop: "5px" }}
            onChange={(e) =>
              setSelectedLicenseItem({
                ...selectedLicenseItem,
                maximumAdminsAllowed: e.target.value,
              })
            }
            value={selectedLicenseItem.maximumAdminsAllowed}
            //error={validation.planName}
          ></Controls.InputControl>
        </Box>
      </Box>
      <Box className="row mt-10">
        <Box className="col-md-6">
          <label className="field-title">No. of Players</label>
          <Controls.InputControl
            name="maximumAllowedPlayers"
            other={{
              type: "number",
              placeholder: "Enter number of centers allowed",
            }}
            minNumber={0}
            style={{ width: "100%", marginTop: "5px" }}
            onChange={(e) =>
              setSelectedLicenseItem({
                ...selectedLicenseItem,
                maximumAllowedPlayers: e.target.value,
              })
            }
            value={selectedLicenseItem.maximumAllowedPlayers}
            //error={validation.planName}
          ></Controls.InputControl>
        </Box>
        <Box className="col-md-6">
          <label className="field-title">No. of Storage years</label>
          <Controls.InputControl
            name="numberOfYearsDataStorage"
            other={{
              type: "number",
              placeholder: "Enter number of admins",
            }}
            minNumber={0}
            style={{ width: "100%", marginTop: "5px" }}
            onChange={(e) =>
              setSelectedLicenseItem({
                ...selectedLicenseItem,
                numberOfYearsDataStorage: e.target.value,
              })
            }
            value={selectedLicenseItem.numberOfYearsDataStorage}
            //error={validation.planName}
          ></Controls.InputControl>
        </Box>
      </Box>
      <Box className="row mt-10">
        <Box className="col-md-6">
          <label className="field-title">Booking Platform charges</label>
          <Controls.InputControl
            name="bookingPlatformChargesPercentage"
            other={{
              type: "number",
              placeholder: "Enter platform charges",
            }}
            minNumber={0}
            style={{ width: "100%", marginTop: "5px" }}
            onChange={(e) =>
              setSelectedLicenseItem({
                ...selectedLicenseItem,
                bookingPlatformChargesPercentage: e.target.value,
              })
            }
            value={selectedLicenseItem.bookingPlatformChargesPercentage}
            //error={validation.planName}
          ></Controls.InputControl>
        </Box>
        <Box className="col-md-6">
          <label className="field-title">Subsciption Platform Charges</label>
          <Controls.InputControl
            name="subscriptionPlatformChargesPercentage"
            other={{
              type: "number",
              placeholder: "Enter number of admins",
            }}
            minNumber={0}
            style={{ width: "100%", marginTop: "5px" }}
            onChange={(e) =>
              setSelectedLicenseItem({
                ...selectedLicenseItem,
                subscriptionPlatformChargesPercentage: e.target.value,
              })
            }
            value={selectedLicenseItem.subscriptionPlatformChargesPercentage}
            //error={validation.planName}
          ></Controls.InputControl>
        </Box>
      </Box>
      <Box className="row mt-10">
        <Box className="col-md-6">
          <label className="field-title">Payment Gateway charges</label>
          <Controls.InputControl
            name="paymentGatewayChargesPercentage"
            other={{
              type: "number",
              placeholder: "Enter platform charges",
            }}
            minNumber={0}
            style={{ width: "100%", marginTop: "5px" }}
            onChange={(e) =>
              setSelectedLicenseItem({
                ...selectedLicenseItem,
                paymentGatewayChargesPercentage: e.target.value,
              })
            }
            value={selectedLicenseItem.paymentGatewayChargesPercentage}
            //error={validation.planName}
          ></Controls.InputControl>
        </Box>
      </Box>
      <Box className="mt-20">
        <label className="field-title">
          Plan features
          <Link onClick={addFeature}> (Add new Feature)</Link>
        </label>
        {selectedLicenseItem.planFeatures.map((feature) => (
          <Box className="mt-10 row" key={feature.licensePlanFeatureId}>
            <Box className="col-md-10">
              <Controls.InputControl
                //name="paymentGatewayChargesPercentage"
                other={{
                  placeholder: "Enter Feature name",
                }}
                style={{ width: "100%", marginTop: "5px" }}
                onChange={(e) =>
                  handleFeatureChange(
                    e.target.value,
                    feature.licensePlanFeatureId
                  )
                }
                value={feature.licensePlanFeatureName}
                //error={validation.planName}
              ></Controls.InputControl>
            </Box>
            <Box className="col-md-2">
              <Controls.ButtonControl
                onClick={(e) => {
                  removeFeature(feature.licensePlanFeatureId);
                }}
                style={{
                  height: "80%",
                  background: "lightgray",
                  marginTop: "5px",
                }}
                innercomponent={<FontAwesome icon={faMinus}></FontAwesome>}
              ></Controls.ButtonControl>
            </Box>
          </Box>
        ))}
      </Box>
      <Box className="mt-20">
        <label className="field-title">
          Plan Pricing
          <Link onClick={addPricing}> (Add new pricing)</Link>
        </label>
        <Box>
          {selectedLicenseItem.planPricings &&
            selectedLicenseItem.planPricings
              .filter((p) => p.isNew == true)
              .map((pricing) => {
                return (
                  <div
                    key={pricing.licensePlanPricingId}
                    className="display-block mt-10 w-100"
                  >
                    <div className="row">
                      <div className="col-md-3">
                        <Controls.SelectControl
                          name="plantype"
                          style={{ width: "130px" }}
                          options={planTypeItems}
                          value={pricing.licensePlanPricingType}
                          onChange={(e) =>
                            handlePricingTypeChange(
                              e.target.value,
                              pricing.licensePlanPricingId
                            )
                          }
                        ></Controls.SelectControl>
                      </div>
                      <div className="col-md-3">
                        <Controls.InputControl
                          name="title"
                          other={{
                            type: "number",
                            placeholder: "Duration",
                          }}
                          minNumber={0}
                          style={{ width: "100%" }}
                          onChange={(e) =>
                            handlePricingDurationChange(
                              e.target.value,
                              pricing.licensePlanPricingId
                            )
                          }
                          value={pricing.planDuration}
                          // error={validation.bankName}
                        ></Controls.InputControl>
                      </div>
                      <div className="col-md-3">
                        <Controls.InputControl
                          name="title"
                          other={{
                            type: "number",
                            placeholder: "Price",
                          }}
                          minNumber={0}
                          style={{ width: "100%" }}
                          onChange={(e) =>
                            handlePricingPriceChange(
                              e.target.value,
                              pricing.licensePlanPricingId
                            )
                          }
                          value={pricing.planPrice}
                          // error={validation.bankName}
                        ></Controls.InputControl>
                      </div>
                      <div className="col-md-3">
                        <Controls.ButtonControl
                          id={"archivePricing_" + pricing.licensePlanPricingId}
                          style={{ background: "gray" }}
                          innercomponent={
                            <FontAwesomeIcon icon={faTrash}></FontAwesomeIcon>
                          }
                          onClick={(e) => removePricing(e)}
                        ></Controls.ButtonControl>
                      </div>
                    </div>
                  </div>
                );
              })}
        </Box>
        {selectedLicenseItem.planPricings && (
          <Accordion
            id="effective"
            expanded={expendedAccordian === "effective"}
            sx={{ marginTop: "20px" }}
            onChange={() => handleExpendedAccordianChange("effective")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>Effective Pricings</Typography>
              <Badge color="success" variant="dot"></Badge>
            </AccordionSummary>
            <AccordionDetails>
              {selectedLicenseItem.planPricings &&
                selectedLicenseItem.planPricings
                  .filter((p) => p.isActive == true && p.isNew == undefined)
                  .map((pricing) => {
                    return (
                      <div
                        key={pricing.licensePlanPricingId}
                        className="display-block mt-10 w-100"
                      >
                        <div className="row">
                          <div className="col-md-3">
                            <Controls.SelectControl
                              name="plantype"
                              style={{ width: "100%" }}
                              options={planTypeItems}
                              value={pricing.licensePlanPricingType}
                              disabled={true}
                              onChange={(e) =>
                                handlePricingTypeChange(
                                  e.target.value,
                                  pricing.licensePlanPricingId
                                )
                              }
                            ></Controls.SelectControl>
                          </div>
                          <div className="col-md-3">
                            <Controls.InputControl
                              name="title"
                              other={{
                                type: "number",
                                placeholder: "Duration",
                                disabled: true,
                              }}
                              minNumber={0}
                              style={{ width: "100%" }}
                              onChange={(e) =>
                                handlePricingDurationChange(
                                  e.target.value,
                                  pricing.licensePlanPricingId
                                )
                              }
                              value={pricing.planDuration}
                              // error={validation.bankName}
                            ></Controls.InputControl>
                          </div>
                          <div className="col-md-3">
                            <Controls.InputControl
                              name="title"
                              other={{
                                type: "number",
                                placeholder: "Price",
                                disabled: true,
                              }}
                              style={{ width: "100%" }}
                              onChange={(e) =>
                                handlePricingPriceChange(
                                  e.target.value,
                                  pricing.licensePlanPricingId
                                )
                              }
                              value={pricing.planPrice}
                              // error={validation.bankName}
                            ></Controls.InputControl>
                          </div>
                          <div className="col-md-3">
                            <Controls.ButtonControl
                              id={
                                "archivePricing_" + pricing.licensePlanPricingId
                              }
                              style={{ background: "gray" }}
                              innercomponent={
                                <FontAwesomeIcon
                                  icon={faFileArchive}
                                ></FontAwesomeIcon>
                              }
                              onClick={(e) => archivePricing(e)}
                            ></Controls.ButtonControl>
                          </div>
                        </div>
                      </div>
                    );
                  })}
            </AccordionDetails>
          </Accordion>
        )}
        {selectedLicenseItem.planPricings &&
          selectedLicenseItem.planPricings.filter((p) => p.isActive == false)
            .length > 0 && (
            <Accordion
              id="archived"
              expanded={expendedAccordian === "archived"}
              onChange={() => handleExpendedAccordianChange("archived")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
              >
                <Typography>Archieved Pricings</Typography>
                <Badge color="warning" variant="dot"></Badge>
              </AccordionSummary>
              <AccordionDetails>
                {selectedLicenseItem.planPricings &&
                  selectedLicenseItem.planPricings
                    .filter((p) => p.isActive == false)
                    .map((pricing) => {
                      return (
                        <div
                          key={pricing.licensePlanPricingId}
                          className="display-block mt-10 w-100"
                        >
                          <div className="row">
                            <div className="col-md-3">
                              <Controls.SelectControl
                                name="plantype"
                                style={{ width: "120px" }}
                                options={planTypeItems}
                                value={pricing.licensePlanPricingType}
                                disabled={true}
                                onChange={(e) =>
                                  handlePricingTypeChange(
                                    e.target.value,
                                    pricing.licensePlanPricingId
                                  )
                                }
                              ></Controls.SelectControl>
                            </div>
                            <div className="col-md-3">
                              <Controls.InputControl
                                name="title"
                                other={{
                                  type: "number",
                                  placeholder: "Duration",
                                  disabled: true,
                                }}
                                style={{ width: "100%" }}
                                onChange={(e) =>
                                  handlePricingDurationChange(
                                    e.target.value,
                                    pricing.licensePlanPricingId
                                  )
                                }
                                value={pricing.planDuration}
                                // error={validation.bankName}
                              ></Controls.InputControl>
                            </div>
                            <div className="col-md-3">
                              <Controls.InputControl
                                name="title"
                                other={{
                                  type: "number",
                                  placeholder: "Price",
                                  disabled: true,
                                }}
                                style={{ width: "100%" }}
                                onChange={(e) =>
                                  handlePricingPriceChange(
                                    e.target.value,
                                    pricing.licensePlanPricingId
                                  )
                                }
                                value={pricing.planPrice}
                                // error={validation.bankName}
                              ></Controls.InputControl>
                            </div>
                          </div>
                        </div>
                      );
                    })}
              </AccordionDetails>
            </Accordion>
          )}
      </Box>
    </Box>
  );
});

export default EditLicense;
