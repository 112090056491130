import React, { useState, useEffect } from "react";
import ImageProcessor from "../../../components/ImageProcessor/image-processor.component";
import Controls from "../../../components/Controls/Controls";

import { URLS } from "../../../configs/api_urls";

const BasicDetails = ({ newCenterDetails, updateValues }) => {
  const [localCenterState, setLocalCenterState] = useState(newCenterDetails);

  const onChangeInput = ({ currentTarget }) => {
    var name = currentTarget.name;
    var value = currentTarget.value;
    updateValues({ ...localCenterState, [name]: value });
  };

  const onChangeGameDetailsInput = ({ currentTarget }) => {
    var name = currentTarget.name;
    var value = currentTarget.value;
    updateValues({
      ...localCenterState,
      gameDetails: { ...localCenterState.gameDetails, [name]: value },
    });
  };

  useEffect(() => {
    setLocalCenterState(newCenterDetails);
  }, [newCenterDetails]);

  return (
    <React.Fragment>
      <div className="w-100">
        <div style={{ width: "80px" }}>
          <ImageProcessor
            alt="logo_image"
            style={{ borderRadius: "15px", height: "90px" }}
            imageUrl={localCenterState.gameDetails.gameLogoUrl}
            uploadUrl={URLS.FILES.FACILITES_IMAGES}
            setImageData={(e) => {
              updateValues({
                ...localCenterState,
                gameDetails: {
                  ...localCenterState.gameDetails,
                  gameLogoUrl: e.imageFullUrl,
                },
              });
            }}
          ></ImageProcessor>
        </div>
        <div className="mt-20">
          <label className="field-title">Play facility Name</label>
          <Controls.InputControl
            name="applicationInstanceName"
            other={{ placeholder: "Enter Center Name" }}
            style={{ width: "100%", marginTop: "5px" }}
            onChange={onChangeInput}
            value={localCenterState.applicationInstanceName}
            //error={validation.planName}
          ></Controls.InputControl>
        </div>
        <div className="mt-20">
          <label className="field-title">Tag Line</label>
          <Controls.InputControl
            name="tagLine"
            other={{ placeholder: "Tag Line" }}
            style={{ width: "100%", marginTop: "5px" }}
            onChange={onChangeGameDetailsInput}
            value={localCenterState.gameDetails.tagLine}
            //error={validation.planName}
          ></Controls.InputControl>
        </div>
        <div className="mt-20">
          <label className="field-title">Facility Description</label>
          <Controls.InputControl
            name="gameDescription"
            other={{ placeholder: "Facility Description" }}
            style={{ width: "100%", marginTop: "5px" }}
            onChange={onChangeGameDetailsInput}
            value={localCenterState.gameDetails.gameDescription}
            isMultiLine={true}
            rows={3}
            //error={validation.planName}
          ></Controls.InputControl>
        </div>
        <div className="row mt-20">
          <div className="col-md-6 display-grid">
            <label className="field-title">Opening time</label>
            <Controls.TimePickerControl
              name="availabilityStartTime"
              value={localCenterState.availabilityStartTime}
              onChange={(value) => {
                var change = {
                  type: "dattime",
                  currentTarget: {
                    name: "availabilityStartTime",
                    value: value,
                  },
                };
                onChangeInput(change);
              }}
            ></Controls.TimePickerControl>
          </div>
          <div className="col-md-6 display-grid">
            <label className="field-title mb-2">Closing time</label>
            <Controls.TimePickerControl
              name="availabilityEndTime"
              value={localCenterState.availabilityEndTime}
              onChange={(value) => {
                var change = {
                  type: "dattime",
                  currentTarget: {
                    name: "availabilityEndTime",
                    value: value,
                  },
                };
                onChangeInput(change);
              }}
            ></Controls.TimePickerControl>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default BasicDetails;
