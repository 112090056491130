import { useEffect, useState } from "react";

import { Box, Typography, Table, TableBody, TableRow, TableCell } from "@mui/material";
import FontAwesome from "../../../components/Icons/fontawesome";

import { URLS } from "../../../configs/api_urls";
import { httpGet } from "../../../utility/request";

import {
  faCheckCircle,
  faClock,
  faShare,
} from "@fortawesome/free-solid-svg-icons";

import moment from "moment-timezone";

const PaymentSettlementDetails = ({paymentSettlement}) => {
    const [isLoading, setIsLoading] = useState(true);
    const [paymentSettlementDetail, setPaymentSettlementDetail] =
      useState(null);

    const GetPaymentSettlementDetail = async (paymentSettlementId) => {
      setIsLoading(true);
      await httpGet(
        URLS.PAYMENT_SETTLEMENTS.GET_PAYMENT_SETTLEMENT_BY_ID.replace(
          "#ID#",
          paymentSettlementId
        )
      )
        .then((response) => {
          var data = response.data;
          setPaymentSettlementDetail(data);
        })
        .catch((err) => {
          //err
        })
        .finally(() => setIsLoading(false));
    };

    useEffect(() => {
        GetPaymentSettlementDetail(paymentSettlement.paymentSettlementId);
    }, []);

  return (
    <Box>
      {!isLoading && (
        <Box>
          <Box style={{ textAlign: "center", marginTop: "10px" }}>
            {paymentSettlementDetail.settlementStatus == "Success" && (
              <Box>
                <FontAwesome
                  icon={faCheckCircle}
                  iconSize={"60px"}
                  iconcolor={"green"}
                ></FontAwesome>
                <Typography variant="h5" sx={{ textAlign: "center" }}>
                  Payment Successful
                </Typography>
              </Box>
            )}
            {paymentSettlementDetail.settlementStatus == "Pending" && (
              <Box>
                <FontAwesome
                  icon={faClock}
                  iconSize={"60px"}
                  iconcolor={"gray"}
                ></FontAwesome>
                <Typography variant="h5" sx={{ textAlign: "center" }}>
                  Payment Pending
                </Typography>
              </Box>
            )}
            {paymentSettlementDetail.settlementStatus == "Cancelled" && (
              <Box>
                <FontAwesome
                  icon={faShare}
                  iconSize={"60px"}
                  iconcolor={"gray"}
                ></FontAwesome>
                <Typography variant="h5" sx={{ textAlign: "center" }}>
                  Payment Cancelled
                </Typography>
              </Box>
            )}
          </Box>
          <Box className="mt-20">
            <Typography variant="h6" sx={{ textAlign: "center" }}>
              Payment Settlement Details
            </Typography>
            <Table sx={{ minWidth: "500px", fontFamily: "Kanit" }}>
              <TableRow>
                <TableCell sx={{ border: "none" }}>
                  Payment Settlement Id
                </TableCell>
                <TableCell sx={{ float: "right", border: "none" }}>
                  {"#" + paymentSettlementDetail.paymentSettlementId}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ border: "none" }}>Settlement Time</TableCell>
                <TableCell sx={{ float: "right", border: "none" }}>
                  {moment
                    .utc(paymentSettlementDetail.settlementDate)
                    .local()
                    .format("MMM DD, yyyy, hh:mm A")}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ border: "none" }}>
                  No. of Trns. Settled
                </TableCell>
                <TableCell sx={{ float: "right", border: "none" }}>
                  {paymentSettlementDetail.noOfTransections}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ border: "none" }}>Amount</TableCell>
                <TableCell sx={{ float: "right", border: "none" }}>
                  {paymentSettlementDetail.amount / 100 + " Rs."}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ border: "none" }}>Deductions</TableCell>
                <TableCell
                  sx={{ float: "right", borderBottom: "1px solid black" }}
                >
                  {paymentSettlementDetail.standardDeduction / 100 + " Rs."}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ border: "none", fontWeight: "bold" }}>
                  Net Amount Settled
                </TableCell>
                <TableCell
                  sx={{
                    float: "right",
                    borderBottom: "1px solid black",
                    fontWeight: "bold",
                  }}
                >
                  {paymentSettlementDetail.netAmount / 100 + " Rs."}
                </TableCell>
              </TableRow>
            </Table>
          </Box>
          {paymentSettlementDetail.phonepePayments.map((payment, index) => (
            <Box>
              <div className="mt-20">
                <Typography variant="h6" sx={{ textAlign: "center" }}>
                  {"Transection" + " " + (index + 1)}
                </Typography>
                <Table sx={{ minWidth: "500px", fontFamily: "Kanit" }}>
                  <TableRow>
                    <TableCell sx={{ border: "none" }}>Payment Id</TableCell>
                    <TableCell sx={{ float: "right", border: "none" }}>
                      {"#" + payment.phonepePaymentId}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ border: "none" }}>Payment Time</TableCell>
                    <TableCell sx={{ float: "right", border: "none" }}>
                      {moment
                        .utc(payment.createdOn)
                        .local()
                        .format("MMM DD, yyyy, hh:mm A")}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ border: "none" }}>
                      Transection Id
                    </TableCell>
                    <TableCell sx={{ float: "right", border: "none" }}>
                      {payment.transactionId}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ border: "none" }}>Amount</TableCell>
                    <TableCell sx={{ float: "right", border: "none" }}>
                      {payment.amount / 100 + " Rs."}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ border: "none" }}>Payment For</TableCell>
                    <TableCell sx={{ float: "right", border: "none" }}>
                      {payment.paymnent_for == 2 ? "Booking" : "Subscription"}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ border: "none" }}>
                      Transection charges
                    </TableCell>
                    <TableCell sx={{ float: "right", border: "none" }}>
                      {"- "}
                      {payment.transectionCharges / 100 + " Rs."}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ border: "none" }}>
                      GST On Transection charges
                    </TableCell>
                    <TableCell sx={{ float: "right", border: "none" }}>
                      {"- "}
                      {payment.gstOnTransectionCharges / 100 + " Rs."}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ border: "none" }}>
                      Platform charges
                    </TableCell>
                    <TableCell sx={{ float: "right", border: "none" }}>
                      {"- "}
                      {payment.platformCharges / 100 + " Rs."}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ border: "none", fontWeight: "bold" }}>
                      Total
                    </TableCell>
                    <TableCell
                      sx={{
                        float: "right",
                        border: "none",
                        fontWeight: "bold",
                      }}
                    >
                      {payment.netAmount / 100 + " Rs."}
                    </TableCell>
                  </TableRow>
                </Table>
              </div>
            </Box>
          ))}
        </Box>
      )}
    </Box>
  );
}

export default PaymentSettlementDetails;