import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { fireEvent } from "../../../modules/Events/eventSlice";
import { setIsRenderedState } from "../../../modules/ModalPopup/modalPopupSlice";

import { URLS } from "../../../configs/api_urls";
import { httpPut, httpPost, httpDelete } from "../../../utility/request";

import ImageProcessor from "../../../components/ImageProcessor/image-processor.component";
import ModalPopup from "../../../components/ModalPopup/modalpopup.component";
import Controls from "../../../components/Controls/Controls";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const genderItems = [
  { id: 0, title: "Male", value: "Male" },
  { id: 1, title: "Female", value: "Female" },
];

const AddEditCoach = ({ selectedCoach, cancelClick }) => {
    const dispatch = useDispatch();

  const [selectedCoachItem, setSelectedCoachItem] = useState(selectedCoach);
  const [coachUser, setCoachUser] = useState(selectedCoach.user);

   const updateCoach = async () => {
     // API CAll here
     await httpPut(URLS.COACHES.UPDATE_COACH, {
       ...selectedCoachItem,
       ...coachUser,
       password: ""
     })
       .then((response) => {
         if (response.data == true) {
           dispatch(fireEvent({ eventName: "refresh-coaches-list" }));
         }
       })
       .catch((error) => {
         //  setFormSubmitError(
         //    error.response.data.Message || "Some error occuered!"
         //  );
       })
       .finally(() => {
         cancelClick();
       });
   }

   const createCoach = async () => {
     // API CAll here
     await httpPost(URLS.COACHES.CREATE_COACH, {
       ...selectedCoachItem,
       ...coachUser,
       userName: coachUser.emailId,
       password: "",
     })
       .then((response) => {
         if (response.data == true) {
           dispatch(fireEvent({ eventName: "refresh-coaches-list" }));
         }
       })
       .catch((error) => {
         //  setFormSubmitError(
         //    error.response.data.Message || "Some error occuered!"
         //  );
       })
       .finally(() => {
         cancelClick();
       });
   };

   const handelDeleteClick = async () => {
     await httpDelete(
       URLS.COACHES.DELETE_COACH.replace(
         "#ID#",
         selectedCoachItem.coachMasterId
       )
     )
       .then((response) => {
         if (response.data == true) {
           dispatch(fireEvent({ eventName: "refresh-coaches-list" }));
           dispatch(setIsRenderedState(false));
           cancelClick();
         }
       })
       .catch((error) => {
         //  setFormSubmitError(
         //    error.response.data.Message || "Some error occuered!"
         //  );
       });
   };

     const handleDeleteButtonClick = () => {
       dispatch(setIsRenderedState(true));
     };

   const handleSaveClick = () => 
   {
      if (selectedCoach.coachMasterId > 0) {
        updateCoach();
      } else {
        createCoach();
      }
   }

  useEffect(() => {
    setSelectedCoachItem(selectedCoach);
    setCoachUser(selectedCoach.user);}, 
    [selectedCoach]);

  return (
    <div className="edit-container w-100">
      <div className="display-flex">
        <Controls.ButtonControl
          text={"Cancel"}
          style={{
            width: "100px",
            margin: "10px 10px",
            backgroundColor: "gray",
          }}
          onClick={() => cancelClick()}
          //color={grey[700]}
        ></Controls.ButtonControl>
        <label className="edit-panel-title">
          {selectedCoach.coachMasterId > 0 ? "Edit Coach" : "Add Coach"}
        </label>
        <Controls.ButtonControl
          onClick={() => handleSaveClick()}
          text={"Save"}
        ></Controls.ButtonControl>
        {selectedCoach.coachMasterId > 0 && (
          <Controls.ButtonControl
            onClick={handleDeleteButtonClick}
            style={{
              width: "40px",
              margin: "10px 5px",
              backgroundColor: "red",
            }}
            innercomponent={<FontAwesomeIcon icon={faTrash}></FontAwesomeIcon>}
          ></Controls.ButtonControl>
        )}
      </div>

      <ModalPopup
        heading={"Are you sure that you want to Delete this Coach Profile?"}
        isConfirmButton={true}
        confirmButtonText={"Yes"}
        onConfirmClick={handelDeleteClick}
        isCancelButton={true}
        cancelButtontext={"No"}
        onCancelClick={() => dispatch(setIsRenderedState(false))}
      ></ModalPopup>

      <div className="mt-50" style={{ padding: "10px" }}>
        <div className="row">
          <div className="col-md-2">
            <ImageProcessor
              alt="asset_image"
              style={{ borderRadius: "15px", height: "90px" }}
              imageUrl={coachUser.profilePictureUrl}
              uploadUrl={URLS.FILES.PROFILE_IMAGE_UPLOAD}
              setImageData={(e) => {
                setCoachUser({
                  ...coachUser,
                  profilePictureUrl: e,
                });
              }}
            ></ImageProcessor>
          </div>
          <div className="col-md-5">
            <label className="field-title">First Name</label>
            <Controls.InputControl
              name="title"
              other={{ placeholder: "First Name" }}
              style={{ width: "100%", marginTop: "5px" }}
              onChange={(e) =>
                setCoachUser({
                  ...coachUser,
                  firstName: e.target.value,
                })
              }
              value={coachUser.firstName}
              //error={validation.planName}
            ></Controls.InputControl>
          </div>
          <div className="col-md-5">
            <label className="field-title">Last Name</label>
            <Controls.InputControl
              name="title"
              other={{ placeholder: "Last Name" }}
              style={{ width: "100%", marginTop: "5px" }}
              onChange={(e) =>
                setCoachUser({
                  ...coachUser,
                  lastName: e.target.value,
                })
              }
              value={coachUser.lastName}
              //error={validation.planName}
            ></Controls.InputControl>
          </div>
        </div>
        <div className="edit-section-margin">
          <div className="row">
            <div className="col-md-6" style={{ display: "grid" }}>
              <label className="field-title">Gender</label>
              <Controls.SelectControl
                name="gender"
                style={{ width: "100%", marginTop: "5px" }}
                options={genderItems}
                value={genderItems.find((a) => a.value == coachUser.gender).id}
                onChange={(e) =>
                  setCoachUser({
                    ...coachUser,
                    gender: genderItems.find((a) => a.id == e.target.value)
                      .value,
                  })
                }
              ></Controls.SelectControl>
            </div>
            <div className="col-md-6">
              <label className="field-title">Date of Birth</label>
              <Controls.DatePickerControl
                id={coachUser.userId}
                name="dateOfBirth"
                style={{ width: "100%", marginTop: "5px" }}
                value={coachUser.dateOfBirth}
                onChange={(value) =>
                  setCoachUser({
                    ...coachUser,
                    dateOfBirth: value,
                  })
                }
              ></Controls.DatePickerControl>
            </div>
          </div>
        </div>
        <div className="edit-section-margin">
          <label className="field-title">Email Id</label>
          <Controls.InputControl
            name="emailId"
            other={{ placeholder: "Email Id" }}
            style={{ width: "100%", marginTop: "5px" }}
            onChange={(e) =>
              setCoachUser({
                ...coachUser,
                emailId: e.target.value,
                password: "1234",
              })
            }
            value={coachUser.emailId}
            //error={validation.planName}
          ></Controls.InputControl>
        </div>

        <div className="edit-section-margin">
          <div className="row">
            <div className="col-md-6">
              <label className="field-title">Primary Phone</label>
              <Controls.InputControl
                name="title"
                other={{ placeholder: "Primary Phone" }}
                style={{ width: "100%", marginTop: "5px" }}
                onChange={(e) =>
                  setCoachUser({
                    ...coachUser,
                    primaryPhoneNumber: e.target.value,
                  })
                }
                value={coachUser.primaryPhoneNumber}
                //error={validation.planName}
              ></Controls.InputControl>
            </div>
            <div className="col-md-6">
              <label className="field-title">Secondary Phone</label>
              <Controls.InputControl
                name="title"
                other={{ placeholder: "Secondary Phone" }}
                style={{ width: "100%", marginTop: "5px" }}
                onChange={(e) =>
                  setCoachUser({
                    ...coachUser,
                    secondaryPhoneNumber: e.target.value,
                  })
                }
                value={coachUser.secondaryPhoneNumber}
                //error={validation.planName}
              ></Controls.InputControl>
            </div>
          </div>
        </div>
        <div className="edit-section-margin">
          <label className="field-title">Designation</label>
          <Controls.InputControl
            name="title"
            other={{ placeholder: "Designation" }}
            style={{ width: "100%", marginTop: "5px" }}
            onChange={(e) =>
              setSelectedCoachItem({
                ...selectedCoachItem,
                coachDesignation: e.target.value,
              })
            }
            value={selectedCoachItem.coachDesignation}
            //error={validation.planName}
          ></Controls.InputControl>
        </div>
        <div className="edit-section-margin">
          <label className="field-title">Profile bio</label>
          <Controls.InputControl
            name="title"
            other={{ placeholder: "Write a nice profile bio" }}
            style={{ width: "100%", marginTop: "5px" }}
            onChange={(e) =>
              setSelectedCoachItem({
                ...selectedCoachItem,
                coachBio: e.target.value,
              })
            }
            value={selectedCoachItem.coachBio}
            //error={validation.planName}
          ></Controls.InputControl>
        </div>
        <div className="edit-section-margin">
          <label className="field-title">Experience and Skills</label>
          <Controls.InputControl
            name="title"
            other={{
              placeholder: "Comma separated skills (e.g. diet, workout)",
            }}
            style={{ width: "100%", marginTop: "5px" }}
            onChange={(e) =>
              setSelectedCoachItem({
                ...selectedCoachItem,
                experience: e.target.value,
              })
            }
            value={selectedCoachItem.experience}
            //error={validation.planName}
          ></Controls.InputControl>
        </div>
        <div className="edit-section-margin">
          <label className="field-title">Instagram Handle</label>
          <Controls.InputControl
            name="title"
            other={{
              placeholder: "Instagram Handle",
            }}
            style={{ width: "100%", marginTop: "5px" }}
            onChange={(e) =>
              setSelectedCoachItem({
                ...selectedCoachItem,
                instagramHandle: e.target.value,
              })
            }
            value={selectedCoachItem.instagramHandle}
            //error={validation.planName}
          ></Controls.InputControl>
        </div>
        <div className="edit-section-margin">
          <label className="field-title">Twitter Handle</label>
          <Controls.InputControl
            name="title"
            other={{
              placeholder: "Twitter Handle",
            }}
            style={{ width: "100%", marginTop: "5px" }}
            onChange={(e) =>
              setSelectedCoachItem({
                ...selectedCoachItem,
                twitterHandle: e.target.value,
              })
            }
            value={selectedCoachItem.twitterHandle}
            //error={validation.planName}
          ></Controls.InputControl>
        </div>
        <div className="edit-section-margin">
          <label className="field-title">Facebook Handle</label>
          <Controls.InputControl
            name="title"
            other={{
              placeholder: "Facebook Handle",
            }}
            style={{ width: "100%", marginTop: "5px" }}
            onChange={(e) =>
              setSelectedCoachItem({
                ...selectedCoachItem,
                facebookHandle: e.target.value,
              })
            }
            value={selectedCoachItem.facebookHandle}
            //error={validation.planName}
          ></Controls.InputControl>
        </div>
        <div className="edit-section-margin">
          <label className="field-title">LinkedIn Handle</label>
          <Controls.InputControl
            name="title"
            other={{
              placeholder: "LinkedIn Handle",
            }}
            style={{ width: "100%", marginTop: "5px" }}
            onChange={(e) =>
              setSelectedCoachItem({
                ...selectedCoachItem,
                linkedInHandle: e.target.value,
              })
            }
            value={selectedCoachItem.linkedInHandle}
            //error={validation.planName}
          ></Controls.InputControl>
        </div>
      </div>
    </div>
  );
};

export default AddEditCoach;
