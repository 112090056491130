import React from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const RichEditor = (props) => {
  const { editorValue, setEditorValue, theme = "snow", style } = props;

  return (
    <ReactQuill theme={theme} value={editorValue} onChange={setEditorValue} style={style} />
  );
};
export default RichEditor;
