import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

import { Box, Typography } from "@mui/material";
import Controls from "../../../components/Controls/Controls";

import { httpPost, httpGet } from "../../../utility/request";
import { URLS } from "../../../configs/api_urls";
import { fireEvent } from "../../../modules/Events/eventSlice";

import moment from "moment-timezone";

var dateNow = moment(new Date()).format("YYYY-MM-DD");

const AddGameSession = ({ close }) => {
  var initialSessionValues = {
    assetId: null,
    startTime: moment(),
    endTime: moment(),
    originalStartTime: moment(),
    originalEndTime: moment(),
    numberOfPlayers: 0,
    isInProgress: true,
    comment: "",
    isPaused: false,
    elapsedTime: "0",
  };
  var initialSessionValidations = {
    assetId: "",
    startTime: "",
    comment: "",
  };
  const dispatch = useDispatch();
  const [gameSession, setGameSession] = useState(initialSessionValues);
  const [assetItems, setAssetItems] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchAssets = async () => {
    setIsLoading(true);
    const { data } = await httpGet(URLS.ASSET.GET_ASSET_LIST);
    var assetItems = data
      .filter((p) => p.isActive === true)
      .map((asset) => ({
        id: asset.assetId,
        title: asset.assetName,
      }));
    setAssetItems(assetItems);
    setIsLoading(false);
  };

  const createGameSession = async () => {
    await httpPost(URLS.GAME_SESSIONS.CREATE_GAME_SESSION, gameSession)
      .then((response) => {
        if (response.data == true) {
          dispatch(fireEvent({ eventName: "refresh-game-session-list" }));
        }
      })
      .catch((error) => {
        //  setFormSubmitError(
        //    error.response.data.Message || "Some error occuered!"
        //  );
      })
      .finally(() => {
        close();
      });
  };

  useEffect(() => {
    fetchAssets();
  }, []);

  return (
    <Box>
      <Box className="mt-10 text-center p-2">
        <Typography variant="h5"> Add Game Session</Typography>
      </Box>
      <Box>
        <Box sx={{ padding: "10px" }}>
          <Controls.SelectControl
            name="assetSelection"
            placeholder={"Select asset"}
            label={"Asset"}
            options={assetItems}
            value={gameSession.assetId}
            onChange={(e) =>
              setGameSession({
                ...gameSession,
                assetId: Number(e.target.value),
              })
            }
          ></Controls.SelectControl>
        </Box>
        <Box sx={{ padding: "10px" }}>
          <Controls.TimePickerControl
            id={"startTime"}
            label={"Start time"}
            name="startTime"
            value={gameSession.startTime}
            maxTime={moment()}
            //style={{width: '100%'}}
            //minTime={moment().hour(0)}
            onChange={(value) => {
              setGameSession({
                ...gameSession,
                startTime: value,
                originalStartTime: value,
              });
            }}
          ></Controls.TimePickerControl>
        </Box>
        <Box sx={{ padding: "10px" }}>
          <Controls.InputControl
            name="numberOfPlayers"
            placeholder={"Number of players"}
            value={gameSession.numberOfPlayers}
            //error={gameSession.numberOfPlayers}
            style={{ width: "100%" }}
            other={{
              type: "number",
            }}
            // isMultiLine={true}
            minNumber={0}
            onChange={(e) => {
              setGameSession({
                ...gameSession,
                numberOfPlayers: Number(e.target.value),
              });
              //ckeckValidations(e);
            }}
          ></Controls.InputControl>
        </Box>
        <Box sx={{ padding: "10px" }}>
          <Controls.InputControl
            name="comment"
            placeholder={"comment"}
            value={gameSession.comment}
            //error={offlinePaymentValidations.message}
            style={{ width: "100%" }}
            other={{
              placeholder: "Enter the comment",
            }}
            isMultiLine={true}
            rows={3}
            //maxLength={30}
            onChange={(e) => {
              setGameSession({
                ...gameSession,
                comment: e.target.value,
              });
              //ckeckValidations(e);
            }}
          ></Controls.InputControl>
        </Box>
        <Controls.ButtonControl text="Add" onClick={createGameSession} />
        <Controls.ButtonControl text="Cancel" onClick={close} />
      </Box>
    </Box>
  );
};

export default AddGameSession;
