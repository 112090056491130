import { useSearchParams, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";

import { URLS } from "../../../configs/api_urls";
import { httpGet } from "../../../utility/request";

import FontAwesome from "../../../components/Icons/fontawesome";
import {
  faCheckCircle,
  faTimesCircle,
  faWarning,
} from "@fortawesome/free-solid-svg-icons";

import "./payment-status.styles.scss";
import Loader from "../../../components/Loader";

const PaymentStatus = () => 
{
    const [isLoading, setIsLoading] = useState(true);
  
    const navigate = useNavigate();
    
    const [searchParams, setSearchParams] = useSearchParams();

    const [orderStatus, setOrderStatus] = useState(searchParams.get("Order_Status"));

    var order_no = searchParams.get("Order_No");
    var order_status = searchParams.get("Order_Status");
    var order_amount = searchParams.get("Order_Amount");

    const comapaireAmount = (dbAmount, routeAmount) => 
    {
      var dbValue = parseFloat(dbAmount);
      var routeValue = parseFloat(routeAmount);

      if (dbValue === routeValue) return true;

      return false;
    }

    const fetchPaymentDetails = async () => 
    {
      setIsLoading(true);
      await httpGet(URLS.CHECKOUT.GETPAYMENTBYID.replace("#ID#", order_no))
      .then(
        (response) => 
        { 
          var data = response.data;
          if (
            data.order_id == order_no &&
            data.order_status == order_status &&
            comapaireAmount(data.amount, order_amount)
          ) {
            setIsLoading(false);
          } else 
          {
            setOrderStatus("WrongDetails");
            setIsLoading(false);
          }
        }
      ).catch((err) => 
      {
        setOrderStatus("WrongDetails");
        setIsLoading(false);
      })
    }

    useEffect(() => {
      fetchPaymentDetails();
    }, []);

   return (
     <div className="container payment-status-container">
       {isLoading == false ? (
         <div className="status-details">
           {orderStatus == "Success" && (
             <div>
               <FontAwesome
                 icon={faCheckCircle}
                 iconSize={"60px"}
                 iconcolor={"green"}
               ></FontAwesome>
               <h2>Order completed Successfully!!</h2>
             </div>
           )}
           {orderStatus == "Failure" && (
             <div>
               <FontAwesome
                 icon={faTimesCircle}
                 iconSize={"60px"}
                 iconcolor={"red"}
               ></FontAwesome>
               <h2>Order Failed!!</h2>
             </div>
           )}
           {orderStatus == "Aborted" && (
             <div>
               <FontAwesome
                 icon={faTimesCircle}
                 iconSize={"60px"}
                 iconcolor={"orange"}
               ></FontAwesome>
               <h2>Order has been Cancelled!!</h2>
             </div>
           )}
           {orderStatus == "ApiFailure" && (
             <div>
               <FontAwesome
                 icon={faWarning}
                 iconSize={"60px"}
                 iconcolor={"#eec13b"}
               ></FontAwesome>
               <h2>We could not verify the status of the Payment!!</h2>
               <h5>
                 <i>
                   Please be patient, If the amount was deducted reach out to
                   Playnoot support for help.
                 </i>
               </h5>
             </div>
           )}
           {orderStatus == "WrongDetails" && (
             <div>
               <FontAwesome
                 icon={faWarning}
                 iconSize={"60px"}
                 iconcolor={"red"}
               ></FontAwesome>
               <h2>Something went wrong!!</h2>
               <h5>
                 <i>
                   Something went wrong verifying your payment status.
                 </i>
               </h5>
             </div>
           )}
           <div style={{ marginTop: "40px" }}>
             <h5>
               <b>Order Id</b> : {order_no}
             </h5>
             <h5>
               <b>Amount</b> : {order_amount} Rs.
             </h5>
           </div>
           <button
             className="theme-btn my-2 p-2 ml-auto"
             onClick={() => {
               navigate("/admin/admin-orders");
             }}
           >
             See Details
           </button>
         </div>
       ) : (
         <Loader isLoading={isLoading}></Loader>
       )}
     </div>
   );
}

export default PaymentStatus;