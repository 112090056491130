const GuideAdvertisementOperation = () => {
    return (
      <div className="edit-container">
        <div style={{ display: "inherit" }} className="operations-title">
          <div
            style={{ margin: "auto", textAlign: "center", marginTop: "30px" }}
          >
            Advertise sports facility/offers/merchandise/articles/events on the
            Playnoot app
          </div>
        </div>
        <div className="mt-50 p-20">
          <div className="row">
            <div className="col-md-6">
              <h5 className="text-theme font-600 cursor-pointer">
                Link to center
              </h5>
              <span>
                On click of this advertisement user will navigate to give play center
              </span>
            </div>
            <div className="col-md-6">
              <h5 className="text-theme font-600 cursor-pointer">Add URLs</h5>
              <span>
                Add urls to particular site to point take user to your content.
              </span>
            </div>
          </div>
          <div className="row mt-20">
            <div className="col-md-6">
              <h5 className="text-theme font-600 cursor-pointer">
                Show Filtered
              </h5>
              <span>
                Show filtered play instances on the basis of sports, facility, price, availabiliy, location, etc.
              </span>
            </div>
            <div className="col-md-6">
              {/* <h5 className="text-theme font-600 cursor-pointer">Pricings</h5>
              <span>Put resonable price for each product to increase sale</span> */}
            </div>
          </div>
        </div>
      </div>
    );
}

export default GuideAdvertisementOperation;