import { useState } from "react";

import AssetNavigation from "../AssetNavigation/asset-navigation.component";
import AssetTypeList from "../AssetTypes/AssetTypeList/asset-type-list.component"
import AddEditAssetType from "./AddEditAssetType/addedit-asset-type.component";
import GuideAssetOperations from "../Assets/GuideAssetOperations/guide-asset-operations.component";

const newAssetType = {
  assetTypeId: 0,
  assetTypeName: "",
  typeGracePeriod: 10,
  typeRules: "",
};

const AssetTypeDashboard = () => 
{
    const [selectedAssetType, setSelectedAssetType] = useState(null);
    const [selectedView, setSelectedView] = useState("");
    const [selectedAssetTypeList, setSelectedAssetTypeList] = useState([]);

    const handleAssetTypeSelected = (assetType) => 
    {
      setSelectedAssetType(assetType);
      setSelectedView("addeditview");
      setSelectedAssetTypeList([assetType.assetTypeId]);
    }

    return (
      <div>
        <div>
          <AssetNavigation
            isCreateAssetVisible={false}
            isCreateNewAssetOfferVisible={false}
            isCreateNewAssetTypeVisible={true}
            isAssetDashboardVisible={true}
            isAssetOffersDashboardVisible={true}
            isAssetTypeDashboardVisible={false}
            checkBookingPerformence={true}
            checkBookingEarningPerformence={true}
            createNewAssetTypeClick={(e) => {handleAssetTypeSelected(newAssetType)}}
          ></AssetNavigation>
        </div>
        <div className="display-inline-flex w-100">
          <div className="items-list-container w-50">
            <div className="items-list">
              <AssetTypeList
                selectedAssetTypeList={selectedAssetTypeList}
                clickSelectAssetType={(asset) => {
                  handleAssetTypeSelected(asset);
                }}
              ></AssetTypeList>
            </div>
          </div>
          <div className="items-edit-container  w-50">
            {selectedView == "" && (
              <GuideAssetOperations></GuideAssetOperations>
            )}
            {selectedView == "addeditview" && (
              <AddEditAssetType
                selectedAssetType={selectedAssetType}
                cancelClick={() => {
                  setSelectedView("");
                  setSelectedAssetTypeList([]);
                }}
              ></AddEditAssetType>
            )}
          </div>
        </div>
      </div>
    );
}
export default AssetTypeDashboard;