import { isEmpty } from "lodash";
import React from "react";
import { useNavigate } from "react-router-dom";

import "./module.scss";

const ItemView = (props) => {
  const { icon, label, isCollapse, isActive, onItemClick } = props;

  return (
    <div
      className={`
        nav_item 
        ${isActive ? "nav_item_active" : ""} 
        ${isCollapse ? "max_content" : ""}
      `}
      onClick={onItemClick}>
      {icon}
      <span className='item-label'>{label}</span>
    </div>
  );
};

const SideNavBar = (props) => {
  const {
    selected_nav_item,
    nav_items,
    handleOnLinkClick = null,
    theme = null,
  } = props;
  const router = useNavigate();

  const handleItemChange = (item_id) => {
    const item = nav_items.find((item) => {
      return item.id === item_id;
    });
    if (item.link) router(item.link);
  };

  return (
    <div className='side_bar'>
      {nav_items.map((item) => {
        return (
          <ItemView
            key={item.id}
            {...item}
            isCollapse={false}
            isActive={selected_nav_item === item.id}
            onItemClick={() => {
              handleOnLinkClick
                ? handleOnLinkClick(item.id)
                : handleItemChange(item.id);
            }}
          />
        );
      })}
    </div>
  );
};

export default SideNavBar;
