import {
  SET_ACTIVE_FORM,
  SET_ADDRESS_DTO,
  SET_CENTER_IMAGES,
  SET_CREATE_CENTER_DATA,
  SET_FACILITIE_AMENITIES_DTO,
  SET_ALL_POLICY_TYPES,
  SET_GAME_CENTERS_LIST,
  SET_IS_REQUESTING,
  SET_STANDARD_GAMES_DTO,
  SET_THEME_DTO,
  RESET_CREATE_GAME_CENTER,
  RESET_CENTER_IMAGES_DATA,
  SET_CENTER_POLICY,
} from "./constants";
import { httpGet } from "../../utility/request";
import { URLS } from "../../configs/api_urls";
import { initial_center_images, initial_game_center } from "./reducer";

export const setGameCenterList = (data) => {
  return { type: SET_GAME_CENTERS_LIST, data };
};

export const setStandardGamestDto = (data) => {
  return { type: SET_STANDARD_GAMES_DTO, data };
};

export const setFacilitieAmenitiedDto = (data) => {
  return { type: SET_FACILITIE_AMENITIES_DTO, data };
};

export const setAllPolicyTypes = (data) => {
  return { type: SET_ALL_POLICY_TYPES, data };
};

export const setCreateCenterData = (data) => {
  return { type: SET_CREATE_CENTER_DATA, data };
};

export const setCenterImages = (data) => {
  return { type: SET_CENTER_IMAGES, data };
};

export const setActiveForm = (data) => {
  return { type: SET_ACTIVE_FORM, data };
};

export const setAddressDto = (data) => {
  return { type: SET_ADDRESS_DTO, data };
};

export const setThemesDto = (data) => {
  return { type: SET_THEME_DTO, data };
};

export const setIsRequesting = (data) => {
  return { type: SET_IS_REQUESTING, data };
};

export const setCenterPolicy = (data) => {
  return { type: SET_CENTER_POLICY, data };
};

export const resetCreateGameCenter = () => {
  return { type: RESET_CREATE_GAME_CENTER, data: { ...initial_game_center } };
};

export const resetCenterImages = () => {
  return { type: RESET_CENTER_IMAGES_DATA, data: [...initial_center_images] };
};

export const fetchGameCenterList = () => {
  return (dispatch) => {
    dispatch(setIsRequesting(true));
    return httpGet(URLS.GAME_CENTERS.LIST)
      .then(({ data }) => {
        dispatch(setGameCenterList(data));
        dispatch(setIsRequesting(false));
      })
      .catch((err) => {
        console.error(err);
        dispatch(setIsRequesting(false));
      });
  };
};

export const fetchStandardGamesDto = () => {
  return (dispatch) => {
    return httpGet(URLS.MASTER.GET_STANDARD_GAMES)
      .then(({ data }) => {
        dispatch(setStandardGamestDto(data));
      })
      .catch((err) => {
        console.error(err);
      });
  };
};

export const fetchFacilityAmenitiesDto = () => {
  return (dispatch) => {
    return httpGet(URLS.MASTER.GET_AMENITIES)
      .then(({ data }) => {
        dispatch(setFacilitieAmenitiedDto(data));
      })
      .catch((err) => {
        console.error(err);
      });
  };
};

export const fetchThemeDto = () => {
  return (dispatch) => {
    return httpGet(URLS.MASTER.GET_AVAILABLE_INSTANCE_THEME)
      .then(({ data }) => {
        dispatch(setThemesDto(data));
      })
      .catch((err) => {
        console.error(err);
      });
  };
};

export const fetchAllPolicies = () => {
  return (dispatch) => {
    return httpGet(URLS.MASTER.GET_POLICY_TYPES)
      .then(({ data }) => {
        dispatch(setAllPolicyTypes(data));
      })
      .catch((err) => {
        console.error(err);
      });
  };
};

export const fetchStandardData = () => {
  return (dispatch) => {
      dispatch(fetchStandardGamesDto());
      dispatch(fetchFacilityAmenitiesDto());
      dispatch(fetchThemeDto());
      dispatch(fetchAllPolicies());
  };
};
