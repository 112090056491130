import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Loader from "../../../components/Loader";
import { faPlus, faCrown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import NoContentMessage from "../../../components/NoRowMessage";

import { URLS } from "../../../configs/api_urls";
import { httpGet } from "../../../utility/request";

import { selectApplicationLicense } from "../../../modules/App/selectors";
import { selectApplicationLicenseConsumption } from "../../../modules/App/selectors";

import "./admin-user-list.styles.scss";
import { Typography } from "@mui/material";

const AdminUserList = () => {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [adminUsers, setAdminUsersData] = useState([]);

  const license = useSelector(selectApplicationLicense).license;
  const consumption = useSelector(selectApplicationLicenseConsumption);

  const fetchAdminUsers = async () => {
    setIsLoading(true);

    const { data } = await httpGet(URLS.ADMIN_USERS.GETDEPARTMENTADMINS);

    if (data) {
      setAdminUsersData(data);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchAdminUsers();
  }, []);

  return (
    <div>
      <Loader isLoading={isLoading}></Loader>

      <div>
        <div
          style={{ justifyContent: "space-between" }}
          className="d-flex"
        >
          <Typography variant="h4">Department Admins</Typography>
          {license.maximumAdminsAllowed - consumption.numberOfDeptAdmins > 0 ? (
            <Button
              sx={{ float: "right", marginLeft: "100px" }}
              onClick={() => {
                navigate("/admin/add-admin-user");
              }}
              variant="contained"
              className="theme-btn"
            >
              <FontAwesomeIcon className="text-small mr-2" icon={faPlus} />
              Add Admin User
            </Button>
          ) : (
            <Button
              sx={{ float: "right", marginLeft: "100px" }}
              onClick={() => {
                navigate("/admin/license-plans");
              }}
              variant="contained"
              className="theme-btn"
            >
              <FontAwesomeIcon className="text-small mr-2" icon={faCrown} />
              Upgrade to add more users
            </Button>
          )}
        </div>

        {!isLoading && adminUsers.length > 0
          ? adminUsers.map((user) => (
              <div key={user.userId}>
                <Card
                  sx={{
                    minWidth: 275,
                    padding: "10px",
                    marginBottom: "10px",
                    display: "inline-flex",
                    width: "100%",
                  }}
                >
                  <CardContent style={{ width: "80%", display: "inline-flex" }}>
                    <CardMedia
                      style={{
                        height: "100px",
                        width: "100px",
                        borderRadius: "50%",
                        padding: "3px",
                        boxShadow: "2px 2px 2px grey",
                      }}
                      component="img"
                      image={user.profilePictureUrl}
                      title="Picture"
                      alt="pic"
                    />
                    <br />
                    <div className="dept-profile-details">
                      <span>
                        <b>Name : </b>
                      </span>
                      <span>{user.firstName + " " + user.lastName}</span>
                      <br />
                      <span>
                        <b>Email : </b>
                      </span>
                      <span>{user.emailId}</span>
                      <br />
                      <span>
                        <b>Primary Phone : </b>
                      </span>
                      <span>{user.primaryPhoneNumber}</span>
                    </div>
                  </CardContent>
                  <CardActions style={{ width: "25%" }}>
                    <Button variant="contained">Details</Button>
                    <Button variant="contained">Disable Access</Button>
                  </CardActions>
                </Card>
              </div>
            ))
          : !isLoading && (
              <NoContentMessage
                message={
                  "No admin users created yet. Please begin adding users by using Add Admin User Button above."
                }
              ></NoContentMessage>
            )}
      </div>
    </div>
  );
};

export default AdminUserList;
