import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { fireEvent } from "../../../../modules/Events/eventSlice";

import ButtonControl from "../../../../components/Controls/ButtonControl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileArchive, faUndo } from "@fortawesome/free-solid-svg-icons";

import { ToggleButton, ToggleButtonGroup, Box } from "@mui/material";

import EditAsset from "./EditAsset/edit-asset.component";
import SlotMointoring from "./SlotMonitoring/slot-monitoring.component";
import AssetAnalytics from "./AssetAnalytics/asset-analytics.component";
import ModalPopup from "../../../../components/ModalPopup/modalpopup.component";

import { URLS } from "../../../../configs/api_urls";
import { httpDelete } from "../../../../utility/request";
import { setIsRenderedState } from "../../../../modules/ModalPopup/modalPopupSlice";

import "./add-edit-asset.styles.scss";

const AddEditAsset = ({ selectedAsset, cancelClick }) => {
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState("settings");

  const handleSaveClick = () => {
    dispatch(fireEvent({ eventName: "save-asset-details" }));
  };

  const handelArchiveClick = async () => {
    await httpDelete(
      URLS.ASSET.UPDATE_ASSET_ACTIVE_STATUS.replace(
        "#ID#",
        selectedAsset.assetId
      ).replace("#STATUS#", !selectedAsset.isActive)
    )
      .then((response) => {
        if (response.data == true) {
          dispatch(fireEvent({ eventName: "refresh-asset-list" }));
          cancelClick();
        }
      })
      .catch((error) => {
        //  setFormSubmitError(
        //    error.response.data.Message || "Some error occuered!"
        //  );
      })
      .finally(() => {
        dispatch(setIsRenderedState(false));
      });
  };

  useEffect(() => {
    setActiveTab("settings");
  }, [selectedAsset]);

  return (
    <Box className="edit-container w-100" sx={{ padding: "10px" }}>
      <Box className="display-flex" sx={{alignItems: 'center'}}>
        <ButtonControl
          text={"Cancel"}
          style={{
            width: "100px",
            margin: "10px 10px",
            backgroundColor: "gray",
          }}
          onClick={() => cancelClick()}
          //color={grey[700]}
        />
        <label className="edit-panel-title">
          {selectedAsset.assetId > 0 ? "Edit Asset" : "Add Asset"}
        </label>
        <ButtonControl
          onClick={() => handleSaveClick()}
          text={"Save"}
          // style={{
          //   width: "100px",
          //   margin: "10px 10px",
          //   backgroundColor: "primary",
          // }}
          color={"primary"}
        />
        {selectedAsset.assetId > 0 && (
          <ButtonControl
            onClick={() => dispatch(setIsRenderedState(true))}
            color={"error"}
            style={{height: "40px"}}
            innercomponent={
              selectedAsset.isActive ? (
                <FontAwesomeIcon icon={faFileArchive}></FontAwesomeIcon>
              ) : (
                <FontAwesomeIcon icon={faUndo}></FontAwesomeIcon>
              )
            }
          ></ButtonControl>
        )}
      </Box>

      <ModalPopup
        heading={"Are you sure that you want to Archive this asset?"}
        subHeadhing={
          "Archiving asset will make asset unavailable for booking immediately"
        }
        isConfirmButton={true}
        confirmButtonText={"Yes"}
        onConfirmClick={handelArchiveClick}
        isCancelButton={true}
        cancelButtontext={"No"}
        onCancelClick={() => dispatch(setIsRenderedState(false))}
      ></ModalPopup>
      {selectedAsset.assetId > 0 && (
        <ToggleButtonGroup
          color="primary"
          value={activeTab}
          exclusive
          sx={{ display: "flex", justifyContent: "center" }}
          className="tab-margins edit-asset-tabs display-flex"
          onChange={(e) => setActiveTab(e.target.value)}
          aria-label="Platform"
        >
          <ToggleButton
            sx={{ textTransform: "initial", width: "100%" }}
            value="settings"
          >
            Settings
          </ToggleButton>
          <ToggleButton
            sx={{ textTransform: "initial", width: "100%" }}
            value="slotsmonitoring"
          >
            Slots
          </ToggleButton>
          <ToggleButton
            sx={{ textTransform: "initial", width: "100%" }}
            value="analytics"
          >
            Analytics
          </ToggleButton>
        </ToggleButtonGroup>
      )}
      {activeTab === "settings" && (
        <EditAsset
          selectedAsset={selectedAsset}
          closeEdit={() => cancelClick()}
        />
      )}
      {activeTab === "slotsmonitoring" && (
        <SlotMointoring selectedAsset={selectedAsset} />
      )}
      {activeTab === "analytics" && (
        <AssetAnalytics assetId={selectedAsset.assetId} />
      )}
      {/* <Tabs
        defaultActiveKey={activeTab}
        activeKey={activeTab}
        variant="pills"
        onSelect={(key) => setActiveTab(key)}
        id="fill-tab-example"
        className="tab-margins edit-asset-tabs"
        fill
      >
        <Tab
          eventKey="settings"
          title="Settings"
          tabClassName={
            "settings-tab " + (activeTab != "settings" ? "inactive-tab" : "")
          }
        >
          <EditAsset
            selectedAsset={selectedAsset}
            closeEdit={() => cancelClick()}
          ></EditAsset>
        </Tab>
        <Tab
          eventKey="slotsmonitoring"
          title="Slots"
          tabClassName={
            "slotsmonitoring-tab " +
            (activeTab != "slotsmonitoring" ? "inactive-tab " : "")
          }
        >
          {activeTab === "slotsmonitoring" && (
            <SlotMointoring selectedAsset={selectedAsset} />
          )}
        </Tab>
        <Tab
          eventKey="analytics"
          title="Analytics"
          tabClassName={
            "analytics-tab " + (activeTab != "analytics" ? "inactive-tab" : "")
          }
        >
          {activeTab === "analytics" && (
            <AssetAnalytics assetId={selectedAsset.assetId} />
          )}
        </Tab>
      </Tabs> */}
    </Box>
  );
};
export default AddEditAsset;
