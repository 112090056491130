import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../utility/context";
import { useSelector } from "react-redux";
import { selectApplicationInfo } from "../../modules/App/selectors";

import { Carousel, Card } from "react-bootstrap";
import { Button, Box, ListItem, List, ListItemIcon, ListItemText } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import welcomepng from "../../static/welcome.png";
import BusinessGroup from "../../static/buiness_group_chart.png"

import "./welcome-flyer.styles.scss";

const carousal2List = [
  "Manage earning by online/offline bookings",
  "Manage your sports assets",
  "Manage bookings",
  "Create promotional campaigns",
  "Launch offers and attract more players",
  "Create subscription plan",
];

const WelcomeFlyer = () => {

  const user = useContext(UserContext);
  const navigate = useNavigate();
  const applicationInfo = useSelector(selectApplicationInfo);

  return (
    <div className="welcome-flyer-container">
      <Card className="py-2 px-4">
        <Carousel
          style={{ fontStyle: "italic" }}
          controls={true}
          interval={null}
          indicators={true}
        >
          {/* Asset  Details   */}
          <Carousel.Item>
            <div className="welcome-flyer-carousal-item">
              <div
                style={{
                  width: "100%",
                  color: "white",
                  fontWeight: "500",
                  fontSize: "20px",
                  textAlign: "center",
                  padding: "40px 145px 40px",
                }}
              >
                <span>Welcome to</span>
                <h3 style={{ fontSize: "30px", fontWeight: "bold" }}>
                  {" "}
                  {applicationInfo.applicationName}{" "}
                </h3>
                <span>{user.firstName + " " + user.lastName}</span>
                <p>
                  Managing your sports facility has become interesting and
                  effcient with PLAYNOOT Facility manager. Enable online booking
                  to your facility via Playnnot app. <br />
                  Activate your welcome plan now to doing real buisness!!
                </p>
                <br />
                <div className="image">
                  <img className="welcome-png" src={welcomepng} alt="" />
                </div>
              </div>
              <Carousel.Caption>
                <Button
                  onClick={() => {
                    navigate("/admin/license-plans");
                  }}
                  variant="contained"
                >
                  Activate license
                </Button>
              </Carousel.Caption>
            </div>
          </Carousel.Item>

          {/* Add Asset Details  */}
          <Carousel.Item>
            <div className="welcome-flyer-carousal-item">
              <div
                style={{
                  width: "100%",
                  color: "white",
                  fontWeight: "500",
                  fontSize: "30px",
                  padding: "40px 145px 40px",
                  display: "flex",
                }}
              >
                <div className="w-50">
                  <Box>
                    <List>
                      {carousal2List.map((item) => (
                        <ListItem>
                          <ListItemIcon>
                            <FontAwesomeIcon
                              className="mr-5"
                              icon={faCheck}
                              color="green"
                            />
                            <ListItemText
                              sx={{ color: "white", fontSize: "25px" }}
                              primary={item}
                            ></ListItemText>
                          </ListItemIcon>
                        </ListItem>
                      ))}
                    </List>
                  </Box>
                </div>
                <div className="w-50">
                  <img className="business-group-png" src={BusinessGroup} alt="" />
                </div>
              </div>
            </div>
          </Carousel.Item>

         
          {/* <Carousel.Item>
            <div className="welcome-flyer-carousal-item"></div>
          </Carousel.Item>

         
          <Carousel.Item>
            <div className="welcome-flyer-carousal-item"></div>
          </Carousel.Item> */}
        </Carousel>
      </Card>
    </div>
  );
};

export default WelcomeFlyer;
