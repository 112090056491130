import { useState, useEffect, useContext } from "react";
import { useDispatch } from "react-redux";

import { Card } from "@mui/material";
import InputControl from "../../../components/Controls/InputControl";
import ButtonControl from "../../../components/Controls/ButtonControl";
import CircularProgress from "@mui/material/CircularProgress";
import { amber } from "@mui/material/colors";

import { GameCenterViewContext } from "../../../utility/context";
import { URLS } from "../../../configs/api_urls";
import { httpPost } from "../../../utility/request";
import { addAlert } from "../../../modules/App/actions";

const ContactDetailsUpdate = () => {
  const dispatch = useDispatch();
  const gameCenterDetails = useContext(GameCenterViewContext);

  const [isSaving, setIsSaving] = useState(false);
  const [contactDetails, setContactDetails] = useState({
    contactPhoneNumber: gameCenterDetails.GameCenter.contactPhoneNumber,
    contactEmailId: gameCenterDetails.GameCenter.contactEmailId,
    webSiteLink: gameCenterDetails.GameCenter.webSiteLink,
  });

  const onChangeInput = ({ currentTarget }) => {
    var name = currentTarget.name;
    var value = currentTarget.value;
    setContactDetails({ ...contactDetails, [name]: value });
  };

   const handleSubmit = async () => {
     // API CAll here
     setIsSaving(true);
     await httpPost(
       URLS.APPLICATION_INSTANCE_SETTINGS.UPDATE_CONTACT_DETAILS,
       contactDetails
     )
       .then((response) => {
         if (response.data == true) {
           gameCenterDetails.setGameCenter({
             ...gameCenterDetails.GameCenter,
             contactPhoneNumber: contactDetails.contactPhoneNumber,
             contactEmailId: contactDetails.contactEmailId,
             webSiteLink: contactDetails.webSiteLink
           });
           dispatch(
             addAlert({
               type: "success",
               message: "Center deatils updated Successfully",
             })
           );
         }
       })
       .catch((error) => {
         dispatch(
           addAlert({
             type: "error",
             message: "Failed to update contact",
           })
         );
       })
       .finally(() => {
         setIsSaving(false);
       });
   };

  return (
    <div>
      <Card sx={{ maxWidth: "500px", margin: "auto" }}>
        <div className="mt-20">
          <label className="field-title">Contact Phone Number</label>
          <InputControl
            name="contactPhoneNumber"
            other={{ placeholder: "Enter Mobile Number" }}
            style={{ width: "100%", marginTop: "5px" }}
            onChange={onChangeInput}
            value={contactDetails.contactPhoneNumber}
            //error={validation.planName}
          ></InputControl>
        </div>
        <div className="mt-20">
          <label className="field-title">Contact Email Id</label>
          <InputControl
            name="contactEmailId"
            other={{ placeholder: "Enter Email Id" }}
            style={{ width: "100%", marginTop: "5px" }}
            onChange={onChangeInput}
            value={contactDetails.contactEmailId}
            //error={validation.planName}
          ></InputControl>
        </div>
        <div className="mt-20">
          <label className="field-title">Website Url</label>
          <InputControl
            name="webSiteLink"
            other={{ placeholder: "Enter Your Website Url" }}
            style={{ width: "100%", marginTop: "5px" }}
            onChange={onChangeInput}
            value={contactDetails.webSiteLink}
            //error={validation.planName}
          ></InputControl>
        </div>
      </Card>
      <div className="mt-20 text-align-right">
        <ButtonControl
          type="submit"
          text="Submit"
          onClick={handleSubmit}
          disabled={isSaving}
          innercomponent={
            isSaving && (
              <CircularProgress
                size={20}
                sx={{
                  color: amber[500],
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  marginTop: "-12px",
                  marginLeft: "-12px",
                }}
              />
            )
          }
        ></ButtonControl>
      </div>
    </div>
  );
};

export default ContactDetailsUpdate;
