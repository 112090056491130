import { styled } from "@mui/material/styles";
import { TextField } from "@mui/material";


const CustomTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "#43425d",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#dfb11f",
  },
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderColor: "#dfb11f",
    },
  },
});

export default CustomTextField;
