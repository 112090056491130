import { useContext, useState } from "react";
import { useSelector } from "react-redux";

import { UserContext } from "../../../utility/context";
import { selectApplicationLicense } from "../../../modules/App/selectors";

import { Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Collapse from "@mui/material/Collapse";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

import LicenseItemDetails from "../../License/LicenseItemDetails/license-item-details.component";

import moment from "moment-timezone";

import "./account-details.styles.scss";

const AccountDetails = () => 
{
    const [open, setOpen] = useState(false);
    const user = useContext(UserContext);

    const applicationLicense = useSelector(selectApplicationLicense);

    const getPlanPricingTypeText = (pricingTypeId) => 
    {
       var pricingTypeString = "";
       var pricing = applicationLicense.license.planPricings.find(
         (p) => p.licensePlanPricingId == pricingTypeId
       );
       if (pricing.licensePlanPricingType == 1)
       {
         pricingTypeString = "Annual";
       } else if (pricing.licensePlanPricingType == 2) 
       {
         pricingTypeString = "Monthly";
       }
         return pricingTypeString + " / " + pricing.planDuration;
    } 

    const getLocalDate = (date) => {
     return moment(date).tz("Asia/Calcutta").format("DD/MM/YYYY");
    }

    return (
      <div>
        <Typography variant="h4">Account</Typography>
        <div className="account-details-container">
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 400 }} aria-label="spanning table">
              <TableBody>
                <TableRow className="account-details-row">
                  <TableCell>Current User</TableCell>
                  <TableCell align="right">
                    {user.firstName + " " + user.lastName}
                  </TableCell>
                </TableRow>
                <TableRow className="account-details-row">
                  <TableCell>Email Id</TableCell>
                  <TableCell align="right">{user.emailId}</TableCell>
                </TableRow>
                <TableRow className="account-details-row">
                  <TableCell className="w-50">Primary Phone</TableCell>
                  <TableCell align="right">{user.primaryPhoneNumber}</TableCell>
                </TableRow>
                <TableRow className="account-details-row">
                  <TableCell>Secondary Phone</TableCell>
                  <TableCell align="right">
                    {user.secondaryPhoneNumber}
                  </TableCell>
                </TableRow>
                <TableRow className="account-details-row">
                  <TableCell>
                    Active Plan
                    <IconButton
                      aria-label="expand row"
                      size="small"
                      onClick={() => setOpen(!open)}
                    >
                      {open ? (
                        <KeyboardArrowUpIcon />
                      ) : (
                        <KeyboardArrowDownIcon />
                      )}
                    </IconButton>
                  </TableCell>
                  <TableCell align="right">
                    {applicationLicense.license.licenseName}
                  </TableCell>
                </TableRow>
                <TableRow className="account-details-row">
                  <TableCell>Type / Duration</TableCell>
                  <TableCell align="right">
                    {getPlanPricingTypeText(applicationLicense.licensePlanPricingId)}
                  </TableCell>
                </TableRow>
                <TableRow className="account-details-row">
                  <TableCell>Expires on</TableCell>
                  <TableCell align="right">
                    {getLocalDate(applicationLicense.planExpiryDateTime)}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    style={{ paddingBottom: 0, paddingTop: 0 }}
                    colSpan={6}
                  >
                    <Collapse in={open} timeout="auto" unmountOnExit>
                      <LicenseItemDetails
                        license={applicationLicense.license}
                      ></LicenseItemDetails>
                    </Collapse>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    );
}

export default AccountDetails;