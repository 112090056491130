import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { FormControl } from "@mui/material";
import {Checkbox } from "@mui/material";
import Controls from "../../../../../components/Controls/Controls";
import RichEditor from "../../../../../components/RichTextEditor";

import { URLS } from "../../../../../configs/api_urls";
import { httpGet, httpPut, httpPost } from "../../../../../utility/request";
import EditSubscriptionPlanOfferSkeleton from "../../../Skeletons/edit-subscription-plan-offer.component";

import { fireEvent } from "../../../../../modules/Events/eventSlice";

import "./edit-subscriptionplan-offer.styles.scss";

const planOfferTypeItems = [
  { id: 1, title: "Discount" },
  { id: 2, title: "Price Off" },
];

const validationIntialState = {
  offerType: 0,
  offerName: "",
  offerDescription: "",
  offerAmount: 0,
  offerTermsAndConditions: "",
};

const EditSubscriptionPlanOffer = ({ selectedOffer, closeEdit }) => {
  var dispatch = useDispatch();

  const [subscriptionPlanOffer, setSubscriptionPlanOffer] =
    useState(selectedOffer);
  const [isLoadingOfferLinks, setIsLoadingOfferLinks] = useState(true);
  const [linkedSubscriptionPlans, setLinkedSubscriptionPlans] = useState([]);
  const [validation, setValidation] = useState({ validationIntialState });

  const getSubscriptionOfferLinkedPlans = async () => {
    setIsLoadingOfferLinks(true);
    await httpGet(
      URLS.SUBSCRIPTION_PLAN_OFFERS.GETSUBSCRIPTIONPLANOFFERLINKEDPLANS.replace(
        "#ID#",
        selectedOffer.subscriptionPlanOfferId
      )
    )
      .then((response) => {
        setLinkedSubscriptionPlans(response.data);
        setIsLoadingOfferLinks(false);
      })
      .catch((error) => {
        //  setFormSubmitError(
        //    error.response.data.Message || "Some error occuered!"
        //  );
        setIsLoadingOfferLinks(false);
      });
  };

  const updateSubscriptionPlanOffer = async () => {
    // API CAll here
    await httpPut(URLS.SUBSCRIPTION_PLAN_OFFERS.UPDATESUBSCRIPTIONPLANOFFER, {
      ...subscriptionPlanOffer,
      linkedPlans: linkedSubscriptionPlans,
    })
      .then((response) => {
        if (response.data == true) {
          dispatch(
            fireEvent({ eventName: "refresh-subscriptionplanoffer-list" })
          );
          closeEdit();
        }
      })
      .catch((error) => {
        //  setFormSubmitError(
        //    error.response.data.Message || "Some error occuered!"
        //  );
      });
  };

  const createSubscriptionPlanOffer = async () => {
    // API CAll here
    await httpPost(URLS.SUBSCRIPTION_PLAN_OFFERS.CREATESUBSCRIPTIONPLANOFFER, {
      ...subscriptionPlanOffer,
      linkedPlans: linkedSubscriptionPlans,
    })
      .then((response) => {
        if (response.data == true) {
          dispatch(
            fireEvent({ eventName: "refresh-subscriptionplanoffer-list" })
          );
          closeEdit();
        }
      })
      .catch((error) => {
        //  setFormSubmitError(
        //    error.response.data.Message || "Some error occuered!"
        //  );
      });
  };

  const handleOfferExpiryDateChange = (value, subscriptionPlanId) => {
    var linkedSubscriptionPlansVal = linkedSubscriptionPlans.map((plan) =>
      plan.subscriptionPlanId === subscriptionPlanId
        ? { ...plan, expiryDate: value }
        : plan
    );
    setLinkedSubscriptionPlans(linkedSubscriptionPlansVal);
  };

  const handleTermsAndConditionsChange = (value) => {
    if (
      selectedOffer.subscriptionPlanOfferId ===
      subscriptionPlanOffer.subscriptionPlanOfferId
    ) {
      setSubscriptionPlanOffer({
        ...subscriptionPlanOffer,
        offerTermsAndConditions: value,
      });
    }
  };

  const handlePlanCheckedChange = (e, subscriptionPlanId) => {
    var value = e.target.checked;
    var linkedSubscriptionPlansVal = linkedSubscriptionPlans.map((plan) =>
      plan.subscriptionPlanId === subscriptionPlanId
        ? { ...plan, isLinked: value }
        : plan
    );
    setLinkedSubscriptionPlans(linkedSubscriptionPlansVal);
  };

  const event = useSelector((onEvent) => {
    var eventName = onEvent.events.eventName;
    if (eventName === "save-subscription-plan-offer") {
      if (subscriptionPlanOffer.subscriptionPlanOfferId > 0) {
        updateSubscriptionPlanOffer();
      } else {
        createSubscriptionPlanOffer();
      }

      dispatch(fireEvent({ eventName: "" }));
    }
    //reset event listner
  });

  useEffect(() => {
    setSubscriptionPlanOffer(selectedOffer);
    getSubscriptionOfferLinkedPlans();
  }, [selectedOffer]);

  return (
    <div>
      <div className="display-grid">
        <label className="field-title">Offer Type</label>
        <Controls.SelectControl
          name="plantype"
          style={{ width: "100%", marginTop: "5px" }}
          options={planOfferTypeItems}
          value={subscriptionPlanOffer.offerType}
          onChange={(e) =>
            setSubscriptionPlanOffer({
              ...subscriptionPlanOffer,
              offerType: e.target.value,
            })
          }
        ></Controls.SelectControl>

        <label className="field-title" style={{ marginTop: "20px" }}>
          Title
        </label>
        <FormControl>
          <Controls.InputControl
            name="title"
            other={{ placeholder: "Offer name" }}
            style={{ width: "100%", marginTop: "5px" }}
            onChange={(e) =>
              setSubscriptionPlanOffer({
                ...subscriptionPlanOffer,
                offerName: e.target.value,
              })
            }
            value={subscriptionPlanOffer.offerName}
            error={validation.planName}
          ></Controls.InputControl>
        </FormControl>

        <label className="field-title" style={{ marginTop: "20px" }}>
          Description
        </label>
        <FormControl>
          <Controls.InputControl
            name="description"
            other={{ placeholder: "Offer Description" }}
            style={{ width: "100%", marginTop: "5px" }}
            onChange={(e) =>
              setSubscriptionPlanOffer({
                ...subscriptionPlanOffer,
                offerDescription: e.target.value,
              })
            }
            value={subscriptionPlanOffer.offerDescription}
            error={validation.planName}
          ></Controls.InputControl>
        </FormControl>
        <label className="field-title" style={{ marginTop: "20px" }}>
          Offer amount
        </label>
        <Controls.InputControl
          name="amount"
          other={{
            type: "number",
            placeholder: "Amount",
          }}
          minNumber={0}
          style={{ width: "100%", marginTop: "5px" }}
          onChange={(e) =>
            setSubscriptionPlanOffer({
              ...subscriptionPlanOffer,
              offerAmount: e.target.value,
            })
          }
          value={subscriptionPlanOffer.offerAmount}
          error={validation.bankName}
        ></Controls.InputControl>

        <label className="field-title" style={{ marginTop: "20px" }}>
          Terms and Conditions
        </label>
        <RichEditor
          editorValue={subscriptionPlanOffer.offerTermsAndConditions}
          setEditorValue={(value) => handleTermsAndConditionsChange(value)}
        ></RichEditor>

        {subscriptionPlanOffer.subscriptionPlanOfferId > 0 && (
          <div>
            <label className="field-title" style={{ marginTop: "60px" }}>
              Linked Subscription Plans
            </label>
            {isLoadingOfferLinks && (
              <EditSubscriptionPlanOfferSkeleton></EditSubscriptionPlanOfferSkeleton>
            )}
            {!isLoadingOfferLinks && (
              <div>
                <div className="display-flex">
                  <div className="w-70">
                    <span>Plan name</span>
                  </div>
                  <div className="w-30">
                    <span>Expiry date</span>
                  </div>
                </div>
                {linkedSubscriptionPlans.map((item) => {
                  return (
                    <div className="display-flex offer-link-item">
                      <div className="w-70" style={{ marginTop: "10px" }}>
                        <Checkbox
                          id={item.subscriptionPlanId}
                          checked={item.isLinked}
                          onChange={(e) =>
                            handlePlanCheckedChange(e, item.subscriptionPlanId)
                          }
                        />
                        <span>{item.subscriptionPlanName}</span>
                      </div>
                      <div className="w-30">
                        <Controls.DatePickerControl
                          id={item.subscriptionPlanOfferId}
                          name="expirtyDate"
                          value={item.expiryDate}
                          disabled={!item.isLinked}
                          onChange={(e) =>
                            handleOfferExpiryDateChange(
                              e,
                              item.subscriptionPlanId
                            )
                          }
                        ></Controls.DatePickerControl>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default EditSubscriptionPlanOffer;
