import { Typography } from "@mui/material";

const GuideAssetOperations = () => 
{
    return (
      <div className="edit-container">
        <div>
          <Typography
            variant="h6"
            className="text-muted"
            sx={{ textAlign: "center", marginTop: "40px", fontStyle: "italic", fontWeight: "bold" }}
          >
            Let people book at your facility on Playnoot app
          </Typography>
        </div>
        <div className="mt-50 p-20">
          <div className="row">
            <div className="col-md-6">
              <Typography
                variant="h6"
                className="text-theme font-600 cursor-pointer"
              >
                Create Asset
              </Typography>
              <Typography>
                create bookable so that people can book turfs, grounds online.
              </Typography>
            </div>
            <div className="col-md-6">
              <Typography
                variant="h6"
                className="text-theme font-600 cursor-pointer"
              >
                Create asset offers
              </Typography>
              <Typography>
                create asset offers for users to increase engagement.
              </Typography>
            </div>
          </div>
          <div className="row mt-20">
            <div className="col-md-6">
              <Typography
                variant="h6"
                className="text-theme font-600 cursor-pointer"
              >
                Check asset Analytics
              </Typography>
              <Typography>
                See Asset booking analytics, Check montly bookings.
              </Typography>
            </div>
            <div className="col-md-6">
              <Typography
                variant="h6"
                className="text-theme font-600 cursor-pointer"
              >
                Interactive Pricings
              </Typography>
              <Typography>
                Create interactive pricings for assets. Create Fixed, Interveled
                or Weekly Pricings
              </Typography>
            </div>
          </div>
        </div>
      </div>
    );
}

export default GuideAssetOperations;