import { Avatar, Checkbox } from "@mui/material";

const BrandItem = ({
  brand,
  clickSelectBrand,
  isSelected,
  isDisabled,
  addToSelectedList,
}) => {
  return (
    <div>
      <div
        className={
          "asset-item cursor-pointer " +
          (isSelected ? "seleted-asset-background" : "")
        }
        onClick={clickSelectBrand}
      >
        <div className="w-100 display-inline-flex">
          <div style={{ margin: "auto" }}>
            <Checkbox
              id={brand.instanceBrandingId}
              checked={isSelected}
              onChange={addToSelectedList}
            />
          </div>
          <div className="w-10" style={{ margin: "auto" }}>
            <Avatar
              src={brand.brandingImageUrl}
              style={{ borderRadius: "50%", height: "70px", width: "70px" }}
            />
          </div>
          <div className="w-80" style={{marginLeft: "20px"}}>
            <label sx={{fontWeight: 500}}>{brand.instanceBrandingTitle}</label>
            <br />
            <span style={{ color: "dimgray" }}>{brand.instanceBrandingDescription}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BrandItem;
