import { Chip, Checkbox } from "@mui/material";

const SubscriptionPlanOfferItem = ({
  offer,
  clickSelectOffer,
  isSelected,
  isDisabled,
  addToSelectedList,
}) => {
  return (
    <div
      className={
        "plan-item cursor-pointer " +
        (isSelected ? "seleted-plan-background" : "")
      }
      onClick={clickSelectOffer}
    >
      <div className="w-100 display-inline-flex">
        <div className="w-10" style={{ margin: "auto" }}>
          <Checkbox
            id={offer.subscriptionPlanOfferId}
            checked={isSelected}
            onChange={addToSelectedList}
          />
        </div>
        <div className="w-80">
          <label>{offer.offerName}</label>
          <br />
          <span style={{ color: "dimgray" }}>{offer.offerDescription}</span>
          <div className="row">
            <span className="col-md-6">
              Offer type : {offer.offerType == 1 ? "discount" : "price off"}
            </span>
            <span className="col-md-6">
              Offers amount : {offer.offerAmount}
            </span>
          </div>
        </div>
        <div className="w-10" style={{ margin: "auto" }}>
          <Chip label={6} />
        </div>
      </div>
    </div>
  );
};

export default SubscriptionPlanOfferItem;
