const APP = "Cart";

export const CART_ACTION_CONSTANTS = {
  SET_IS_CART_OPEN: "SET_IS_CART_OPEN",
  SET_CART_ITEMS: "SET_CART_ITEMS",
  CARTITEMS_REQUEST_START: `${APP}/set-is-requesting`,
  CARTITEMS_REQUEST_SUCCESS: `${APP}/request-success`,
  CARTITEMS_REQUEST_FAILURE: `${APP}/request-failure`,

  SET_PRICING_TYPE: `${APP}/SET_PRICING_TYPE`,
  SET_IS_PAYMENT_WINDOW_OPEN: "SET_IS_PAYMENT_WINDOW_OPEN",
};
