import React from "react";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";

export default function CenterContentLoader() {
  return (
    <div className="card p-2 my-2">
      <div className="row">
        <Stack spacing={1} width="100px">
          <Skeleton variant="rounded" width={80} height={70} />
          <Skeleton variant="rounded" width={80} height={10} />
        </Stack>
        <Stack spacing={1} className="col-8">
          <Skeleton variant="rounded" width={210} height={15} />
          <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
          <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
        </Stack>
      </div>
    </div>
  );
}
