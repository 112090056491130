import { useDebugValue, useState } from "react";
import { useContext } from "react";
import { useDispatch } from "react-redux";

import { Box, Paper } from "@mui/material";
import Controls from "../../components/Controls/Controls";
import CircularProgress from "@mui/material/CircularProgress";
import ImageProcessor from "../../components/ImageProcessor/image-processor.component";

import { URLS } from "../../configs/api_urls";
import { httpPost, httpPut } from "../../utility/request";
import { UserContext } from "../../utility/context";
import { addAlert } from "../../modules/App/actions";

var initialUserInfoError = {
  emailId: "",
  password: "",
  firstName: "",
  middleName: "",
  lastName: "",
  gender: "",
  dateOfBirth: "",
  primaryPhoneNumber: "",
  secondaryPhoneNumber: "",
  profilePictureUrl: "",
  applicationType: "2",
};

const userGenderOptions = [
  { id: "Male", title: "Male" },
  { id: "Female", title: "Female" },
];

const regEx =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const UserProfileSettings = () => {
    const dispatch = useDispatch();
  const user = useContext(UserContext) || { user: { userName: "..." } };
  const [userInfo, setUserInfo] = useState(user);
  const [userInfoError, setUserInfoError] = useState(initialUserInfoError);
  const [isLoading, setIsLoading] = useState(false);

  const updateBasicDetails = async () => {
    setIsLoading(true);
    await httpPut(URLS.USER.UPDATE_BASIC_DETAILS, {
      ...userInfo,
    })
      .then((response) => {
        if (response.data == true) {
         dispatch(
           addAlert({
             type: "success",
             message: "Profile picture updated Successfully",
           })
         );
        }
      })
      .catch((error) => {
        debugger;
        alert("Some error occurerd");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

    const updateProfilePicture = async (pictureUrl) => {
      setIsLoading(true);
      await httpPut(
        URLS.USER.UPDATE_PROFILE_PICTURE.replace("#PICTUREURL#", pictureUrl)
      )
        .then((response) => {
          if (response.data == true) {
            dispatch(
              addAlert({
                type: "success",
                message: "Profile picture updated Successfully",
              })
            );
            setUserInfo({
              ...userInfo,
              profilePictureUrl: pictureUrl,
            });
          }
        })
        .catch((error) => {
          alert("Some error occurerd");
        })
        .finally(() => {
          setIsLoading(false);
        });
    };

  const onInputUserDetails = (e) => {
    const { name, value } = e.target;
    userDetailsValidationHandler({ name, value });
    setUserInfo({
      ...userInfo,
      [name]: value,
    });
  };

  const userDetailsValidationHandler = ({ name, value }) => {};

  return (
    <div>
      <Box sx={{ width: "100%" }}>
        <Paper
          sx={{
            width: "100%",
            mb: 2,
            padding: "15px",
          }}
        >
          <div className="display-flex">
            <div style={{ width: "120px", height: "120px" }}>
              <ImageProcessor
                alt="user_image"
                style={{ borderRadius: "15px" }}
                imageUrl={userInfo.profilePictureUrl}
                uploadUrl={URLS.FILES.PROFILE_IMAGE_UPLOAD}
                setImageData={(e) => {
                  updateProfilePicture(e);
                }}
              ></ImageProcessor>
            </div>
          </div>
          <div className="mt-20 row">
            <div className="col-md-4">
              <Controls.InputControl
                name="firstName"
                label={"First Name"}
                style={{ width: "100%", marginTop: "5px" }}
                onChange={onInputUserDetails}
                value={userInfo.firstName}
                error={userInfoError.firstName}
              ></Controls.InputControl>
            </div>
            <div className="col-md-4">
              <Controls.InputControl
                name="middleName"
                label={"Middle Name"}
                style={{ width: "100%", marginTop: "5px" }}
                onChange={onInputUserDetails}
                value={userInfo.middleName}
                error={userInfoError.middleName}
              ></Controls.InputControl>
            </div>
            <div className="col-md-4">
              <Controls.InputControl
                name="lastName"
                label={"Last Name"}
                style={{ width: "100%", marginTop: "5px" }}
                onChange={onInputUserDetails}
                value={userInfo.lastName}
                error={userInfoError.lastName}
              ></Controls.InputControl>
            </div>
          </div>
          <div className="row mt-20">
            <div className="col-md-6 display-grid">
              <Controls.SelectControl
                name="gender"
                style={{ minWidth: "100%", marginTop: "5px" }}
                options={userGenderOptions}
                value={userInfo.gender}
                onChange={onInputUserDetails}
              ></Controls.SelectControl>
            </div>
            <div className="col-md-6 display-grid">
              <Controls.DatePickerControl
                id={"dateOfBirth"}
                name="dateOfBirth"
                value={userInfo.dateOfBirth}
                style={{ width: "100%", marginTop: "10px" }}
                onChange={(date) =>
                  onInputUserDetails({
                    target: { name: "dateOfBirth", value: date },
                  })
                }
              ></Controls.DatePickerControl>
            </div>
          </div>
          <div className="row mt-20">
            <div className="col-md-4">
              <Controls.InputControl
                name="emailId"
                label={"Email Id"}
                style={{ width: "100%", marginTop: "5px" }}
                onChange={onInputUserDetails}
                value={userInfo.emailId}
                disabled={true}
                error={userInfoError.emailId}
              ></Controls.InputControl>
            </div>
            <div className="col-md-4">
              <Controls.InputControl
                name="primaryPhoneNumber"
                label={"Primary Phone Number"}
                style={{ width: "100%", marginTop: "5px" }}
                onChange={onInputUserDetails}
                disabled={true}
                value={userInfo.primaryPhoneNumber}
                error={userInfoError.primaryPhoneNumber}
              ></Controls.InputControl>
            </div>
            <div className="col-md-4">
              <Controls.InputControl
                name="secondaryPhoneNumber"
                label={"Secondary Phone Number"}
                maxLength={10}
                style={{ width: "100%", marginTop: "5px" }}
                onChange={onInputUserDetails}
                value={userInfo.secondaryPhoneNumber}
                error={userInfoError.secondaryPhoneNumber}
              ></Controls.InputControl>
            </div>
          </div>
          <div className="mt-20 row">
            <div className="col-md-4"></div>
            <div className="col-md-4"></div>
            <div className="col-md-4 text-align-right">
              <Controls.ButtonControl
                text={"Update"}
                style={{
                  width: "100px",
                  margin: "10px 10px",
                  backgroundColor: "playnootPrimary",
                }}
                onClick={() => updateBasicDetails()}
                innercomponent={
                  isLoading && (
                    <CircularProgress
                      size={25}
                      style={{ padding: "2px", marginLeft: "10px" }}
                      color="inherit"
                    ></CircularProgress>
                  )
                }
                //color={grey[700]}
              ></Controls.ButtonControl>
            </div>
          </div>
        </Paper>
      </Box>
    </div>
  );
};

export default UserProfileSettings;
