//notificationSlice.js
import { createSlice } from "@reduxjs/toolkit";
import { URLS } from "../../configs/api_urls";
import { httpGet } from "../../utility/request";

const initialState = {
  notifications: [],
  notificationsCount: 0,
  isLoading: false,
  error: null,
};

const notificationSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    fetchStart: (state) => {
      state.isLoading = true;
    },
    fetchNotificationSuccess: (state, action) => {
      state.isLoading = false;
      state.notifications = action.payload;
    },
    fetchNotificationCountSuccess: (state, action) => {
      state.isLoading = false;
      state.notificationsCount = action.payload;
    },
    fetchFailure: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

//reducer for licenseSlice.js
export default notificationSlice.reducer;

//selectors for licenseSlice.js
export const getAllNotifications = (state) => state.notifications.notifications;
export const getNotificationsCount = (state) => state.notifications.notificationsCount;
export const getIsLoading = (state) => state.notifications.isLoading;
export const getError = (state) => state.notifications.error;

//actions for licenseSlice.js
export const { fetchStart, fetchNotificationSuccess, fetchNotificationCountSuccess, fetchFailure } =
  notificationSlice.actions;

//thunk for notificationSlice.js
export const fetchNotifications = () => {
  return async (dispatch) => {
    const url = URLS.NOTIFICATIONS.GETALLNOTIFICATIONS;
    dispatch(fetchStart());

    httpGet(url)
      .then(({ data }) => {
        dispatch(fetchNotificationSuccess(data));
      })
      .catch((error) => {
        dispatch(fetchFailure(error));
      });
  };
};

export const fetchNotificationCount = () => {
  return async (dispatch) => {
    const url = URLS.NOTIFICATIONS.GETNOTIFICATIONCOUNT;
    dispatch(fetchStart());

    httpGet(url)
      .then(({ data }) => {
        dispatch(fetchNotificationCountSuccess(data));
      })
      .catch((error) => {
        dispatch(fetchFailure(error));
      });
  };
};
