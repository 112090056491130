import { useState } from "react";
import SubscriptionNavigation from "../SubscriptionNavigation/subscription-navigation.component";
import AddEditSubscriptionPlanOffer from "./AddEditSubscriptionPlanOffer/addedit-subscriptionplan-offer.component";
import SubscriptionPlanOffersList from "./SubscriptionPlansOfferList/subscriptionplan-offers-list.component";
import GuideSubscriptionPlanOperations from "../GuideSubscriptionPlanOperations/guide-subscription-plan-operations.component";

const SubscriptionPlanOffersDashboard = () => {
    const [selectedOffer, setSelectedOffer] = useState(null);
    const [selectedView, setSelectedView] = useState("");
    const [selectedOffersList, setSelectedOffersList] = useState([]);

  const newSubscriptiopnPlanOffer = {
    subscriptionPlanOfferId: 0,
    offerType: 1,
    offerName: "",
    offerDescription: "",
    offerAmount: 0,
    offerTermsAndConditions: "",
  };
      const handleOfferSelected = (offer) => {
        setSelectedOffer(offer);
        setSelectedView("addeditview");
        setSelectedOffersList([offer.subscriptionPlanOfferId]);
      };

  return (
    <div>
      <SubscriptionNavigation
        isCreateNewPlanVisible={false}
        isCreateNewOfferVisible={true}
        isPlansDashboardVisible={true}
        isPlanOffersDashboardVisible={false}
        isSubscribersPurchaseDashboardVisible={true}
        createNewOfferClick={() =>
          handleOfferSelected(newSubscriptiopnPlanOffer)
        }
      ></SubscriptionNavigation>
      <div className="display-inline-flex w-100">
        <div className="items-list-container w-50">
          <div className="items-list">
            <SubscriptionPlanOffersList
              selectedOffersList={selectedOffersList}
              clickSelectOffer={(offer) => {
                handleOfferSelected(offer);
              }}
            ></SubscriptionPlanOffersList>
          </div>
        </div>
        <div className="items-edit-container w-50">
          {selectedView == "" && (
            <GuideSubscriptionPlanOperations></GuideSubscriptionPlanOperations>
          )}
          {selectedView == "addeditview" && (
            <AddEditSubscriptionPlanOffer
              selectedOffer={selectedOffer}
              cancelClick={() => {
                setSelectedView("");
                setSelectedOffersList([]);
              }}
            ></AddEditSubscriptionPlanOffer>
          )}
        </div>
      </div>
    </div>
  );
};

export default SubscriptionPlanOffersDashboard;
