import { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import ButtonControl from "../../../../components/Controls/ButtonControl";

import { Box } from "@mui/material";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import EditLicense from "./EditLicense/edit-license.component";
import LicenseSubscribers from "./LicenseSubscribers/license-subscribers.component";
import ModalPopup from "../../../../components/ModalPopup/modalpopup.component";

import { setIsRenderedState } from "../../../../modules/ModalPopup/modalPopupSlice";
import { httpDelete } from "../../../../utility/request";
import { URLS } from "../../../../configs/api_urls";
import { fireEvent } from "../../../../modules/Events/eventSlice";

const AddEditLicense = ({ selectedLicense, cancelClick }) => {
  const clickSubmitRef = useRef();
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState("settings");
  const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false);

  const handelDeleteClick = async () => {
    await httpDelete(
      URLS.SUPER_ADMIN.LICENSE.DELETE_LICENSE.replace(
        "#ID#",
        selectedLicense.licenseId
      )
    )
      .then((response) => {
        if (response.data == true) {
          dispatch(fireEvent({ eventName: "refresh-licenses-list" }));
          dispatch(setIsRenderedState(false));
          setIsDeletePopupOpen(false);
          cancelClick();
        }
      })
      .catch((error) => {
        //  setFormSubmitError(
        //    error.response.data.Message || "Some error occuered!"
        //  );
      });
  };

  const handelSaveClick = () => {
    setActiveTab("settings");
    if (clickSubmitRef.current) {
      clickSubmitRef.current.saveLicensePlan();
    }
  };

  useEffect(() => {
    setActiveTab("settings");
  }, [selectedLicense]);

  return (
    <Box className="edit-container w-100">
      <Box className="display-flex">
        <ButtonControl
          text={"Cancel"}
          style={{
            width: "100px",
            margin: "10px 10px",
            backgroundColor: "gray",
          }}
          onClick={() => cancelClick()}
          //color={grey[700]}
        ></ButtonControl>
        <label className="edit-panel-title">
          {selectedLicense.licenseId > 0 ? "Edit License" : "Add License"}
        </label>
        <ButtonControl onClick={handelSaveClick} text={"Save"}></ButtonControl>
        {selectedLicense.licenseId > 0 && !selectedLicense.isDefault && (
          <ButtonControl
            onClick={() => {
              dispatch(setIsRenderedState(true));
              setIsDeletePopupOpen(true);
            }}
            style={{
              width: "40px",
              margin: "10px 5px",
              backgroundColor: "red",
            }}
            innercomponent={<FontAwesomeIcon icon={faTrash}></FontAwesomeIcon>}
          ></ButtonControl>
        )}
      </Box>
      <Box>
        {isDeletePopupOpen && (
          <ModalPopup
            heading={"Are you sure that you want to Delete this license?"}
            subHeadhing={
              "After deletion admin will not be able to see this license on license list"
            }
            isConfirmButton={true}
            confirmButtonText={"Yes"}
            onConfirmClick={handelDeleteClick}
            isCancelButton={true}
            cancelButtontext={"No"}
            onCancelClick={() => dispatch(setIsRenderedState(false))}
          ></ModalPopup>
        )}
        <Tabs
          defaultActiveKey={activeTab}
          activeKey={activeTab}
          variant="pills"
          onSelect={(key) => setActiveTab(key)}
          id="fill-tab-example"
          className="tab-margins edit-asset-tabs"
          fill
        >
          <Tab
            eventKey="settings"
            title="Settings"
            tabClassName={
              "left-tab " + (activeTab != "settings" ? "inactive-tab" : "")
            }
          >
            <EditLicense
              selectedLicense={selectedLicense}
              ref={clickSubmitRef}
              closeEdit={() => cancelClick()}
            />
          </Tab>
          <Tab
            eventKey="subscribers"
            title="Subscribers"
            tabClassName={
              "right-tab " + (activeTab != "subscribers" ? "inactive-tab " : "")
            }
          >
            <LicenseSubscribers />
          </Tab>
        </Tabs>
      </Box>
    </Box>
  );
};

export default AddEditLicense;
