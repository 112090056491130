import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { httpPost } from "../../../utility/request";
import { URLS } from "../../../configs/api_urls";
import Controls from "../../../components/Controls/Controls";
import SelectImage from '../../../components/EmptyImageSelect';

import { FormControl } from "@mui/material";
import { isEmpty } from "lodash";

import { selectApplicationLicenseConsumption } from '../../../modules/App/selectors';
import { mainAppSetApplicationLicenseConsumption } from "../../../modules/App/actions";

import "./create-admin-user.styles.scss";


const genderItems = [
  { id: "male", title: "Male" },
  { id: "female", title: "Female" },
  { id: "other", title: "Other" },
];

const CreateAdminUser = () => 
{
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const licenseConsumption = useSelector(selectApplicationLicenseConsumption);

  const [isImageUploading, setIsImageUploading] = useState(false);
  const [imageData, setImageData] = useState({
    image: {},
    key: "asset_image_01",
  });

  const [inputValues, setInputValue] = useState({
    emailId: "",
    password: "Password@123",
    gender: "",
    firstName: "",
    middleName: "",
    lastName: "",
    dateOfBirth: new Date(),
    profilePictureUrl: "",
    primaryPhoneNumber: "",
    secondaryPhoneNumber: "",
    applicationType: 2
  });

  const [validation, setValidation] = useState({
    emailId: "",
    password: "Password@123",
    applicationType: "",
    gender: "",
    firstName: "",
    middleName: "",
    lastName: "",
    dateOfBirth: new Date(),
    profilePictureUrl: "",
    primaryPhoneNumber: "",
    secondaryPhoneNumber: "",
  });

  /**
   * handle change image
   *
   * @param {Event} e
   */
  const handleImageChange = async (e) => {
    // upload image
    let form = new FormData();
    let image_file = e.target;
    form.append("formFiles", image_file.files[0]);
    setIsImageUploading(true);

    try {
      const { data } = await httpPost(URLS.FILES.PROFILE_IMAGE_UPLOAD, form, {
        "Content-Type": "multipart/form-data",
      });
      setImageData({
        ...imageData,
        image: { imageFullUrl: data.urls[0], isThumbnailImage: false },
      });
      setInputValue({ ...inputValues, profilePictureUrl: data.urls[0] });

    } catch (err) {
      console.error(err);
    } finally {
      image_file.value = "";
      setIsImageUploading(false);
    }
  };

  /**
   * Remove Image
   *
   * @param {String} id
   */
  const removeImage = (id) => {
    setImageData({});
  };

  //handle submit updates
  function handleChange(event) {
    const { name, value } = event.target;
    checkValidation();
    setInputValue({ ...inputValues, [name]: value });
  }

  const checkValidation = () => {
     let errors = validation;
     //first Name validation
     if (!inputValues.firstName.trim() || inputValues.firstName.trim() == "") {
       errors.firstName = "First name is required";
     } else {
       errors.firstName = "";
     }
     //last Name validation
     if (!inputValues.lastName.trim() || inputValues.lastName.trim() == "") {
       errors.lastName = "Last name is required";
     } else {
       errors.lastName = "";
     }

     // email validation
     const emailCond =
       "/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)*$/";
     if (!inputValues.emailId.trim() || inputValues.emailId.trim() == "") {
       errors.emailId = "Email is required";
     } else {
       errors.emailId = "";
     }

     //password validation
     const cond1 = "/^(?=.*[a-z]).{6,20}$/";
     const cond2 = "/^(?=.*[A-Z]).{6,20}$/";
     const cond3 = "/^(?=.*[0-9]).{6,20}$/";
     const password = inputValues.password;
     if (!password) {
       errors.password = "password is required";
     } else if (password.length < 6) {
       errors.password = "Password must be longer than 6 characters";
     } else if (!password.match(cond1)) {
       errors.password = "Password must contain at least one lowercase";
     } else if (!password.match(cond2)) {
       errors.password = "Password must contain at least one capital letter";
     } else if (!password.match(cond3)) {
       errors.password = "Password must contain at least a number";
     } else {
       errors.password = "";
     }

     //matchPassword validation
    //  if (!inputValues.confirmPassword) {
    //    errors.confirmPassword = "Password confirmation is required";
    //  } else if (inputValues.confirmPassword !== inputValues.Password) {
    //    errors.confirmPassword = "Password does not match confirmation password";
    //  } else {
    //    errors.password = "";
    //  }

    if (!inputValues.gender || inputValues.gender.trim() == "None" || inputValues.gender.trim() == "") {
      errors.gender = "Gender is required";
    } else {
      errors.gender = "";
    }

    if (!inputValues.primaryPhoneNumber.trim() || inputValues.primaryPhoneNumber.trim() == "") {
      errors.primaryPhoneNumber = "Primary phone is required";
    } else if (inputValues.primaryPhoneNumber.length != 9) {
      errors.primaryPhoneNumber = "Incorrect Phone number";
    } else {
      errors.primaryPhoneNumber = "";
    }

     setValidation(errors);
   };

  async function handleSubmit ()  {
    const { data } = await httpPost(URLS.ADMIN_USERS.CREATEDEPARTMENTUSER, inputValues);
    if (data)
    {   
      dispatch(
        mainAppSetApplicationLicenseConsumption({
          ...licenseConsumption,
          numberOfDeptAdmins: licenseConsumption.numberOfDeptAdmins + 1,
        })
      );
      navigate("/admin/admin-users");
    }
  };

  const resetForm = () => {};

  useEffect(() => {
    checkValidation();
  }, [inputValues]);

  return (
    <div className="create-admin-container">
      <SelectImage
        keySelect="select-image-asset"
        isEmpty={isEmpty(imageData.image)}
        classNames="col-md-2_5"
        imageData={imageData}
        handleImageChange={handleImageChange}
        onClickClose={removeImage}
        isLoading={isImageUploading}
        label={
          <p className="text-center mt-1 mb-0 text-small">Profile Picture</p>
        }
      />
      <div className="row">
        <div className="col-md-4">
          <FormControl>
            <Controls.InputControl
              name="firstName"
              label="First Name"
              onChange={(e) => handleChange(e)}
              value={inputValues.firstName}
              error={validation.firstName}
            ></Controls.InputControl>
          </FormControl>
        </div>
        <div className="col-md-4">
          <FormControl>
            <Controls.InputControl
              name="middleName"
              label="Middle Name"
              onChange={(e) => handleChange(e)}
              value={inputValues.middleName}
            ></Controls.InputControl>
          </FormControl>
        </div>
        <div className="col-md-4">
          <FormControl>
            <Controls.InputControl
              name="lastName"
              label="Last Name"
              onChange={(e) => handleChange(e)}
              value={inputValues.lastName}
              error={validation.lastName}
            ></Controls.InputControl>
          </FormControl>
        </div>
      </div>
      <div className="row">
        <div className="col-md-4">
          <FormControl>
            <Controls.InputControl
              name="emailId"
              label="Email Id"
              onChange={(e) => handleChange(e)}
              value={inputValues.emailId}
              error={validation.emailId}
            ></Controls.InputControl>
          </FormControl>
        </div>
        <div className="col-md-4">
          <FormControl>
            <Controls.InputControl
              name="primaryPhoneNumber"
              label="Primary Phone"
              onChange={(e) => handleChange(e)}
              value={inputValues.primaryPhoneNumber}
              error={validation.primaryPhoneNumber}
            ></Controls.InputControl>
          </FormControl>
        </div>
        <div className="col-md-4">
          <FormControl>
            <Controls.InputControl
              name="secondaryPhoneNumber"
              label="Secondary Phone"
              onChange={(e) => handleChange(e)}
              value={inputValues.secondaryPhoneNumber}
            ></Controls.InputControl>
          </FormControl>
        </div>
      </div>
      <div className="row">
        <div className="col-md-4">
          <FormControl>
            <Controls.DatePickerControl
              name="dateOfBirth"
              label="Date of Birth"
              value={inputValues.dateOfBirth}
              onChange={(e) => handleChange(e)}
            ></Controls.DatePickerControl>
          </FormControl>
        </div>
        <div className="col-md-4">
          <FormControl>
            <Controls.SelectControl
              name="gender"
              label="Gender"
              options={genderItems}
              value={inputValues.gender}
              onChange={(e) => handleChange(e)}
              error={validation.gender}
            ></Controls.SelectControl>
          </FormControl>
        </div>
        <div className="col-md-4"></div>
      </div>
      <div>
        <Controls.ButtonControl
          type="submit"
          text="Submit"
          onClick={handleSubmit}
        ></Controls.ButtonControl>
        <Controls.ButtonControl
          text="Reset"
          onClick={resetForm}
        ></Controls.ButtonControl>
        <Controls.ButtonControl
          text="Back"
          onClick={() => {navigate("/admin/admin-users");}}
        ></Controls.ButtonControl>
      </div>
    </div>
  );
}

export default CreateAdminUser;