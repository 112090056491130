import React, { useEffect, useState } from "react";

import Header from "../../components/Header";
import SideNavBar from "../../components/SideNavBar";
import Notify from "../../components/Notify";

//import "./layout.scss";

const SuperAdminAppLayout = ({
  children,
  selected_nav_item = 1,
  nav_items,
  handleOnLinkClick = null,
  theme = {},
}) => {
  return (
    <div>
      <div>
        <Header theme={theme} />
        <Notify />
        <SideNavBar
          selected_nav_item={selected_nav_item}
          nav_items={nav_items}
          handleOnLinkClick={handleOnLinkClick}
          theme={theme}
        ></SideNavBar>
        <div
          className="main scrollbar main_normal"
          style={{ maxHeight: "calc(100vh - 80px)", overflowY: "scroll" }}
        >
          {children}
        </div>
        {/* Footer */}
      </div>
    </div>
  );
};

export default SuperAdminAppLayout;
