import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Skeleton } from "@mui/material";

import { useEffect, useState } from "react";

export default function TableSkeleton({ pageNumber = 1, pageSize = 10 }) {
  const [rows, setRows] = useState([]);
  var ids = [];
  useEffect(() => {
    for (var i = 0; i < pageSize; ++i) {
      ids.push(i);
    }
    setRows(ids);
  }, [pageNumber, pageSize]);

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>
              <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
            </TableCell>
            <TableCell align="right">
              <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
            </TableCell>
            <TableCell align="right">
              <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
            </TableCell>
            <TableCell align="right">
              <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
            </TableCell>
            <TableCell align="right">
              <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
              </TableCell>
              <TableCell align="right">
                <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
              </TableCell>
              <TableCell align="right">
                <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
              </TableCell>
              <TableCell align="right">
                <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
              </TableCell>
              <TableCell align="right">
                <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
