import React from "react";
import { Box, Modal, Typography } from "@mui/material";

import { getIsRenderedState } from "../../modules/ModalPopup/modalPopupSlice";
import { useSelector } from "react-redux";

import "./modalpopup.styles.scss";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "4px",
  p: 4,
};

const ModalPopup = ({
  heading,
  subHeadhing,
  body,
  isConfirmButton,
  confirmButtonText,
  onConfirmClick,
  isCancelButton,
  cancelButtontext,
  onCancelClick,
  errorContent,
  data
}) => {

  const isRendered = useSelector(getIsRenderedState);

  return (
    <div className="confirm-modal">
      <Modal
        open={isRendered}
        //onClose={handleModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{ fontWeight: "bold" }}
          >
            {heading}
          </Typography>
          <span style={{ margin: "20px 5px 20px" }}>
            <i>{subHeadhing}</i>
          </span>
          <div>{body}</div>
          {errorContent != null && (
            <div className="modal-error">{errorContent}</div>
          )}
          <div className="text-align-right">
            {isConfirmButton && (
              <button
                className="theme-btn btn my-2 px-4"
                onClick={onConfirmClick}
              >
                {confirmButtonText}
              </button>
            )}

            {isCancelButton && (
              <button
                className="btn-danger btn my-2 mx-2"
                onClick={onCancelClick}
              >
                {cancelButtontext}
              </button>
            )}
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default ModalPopup;