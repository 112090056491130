import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import Loader from "../../../../components/Loader";
import SubscriptionPlanOfferItem from "./SubscriptionPlanOfferItem/subscriptionplan-offer-item.component";
import SelectControl from "../../../../components/Controls/SelectControl";

import { URLS } from "../../../../configs/api_urls";
import { httpGet } from "../../../../utility/request";

import { fireEvent } from "../../../../modules/Events/eventSlice";

const SubscriptionPlanOffersList = ({
  selectedOffersList,
  clickSelectOffer,
  addToSelectedList,
}) => {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState(0);
  const [subscriptionPlanOffers, setSubscriptionPlanOffers] = useState([]);
  const [selectedFilterItems, setSelectedFilterItems] = useState([]);

  const offerAvailability = [
    { id: 0, title: "Active", isArchived: false },
    { id: 1, title: "Archived", isArchived: true },
  ];

  const fetchSubscriptionPlanOffers = async () => {
    setIsLoading(true);
    const { data } = await httpGet(
      URLS.SUBSCRIPTION_PLAN_OFFERS.GETSUBSCRIPTIONPLANOFFERS
    );
    setSubscriptionPlanOffers(data);
    var isArchived = offerAvailability.find(
      (x) => x.id == selectedFilter
    ).isArchived;
    var offers = data.filter((p) => p.isArchived === isArchived);
    setSelectedFilterItems(offers);
    setIsLoading(false);
  };

    const handleFilterChange = (filter) => {
      var isArchived = offerAvailability.find((x) => x.id == filter).isArchived;
      setSelectedFilter(filter);
      var offers = subscriptionPlanOffers.filter(
        (p) => p.isArchived === isArchived
      );
      setSelectedFilterItems(offers);
    };

    const event = useSelector((onEvent) => {
      var eventName = onEvent.events.eventName;
      if (eventName === "refresh-subscriptionplanoffer-list") {
        fetchSubscriptionPlanOffers();
        dispatch(fireEvent({ eventName: "" }));
      }
      //reset event listner
    });

  useEffect(() => {
    fetchSubscriptionPlanOffers();
  }, []);

  return (
    <div>
      <Loader isLoading={isLoading}></Loader>
      <div className="list-filter-header">
        <SelectControl
          name="offer-availability"
          style={{ width: "120px", height: "35px" }}
          options={offerAvailability}
          value={selectedFilter}
          onChange={(e) => handleFilterChange(e.target.value)}
        ></SelectControl>
      </div>
      <div className="list-container">
        {selectedFilterItems.length == 0 && selectedFilter == 0 && (
          <div className="no-item-section">
            <span className="no-item-message">
              You have not published any subscription pla offer. Create subscription
              plans offers to attract more subscribers. Launch offers
              and get maximum benefits from business
            </span>
          </div>
        )}
        {selectedFilterItems.length == 0 && selectedFilter == 1 && (
          <div className="no-item-section">
            <span className="no-item-message">
              Archived subscription plan offers are not visible on playnoot mobile
              app, Hence can not be applied. If this offer was applied by any
              user before archiving then offer will be considered valid.
            </span>
          </div>
        )}
        {!isLoading &&
          selectedFilterItems.map((offer) => {
            return (
              <div key={offer.subscriptionPlanOfferId}>
                <SubscriptionPlanOfferItem
                  offer={offer}
                  isSelected={selectedOffersList.includes(
                    offer.subscriptionPlanOfferId
                  )}
                  addToSelectedList={addToSelectedList}
                  clickSelectOffer={() => {
                    clickSelectOffer(offer);
                  }}
                ></SubscriptionPlanOfferItem>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default SubscriptionPlanOffersList;
