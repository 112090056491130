import React from "react";
import { useNavigate } from "react-router-dom";
import { Route, Routes, useLocation } from "react-router-dom";
import {ThemeProvider} from "@mui/material";
import withUser from "../../hoc/withUser";

import { UserContext } from "../../utility/context";

import SuperAdminAppLayout from "./super-admin-app-layout.component";

import { APP_SUPERADMIN_ROUTES } from "../../configs/routes";
import { SUPER_ADMIN_NAV_ITEMS } from "../../configs/app_configs";
import playnootTheme from "../../utility/themeproviders";

const SuperAdminHome = ({ user }) => {
  const location = useLocation();
  const router = useNavigate();

    const handleOnLinkClick = (item_id) => {
      const item = SUPER_ADMIN_NAV_ITEMS.find((item) => {
        return item.id === item_id;
      });

      if (item.link) {
        router(item.link);
      }
    };

  const index = SUPER_ADMIN_NAV_ITEMS.findIndex((item) => {
    return item.link === location.pathname;
  });

  return (
    <ThemeProvider theme={playnootTheme}>
      <UserContext.Provider value={user}>
        <SuperAdminAppLayout
          nav_items={SUPER_ADMIN_NAV_ITEMS}
          selected_nav_item={index >= 0 ? index + 1 : 0}
          handleOnLinkClick={handleOnLinkClick}
        >
          <Routes>
            {APP_SUPERADMIN_ROUTES.map((route, index) => {
              return <Route {...route} key={`admin-route-${index}`} />;
            })}
          </Routes>
        </SuperAdminAppLayout>
      </UserContext.Provider>
    </ThemeProvider>
  );
};

export default withUser(SuperAdminHome);
