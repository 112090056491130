import { Grid, Item } from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchAvailableLicenses } from "../../../modules/License/licenseSlice";
import { getAllLicenses, getIsLoading } from "../../../modules/License/licenseSlice";

import Loader from "../../../components/Loader";
import LicenseItem from "../LicenseItem/licenseitem.component";

const LicenseList = () => {

  const licenseList = useSelector(getAllLicenses);
  const isRequesting = useSelector(getIsLoading);
  

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchAvailableLicenses());
  }, []);

  return (
    <Grid
      container
      spacing={3}
      width="100%"
      //align center
      direction="row"
      justifyContent="center"
    >
      {isRequesting && <Loader isLoading={isRequesting}></Loader>}
      {!isRequesting &&
        licenseList.map((item) => (
          <Grid item key={item.licenseId}>
            <LicenseItem key={item.licenseId} license={item}></LicenseItem>
          </Grid>
        ))}
    </Grid>
  );
};



export default LicenseList;