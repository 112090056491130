import { useState, useContext, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import Icon from "../../../components/Icons";
import ToggleCardSwitch from "../../../components/ToggleCardSwitch";

import { getFacilityAmenitiesDto } from "../../../modules/GameCenter/selector";

const FacilityAmenity = ({ newCenterAmenities, updateAmenities }) => {
  const amenities = useSelector(getFacilityAmenitiesDto());
  const [selectedAmenities, setSelectedAmenitiesList] =
    useState(newCenterAmenities);

  const isAmenityChecked = (amenityId) => {
    return selectedAmenities
      .map((aminity) => aminity.amenityId)
      .includes(amenityId);
  };

  const selectAmenity = async (amenity) => {
    if (selectedAmenities.includes(amenity)) {
      var newList = selectedAmenities.filter(
        (g) => g.amenityId !== amenity.amenityId
      );
      updateAmenities(newList);
    } else {
      selectedAmenities.push(amenity);
      updateAmenities(selectedAmenities);
    }
  };

  useEffect(() => {
    setSelectedAmenitiesList(newCenterAmenities);
  }, [newCenterAmenities]);

  return (
    <div className="mt-2">
      <h6 className="font-family-unset">Games</h6>
      <div className="row">
        {amenities.map((amenity) => {
          return (
            <div className="col-md-2">
              <ToggleCardSwitch
                key={amenity.amenityId}
                id={amenity.amenityId}
                isSelected={isAmenityChecked(amenity.amenityId)}
                onClick={() => {
                  selectAmenity(amenity);
                }}
                iconOn={
                  <Icon
                    color="white"
                    category="amenities"
                    icon={amenity.amenityName}
                  ></Icon>
                }
                iconOff={
                  <Icon
                    color="black"
                    category="amenities"
                    icon={amenity.amenityName}
                  ></Icon>
                }
                title={amenity.amenityName}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default FacilityAmenity;
