import React from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import withUser from "../../hoc/withUser";

import { UserContext } from "../../utility/context";

import AppLayout from "../../components/AppLayout";

import { APP_ADMIN_ROUTES } from "../../configs/routes";
import { ADMIN_NAV_ITEMS } from "../../configs/app_configs";
import { resetThemeColors } from "../../utility/common";

const AdminHome = ({ user }) => {
  const location = useLocation();

  const index = ADMIN_NAV_ITEMS.findIndex((item) => {
    return item.link === location.pathname;
  });

  resetThemeColors();

  return (
    <UserContext.Provider value={user}>
      <AppLayout nav_items={ADMIN_NAV_ITEMS} selected_nav_item={index >= 0 ? index + 1 : 0}>
        <Routes>
          {APP_ADMIN_ROUTES.map((route, index) => {
            return <Route {...route} key={`admin-route-${index}`} />;
          })}
        </Routes>
      </AppLayout>
    </UserContext.Provider>
  );
};

export default withUser(AdminHome);
