//eventSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  eventName: "",
  eventData: null,
};

const eventSlice = createSlice({
  name: "eventSlice",
  initialState,
  reducers: {
    setEvent: (state, action) => {
      state.eventName = action.payload.eventName;
      state.eventData = action.payload.eventData;
    },
  },
});

//reducer for eventSlice.js
export default eventSlice.reducer;

//selectors for eventSlice.js
export const onEvent = (state) => state.events;
//actions for eventSlice.js
export const { setEvent } = eventSlice.actions;


//thunk for eventSlice.js
export const fireEvent = (eventDetails) => {
  return async (dispatch) => {
    dispatch(setEvent(eventDetails));
  }
};

export const flushEvent = () => {
  return async (dispatch) => {
    dispatch(setEvent({ eventName: "" }));
  };
};