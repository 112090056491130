import { useState, useEffect } from "react";

import SubscriptionPlanItem from "./SubscriptionPlanItem/subscription-plan-item.component";
import SelectControl from "../../../components/Controls/SelectControl";

import { URLS } from "../../../configs/api_urls";
import { httpGet } from "../../../utility/request";
import { useSelector, useDispatch } from "react-redux";

import { fireEvent } from "../../../modules/Events/eventSlice";

import "./subscription-plan-list.styles.scss";
import SubscriptionListSkeleton from "../Skeletons/subscriptionplan-list-skeleton.component";

const planAvailability = [
  { id: 0, title: "Active", isArchived: false },
  { id: 1, title: "Archived", isArchived: true },
];

const SubscriptionPlanList = ({
  selectedPlanList,
  clickSelectPlan,
  addToSelectedList,
  cancelClick
}) => {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState(0);
  const [subscriptionPlans, setSubscriptionPlans] = useState([]);
  const [selectedFilterItems, setSelectedFilterItems] = useState([]);

  const handleFilterChange = (filter) => {
    var isArchived = planAvailability.find((x) => x.id == filter).isArchived;
    setSelectedFilter(filter);
    var plans = subscriptionPlans.filter((p) => p.isArchived === isArchived);
    setSelectedFilterItems(plans);
  };

  const fetchSubscriptionPlans = async () => {
    setIsLoading(true);
    const { data } = await httpGet(
      URLS.SUBSCRIPTION_PLANS.GETSUBSCRIPTIONPLANSLISTVM
    );
    setSubscriptionPlans(data);
    var isArchived = planAvailability.find((x) => x.id == selectedFilter).isArchived;
    var plans = data.filter((p) => p.isArchived === isArchived);
    setSelectedFilterItems(plans);
    setIsLoading(false);
  };

  const event = useSelector((onEvent) => {
    var eventName = onEvent.events.eventName;
    if (eventName === "refresh-subscriptionplan-list") {
      fetchSubscriptionPlans();
      dispatch(fireEvent({ eventName: "" }));
    }
    //reset event listner
  });

  useEffect(() => {
    fetchSubscriptionPlans();
  }, []);

  return (
    <div>
      <div className="list-filter-header">
        <SelectControl
          name="plan-availability"
          style={{ width: "120px", height: "35px" }}
          options={planAvailability}
          value={selectedFilter}
          onChange={(e) => {
            handleFilterChange(e.target.value);
            cancelClick();
          }}
        ></SelectControl>
      </div>
      <div className="list-container scrollbar">
        {isLoading && <SubscriptionListSkeleton></SubscriptionListSkeleton>}
        {selectedFilterItems.length == 0 &&
          selectedFilter == 0 &&
          !isLoading && (
            <div className="no-item-section">
              <span className="no-item-message">
                You have not published any subscription plan. Create
                subscription plans to manges your subscribers, payment online.
                Launch offers and get maximum benefits from business
              </span>
            </div>
          )}
        {selectedFilterItems.length == 0 &&
          selectedFilter == 1 &&
          !isLoading && (
            <div className="no-item-section">
              <span className="no-item-message">
                Archived subscription plans are not visible on playnoot mobile
                app, Hence can not be purchased. If this plan was purchased by
                any user before archiving they will stay subscribed to the same
                plan till their plan expiry.
              </span>
            </div>
          )}
        {!isLoading &&
          selectedFilterItems.map((plan) => {
            return (
              <div key={plan.subscriptionPlanId}>
                <SubscriptionPlanItem
                  plan={plan}
                  isSelected={selectedPlanList.includes(
                    plan.subscriptionPlanId
                  )}
                  addToSelectedList={addToSelectedList}
                  clickSelectPlan={() => {
                    clickSelectPlan(plan);
                  }}
                ></SubscriptionPlanItem>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default SubscriptionPlanList;
