import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fireEvent } from "../../../../modules/Events/eventSlice";

import { FormControl } from "@mui/material";
import Controls from "../../../../components/Controls/Controls";
import ImageProcessor from "../../../../components/ImageProcessor/image-processor.component";

import { URLS } from "../../../../configs/api_urls";
import { httpPut, httpPost } from "../../../../utility/request";
import { useEffect } from "react";

const externalProductVegNonVeg = [
  { id: 1, isVeg: true, title: "Veg" },
  { id: 2, isVeg: false, title: "Non Veg" },
];

const EditExternalProduct = ({ selectedExternalProduct, closeEdit }) => {
  const dispatch = useDispatch();

  const [selectedExternalProductItem, setSelectedExternalProductItem] =
    useState(selectedExternalProduct);

  const event = useSelector((onEvent) => {
    var eventName = onEvent.events.eventName;
    if (eventName === "save-external-product") {
      if (selectedExternalProduct.externalProductId > 0) {
        updateExternalProduct();
      } else {
        createExternalProduct();
      }

      dispatch(fireEvent({ eventName: "" }));
    }
    //reset event listner
  });

  const createExternalProduct = async () => {
    // API CAll here
    await httpPost(URLS.External_PRODUCTS.CREATE, selectedExternalProductItem)
      .then((response) => {
        if (response.data == true) {
          dispatch(fireEvent({ eventName: "refresh-external-product-list" }));
        }
      })
      .catch((error) => {
        //  setFormSubmitError(
        //    error.response.data.Message || "Some error occuered!"
        //  );
      })
      .finally(() => {
        closeEdit();
      });
  };

  const updateExternalProduct = async () => {
    // API CAll here
    await httpPut(URLS.External_PRODUCTS.UPDATE, selectedExternalProductItem)
      .then((response) => {
        if (response.data == true) {
          dispatch(fireEvent({ eventName: "refresh-external-product-list" }));
        }
      })
      .catch((error) => {
        //  setFormSubmitError(
        //    error.response.data.Message || "Some error occuered!"
        //  );
      })
      .finally(() => {
        closeEdit();
      });
  };

  useEffect(() => {
    setSelectedExternalProductItem(selectedExternalProduct);
  }, [selectedExternalProduct]);
  
  return (
    <div className="display-grid">
      <div className="row">
        <div className="col-md-2">
          <ImageProcessor
            alt="asset_image"
            style={{ height: "100%", borderRadius: "15px" }}
            imageUrl={selectedExternalProductItem.productPictureUrl}
            uploadUrl={URLS.FILES.EXTERNALPRODUCT_IMAGE_UPLOAD}
            setImageData={(imageFullUrl) => {
              setSelectedExternalProductItem({
                ...selectedExternalProductItem,
                productPictureUrl: imageFullUrl,
              });
            }}
          ></ImageProcessor>
        </div>
        <div className="col-md-10">
          <label className="field-title">Prooduct Name</label>
          <Controls.InputControl
            name="title"
            other={{ placeholder: "Product name" }}
            style={{ width: "100%", marginTop: "5px" }}
            onChange={(e) =>
              setSelectedExternalProductItem({
                ...selectedExternalProductItem,
                productName: e.target.value,
              })
            }
            value={selectedExternalProductItem.productName}
            //error={validation.planName}
          ></Controls.InputControl>
        </div>
      </div>
      <div className="edit-section-margin">
        <label className="field-title">Select type of this item</label>
        <Controls.SelectControl
          name="isVeg"
          style={{ marginTop: "5px" }}
          options={externalProductVegNonVeg}
          value={
            externalProductVegNonVeg.find(
              (x) => x.isVeg == selectedExternalProductItem.isVeg
            ).id
          }
          onChange={(e) =>
            setSelectedExternalProductItem({
              ...selectedExternalProductItem,
              isVeg: externalProductVegNonVeg.find(
                (x) => x.id == e.target.value
              ).isVeg,
            })
          }
        ></Controls.SelectControl>
      </div>
      <div className="edit-section-margin">
        <label className="field-title">Description</label>
        <FormControl>
          <Controls.InputControl
            name="title"
            isMultiLine={true}
            rows={3}
            other={{ placeholder: "Product Description" }}
            style={{ width: "100%", marginTop: "5px" }}
            onChange={(e) =>
              setSelectedExternalProductItem({
                ...selectedExternalProductItem,
                productDescription: e.target.value,
              })
            }
            value={selectedExternalProductItem.productDescription}
            //error={validation.planName}
          ></Controls.InputControl>
        </FormControl>
      </div>

      <div className="edit-section-margin">
        <label className="field-title">Price</label>
        <FormControl>
          <Controls.InputControl
            name="title"
            other={{ type: "number", placeholder: "Product Price" }}
            minNumber={0}
            style={{ width: "100%", marginTop: "5px" }}
            onChange={(e) =>
              setSelectedExternalProductItem({
                ...selectedExternalProductItem,
                productPrice: Number(e.target.value),
              })
            }
            value={selectedExternalProductItem.productPrice}
            //error={validation.planName}
          ></Controls.InputControl>
        </FormControl>
      </div>
    </div>
  );
};

export default EditExternalProduct;
