import "./guide-subscription-plan-operations.styles.scss";

const GuideSubscriptionPlanOperations = () => 
{
    return (
      <div className="component-container">
        <div style={{display: "inherit"}} className="operations-title">
          <div style={{margin: "auto"}}>Make your subscriber mangement digital</div>
        </div>
        <div className="mt-50 p-20">
          <div className="row">
            <div className="col-md-6">
              <h5 className="text-theme font-600 cursor-pointer">
                Create Subscription plan
              </h5>
              <span>
                create plans so that people can subscribe from the mobile app.
              </span>
            </div>
            <div className="col-md-6">
              <h5 className="text-theme font-600 cursor-pointer">
                Create plan offers
              </h5>
              <span>create subscription plan offers for users.</span>
            </div>
          </div>
          <div className="row mt-20">
            <div className="col-md-6">
              <h5 className="text-theme font-600 cursor-pointer">
                Check plan Analytics
              </h5>
              <span>
                See subscription analytics, Mange overdues, Check montly sales.
              </span>
            </div>
            <div className="col-md-6">
              <h5 className="text-theme font-600 cursor-pointer">
                Check varied pricings
              </h5>
              <span>
                Create modern pricings of diffeent duration for more customers.
              </span>
            </div>
          </div>
        </div>
      </div>
    );
}

export default GuideSubscriptionPlanOperations;