//modalPopupSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isRendered: false,
  data: null
};

const modalPopupSlice = createSlice({
  name: "modalPopup",
  initialState,
  reducers: {
    setIsRenderedState: (state, action) => {
      state.isRendered = action.payload;
    },
    setData: (state, action) => {
      state.data = action.payload;
    },
  },
});

//reducer for modalPopupSlice.js
export default modalPopupSlice.reducer;

//selectors for modalPopupSlice.js
export const getIsRenderedState = (state) => state.modalPopup.isRendered;
export const getModalDataState = (state) => state.modalPopup.data;

//actions for modalPopupSlice.js
export const { setIsRenderedState } = modalPopupSlice.actions;
export const { setData } = modalPopupSlice.actions;
