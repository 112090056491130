import { useState } from "react";
import PlayerList from "./PlayerList/player-list.component";
import PlayersNavigation from "./PlayersNavigation/player-navigation.component";
import PlayerSearch from "./AddPlayer/player-search.component";

const PlayerDashboard = () => 
{
    const [view, setView] = useState("list");
    return (
      <div>
        <PlayersNavigation
          selectedView={view}
          addPlayerClick={() => setView("addplayer")}
          playerListClick={() => setView("list")}
        ></PlayersNavigation>
        <div>
          {view == "list" && <PlayerList></PlayerList>}
          {view == "addplayer" && <PlayerSearch></PlayerSearch>}
        </div>
      </div>
    );
}

export default PlayerDashboard;