import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { fireEvent } from "../../../modules/Events/eventSlice";
import { setIsRenderedState } from "../../../modules/ModalPopup/modalPopupSlice";

import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import ButtonControl from "../../../components/Controls/ButtonControl";
import { faFileArchive } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import ModalPopup from "../../../components/ModalPopup/modalpopup.component";
import EditExternalProduct from "./EditExternalProduct/edit-external-product.component";
import ExternalProductSellingMatrix from "./SellingMatrix/external-product-selling-matrix.component";

import { URLS } from "../../../configs/api_urls";
import { httpDelete } from "../../../utility/request";

const AddEditExternalProduct = ({ selectedExternalProduct, cancelClick }) => {
    const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState("settings");

  const handelArchiveClick = async () => 
  {
       await httpDelete(
         URLS.External_PRODUCTS.DELETE.replace(
           "#ID#",
           selectedExternalProduct.externalProductId
         )
       )
         .then((response) => {
           if (response.data == true) {
             dispatch(
               fireEvent({ eventName: "refresh-external-product-list" })
             );
             dispatch(setIsRenderedState(false));
             cancelClick();
           }
         })
         .catch((error) => {
           //  setFormSubmitError(
           //    error.response.data.Message || "Some error occuered!"
           //  );
         });
  }

  const handleSaveClick = () => {
    dispatch(fireEvent({ eventName: "save-external-product" }));
  };

  const handleArchieveButtonClick = () => 
  {
     dispatch(setIsRenderedState(true));
  }

  useEffect(() => {
    setActiveTab("settings");
  }, [selectedExternalProduct]);

  return (
    <div className="edit-container w-100">
      <div className="display-flex">
        <ButtonControl
          text={"Cancel"}
          style={{
            width: "100px",
            margin: "10px 10px",
            backgroundColor: "gray",
          }}
          onClick={() => cancelClick()}
          //color={grey[700]}
        ></ButtonControl>
        <label className="edit-panel-title">
          {selectedExternalProduct.externalProductId > 0
            ? "Edit Product"
            : "Add Product"}
        </label>
        <ButtonControl
          onClick={() => handleSaveClick()}
          text={"Save"}
        ></ButtonControl>
        {selectedExternalProduct.externalProductId > 0 && (
          <ButtonControl
            onClick={handleArchieveButtonClick}
            style={{
              width: "40px",
              margin: "10px 5px",
              backgroundColor: "red",
            }}
            innercomponent={
              <FontAwesomeIcon icon={faFileArchive}></FontAwesomeIcon>
            }
          ></ButtonControl>
        )}
      </div>
      <ModalPopup
        heading={"Are you sure that you want to Arhive this Product?"}
        isConfirmButton={true}
        confirmButtonText={"Yes"}
        onConfirmClick={handelArchiveClick}
        isCancelButton={true}
        cancelButtontext={"No"}
        onCancelClick={() => dispatch(setIsRenderedState(false))}
      ></ModalPopup>
      <div>
        <Tabs
          defaultActiveKey={activeTab}
          activeKey={activeTab}
          variant="pills"
          onSelect={(key) => setActiveTab(key)}
          id="fill-tab-example"
          className="tab-margins edit-asset-tabs"
          fill
        >
          <Tab
            eventKey="settings"
            title="Settings"
            tabClassName={
              "settings-tab " + (activeTab != "settings" ? "inactive-tab" : "")
            }
          >
            <EditExternalProduct
              selectedExternalProduct={selectedExternalProduct}
              closeEdit={() => cancelClick()}
            ></EditExternalProduct>
          </Tab>
          <Tab
            eventKey="analytics"
            title="Analytics"
            tabClassName={
              "analytics-tab " +
              (activeTab != "analytics" ? "inactive-tab" : "")
            }
          >
            <ExternalProductSellingMatrix></ExternalProductSellingMatrix>
          </Tab>
        </Tabs>
      </div>
    </div>
  );
};

export default AddEditExternalProduct;
