import { URLS } from "../../configs/api_urls";
import { httpGet } from "../../utility/request";

import { createAction } from "../../utility/reducer.utils";
import { CART_ACTION_CONSTANTS } from "./cart.constants";

import LicenseImag from "../../static/license.png";

const addCartItem = (cartItems, productToAdd) => {
  const existingCartItem = cartItems.find(
    (cartItem) => cartItem.itemId === productToAdd.itemId
  );

  if (existingCartItem) {
    return cartItems.map((cartItem) =>
      cartItem.itemId === productToAdd.itemId
        ? {
            ...cartItem,
            quantity: cartItem.quantity + 1,
            price:
              productToAdd.param2.planPricings.find(
                (x) => x.licensePlanPricingId == cartItem.param1
              ).planPrice *
              (cartItem.quantity + 1),
          }
        : cartItem
    );
  }

  return [...cartItems, { ...productToAdd, quantity: 1 }];
};

//optimized removeCartItem function

const removeCartItem = (cartItems, cartItemToRemove) => {
  const existingCartItemIndex = cartItems.findIndex(
    (cartItem) => cartItem.itemId === cartItemToRemove.itemId
  );

  if (existingCartItemIndex === -1) {
    // if item is not found in cart, return the original cartItems array
    return cartItems;
  }

  const existingCartItem = cartItems[existingCartItemIndex];

  if (existingCartItem.quantity === 1) {
    // if item quantity is 1, remove it from the cart
    return cartItems.filter((cartItem) => cartItem.itemId !== cartItemToRemove.itemId);
  }

  // create a new cartItem object with reduced quantity and updated price
  const newCartItem = {
    ...existingCartItem,
    quantity: existingCartItem.quantity - 1,
    price:
      cartItemToRemove.param2.planPricings.find(
        (x) => x.licensePlanPricingId == existingCartItem.param1
      ).planPrice *
      (existingCartItem.quantity - 1),
  };

  // create a new array with the updated cartItem object
  const newCartItems = [...cartItems];
  newCartItems[existingCartItemIndex] = newCartItem;
  return newCartItems;
};


const updateCartItem = (cartItems, cartItemToUpdate, pricingId, quantity) => {
  return cartItems.map((cartItem) =>
    cartItem.adminCartId === cartItemToUpdate.adminCartId
      ? {
          ...cartItem,
          param1: pricingId,
          quantity: quantity,
          price:
            cartItemToUpdate.param2.planPricings.find(
              (x) => x.licensePlanPricingId == pricingId
            ).planPrice * quantity,
        }
      : cartItem
  );
};

const clearCartItem = (cartItems, cartItemToClear) =>
  cartItems.filter(
    (cartItem) => cartItem.adminCartId !== cartItemToClear.adminCartId
  );

export const fetchCartItemsSuccess = (data) => {
  var transformedArr = [];
  var transformedArr = data.reduce((total, data) => {
    transformedArr.push({
      adminCartId: data.adminCartId,
      itemId: data.itemId,
      name: JSON.parse(data.param2).licenseName,
      imageUrl: LicenseImag,
      quantity: data.quantity,
      price: data.totalPrice,
      param1: data.param1,
      param2: JSON.parse(data.param2),
    });
    return transformedArr;
  }, []);

  return createAction(
    CART_ACTION_CONSTANTS.CARTITEMS_REQUEST_SUCCESS,
    transformedArr
  );
};

export const fetchCartItemsFailure = (data) => {
  return createAction(CART_ACTION_CONSTANTS.CARTITEMS_REQUEST_FAILURE, data);
};

// to be called from functional component to intiate redux
export const fetchCartItems = () => {
  return async (dispatch) => {
    const url = URLS.CART.GET_CART_ITEMS;
    dispatch(fetchCartItemsStart());

    httpGet(url)
      .then(({ data }) => {
        dispatch(fetchCartItemsSuccess(data));
      })
      .catch((error) => {
        dispatch(fetchCartItemsFailure(error));
      });
  };
};

//#region action methods calls

export const addItemToCart = (cartItems, productToAdd) => {
  const newCartItems = addCartItem(cartItems, productToAdd);
  return createAction(CART_ACTION_CONSTANTS.SET_CART_ITEMS, newCartItems);
};

export const updateItemInCart = (cartItems, cartItemToUpdate, pricingId, quantity) => {
  const newCartItems = updateCartItem(cartItems, cartItemToUpdate, pricingId, quantity);
  return createAction(CART_ACTION_CONSTANTS.SET_CART_ITEMS, newCartItems);
};

export const clearItemFromCart = (cartItems, cartItemToClear) => {
  const newCartItems = clearCartItem(cartItems, cartItemToClear);
  return createAction(CART_ACTION_CONSTANTS.SET_CART_ITEMS, newCartItems);
};

export const removeItemFromCart = (cartItems, cartItemToRemove) => {
  const newCartItems = removeCartItem(cartItems, cartItemToRemove);
  return createAction(CART_ACTION_CONSTANTS.SET_CART_ITEMS, newCartItems);
};

export const setIsCartOpen = (boolean) =>
    createAction(CART_ACTION_CONSTANTS.SET_IS_CART_OPEN, boolean);

export const setIsPaymentScreenOpen = (boolean) =>
  createAction(CART_ACTION_CONSTANTS.SET_IS_PAYMENT_WINDOW_OPEN, boolean);

export const fetchCartItemsStart = () => {
  return createAction(CART_ACTION_CONSTANTS.CARTITEMS_REQUEST_START, true);
};

//#endregion action methods calls