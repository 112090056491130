import React from 'react'
import  {FormControl, InputLabel, Select, MenuItem, FormHelperText, Box, Typography}  from '@mui/material';

const SelectControl = (props) => {

    const { name, label, value,error=null, onChange, options, style, icon, disabled, showDefaultOption, defaultOptionText, containerClass } = props;

    return (
      <Box className={containerClass + " display-grid"}>
        <FormControl variant="outlined" {...(error && { error: true })}>
          <InputLabel>{label}</InputLabel>
          <Select
            sx={style}
            label={label}
            name={name}
            value={value}
            onChange={onChange}
            disabled={disabled}
            IconComponent={icon}
          >
            {showDefaultOption && (
              <MenuItem key={0} value={0}>
                {defaultOptionText || "All"}
              </MenuItem>
            )}
            {options.map((item) => (
              <MenuItem key={item.id} value={item.id}>
                <Typography>{item.title}</Typography>
              </MenuItem>
            ))}
          </Select>
          {error && <FormHelperText>{error}</FormHelperText>}
        </FormControl>
      </Box>
    );
}

export default SelectControl;