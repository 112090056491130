import { useState, useEffect } from "react";
import AssetNavigation from "../AssetNavigation/asset-navigation.component";
import AssetList from "./AssetsList/asset-list.component";

import moment from "moment";

import GuideAssetOperations from "./GuideAssetOperations/guide-asset-operations.component";
import AddEditAsset from "./AddEditAssets/add-edit-asset.component";

const AssetsDashboard = () => {
  const [selectedAsset, setSelectedAsset] = useState(null);
  const [selectedView, setSelectedView] = useState("");
  const [selectedAssetList, setSelectedAssetList] = useState([]);

    const handleAssetSelected = (asset) => {
      setSelectedAsset(asset);
      setSelectedView("addeditview");
      setSelectedAssetList([asset.assetId]);
    };

    const handleCreateNewAsset = () => 
    {
      var dateNow = moment(new Date()).format("YYYY-MM-DD");
        const newAsset = {
          assetId: 0,
          assetTypeId: 0,
          applicationInstanceId: 88,
          pricingId: 1,
          standardGameId: 0,
          assetName: "",
          assetDescription: "",
          assetCapacity: 0,
          isMultiBookingAllowed: false,
          minimumBookingsAllowed: 0,
          maximumBookingsAllowed: 1,
          isAddBookingCharges: false,
          bookingCharges: 0,
          isUpdatingSlots: false,
          slotUpdateOption: 0,
          pictureUrl: "",
          slotsStartTime: dateNow + "T03:30:00.237",
          slotsEndTime: dateNow + "T12:30:00.237",
          slotDuration: 60,
          timeZone: "Asia/Kolkata",
          isAllowPayLater: false,
          fixedPricing: {
            assetPricingFixedId: 0,
            timeInMinutes: 0,
            price: 0,
          },
          assetTimeSlots: [
            {
              slotStartTime: dateNow + "T03:30:00.237",
              slotEndTime: dateNow + "T04:30:00.237",
              slotDisplayText: "9:00 AM - 10:00 AM ",
              slotDuration: 60,
              isDisabled: false,
              isBooked: false,
            },
            {
              slotStartTime: dateNow + "T04:30:00.237",
              slotEndTime: dateNow + "T05:30:00.237",
              slotDisplayText: "10:00 AM - 11:00 AM ",
              slotDuration: 60,
              isDisabled: false,
              isBooked: false,
            },
            {
              slotStartTime: dateNow + "T05:30:00.237",
              slotEndTime: dateNow + "T06:30:00.237",
              slotDisplayText: "11:00 AM - 12:00 PM ",
              slotDuration: 60,
              isDisabled: false,
              isBooked: false,
            },
            {
              slotStartTime: dateNow + "T06:30:00.237",
              slotEndTime: dateNow + "T07:30:00.237",
              slotDisplayText: "12:00 PM - 1:00 PM ",
              slotDuration: 60,
              isDisabled: false,
              isBooked: false,
            },
            {
              slotStartTime: dateNow + "T07:30:00.237",
              slotEndTime: dateNow + "T08:30:00.237",
              slotDisplayText: "1:00 PM - 2:00 PM ",
              slotDuration: 60,
              isDisabled: false,
              isBooked: false,
            },
            {
              slotStartTime: dateNow + "T08:30:00.237",
              slotEndTime: dateNow + "T09:30:00.237",
              slotDisplayText: "2:00 PM - 3:00 PM ",
              slotDuration: 60,
              isDisabled: false,
              isBooked: false,
            },
            {
              slotStartTime: dateNow + "T09:30:00.237",
              slotEndTime: dateNow + "T10:30:00.237",
              slotDisplayText: "3:00 PM - 4:00 PM ",
              slotDuration: 60,
              isDisabled: false,
              isBooked: false,
            },
            {
              slotStartTime: dateNow + "T10:30:00.237",
              slotEndTime: dateNow + "T11:30:00.237",
              slotDisplayText: "4:00 PM - 5:00 PM ",
              slotDuration: 60,
              isDisabled: false,
              isBooked: false,
            },
            {
              slotStartTime: dateNow + "T11:30:00.237",
              slotEndTime: dateNow + "T12:30:00.237",
              slotDisplayText: "5:00 PM - 6:00 PM ",
              slotDuration: 60,
              isDisabled: false,
              isBooked: false,
            },
          ],
          pricings: [],
          assetOffers: [],
          weeklyPricing: [],
        };
        handleAssetSelected(newAsset);
    }

  return (
    <div>
      <div>
        <AssetNavigation
          isCreateAssetVisible={true}
          isCreateNewAssetOfferVisible={false}
          isCreateNewAssetTypeVisible={false}
          isAssetDashboardVisible={false}
          isAssetOffersDashboardVisible={true}
          isAssetTypeDashboardVisible={true}
          checkBookingPerformence={true}
          checkBookingEarningPerformence={true}
          createNewAssetClick={(e) => {
            handleCreateNewAsset();
          }}
        ></AssetNavigation>
      </div>
      <div className="display-inline-flex w-100">
        <div className="items-list-container w-50">
          <div className="items-list">
            <AssetList
              selectedAssetList={selectedAssetList}
              clickSelectAsset={(asset) => {
                handleAssetSelected(asset);
              }}
              cancelClick={() => {
                setSelectedView("");
                setSelectedAssetList([]);
              }}
            ></AssetList>
          </div>
        </div>
        <div className="items-edit-container  w-50">
          {selectedView == "" && <GuideAssetOperations></GuideAssetOperations>}
          {selectedView == "addeditview" && (
            <AddEditAsset
              selectedAsset={selectedAsset}
              cancelClick={() => {
                setSelectedView("");
                setSelectedAssetList([]);
              }}
            ></AddEditAsset>
          )}
        </div>
      </div>
    </div>
  );
};

export default AssetsDashboard;
