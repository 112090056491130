import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { URLS } from "../../../configs/api_urls";
import { httpPost } from "../../../utility/request";

import { Box, CircularProgress, IconButton } from "@mui/material";
import { Paper } from "@mui/material";
import { List } from "@mui/material";
import { ListItem } from "@mui/material";
import { ListItemButton } from "@mui/material";

import InputControl from "../../../components/Controls/InputControl";
import FontAwesome from "../../../components/Icons/fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

import { addAlert } from "../../../modules/App/actions";

const PlayerSearch = () => {
  const dispatch = useDispatch();

  const [searchData, setSearchData] = useState(null);
  const [userList, setUserList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [userName, setUserName] = useState("");
  const [pageNumber, setPage] = useState(1);
  const [pageSize, setRowsPerPage] = useState(10);

  const searchUsers = async () => {
    if (userName.length < 3) return;
    setIsLoading(true);
    const { data } = await httpPost(
      URLS.USER.SEARCH_AVAILABLE_PLAYERS.replace("#NAME#", userName)
        .replace("#PAGENUMBER#", pageNumber)
        .replace("#PAGESIZE#", pageSize)
    );
    setSearchData(data);
    setUserList(data.items);
    setIsLoading(false);
  };

  const AddUser = async (userId) => {
    await httpPost(URLS.PLAYERS.ADDPLAYER.replace("#ID#", userId)).then(
      ({ data }) => {
        if (data == true) {
          dispatch(
            addAlert({
              type: "success",
              message: "User added Successfully",
            })
          );
          searchUsers();
        }
      }
    );
  };

  useEffect(() => {
    searchUsers();
  }, [userName]);

  return (
    <div>
      <Box sx={{ width: "100%" }}>
        <Paper
          sx={{
            width: "100%",
            mb: 2,
            height: "calc(100vh - 150px)",
            padding: "15px",
          }}
        >
          <InputControl
            name="searchfiels"
            other={{ placeholder: "Enter username, email or full name" }}
            style={{ width: "100%", marginTop: "5px" }}
            onChange={(e) => setUserName(e.target.value)}
          ></InputControl>
          {isLoading && <CircularProgress></CircularProgress>}
          {!isLoading && userList.length == 0 && (
            <div style={{ margin: "auto" }}>
              <span>No user found for search "{userName}"</span>
            </div>
          )}
          {!isLoading && userList.length > 0 && (
            <List>
              {userList.map((user, index) => {
                return (
                  <ListItem disablePadding>
                    <ListItemButton>
                      <div className="row w-100">
                        {index !== 0 && <hr />}
                        <div className="col-md-4">
                          <img
                            alt={""}
                            src={user.profilePictureUrl}
                            style={{
                              borderRadius: "35px",
                              height: "70px",
                              width: "70px",
                              padding: "10px",
                            }}
                            className=""
                          />
                          <span>{user.userName}</span>
                        </div>
                      </div>
                      <IconButton onClick={() => AddUser(user.userId)}>
                        <FontAwesome
                          icon={faPlus}
                          iconSize={"20px"}
                          className={"info-icon-margin"}
                        ></FontAwesome>
                      </IconButton>
                    </ListItemButton>
                  </ListItem>
                );
              })}
            </List>
          )}
        </Paper>
      </Box>
    </div>
  );
};

export default PlayerSearch;
