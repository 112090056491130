import { useState } from "react";
import { useDispatch } from "react-redux";

import { CircularProgress, Table, TableBody } from "@mui/material";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";

import FontAwesome from "../../../../components/Icons/fontawesome";
import {
  faCheckCircle,
  faClock,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { Typography } from "@mui/material";
import ButtonControl from "../../../../components/Controls/ButtonControl";

import { URLS } from "../../../../configs/api_urls";
import { httpPost } from "../../../../utility/request";
import { fireEvent } from "../../../../modules/Events/eventSlice";

const BankAccountDetails = ({ bankAccountDetails }) => {
  const dispatch = useDispatch();
  var [isLoadingStatusUpdate, setIsLoadingStatusUpdate] = useState(false);

  const ChangeVergivationStatus = async (status) => {
    setIsLoadingStatusUpdate(true);
    await httpPost(
      URLS.BANKING_DETAILS.VERIFY_BANK_DETAILS.replace(
        "#ID#",
        bankAccountDetails.adminBankDetailId
      ).replace("#STATUS#", status)
    )
      .then(({ data }) => {
        if (data == true) {
          dispatch(
            fireEvent({
              eventName: "update-bank-detail-status",
              eventData: {
                adminBankDetailId: bankAccountDetails.adminBankDetailId,
                verificationStatus: status,
              },
            })
          );
        }
      })
      .finally(() => {
        setIsLoadingStatusUpdate(false);
      });
  };

  return (
    <div>
      <div style={{ textAlign: "center", marginTop: "10px" }}>
        {bankAccountDetails.verificationStatus == 1 && (
          <div>
            <FontAwesome
              icon={faClock}
              iconSize={"60px"}
              iconcolor={"#ffcc00"}
            ></FontAwesome>
            <Typography variant="h5" sx={{ textAlign: "center" }}>
              Submitted
            </Typography>
          </div>
        )}
        {bankAccountDetails.verificationStatus == 2 && (
          <div>
            <FontAwesome
              icon={faCheckCircle}
              iconSize={"60px"}
              iconcolor={"green"}
            ></FontAwesome>
            <Typography variant="h5" sx={{ textAlign: "center" }}>
              Verified
            </Typography>
          </div>
        )}
        {bankAccountDetails.verificationStatus == 3 && (
          <div>
            <FontAwesome
              icon={faTimesCircle}
              iconSize={"60px"}
              iconcolor={"red"}
            ></FontAwesome>
            <Typography variant="h5" sx={{ textAlign: "center" }}>
              Failed
            </Typography>
          </div>
        )}
      </div>
      <div className="mt-20">
        <Typography variant="h6" sx={{ textAlign: "center" }}>
          Account Details
        </Typography>
        <Table sx={{ minWidth: "500px", fontFamily: "Kanit" }}>
          <TableRow>
            <TableCell sx={{ border: "none" }}>Request Id</TableCell>
            <TableCell sx={{ float: "right", border: "none" }}>
              {"#" + bankAccountDetails.adminBankDetailId}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ border: "none" }}>App Name</TableCell>
            <TableCell sx={{ float: "right", border: "none" }}>
              {bankAccountDetails.application.applicationName}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ border: "none" }}>App Instance Name</TableCell>
            <TableCell sx={{ float: "right", border: "none" }}>
              {bankAccountDetails.applicationInstance.applicationInstanceName}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ border: "none" }}>Bank Name</TableCell>
            <TableCell sx={{ float: "right", border: "none" }}>
              {bankAccountDetails.bankName}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ border: "none" }}>Bank Address</TableCell>
            <TableCell sx={{ float: "right", border: "none" }}>
              {bankAccountDetails.bankAddress}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ border: "none" }}>Account Type</TableCell>
            <TableCell sx={{ float: "right", border: "none" }}>
              {bankAccountDetails.accountType}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ border: "none" }}>Bank Address</TableCell>
            <TableCell sx={{ float: "right", border: "none" }}>
              {bankAccountDetails.ifscCode}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ border: "none" }}>Bank Address</TableCell>
            <TableCell sx={{ float: "right", border: "none" }}>
              {bankAccountDetails.accountNumber}
            </TableCell>
          </TableRow>
        </Table>
        {bankAccountDetails.verificationStatus == 1 && (
          <div>
            <ButtonControl
              text="Verify"
              color={"success"}
              onClick={() => ChangeVergivationStatus(2)}
            />
            <ButtonControl
              text="Reject"
              color={"error"}
              onClick={() => ChangeVergivationStatus(3)}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default BankAccountDetails;
