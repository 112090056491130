import { useState, useEffect } from "react";
import { Box, Grid, Typography } from "@mui/material";
import DatePickerControl from "../../../../../components/Controls/DatePickerControl";

import { URLS } from "../../../../../configs/api_urls";
import { httpPost } from "../../../../../utility/request";
import moment from "moment-timezone";

import Highcharts from "highcharts";

var initialQuery = {
  startDateTime: moment().subtract(1, "months").toISOString(),
  endDateTime: moment().toISOString(),
};

const AssetAnalytics = (assetId) => {
  const [assetAnalyticsQuery, setAssetAnalyticsQuery] = useState({
    ...initialQuery,
    assetId: assetId.assetId,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [assetTrendAnalyticsData, setTrendAssetAnalyticsData] = useState([]);

  const fetchAssetTrendAnalytics = async () => {
    setTrendAssetAnalyticsData([]);
    setIsLoading(true);
    await httpPost(
      URLS.ASSET.ASSET_ANALYTICS.GET_ASSET_TREND_ANALYTICS,
      assetAnalyticsQuery
    )
      .then(({ data }) => {
        var assetTrendData = [];
        var difference = moment(assetAnalyticsQuery.endDateTime).diff(
          moment(assetAnalyticsQuery.startDateTime),
          "days"
        );
        for (var i = 0; i <= difference; i++) {
          var currDay = moment(assetAnalyticsQuery.startDateTime).add(
            i,
            "days"
          );
          var point = data.find(
            (point) =>
              moment(point.date).format("YYYY-MM-DD") ==
              currDay.format("YYYY-MM-DD")
          );
          if (point) {
            assetTrendData.push({ ...point, date: currDay });
          } else {
            assetTrendData.push({
              date: currDay,
              aggregatedIncome: 0,
              totalBookings: 0,
            });
          }
        }
        setTrendAssetAnalyticsData(assetTrendData);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const makeLineChart = () => {
    Highcharts.chart("linecontainer", {
      title: {
        text: "Booking trend",
        align: "left",
      },

      subtitle: {
        text: "Booking trend of a selected time period",
        align: "left",
      },

      yAxis: {
        min: 0,
        tickInterval: 1,
        title: {
          text: "Number of Bookings",
        },
      },

      xAxis: {
        type: "datetime",
        labels: {
          format: "{value:%Y-%m-%d}",
        },
      },

      plotOptions: {
        series: {
          label: {
            connectorAllowed: false,
          },
        },
      },

      series: [
        {
          name: "Bookings",
          data: assetTrendAnalyticsData.map((point) => {
            return [moment(point.date).valueOf(), point.totalBookings]; // x, y format
          }),
        },
      ],

      responsive: {
        rules: [
          {
            condition: {
              maxWidth: 500,
            },
            chartOptions: {
              legend: {
                layout: "horizontal",
                align: "center",
                verticalAlign: "bottom",
              },
            },
          },
        ],
      },
    });
  };

  const makeBarChart = () => {
    Highcharts.chart("barcontainer", {
      chart: {
        type: "column",
      },
      title: {
        text: "Earning trend",
        align: "left",
      },
      subtitle: {
        text: "Earning trend of a selected time period",
        align: "left",
      },
      xAxis: {
        type: "datetime",
        labels: {
          format: "{value:%Y-%m-%d}",
        },
      },
      plotOptions: {
        series: {
          pointWidth: 15,
        },
      },
      yAxis: {
        min: 0,
        title: {
          text: "Earning (Rs.)",
        },
      },
      series: [
        {
          name: "Income",
          data: assetTrendAnalyticsData.map((point) => {
            return [moment(point.date).valueOf(), point.aggregatedIncome]; // x, y format
          }),
        },
      ],
    });
  };

  useEffect(() => {
    makeLineChart();
    makeBarChart();
  }, [assetTrendAnalyticsData])
  useEffect(() => {
    fetchAssetTrendAnalytics();
  }, [assetAnalyticsQuery]);

  return (
    <Box>
      <Box>
        <Typography fontWeight={600} className="field-title">
          Select Date Range
        </Typography>
        <Grid sx={{ mt: 2, mb: 2 }} container>
          <Grid item md={6}>
            <DatePickerControl
              id={"startDateTime"}
              name="startDateTime"
              label={"From"}
              inputFormat={"MMM DD, yyyy"}
              inputStyle={{ width: "95%" }}
              maxDate={moment()}
              value={assetAnalyticsQuery.startDateTime}
              onChange={(e) =>
                setAssetAnalyticsQuery({
                  ...assetAnalyticsQuery,
                  startDateTime: moment(e).toISOString(),
                })
              }
            ></DatePickerControl>
          </Grid>
          <Grid item md={6}>
            <DatePickerControl
              id={"endDateTime"}
              name="endDateTime"
              label={"To"}
              inputFormat={"MMM DD, yyyy"}
              inputStyle={{ width: "100%" }}
              maxDate={moment()}
              value={assetAnalyticsQuery.endDateTime}
              onChange={(e) =>
                setAssetAnalyticsQuery({
                  ...assetAnalyticsQuery,
                  endDateTime: moment(e).toISOString(),
                })
              }
            ></DatePickerControl>
          </Grid>
        </Grid>
      </Box>
      <div id="linecontainer"></div>
      <div id="barcontainer"></div>
    </Box>
  );
};

export default AssetAnalytics;
