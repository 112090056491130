import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

import { Country, State, City } from "country-state-city";
import Controls from "../../../components/Controls/Controls";
import GoogleMap from "../../../components/GoogleMap/google-map.component";

const Address = ({ addressInput, updateAddress }) => {
  const dispatch = useDispatch();

  const [address, setAddress] = useState(addressInput);
  const [states, setStates] = useState([{ id: 0, title: "None" }]);
  const [cities, setCities] = useState([{ id: 0, title: "None" }]);

  const countries = Country.getAllCountries().map((item) => ({
    ...item,
    id: item.isoCode,
    title: item.name,
  }));

  const getStatesByCountry = (countryCode) => {
    const states = State.getStatesOfCountry(countryCode).map((item) => ({
      ...item,
      id: item.isoCode,
      title: item.name,
    }));

    setStates(states);
  };

  const getCitiesByState = (countryCode, stateCode) => {
    var cities = City.getCitiesOfState(countryCode, stateCode).map((item) => ({
      ...item,
      id: item.name,
      title: item.name,
    }));

    setCities(cities);
  };

  const setGoogleMapLocation = (mapLocation) => {
    setAddress({
      ...address,
      googleMapId: mapLocation.googleMapId,
      googleMapAddress: mapLocation.googleMapAddress,
      latitude: mapLocation.latitude,
      longitude: mapLocation.longitude,
      addressLane1: mapLocation.addressLane1,
      addressLane2: mapLocation.addressLane2,
      zoomVal: 16,
    });
  };

  useEffect(() => {
    //var countryCode = countries.find((a) => a.title == address.country).id;
    // var states = State.getStatesOfCountry(countryCode).map((item) => ({
    //   ...item,
    //   id: item.isoCode,
    //   title: item.name,
    // }));
    // setStates(states);
    // var stateCode = states.find((a) => a.title == address.state).id;
    // var cities = City.getCitiesOfState(countryCode, stateCode).map((item) => ({
    //   ...item,
    //   id: item.name,
    //   title: item.name,
    // }));
    // setCities(cities);
    updateAddress(address);
  }, [address]);

  return (
    <div>
      <div className="row">
        <div className="col-md-4" style={{ display: "grid" }}>
          <label className="field-title">Country</label>
          <Controls.SelectControl
            name="country"
            style={{ width: "100%", marginTop: "5px" }}
            options={countries}
            //value={countries.find((a) => a.title == address.country).id}
            onChange={(e) => {
              var cou = countries.find((a) => a.id == e.target.value);
              setAddress({
                ...address,
                country: cou.title,
                latitude: parseFloat(cou.latitude),
                longitude: parseFloat(cou.longitude),
                zoomVal: 6,
              });
              getStatesByCountry(e.target.value);
            }}
          ></Controls.SelectControl>
        </div>
        <div className="col-md-4" style={{ display: "grid" }}>
          <label className="field-title">State</label>
          <Controls.SelectControl
            name="state"
            style={{ width: "100%", marginTop: "5px" }}
            options={states}
            value={
              states.find((a) => a.title == address.state) !== undefined
                ? states.find((a) => a.title == address.state).id
                : 0
            }
            onChange={(e) => {
              var sta = states.find((a) => a.id == e.target.value);
              setAddress({
                ...address,
                state: sta.title,
                latitude: parseFloat(sta.latitude),
                longitude: parseFloat(sta.longitude),
                zoomVal: 8,
              });
              getCitiesByState(sta.countryCode, e.target.value);
            }}
          ></Controls.SelectControl>
        </div>
        <div className="col-md-4" style={{ display: "grid" }}>
          <label className="field-title">City</label>
          <Controls.SelectControl
            name="city"
            style={{ width: "100%", marginTop: "5px" }}
            options={cities}
            value={
              cities.find((a) => a.title == address.city) !== undefined
                ? cities.find((a) => a.title == address.city).id
                : 0
            }
            onChange={(e) => {
              var cit = cities.find((a) => a.id == e.target.value);
              setAddress({
                ...address,
                city: cit.title,
                latitude: parseFloat(cit.latitude),
                longitude: parseFloat(cit.longitude),
                zoomVal: 12,
              });
            }}
          ></Controls.SelectControl>
        </div>
      </div>
      <div className="row mt-20">
        <div className="col-md-6">
          <label className="field-title">Address Lane 1</label>
          <Controls.InputControl
            name="title"
            other={{ placeholder: "Address Lane 1" }}
            style={{ width: "100%", marginTop: "5px" }}
            onChange={(e) =>
              setAddress({
                ...address,
                addressLane1: e.target.value,
              })
            }
            value={address.addressLane1}
            //error={validation.planName}
          ></Controls.InputControl>
        </div>
        <div className="col-md-6">
          <label className="field-title">Address Lane 2</label>
          <Controls.InputControl
            name="title"
            other={{ placeholder: "Address Lane 2" }}
            style={{ width: "100%", marginTop: "5px" }}
            onChange={(e) =>
              setAddress({
                ...address,
                addressLane2: e.target.value,
              })
            }
            value={address.addressLane2}
            //error={validation.planName}
          ></Controls.InputControl>
        </div>
      </div>
      <div className="row mt-20">
        <div className="col-md-6">
          <label className="field-title">Pin code</label>
          <Controls.InputControl
            name="pincode"
            other={{ placeholder: "Pincode" }}
            style={{ width: "100%", marginTop: "5px" }}
            onChange={(e) =>
              setAddress({
                ...address,
                pincode: e.target.value,
              })
            }
            value={address.pincode}
            //error={validation.planName}
          ></Controls.InputControl>
        </div>
        <div className="col-md-6"></div>
      </div>
      <div className="mt-50">
        <GoogleMap
          latitude={parseFloat(address.latitude)}
          longitude={parseFloat(address.longitude)}
          zoomVal={address.zoomVal || 15}
          onClickCallback={(val) => setGoogleMapLocation(val)}
        ></GoogleMap>
      </div>
    </div>
  );
};

export default Address;
