import { useState, useEffect } from "react";

import { URLS } from "../../../configs/api_urls";
import { httpGet } from "../../../utility/request";

import BankDetailsItem from "../BankDetailsItem/bank-details-item.component";
import Controls from "../../../components/Controls/Controls";
import Loader from "../../../components/Loader";
import NoContentMessage from "../../../components/NoRowMessage";
import AddUpdateBankDetails from "../AddUpdateBankDetails/addupdate-bank-details";

import { Typography } from "@mui/material";

const BankDetailsList = () => 
{
    const [isLoading, setIsLoading] = useState(true);
    const [isAddDialogueOpen, setIsAddDialogueOpen] = useState(false);
    const [bankingDetailsList, setBankingDetailsList] = useState([]);
    const [editBankDetails, setEditBankDetails] = useState(null);

    const newBankDetail = {
      adminBankDetailId: 0,
      bankName: "",
      accountNumber: "",
      ifscCode: "",
      accountType: "",
      bankAddress: "",
      appicationInstanceId: 0,
    };

    const addNewBankDetailHandler = () => 
    {
      setEditBankDetails(newBankDetail);
      setIsAddDialogueOpen(true);

      //setBankingDetailsList([ ...bankingDetailsList, newBankDetail ]);
      // setTimeout(() => {
      //   scrollToNewItem();
      // }, 100);
    }

    const editBankDetailsHandler = (data) => 
    {
      setEditBankDetails({...data});
      setIsAddDialogueOpen(true);
    }

    const closeAddUpdateWindow = () => 
    {
      setIsAddDialogueOpen(false);
      fetchallBenkingDetails();
    }

    // function to move scroll to the top
  // const scrollToNewItem = () => {
  //   setTimeout(() => {
  //     const el = document.getElementById("bank-detail-0");
  //     if (el) {
  //       el.scrollIntoView({
  //         block: 'start',
  //         behavior: 'smooth',
  //         inline: 'start',
  //       });
  //     }
  //   }, 100);
  // }

    const fetchallBenkingDetails = async () => {
      setIsLoading(true);
      await httpGet(URLS.ADMIN_BANKING.GETALLBANKINGDETAILS)
        .then((response) => {
          var data = response.data;
          setBankingDetailsList(data);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
        });
    };

    useEffect(() => {
      fetchallBenkingDetails();
    }, []);

    return (
      <div>
        {isAddDialogueOpen && (
          <AddUpdateBankDetails
            bankDetail={editBankDetails}
            bankDetailList={bankingDetailsList}
            bankDetailClose={closeAddUpdateWindow}
          ></AddUpdateBankDetails>
        )}
        <div
          className="display-inline-flex w-100"
          style={{
            justifyContent: "space-between",
            padding: "10px 0px  13px",
          }}
        >
          <Typography variant="h4">Bank details</Typography>
          <Controls.ButtonControl
            type="submit"
            text="Add new Bank Detail"
            className="theme-btn"
            style={{ width: "210px" }}
            onClick={addNewBankDetailHandler}
          ></Controls.ButtonControl>
        </div>
        <div>
          {!isLoading &&
            bankingDetailsList.length > 0 &&
            bankingDetailsList.map((detail) => (
              <div
                key={detail.adminBankDetailId}
                id={"bank-detail-" + detail.adminBankDetailId}
              >
                <BankDetailsItem
                  bankDetail={detail}
                  editBankDetail={() => {
                    editBankDetailsHandler(detail);
                  }}
                ></BankDetailsItem>
              </div>
            ))}
          {isLoading && <Loader isLoading={isLoading}></Loader>}
          {bankingDetailsList.length == 0 && (
            <NoContentMessage
              message={"No Bank Detail has been added yet"}
            ></NoContentMessage>
          )}
        </div>
      </div>
    );
}

export default BankDetailsList;