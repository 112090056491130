const APP = "game-center";

export const SET_GAME_CENTERS_LIST = `${APP}/set-game-centers-list`;

export const SET_STANDARD_GAMES_DTO = `${APP}/set-standard-game-dto`;

export const SET_FACILITIE_AMENITIES_DTO = `${APP}/set-facility-amenities-dto`;

export const SET_ALL_POLICY_TYPES = `${APP}/set-all-policy-types`;

export const SET_CREATE_CENTER_DATA = `${APP}/set-create-center-data`;

export const SET_ACTIVE_FORM = `${APP}/set-active-form`;

export const SET_CENTER_IMAGES = `${APP}/set-center-images`;

export const SET_ADDRESS_DTO = `${APP}/set-address-dto`;

export const SET_THEME_DTO = `${APP}/set-theme-dto`;

export const SET_CENTER_POLICY = `${APP}/set-center-policies`;

export const SET_IS_REQUESTING = `${APP}/set-is-requesting`;

export const RESET_CREATE_GAME_CENTER = `${APP}/reset-game-center`;

export const RESET_CENTER_IMAGES_DATA = `${APP}/reset-game-center-images`;
