import { useState, useContext, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import Controls from "../../../components/Controls/Controls";
import RichEditor from "../../../components/RichTextEditor";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

import { getAllPolicyTypes } from "../../../modules/GameCenter/selector";


const Policies = ({ newCenterPolicies, updatePolicies }) => {
  const dispatch = useDispatch();
  const [isSaving, setIsSaving] = useState(false);
  const policies = useSelector(getAllPolicyTypes());
  const [dynamicId, setDynamicId] = useState(-1);
  const [cenetrPolicyList, setCenetrPolicyList] = useState(newCenterPolicies);

  const [policyMenuItems, setPolicyMenuItems] = useState([
    ...policies.map((item) => ({
      ...item,
      id: item.centerPoliciesTypeId,
      title: item.policyName,
    })),
    { id: 0, title: "None" },
  ]);

  const getPoliciesForOption = (policyTypeId) => {
    return policyMenuItems.filter(
      (policy) =>
        cenetrPolicyList
          .filter((p) => p.centerPoliciesTypeId !== policyTypeId) // elemenate the already existing option
          .map((p) => p.centerPoliciesTypeId)
          .indexOf(policy.centerPoliciesTypeId) == -1
    );
  };

  const addNewPolicy = () => {
    var newPolicy = {
      applicationInstanceCenterPolicyMapId: dynamicId,
      centerPoliciesTypeId: 0,
      policyDescription: "",
    };

    updatePolicies([...cenetrPolicyList, newPolicy]);
    setDynamicId(dynamicId - 1);
  };

  const changePolicyType = (policyTypeEvent, mapId) => {
    var policyTypeId = Number(policyTypeEvent.target.value);
    var cenetrPolicyListVal = cenetrPolicyList.map((policy) =>
      policy.applicationInstanceCenterPolicyMapId === mapId
        ? { ...policy, centerPoliciesTypeId: policyTypeId }
        : policy
    );
    updatePolicies(cenetrPolicyListVal);
  };

  const handleDescriptionChange = (value, mapId) => {
    var cenetrPolicyListVal = cenetrPolicyList.map((policy) =>
      policy.applicationInstanceCenterPolicyMapId === mapId
        ? { ...policy, policyDescription: value }
        : policy
    );

    // called this local state here to prevent lag update
    setCenetrPolicyList(cenetrPolicyListVal);

    // then update parent as in other methods
    updatePolicies(cenetrPolicyListVal);
  };

  const removePolicy = (mapId) => {
    var cenetrPolicyListVal = cenetrPolicyList.filter(
      (policy) => policy.applicationInstanceCenterPolicyMapId !== mapId
    );
    updatePolicies(cenetrPolicyListVal);
  };

  useEffect(() => {
    setCenetrPolicyList(newCenterPolicies);
  }, [newCenterPolicies]);

  return (
    <div
      className="display-grid scrollbar"
      style={{ overflow: "auto", maxHeight: "calc(100vh - 120px)" }}
    >
      <div>
        <Controls.ButtonControl
          text={"Add policy"}
          style={{ width: "150px", margin: "0px 5px", float: "right" }}
          onClick={() => {
            addNewPolicy();
          }}
        ></Controls.ButtonControl>
      </div>
      <div className="mt-20">
        {cenetrPolicyList.length > 0 ? (
          cenetrPolicyList.map((policy) => {
            return (
              <div>
                <div
                  className="row"
                  style={{ padding: "10px", height: "170px" }}
                >
                  <div className="col-md-3">
                    <Controls.SelectControl
                      name="polictitem"
                      style={{ width: "100%", marginTop: "5px" }}
                      options={getPoliciesForOption(
                        policy.centerPoliciesTypeId
                      )}
                      value={policy.centerPoliciesTypeId}
                      onChange={(e) =>
                        changePolicyType(
                          e,
                          policy.applicationInstanceCenterPolicyMapId
                        )
                      }
                    ></Controls.SelectControl>
                  </div>
                  <div className="col-md-8">
                    <RichEditor
                      editorValue={policy.policyDescription}
                      style={{ height: "100px" }}
                      setEditorValue={(value) =>
                        handleDescriptionChange(
                          value,
                          policy.applicationInstanceCenterPolicyMapId
                        )
                      }
                    ></RichEditor>
                  </div>
                  <div className="col-md-1">
                    <Controls.ButtonControl
                      style={{
                        width: "30px",
                        margin: "0px 5px",
                        float: "right",
                        background: "red",
                      }}
                      onClick={() =>
                        removePolicy(
                          policy.applicationInstanceCenterPolicyMapId
                        )
                      }
                      innercomponent={
                        <FontAwesomeIcon icon={faTrash}></FontAwesomeIcon>
                      }
                    ></Controls.ButtonControl>
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <div>No Policies added yet</div>
        )}
      </div>
    </div>
  );
};

export default Policies;
