import { getApplicationId } from "../../hoc/helper";
import  PaymentSignalRHandlers  from "../../SignalR/Handlers/PaymentSignalRHandlers";
import {
  JsonHubProtocol,
  HttpTransportType,
  HubConnectionBuilder,
  //LogLevel,
} from "@microsoft/signalr"; // version 1.0.4

// action for user authentication and receiving the access_token
import { SIGNALR_CONEECTION_REQUEST_START } from "../../modules/SignalR/baseSignalR.constant";
import BookingSignalRHandler from "../../SignalR/Handlers/BookingSignalRHandler";

const startSignalRConnection = (connection) =>
  connection
    .start()
    .then(() => {
      const id = getApplicationId();
      connection.invoke("JoinNotificationApplicationGroup", Number(id));

      console.info("SignalR Connected");
    })
    .catch((err) => console.error("SignalR Connection Error: ", err));

const signalRMiddleware =
  ({ getState }) =>
  (next) =>
  async (action) => {
    // register signalR after the user logged in
    if (action.type === SIGNALR_CONEECTION_REQUEST_START) {
      //const urlRoot = (window.appConfig || {}).URL_ROOT;
      const connectionHub =
        "https://playnoot-signalr.azurewebsites.net/hubs/Notification";

      const protocol = new JsonHubProtocol();

      // let transport to fall back to to LongPolling if it needs to
      //const transport = HttpTransportType.WebSockets;

      const options = {
        transport: HttpTransportType.WebSockets,
        skipNegotiation: true,
        // logMessageContent: true,
        // logger: LogLevel.Trace
      };

      // create the connection instance
      const connection = new HubConnectionBuilder()
        .withUrl(connectionHub, options)
        .withHubProtocol(protocol)
        .withAutomaticReconnect()
        .build();

      // re-establish the connection if connection dropped
      connection.onclose(() =>
        setTimeout(startSignalRConnection(connection), 3000)
      );

      startSignalRConnection(connection);

      // event handlers, you can use these to dispatch actions to update your Redux store
      connection.on("ActionPaymentProcessStatus", (signalrResponse) => {
        console.log("------Message received----");
        const notificationItem = JSON.parse(signalrResponse);
        PaymentSignalRHandlers(notificationItem);
      });

      // event handlers, you can use these to dispatch actions to update your Redux store
      connection.on("ActionBookingStatusChange", (signalrResponse) => {
        console.log("------Message received----");
        const notificationItem = JSON.parse(signalrResponse);
        BookingSignalRHandler(notificationItem);
      });
    }

    return next(action);
  };

export default signalRMiddleware;
