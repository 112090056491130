import { useState, useEffect } from "react";

import SubscriptionNavigation from "./SubscriptionNavigation/subscription-navigation.component";
import SubscriptionPlanList from "./SubscriptionPlanList/subscription-plan-list.component";
import AddEditSubscriptionPlan from "./AddEditSubscriptionPlan/addedit-subscription-plan.component";
import GuideSubscriptionPlanOperations from "./GuideSubscriptionPlanOperations/guide-subscription-plan-operations.component";

const SubscriptionPlanDashboard = () => {
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [selectedView, setSelectedView] = useState("");
  const [selectedPlanList, setSelectedPlanList] = useState([]);

  const newSubscriptiopnPlan = {
    subscriptionPlanId: 0,
    planName: "",
    planDescription: "",
    planFeatures: [
      {
        subscriptionPlanFeatureId: -1000,
        subscriptionPlanFeatureName: "",
        subscriptionPlanFeatureIconUrl: "faInfo",
      },
    ],
    planPricings: [
      {
        subscriptionPlanPricingId: -1000,
        subscriptionPlanPricingType: 1,
        planDuration: 1,
        planPrice: 0,
        isNew: true,
      },
    ],
    planOfferMap: [],
  };

  const handleAddToSelectedPlanList = (event) => {
    debugger;
  };

  const handlePlanSelected = (plan) => {
    setSelectedPlan(plan);
    setSelectedView("addeditview");
    setSelectedPlanList([plan.subscriptionPlanId]);
  };

  const cancelClick = () => {
    setSelectedView("");
    setSelectedPlanList([]);
  };

  useEffect(() => {}, []);

  return (
    <div>
      <div>
        <SubscriptionNavigation
          isCreateNewPlanVisible={true}
          isCreateNewOfferVisible={false}
          isPlansDashboardVisible={false}
          isPlanOffersDashboardVisible={true}
          isSubscribersPurchaseDashboardVisible={true}
          createNewPlanClick={() => {
            handlePlanSelected(newSubscriptiopnPlan);
          }}
        ></SubscriptionNavigation>
      </div>
      <div className="display-inline-flex w-100">
        <div className="items-list-container w-50">
          <div className="items-list">
            <SubscriptionPlanList
              selectedPlanList={selectedPlanList}
              clickSelectPlan={(plan) => {
                handlePlanSelected(plan);
              }}
              addToSelectedList={(event) => {
                handleAddToSelectedPlanList(event);
              }}
              cancelClick={cancelClick}
            ></SubscriptionPlanList>
          </div>
        </div>
        <div className="items-edit-container  w-50">
          {selectedView == "" && (
            <GuideSubscriptionPlanOperations></GuideSubscriptionPlanOperations>
          )}
          {selectedView == "addeditview" && (
            <AddEditSubscriptionPlan
              selectedPlan={selectedPlan}
              cancelClick={cancelClick}
            ></AddEditSubscriptionPlan>
          )}
        </div>
      </div>
    </div>
  );
};

export default SubscriptionPlanDashboard;
