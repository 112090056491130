import { useState } from "react";
import BroadcastAnnouncement from "./BroadcastAnnouncement/broadcast-announcement.component";
import ButtonControl from "../../components/Controls/ButtonControl";
import AllFollowers from "./AllFollowers/all-followers.component";

const AnnouncementsDashboard = () => {
      const [selectedView, setSelectedView] = useState("");
  return (
    <div className="display-grid">
      <div style={{ marginLeft: "auto", marginBottom: "10px" }}>
        {selectedView == "" && (
          <ButtonControl
            text={"All Followers"}
            style={{ width: "180px" }}
            onClick={() => setSelectedView("all-followers")}
          ></ButtonControl>
        )}
        {selectedView == "all-followers" && (
          <ButtonControl
            text={"Announcements"}
            style={{ width: "180px" }}
            onClick={() => setSelectedView("")}
          ></ButtonControl>
        )}
      </div>
      {selectedView == "" && <BroadcastAnnouncement></BroadcastAnnouncement>}
      {selectedView == "all-followers" && <AllFollowers></AllFollowers>}
    </div>
  );
};
export default AnnouncementsDashboard;
