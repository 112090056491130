import React from "react";
import { Box, Typography } from "@mui/material";
import Logo from "../../../static/logo.png";

const TermsAndConditions = () => {
  return (
    <>
      <Box sx={{ overflowY: "auto", height: "100vh" }} className="terms">
        <Box sx={{ display: "flex", ml: 3, p: 2 }}>
          <img style={{ height: "80px" }} src={Logo} alt="LOGO" />
          <Typography variant="h2" sx={{ ml: "10px" }}>
            Playnoot
          </Typography>
        </Box>
        <div className="container">
          <h1>Terms and Conditions</h1>
          <br />
          <h4> I. ACCEPTANCE OF TERMS OF USE AND OTHER POLICIES </h4>
          <ul>
            <li>
              All references to "you" or "your", as applicable, means the person
              that accesses, uses, and/or participates in the Playnoot app
              Platform (as defined below) in any manner ("Users"). If you use
              the Playnoot app Platform on behalf of an entity, you represent
              and warrant that you have the authority to bind that entity. Your
              acceptance of the Terms of Use will be deemed an acceptance by the
              entity you represent and "you" and "your" herein shall refer to
              such entity.
            </li>
            <li>
              Terms of Use. These terms of use (the"Terms of Use") govern your
              use of our website https://playnoot.in (the"Website") and our
              Playnoot app application for mobile and handheld devices
              (the"App") and any assignees and permitted licenses thereof. The
              Website and the App are jointly referred to as the"Playnoot
              Platform". The Playnoot app Platform is currently owned and
              operated by Playnoot ("Playnoot"), a company incorporated under
              the Companies Act, 2013 and having its registered office at M/s
              Kot Fatuhi, Hoshiarpur, Punjab, 144519. "We", "us", and "our"
              herein shall refer to Playnoot and our
              associates/partners/successors/permitted assigns. Please read
              these Terms of Use thoroughly and carefully before you use the
              Playnoot app Platform as they contain provisions that define your
              limits, legal rights and obligations with respect to your
              participation in the Playnoot app Platform and your use of the
              services offered by the Playnoot app Platform (such services,
              collectively called the"Services"). These Terms of Use and Privacy
              Policy (as defined below) constitute a legally binding agreement
              between Playnoot and you. By installing, downloading or even
              merely using the Playnoot app Platform, and by clicking on "I have
              read and agree to the terms of use" you shall be contracting with
              Playnoot and you signify your acceptance to the Terms of Use and
              other Playnoot policies (including but not limited to the Privacy
              Policy) as posted on the Playnoot app Platform from time to time,
              which takes effect on the date on which you download, install or
              use the Services, and create a legally binding arrangement to
              abide by the same. If you do not agree to the foregoing, you
              should not click to affirm your acceptance there to, in which case
              you are prohibited from accessing or using the Playnoot app
              Platform. If you do not agree to any of the provisions set forth
              in the Terms of Use, kindly discontinue accessing or participating
              in the Services offered by the Playnoot app Platform immediately.
              By using the Playnoot app Platform, you agree that the terms,
              conditions and disclaimers set out in these Terms of Use are
              reasonable.
            </li>
            <li>
              Privacy Policy. Playnoot has established a privacy policy that
              explains to Users how their information ("User Content") is
              collected and stored ("Privacy Policy"). The Privacy Policy is
              referenced above and hereby incorporated into the Terms of Use set
              forth. You use of the Playnoot app Platform is governed by the
              Privacy Policy, which is located at
              https://playnoot.in/privacypolicy.
            </li>
            <li>
              Amendments to Terms of Use. These Terms of Use and any other
              document incorporated by reference are subject to modifications at
              any time. Playnoot reserves the right to modify or change these
              Terms of Use and other Playnoot policies at any time by posting
              changes on the domain of https://playnoot.in/terms, and you shall
              be liable to update yourself of such changes, if any, by accessing
              the changes on the domain. You shall, at all times, be responsible
              for regularly reviewing the Terms of Use and the other Playnoot
              policies and note the changes made on the Playnoot app Platform.
              If Playnoot proposes any changes to the Terms of Use, you will be
              informed of the same by way of a 'pop up' which will appear on
              your screen. You are expected to review the revised term(s) and
              provide your consent. If you fail to do so, your existing
              registration will also stand terminated.
            </li>
          </ul>
          <h4> II. USE OF PLATFORM AND SERVICES</h4>
          a. Membership eligibility criteria. i. You need not register with
          Playnoot to simply visit and view the Playnoot app Platform, but to
          access and participate in certain features of the Playnoot app
          Platform, you will need to create a password-protected account
          ("Account"). To create an Account, you must submit your name, mobile
          number and/or email address through the account registration
          page/screen on the Playnoot app Platform and create a password. You
          will also have the ability to provide additional optional information,
          which is not required to register for an account but maybe helpful to
          Playnoot in providing you with a more customized experience when using
          the Playnoot app Platform. You may also register for an Account using
          your existing Facebook, Google or any such other account and log-in
          credentials (your"Third-Party Site Account"). An Account holder is
          sometimes referred to on the Playnoot app Platform as"Registered
          User". ii. Use of the Playnoot app Platform is available only to
          individuals who are capable of forming legally binding contracts under
          applicable law. You represent, acknowledge and agree that you are at
          least 18 years of age, and that: (a) all registration information that
          you submit is truthful and accurate, (b) you will maintain the
          accuracy of such information, and (c) your use of the Playnoot app
          Platform and Services offered by the Playnoot app Platform do not
          violate any applicable law or regulation. Your Account may be
          terminated without warning if we at our discretion, believe that you
          are under the age of 18 or that you are not complying with any
          applicable laws, rules or regulations. Further, if you are not of the
          authorised age and you access the Playnoot app Platform, please be
          aware that it shall be assumed that your usage was authorised by such
          person, who is your parent or guardian and that such person has
          supervised your usage. iii. We reserve the right to suspend or
          terminate your Account and your access to the Services (i) if any User
          Content provided during the registration process or thereafter proves
          to be inaccurate, not current or incomplete; (ii) if it is believed
          that your actions may cause legal liability for you, other Users or
          us; and/or (iii) if you are found to be non- compliant with these
          Terms of Use. iv. You are responsible for safeguarding your password.
          You agree that you will not disclose your password to any third party
          and that you will take sole responsibility for any activities or
          actions under your Account. You shall notify Playnoot immediately in
          case of any breach of security or any unauthorized use of your
          Account. Similarly, you shall never use another's Account without
          prior consent from Playnoot. You agree that you will not misrepresent
          yourself or represent yourself as another User of the Playnoot app
          Platform. You hereby expressly acknowledge and agree that you will be
          liable for any losses, damages (whether direct or indirect) caused to
          you, Playnoot or any others as a result of unauthorized use of your
          Account. b. Use of the Platform. You agree, undertake and confirm that
          your use of the Playnoot app Platform shall be strictly governed by
          the following binding principles: i. You shall not host, display,
          upload, download, modify, publish, transmit, update or share any
          information which:  belongs to another person and which you do not
          have any right to;  is grossly harmful, harassing, blasphemous,
          defamatory, obscene, pornographic, slanderous ,paedophilic, libellous,
          criminally inciting or invasive of another's privacy, hateful, or
          racially, objectionable, ethnically, disparaging, relating or
          encouraging money laundering or gambling, or otherwise unlawful in any
          manner whatsoever; or unlawfully threatening or unlawfully harassing
          including but not limited to any content which is in violation of the
          provisions of the Indian Penal Code, 1860;  is misleading or
          misrepresentative in any way;  is patently offensive to the online
          community, such as sexually explicit content, or content that promotes
          obscenity, paedophilia, racism, bigotry, hatred or physical harm of
          any kind against any group or individual;  harasses or advocates
          harassment of another person;  involves the transmission of "junk
          mail", "chain letters", or unsolicited mass mailing or "spamming"; 
          promotes illegal activities or conduct that is abusive, threatening,
          obscene, defamatory or libellous;  infringes upon or violates any
          third party's rights [including, but not limited to, intellectual
          property rights, rights of privacy (including without limitation
          unauthorized disclosure of a person's name, email address, physical
          address or phone number) or rights of publicity];  promotes an
          illegal or unauthorized copy of another person's copyrighted work (see
          "copyright complaint" below for instructions on how to lodge a
          complaint about uploaded copyrighted material), such as providing
          pirated computer programs or links to them, providing information to
          circumvent manufacture-installed copy-protect devices, or providing
          pirated music or links to pirated music files;  contains restricted
          or password-only access pages, or hidden pages or images (those not
          linked to or from another accessible page);  provides material that
          exploits people in a sexual, violent or otherwise inappropriate manner
          or solicits personal information from anyone;  Provides instructional
          information about illegal activities such as making or buying illegal
          weapons, violating someone's privacy, or providing or creating
          computer viruses;  Contains video, photographs, or images of another
          person (with a minor or an adult);  Tries to gain unauthorized access
          or exceeds the scope of authorized access to the Playnoot app Platform
          or to profiles, blogs, communities, account information, bulletins,
          friend request, or other areas of the Playnoot app Platform or
          solicits passwords or personal identifying information for commercial
          or unlawful purposes from other Users;  interferes with another
          User's use and enjoyment of the Playnoot app Platform or any third
          party User and enjoyment of similar services;  solicits gambling or
          engages in any gambling activity which is or could be construed as
          being illegal;  engages in commercial activities and/or sales without
          our prior written consent such as contests, sweepstakes, barter,
          advertising and pyramid schemes, or the buying or selling of products
          related to the Playnoot app Platform. Throughout these Terms of Use,
          Playnoot's prior written consent means a communication coming from
          Playnoot's legal department/authorised representative, specifically in
          response to your request, and expressly addressing and allowing the
          activity or conduct for which you seek authorization;  refers to any
          website or URL that, in our sole discretion, contains material that is
          inappropriate for the Playnoot app Platform or any other website,
          contains content that would be prohibited or violates the letter or
          spirit of these Terms of Use;  harm minors in any way;  infringes
          any patent, trademark, copyright or other intellectual property rights
          or third party's trade secrets or rights of publicity or privacy or
          shall not be fraudulent;  violates any law for the time being in
          force;  deceives or misleads the addressee/Users about the origin of
          such messages or communicates any information which is grossly
          offensive or menacing in nature;  impersonate another person; 
          contains software viruses or any other computer code, files or
          programs designed to interrupt, destroy or limit the functionality of
          any computer resource; or contains any trojan horses, worms, time
          bombs, cancelbots, easter eggs or other computer programming routines
          that may damage, detrimentally interfere with, diminish value of,
          surreptitiously intercept or expropriate any system, data or personal
          information;  threatens the unity, integrity, defence, security or
          sovereignty of India, friendly relations with foreign states, or
          public order or causes incitement to the commission of any criminal
          offence or prevents investigation of any offence or is insulting any
          other nation;  is false, inaccurate or misleading;  directly or
          indirectly, offers, attempts to offer, trades or attempts to trade in
          any item, the dealing of which is prohibited or restricted in any
          manner under the provisions of any applicable law, rule, regulation or
          guideline for the time being in force; or  creates liability for us
          or causes us to lose (in whole or in part) the services of our
          internet service provider or other suppliers. ii. You shall not
          conduct any systematic or automated data collection activities
          (including scraping, data mining, data extraction, data harvesting,
          'framing' (iframes), article 'spinning') on or in relation to the
          Playnoot app Platform without express written consent from Playnoot.
          iii. You shall not use any "deep-link", "page-scrape", "robot",
          "spider" or other automatic device, program, algorithm or methodology,
          or any similar or equivalent manual process, to access, acquire, copy
          or monitor any portion of the Playnoot app Platform or any content, or
          in any way reproduce or circumvent the navigational structure or
          presentation of the Playnoot app Platform or any content, to obtain or
          attempt to obtain any materials, documents or information through any
          means not purposely made available through the Playnoot app Platform.
          We reserve our right to prohibit any such activity. iv. You shall not
          attempt to gain unauthorized access to any portion or feature of the
          Playnoot app Platform, or any other systems or networks connected to
          the Playnoot app Platform or to any server, computer, network, or to
          any of the services offered on or through the Playnoot app Platform,
          by hacking, "password mining" or any other illegitimate means. v. You
          shall not probe, scan or test the vulnerability of the Playnoot app
          Platform or any network connected to the Playnoot app Platform nor
          breach the security or authentication measures on the Playnoot app
          Platform or any network connected to the Playnoot app Platform. You
          may not reverse look-up, trace or seek to trace any information on any
          other User of or visitor to the Playnoot app Platform, or any other
          Buyer, including any account on the Playnoot app Platform not owned by
          you, to its source, or exploit the Playnoot app Platform or any
          service or information made available or offered by or through the
          Playnoot app Platform, in any way where the purpose is to reveal any
          information, including but not limited to personal identification or
          information, other than your own information, as provided for by the
          Playnoot app Platform. vi. You shall not make any negative,
          denigrating or defamatory statement(s) or comment(s) about us or the
          brand name or domain name used by us including the name 'Playnoot', or
          otherwise engage in any conduct or action that might tarnish the image
          or reputation, of Playnoot on the Playnoot app Platform or otherwise
          tarnish or dilute any of Playnoot's trade or service marks, trade name
          and/or goodwill associated with such trade or service marks, as may be
          owned or used by us. You agree that you will not take any action that
          imposes an unreasonable or disproportionately large load on the
          infrastructure of the Playnoot app Platform or Playnoot's systems or
          networks, or any systems or networks connected to Playnoot. vii. You
          agree not to use any device, software or routine to interfere or
          attempt to interfere with the proper working of the Playnoot app
          Platform or any transaction being conducted on the Playnoot app
          Platform, or with any other person's use of the Playnoot app Platform.
          viii. You may not forge headers or otherwise manipulate identifiers in
          order to disguise the origin of any message or transmittal you send to
          us on or through the Playnoot app Platform or any service offered on
          or through the Playnoot app Platform. You may not pretend that you
          are, or that you represent, someone else, or impersonate any other
          individual or entity. ix. You may not use the Playnoot app Platform or
          any content on the Playnoot Platform for any purpose that is unlawful
          or prohibited by these Terms of Use, or to solicit the performance of
          any illegal activity or other activity that infringes the rights of
          Playnoot and/or others. x. You shall at all times ensure full
          compliance with the applicable provisions, as amended from time to
          time, of (a) the Information Technology Act, 2000, India and the rules
          thereunder; (b) all applicable domestic laws, rules and regulations
          (including the provisions of any applicable exchange control laws or
          regulations in force); and (c) international laws, foreign exchange
          laws, statutes, ordinances and regulations (including, but not limited
          to goods and service taxes, income tax, and local levies) regarding
          your use of our Service and your listing, purchase, solicitation of
          offers to avail our Services. You shall not engage in any transaction
          in an item or service, which is prohibited by the provisions of any
          applicable law including exchange control laws or regulations for the
          time being in force. xi. In order to allow us to use the User Content
          supplied by you, without violating your rights or any laws, you agree
          to grant us a non-exclusive, worldwide, perpetual, irrevocable,
          royalty-free, sub-licensable (through multiple tiers) right to
          exercise the copyright, publicity, database rights or any other rights
          you have in your Information, in any media now known or not currently
          known, with respect to your Information. We will only use your
          information in accordance with these Terms of Use and the Privacy
          Policy applicable to use of the Playnoot app Platform. xii. We will
          monitor the your messages and interaction on the Playnoot app Platform
          and you indulged in in any form of advertising or solicitation, we
          reserve the right to restrict the number of messages or emails which a
          User may send to other Users in any 24-hour period which we deem
          appropriate in its sole discretion. You understand that we have the
          right at all times to disclose any information (including the identity
          of the persons providing information or materials on the Playnoot app
          Platform) as necessary to satisfy any law, regulation or valid
          governmental request. This may include, without limitation, disclosure
          of the information in connection with investigation of alleged illegal
          activity or solicitation of illegal activity or in response to a
          lawful court order or subpoena. In addition, We can (and you hereby
          expressly authorize us to) disclose any information about you to law
          enforcement or other government officials, as we, in our sole
          discretion, believe necessary or appropriate in connection with the
          investigation and/or resolution of possible crimes, especially those
          that may involve personal injury. xiii. We reserve the right, but have
          no obligation, to monitor the materials posted on the Playnoot app
          Platform. Playnoot shall have the right to remove or edit any content
          that in its sole discretion violates, or is alleged to violate, any
          applicable law or either the spirit or letter of these Terms of Use.
          Notwithstanding this right, you remain solely responsible for the
          content of the materials you post on the Playnoot app Platform and in
          your private messages. Please be advised that such content posted does
          not necessarily reflect Playnoot's views. In no event shall Playnoot
          assume or have any responsibility or liability for any content posted
          or for any claims, damages or losses resulting from use of content
          and/or appearance of content on the Playnoot app Platform. You hereby
          represent and warrant that you have all necessary rights in and to all
          content which you provide and all information it contains and that
          such content shall not infringe any proprietary or other rights of
          third parties or contain any libellous, tortious, or otherwise
          unlawful information. xiv. Your correspondence or business dealings
          with, or participation in promotions of, advertisers found on or
          through the Playnoot app Platform, including payment and delivery of
          related products or services, and any other terms, conditions,
          warranties or representations associated with such dealings, are
          solely between you and such advertiser. We shall not be responsible or
          liable for any loss or damage of any sort incurred as the result of
          any such dealings or as the result of the presence of such advertisers
          on the Playnoot app Platform. Please note that Playnoot does not
          warrant the quality or performance of any products of services which
          are hosted on the Playnoot app Platform. Users are encouraged to
          exercise their personal discretion while choosing any product or
          service available on the Playnoot app Platform. xv. It is possible
          that other Users (including unauthorized Users or 'hackers') may post
          or transmit offensive or obscene materials on the Playnoot app
          Platform and that you may be involuntarily exposed to such offensive
          and obscene materials. It also is possible for others to obtain
          personal information about you due to your use of the Playnoot app
          Platform, and that the recipient may use such information to harass or
          injure you. We do not approve of such unauthorized uses, but by using
          the Playnoot app Platform You acknowledge and agree that we are not
          responsible for the use of any personal information that you publicly
          disclose or share with others on the Playnoot app Platform. Please
          carefully select the type of information that you publicly disclose or
          share with others on the Playnoot app Platform.
          <h4>III. TERMS OF SERVICE</h4>
          a. You acknowledge and agree that Playnoot facilitates the hosting of
          products, services, events and arenas which are offered by third party
          providers ("Service Provider") and Playnoot does not: i. Make any
          warranties, whether express or implied of the quality of the products,
          services, events or arenas which are offered by the Service Providers;
          ii. Endorse, or confirm that a certain product, products, services,
          events or arenas which are provided or offered by the Service
          Provider, confirm to a certain quality or has been rated in a certain
          format; ; iii. Assume any liability if the products, services, events
          or arenas provided by the Service Provider do not meet the expectation
          of the User of if the User suffers any loss or damage, including any
          bodily injury as a consequence of using such products, services,
          events or arenas;; iv. Assume any liability in the event of any
          change, alternation, amendment or replacement to the products,
          services, events or arenas offered by the Service Provider. b. You
          might be required to provide your credit or debit card details to the
          approved payment gateways while making the payment. In this regard,
          you agree to provide correct and accurate credit/ debit card details
          to the approved payment gateways for availing the Services. You shall
          not use the credit/ debit card which is not lawfully owned by you,
          i.e. in any transaction, you must use your own credit/ debit card. The
          information provided by you will not be utilized or shared with any
          third party unless required in relation to fraud verifications or by
          law, regulation or court order. You will be solely responsible for the
          security and confidentiality of your credit/ debit card details. We
          expressly disclaim all liabilities that may arise as a consequence of
          any unauthorized use of your credit/ debit card. c. The Playnoot app
          Platform is not equipped or authorised to conclude any financial
          transaction. All payments shall be made through the payment gateway.
          Playnoot will conduit the payments received through the payment
          gateway, or such other authorised payment system, to the Service
          Provider. The receipt for such payment shall be directly issued to you
          by the Service Provider. Please ensure that you claim an invoice from
          the Service Provider. d. We do not endorse any Service Provider. In
          addition, although these Terms of Use require you to provide accurate
          information, we do not attempt to confirm, and do not confirm if it is
          purported identity. e. You agree not to attempt to impose liability
          on, or seek any legal remedy from us with respect to any actions or
          omissions of the Service Provider.
          <h4>IV. GENERAL TERMS OF USE</h4>
          a. Payments made through the Playnoot app Platform will not be
          refunded for any reason including for not purchasing, or availing any
          products, services, events or arenas. b. Information provided by the
          Playnoot app Platform shall be updated on a regular basis subject to
          the same being provided to us by the Service Provider. If the Service
          Provider amends, alters or withdraws any product, service, venue or
          arena and the same is not updated on the Playnoot app Platform at the
          time of the User availing such products, services, events or arenas,
          then Service Provider shall ensure the replacement of the products,
          services, events or arenas with an equivalent offering or to refund
          the money receive from the User c. If you choose to use the Playnoot
          app Platform, it shall be your responsibility to treat your User
          identification code, password and any other piece of information that
          we may provide, as part of our security procedures, as confidential
          and not disclose the same to any person or entity other than us. We
          shall at times and at our sole discretion reserve the right to disable
          any User identification code or password if you have failed to comply
          with any of the provisions of these Terms of Use. d. The Playnoot app
          Platform can be used to procure or avail products, services, events or
          arenas within select cities in India. The Playnoot app Platform will
          provide and facilitate access only to those Service Providers who are
          registered on the Playnoot app Platform. As we are providing Services
          in the select cities in India, we have complied with applicable laws
          of India in making the Playnoot app Platform and its content available
          to you. In the event the Playnoot app Platform is accessed from
          outside India or outside our operating zones, it shall be entirely at
          your risk. We make no representation that the Playnoot app Platform
          and its contents are available or otherwise suitable for use outside
          select cities. If you choose to access or use the Playnoot app
          Platform from or in locations outside select cities, you do so on your
          own and shall be responsible for the consequences and ensuring
          compliance of applicable laws, regulations, byelaws, licenses,
          registrations, permits, authorisations, rules and guidelines. e. You
          shall at all times be responsible for the use of the Services through
          your computer or mobile device and for bringing these Terms of Use and
          Playnoot policies to the attention of all such persons accessing the
          Playnoot app Platform on your computer or mobile device. f. You
          understand and agree that the use of the Services does not include the
          provision of a computer or mobile device or other necessary equipment
          to access it. You also understand and acknowledge that the use of the
          Playnoot app Platform requires internet connectivity and
          telecommunication links. You shall bear the costs incurred to access
          and use the Playnoot app Platform and avail the Services, and we shall
          not, under any circumstances whatsoever, be responsible or liable for
          such costs. g. You agree and grant permission to Playnoot to receive
          promotional/transactional SMS and e-mails from Playnoot or allied
          partners. h. By using the Playnoot app Platform you represent and
          warrant that: i. All registration information you submit is truthful,
          lawful and accurate and that you agree to maintain the accuracy of
          such information. ii. Your use of the Playnoot app Platform shall be
          solely for your personal use and you shall not authorize others to use
          your account, including your profile or email address and that you are
          solely responsible for all content published or displayed through your
          account, including any email messages, and your interactions with
          other Users and you shall abide by all applicable local, state,
          national and foreign laws, treaties and regulations, including those
          related to data privacy, international communications and the
          transmission of technical or personal data. iii. All necessary
          licenses, consents, permissions and rights are owned by you and there
          is no need for any payment or permission or authorization required
          from any other party or entity to use, distribute or otherwise exploit
          in all manners permitted by these Terms of Use and Privacy Policy, all
          trademarks, copyrights, patents, trade secrets, privacy and publicity
          rights and / or other proprietary rights contained in any content that
          you submit, post, upload, distribute or otherwise transmit or make
          available. i. You agree to release and fully indemnify Playnoot and/or
          any of its directors, shareholders officers and representatives from
          any cost, damage, liability or other consequence of any of the actions
          of the Users of the Playnoot app Platform and specifically waive any
          claims that you may have in this behalf under any applicable laws of
          India. Without prejudice to this, you agree that the limitations and
          disclaimers set out in these Terms of Use will also protect the
          officers, employees, agents, subsidiaries, successors, assigns and
          sub-contractors of Playnoot. j. Notwithstanding its reasonable efforts
          in that behalf, Playnoot cannot take responsibility or control the
          information provided by other Users which is made available on the
          Playnoot app Platform. You may find other User's information to be
          offensive, harmful, inconsistent, inaccurate, or deceptive. Please use
          caution and practice safe trading when using the Playnoot app
          Platform.
          <h4>V. ACCESS TO THE PLATFORM, ACCURACY AND SECURITY</h4>
          a. We endeavour to make the Playnoot app Platform available 24/7.
          However, we do not represent that access to the Playnoot app Platform
          will be uninterrupted, timely, error free, free of viruses or other
          harmful components or that such defects will be corrected. b. We do
          not warrant that the Playnoot app Platform will be compatible with all
          hardware and software which you may use. We shall not be liable for
          damage to, or viruses or other code that may affect, any equipment
          (including but not limited to your mobile device), software, data or
          other property as a result of your download, installation, access to
          or use of the Playnoot app Platform or your obtaining any material
          from, or as a result of using the Playnoot app Platform. We shall also
          not be liable for the actions of third parties. c. We do not represent
          or warranty that the information available on the Playnoot app
          Platform will be correct, accurate or otherwise reliable. d. We
          reserve the right to suspend or withdraw access to the Playnoot app
          Platform to you personally, or to all Users temporarily or permanently
          at any time without notice.
          <h4>
            VI. RELATIONSHIP WITH OPERATORS IF THE PLATFORM IS ACCESSED ON
            MOBILE DEVICES
          </h4>
          a. In the event the Playnoot app Platform is accessed on a mobile
          device, it is not associated, affiliated, sponsored, endorsed or in
          any way linked to any operator, including, without limitation, Apple,
          Google, Android or RIM Blackberry (each being an"Operator"). b. Your
          download, installation, access to or use of the Playnoot app Platform
          is also bound by the terms and conditions of the Operator. c. You and
          we acknowledge that these Terms of Use are concluded between you and
          us only, and not with an Operator, and we, not those Operators, are
          solely responsible for the Playnoot app Platform and the content
          thereof to the extent specified in these Terms of Use. d. The license
          granted to you for the Playnoot app Platform is limited to a
          non-transferable license to use the Playnoot app Platform on a mobile
          device that you own or control and as permitted by these Terms of Use.
          e. We are solely responsible for providing any maintenance and support
          services with respect to the Playnoot app Platform as required under
          applicable law. You and we acknowledge that an Operator has no
          obligation whatsoever to furnish any maintenance and support services
          with respect to the Playnoot app Platform. f. You and we acknowledge
          that we, not the relevant Operator, are responsible for addressing any
          claims of you or any third party relating to the Playnoot Platform or
          your possession and/or use of the Playnoot Platform, including, but
          not limited to: (i) any claim that the Playnoot app Platform fails to
          conform to any applicable legal or regulatory requirement; and (ii)
          claims arising under consumer protection or similar legislation. g.
          You and we acknowledge that, in the event of any third party claim
          that the Playnoot app Platform or your use of the Playnoot app
          Platform infringes that third party's intellectual property rights,
          we, not the relevant Operator, will be solely responsible for the
          investigation, defence, settlement and discharge of any such
          intellectual property infringement claim. h. You and we acknowledge
          and agree that the relevant Operator, and that Operator's
          subsidiaries, are third party beneficiaries of these Terms of Use, and
          that, upon your acceptance of these Terms of Use, that Operator will
          have the right (and will be deemed to have accepted the right) to
          enforce these Terms of Use against you as a third party beneficiary
          thereof.
          <h4>VII. DISCLAIMERS</h4>
          a. The Playnoot app Platform may be under constant upgrades, and some
          functions and features may not be fully operational. b. Due to the
          vagaries that can occur in the electronic distribution of information
          and due to the limitations inherent in providing information obtained
          from multiple sources, there may be delays, omissions, or inaccuracies
          in the content provided on the Playnoot app Platform or delay or
          errors in functionality of the Playnoot app Platform. As a result, we
          do not represent that the information posted is correct in every case.
          c. We expressly disclaim all liabilities that may arise as a
          consequence of any unauthorized use of credit/ debit cards. d. You
          acknowledge that third party services are available on the Playnoot
          app Platform. We may have formed partnerships or alliances with some
          of these third parties from time to time in order to facilitate the
          provision of certain services to you. however, you acknowledge and
          agree that at no time are we making any representation or warranty
          regarding any third party's services nor will we be liable to you or
          any third party for any consequences or claims arising from or in
          connection with such third party including, and not limited to, any
          liability or responsibility for, death, injury or impairment
          experienced by you or any third party. You hereby disclaim and waive
          any rights and claims you may have against us with respect to third
          parties services. e. Apart from a cursory background verification,
          Playnoot does not make any representation or warranty as to the
          item-specifics (such as legal title, creditworthiness, identity, etc.)
          of any of the Users. You are advised to independently verify the bona
          fides of any particular User that you choose to deal with on the
          Playnoot app Platform and use your best judgment in that behalf. f.
          While the materials provided on the Playnoot app Platform were
          prepared to provide accurate information regarding the subject
          discussed, the information contained in these materials is being made
          available with the understanding that we make no guarantees,
          representations or warranties whatsoever, whether expressed or
          implied, with respect to professional qualifications, expertise,
          quality of work or other information herein. Further, we do not, in
          any way, endorse any service offered or described herein. In no event
          shall we be liable to you or any third party for any decision made or
          action taken in reliance on such information. g. The information
          provided hereunder is provided "as is". We and / or our employees make
          no warranty or representation regarding the timeliness, content,
          sequence, accuracy, effectiveness or completeness of any information
          or data furnished hereunder or that the information or data provided
          hereunder may be relied upon. multiple responses may usually be made
          available from different sources and it is left to the judgement of
          users based on their specific circumstances to use, adapt, modify or
          alter suggestions or use them in conjunction with any other sources
          they may have, thereby absolving us as well as our consultants,
          business associates, affiliates, business partners and employees from
          any kind of professional liability. h. We shall not be liable to you
          or anyone else for any losses or injury arising out of or relating to
          the information or Services provided on the Playnoot app Platform. In
          this regard, you acknowledge that the Services offered by the Playnoot
          app Platform concern activities that carry an inherent risk. However,
          we merely provide a booking platform to facilitate such activities and
          in no event will we or our employees, affiliates, authors or agents be
          liable to you or any third party for any decision made or action taken
          by your reliance on the Services contained herein. i. In no event will
          we be liable for any damages (including, without limitation, direct,
          indirect, incidental, special, consequential or exemplary damages,
          damages arising from personal injury/wrongful death, and damages
          resulting from lost profits, lost data or business interruption),
          resulting from any services provided by any third party through the
          Playnoot app Platform, whether based on warranty, contract, tort, or
          any other legal theory and whether or not we are advised of the
          possibility of such damages. However, we will attempt to refund any
          amount paid by the User, at our sole discretion, and our liability in
          this regard, if any will be limited to just the amount paid by the
          User with respect to the said booking on the Playnoot app Platform. j.
          Nothing in these disclaimers limits the liability of Playnoot in
          respect of: i. Death or personal injury caused by the negligence of or
          its agents or employees; ii. Fraud or fraudulent misrepresentation by
          its agents or employees; iii. Any matter which it would be illegal or
          unlawful for it to exclude or limit, or to attempt or purport to
          exclude or limit, its liability. k. Nothing in these Terms of Use
          constitutes, or is meant to constitute, advice of any kind. If you
          require advice in relation to any legal, financial or medical matter,
          you should consult an appropriate professional.
          <h4>VIII. BREACH OF TERMS</h4>
          a. You hereby acknowledge and agree that Playnoot, in its sole and
          absolute discretion, has the right (but not the obligation) to delete,
          terminate, or deactivate your Account, block your email or IP address,
          cancel the terminate your access to or participation in the use of the
          Playnoot app Platform (or any part thereof), or remove and discard any
          content on the Playnoot app Platform ("Termination"), immediately and
          without notice, for any reason, including without limitation, if
          Playnoot believes or has reason to believe that you have violated any
          provision of the Terms of Use. b. You hereby indemnify and undertake
          to keep Playnoot indemnified against any losses, damages, costs,
          liabilities and expenses (including without limitation legal expenses
          and any amounts paid by to a third party in settlement of a claim or
          dispute) incurred or suffered by it, that arise out of any breach by
          you of any provision of these Terms of Use, or arising out of any
          claim that you have breached any provision of these Terms of Use. c.
          Effect of Termination. i. Upon termination of your Account, your right
          to participate in the Playnoot app Platform shall automatically
          terminate. You acknowledge and agree that your right to receive any
          Services hereunder is conditional upon your proper use of the Playnoot
          app Platform, your adherence to the Terms of Use, the continuous
          activation of your Account, and your permitted participation in the
          Playnoot app Platform. Notwithstanding the foregoing, residual data
          may remain in the Playnoot system. ii. If you provided a valid email
          address during registration, Playnoot will notify you via email of any
          such termination or cancellation, which shall be effective immediately
          upon Playnoot's delivery of such notice. iii. Upon Termination, you
          shall promptly destroy all copies of Playnoot data and other content
          in your possession or control. You further acknowledge and agree that
          Playnoot shall not be liable to you or any third party for any
          termination of your access to the Playnoot app Platform. Upon
          Termination of Service, Playnoot retains the right to use any data
          collected from your use of the Playnoot app Platform for internal
          analysis and archival purposes. In no event is Playnoot obligated to
          return any submitted content to you. Clauses in the Terms of Use
          regarding Termination, Intellectual Property, Dispute Resolution,
          Disclaimers, Limitations of liability, Indemnification, Treatment of
          information User Content, and third party content will survive
          expiration or termination of the Playnoot app Platform and/or your
          Account. iv. You agree to indemnify and hold Playnoot and its
          officers, managers, Registered Users, affiliates, successor,
          assignees, directors, agents, suppliers and employees harmless from
          any claim or demand, including reasonable attorneys' fees and court
          costs, made by any third party due to or arising out of such
          Termination. v. We may any time at our sole discretion reinstate
          suspended/terminated Users. A terminated User may not register or
          attempt to register with us or use the Playnoot app Platform in any
          manner whatsoever until such time that such User is reinstated by us.
          <h4>IX. INTELLECTUAL PROPERTY</h4>
          a. We are either the owner of intellectual property rights or have the
          non-exclusive, worldwide, perpetual, irrevocable, royalty free,
          sub-licensable (through multiple tiers) right to exercise the
          intellectual property, in the Playnoot app Platform, and in the
          material published on it. b. You may print off one copy, and may
          download extracts, of any page(s), files or other content from the
          Playnoot app Platform for your personal reference and you may draw the
          attention of others within your organisation to material available on
          the Playnoot app Platform. c. You must not redistribute any material
          from the Playnoot app Platform and where it has been specifically and
          expressly made evident by Playnoot that certain content is
          specifically made available for redistribution, it may be
          redistributed only within your organization, and as intended by
          Playnoot. d. You must not republish material from the Playnoot app
          Platform on any other print or digital media platform, or display in
          material on the Playnoot app Platform in public. You must also not
          republish or reproduce any material from the Playnoot app Platform
          through the use of iframes or screenscrapers. e. You must not modify
          or edit any materials you have printed off or downloaded from the
          Playnoot app Platform, and you must not use any illustrations,
          photographs, video or audio sequences or any graphics separately from
          any accompanying text. f. You must not use, reproduce, duplicate,
          copy, sell, sub-license or rent out any part of the materials on the
          Playnoot app Platform for commercial purposes without obtaining a
          licence to do so from us or our licensors. g. If you print off, copy
          or download any part of the Playnoot app Platform in breach of these
          Terms of Use, your right to use the Playnoot app Platform will cease
          immediately and you must, at our option, return or destroy any copies
          of the materials you have made.
          <h4>X. TREATMENT OF USER CONTENT</h4>
          We process User Content to us in accordance with our Privacy Policy
          available at https://playnoot.in/privacy.
          <h4>XI. THIRD PARTY CONTENT</h4>
          a. We cannot and will not assure that other Users are or will be
          complying with the foregoing rules or any other provisions of these
          Terms of Use, and, as between you and us, you hereby assume all risk
          of harm or injury resulting from any such lack of compliance. b. You
          acknowledge that when you access a link that leaves the Playnoot app
          Platform, the site you will enter into is not controlled by us and
          different terms of use and privacy policy may apply. By assessing
          links to other sites, you acknowledge that we are not responsible for
          those sites. We reserve the right to disable links to and / or from
          third-party sites to the Playnoot app Platform, although we are under
          no obligation to do so.
          <h4>XII. SEVERABILITY</h4>
          If any of these Terms of Use should be determined to be illegal,
          invalid or otherwise unenforceable by reason of the laws of any state
          or country in which these Terms of Use are intended to be effective,
          then to the extent and within the jurisdiction where that term is
          illegal, invalid or unenforceable, it shall be severed and deleted and
          the remaining Terms of Use shall survive, remain in full force and
          effect and continue to be binding and enforceable.
          <h4>XIII. ASSIGNMENT</h4>
          Playnoot may, at its sole discretion, at any time, assign or transfer
          or sub contract or purport to assign or transfer or subcontract its
          obligations under these Terms of Use to any other person or entity.
          However, you shall not assign or transfer or sub contract or purport
          to assign or transfer or subcontract your obligations under these
          Terms of Use to any other entity or person.
          <h4>XIV. GOVERNING LAW AND DISPUTE RESOLUTION</h4>
          These Terms of Use are governed by the laws of India. Any action,
          suit, or other legal proceeding, which is commenced to resolve any
          matter arising under or relating to the Playnoot app Platform or these
          Terms of Use, shall be subject to the jurisdiction of the courts at
          Mohali, India.
          <h4>XV. ADMIN POLICIES</h4>
          a. Payments made to purchase any service on admin panel such as
          License are non refundable.
          <br />
          b. Payments made to a specific play center via Playnoot app booking
          will be received by Playnoot company in our accounts, this then later
          by settled back to the Play center owner.
          <br />
          c. Settlement of funds are usually done betwwn 48 to 72 hrs from the
          time they are crdedited to Playnoot accounts. Any unforseable error
          may cause longer delay in settlement.
          <br />
          d. Playnoot is not liable for any online transection related errors
          (UPI, Net Bankings, Debit Card, Credit Card and all other online
          modes) that may occur during service purchase from Playnoot.
          <h4>XVI. CONTACT US</h4>
          Please contact us for any questions or comments regarding the Terms of
          Use and other policies governing the Playnoot app Platform by e-mail
          contact@playnoot.com. This document was last updated on 20 May 2023.{" "}
        </div>
      </Box>
    </>
  );
};

export default TermsAndConditions;
