import { useState, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";

import Icon from "../../../components/Icons";
import ToggleCardSwitch from "../../../components/ToggleCardSwitch";

import { getFacilityAmenitiesDto } from "../../../modules/GameCenter/selector";
import { GameCenterViewContext } from "../../../utility/context";

import { URLS } from "../../../configs/api_urls";
import { httpPost, httpDelete } from "../../../utility/request";
import { addAlert } from "../../../modules/App/actions";

const AmenityUpdate = () => {
  const dispatch = useDispatch();
  const amenities = useSelector(getFacilityAmenitiesDto());
   const gameCenterDetails = useContext(GameCenterViewContext);
   const [selectedAmenitiesList, setSelectedAmenitiesList] = useState(
     gameCenterDetails.GameCenter.applicationInstanceAmenities.map(
       (mapItem) => mapItem.aminity
     )
   );

   const isAmenityChecked = (amenityId) => 
   {
    return selectedAmenitiesList
      .map((aminity) => aminity.amenityId)
      .includes(amenityId);
   }

   const selectAmenity =  async (amenityId) => {
     var newList = [];
     if (isAmenityChecked(amenityId)) {
       var mapId =
         gameCenterDetails.GameCenter.applicationInstanceAmenities.find(
           (a) => a.aminity.amenityId == amenityId
         ).applicationAmenitiesMapId;
       await httpDelete(
         URLS.APPLICATION_INSTANCE_SETTINGS.DELETE_INSTANCE_AMENITY.replace(
           "#ID#",
           mapId
         )
       )
         .then((response) => {
           if (response.data == true) {
                   
             dispatch(
               addAlert({
                 type: "success",
                 message: "Amenity deleted successfully",
               })
             );
             newList = selectedAmenitiesList.filter(
               (amenity) => amenity.amenityId !== amenityId
             );
             gameCenterDetails.setGameCenter({
               ...gameCenterDetails.GameCenter,
               applicationInstanceAmenities:
                 gameCenterDetails.GameCenter.applicationInstanceAmenities.filter(
                   (g) => g.applicationAmenitiesMapId !== mapId
                 ),
             });
           }
         })
         .catch((error) => {
           dispatch(
             addAlert({
               type: "error",
               message: "Error while deleting Image",
             })
           );
         });
       //remove api
     } else {
       var selectedAmenity = amenities.find((a) => a.amenityId == amenityId);
       // add api
       await httpPost(
         URLS.APPLICATION_INSTANCE_SETTINGS.ADD_INSTANCE_AMENITY.replace(
           "#ID#",
           amenityId
         )
       )
         .then((response) => {
           if (response.data) {
             newList = [...selectedAmenitiesList, selectedAmenity];
             gameCenterDetails.setGameCenter({
               ...gameCenterDetails.GameCenter,
               applicationInstanceAmenities: [
                 ...gameCenterDetails.GameCenter.applicationInstanceAmenities,
                 response.data,
               ],
             });
             dispatch(
               addAlert({
                 type: "success",
                 message: "New Amenity added succesfully",
               })
             );
           }
         })
         .catch((error) => {
           dispatch(
             addAlert({
               type: "error",
               message: error,
             })
           );
         })
         .finally(() => {
           //setIsSaving(false);
         });
        
     }

     setSelectedAmenitiesList(newList);
   };

  return (
    <div className="mt-2">
      <h6 className="font-family-unset">Amenities</h6>
      <div className="row">
        {amenities.map((amenity) => {
          return (
            <div className="col-md-2">
              <ToggleCardSwitch
                key={amenity.amenityId}
                id={amenity.amenityId}
                isSelected={isAmenityChecked(amenity.amenityId)}
                onClick={() => {
                  selectAmenity(amenity.amenityId);
                }}
                iconOn={
                  <Icon
                    color="white"
                    category="amenities"
                    icon={amenity.amenityName}
                  ></Icon>
                }
                iconOff={
                  <Icon
                    color="black"
                    category="amenities"
                    icon={amenity.amenityName}
                  ></Icon>
                }
                title={amenity.amenityName}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default AmenityUpdate;
