import { useState } from "react";
import PaymentList from "./PaymetsList/payment-list.component";
import PaymentAnalytics from "./PaymentAnalytics/payment-analytics.component";
import PaymentsNavigation from "./PaymentsNavigation/payment-navigation.component";
import AddOfflinePayment from "./AddOfflinePayment/addofflinepayment.component";
import { Drawer, Box } from "@mui/material";
import React from "react";

const PaymentDashboard = () => {
  const [selectedView, setSelectedView] = useState("");
  const [selectedFetchDate, setSelectedFetchDate] = useState(null);
  const [isOfflinePaymentDrawOpen, setOfflinePaymentDrawOpen] = useState(false);
  const [selectedConfirmationFilter, setSelectedConfirmationFilter] =
    useState("All");
  return (
    <div>
      <PaymentsNavigation
        selectedView={selectedView}
        selectedDate={selectedFetchDate}
        selectedConfirmationFilter={selectedConfirmationFilter}
        setSelectedDate={(date) => setSelectedFetchDate(date)}
        setSelectedConfirmationFilter={(status) =>
          setSelectedConfirmationFilter(status)
        }
        setView={(value) => setSelectedView(value)}
        clickAddOfflinePayment={() => setOfflinePaymentDrawOpen(true)}
      ></PaymentsNavigation>
      <div>
        <React.Fragment key={"right"}>
          <Drawer
            anchor={"right"}
            open={isOfflinePaymentDrawOpen}
            onClose={(e) => setOfflinePaymentDrawOpen(false)}
          >
            <Box
              sx={{
                width: "100%",
                minWidth: 360,
                bgcolor: "background.paper",
                p: 2,
                mt: 5,
              }}
            >
              <AddOfflinePayment
                closeEdit={(e) => setOfflinePaymentDrawOpen(false)}
              />
            </Box>
          </Drawer>
        </React.Fragment>
        {selectedView == "" && (
          <div>
            <PaymentList
              selectedDate={selectedFetchDate}
              selectedConfirmationFilter={selectedConfirmationFilter}
            ></PaymentList>
          </div>
        )}
        {selectedView == "paymentAnalytics" && <PaymentAnalytics />}
      </div>
    </div>
  );
};

export default PaymentDashboard;
