
import ButtonControl from "./ButtonControl";
import InputControl from "./InputControl";
import CheckboxControl from "./CheckboxControl";
import RadioGroupControl from "./CheckboxControl";
import SelectControl from "./SelectControl";
import DatePickerControl from "./DatePickerControl";
import TimePickerControl from "./TimePickerControl";
import AutocompleteControl from "./AutoCompleteControl";

const Controls = {
  InputControl,
  RadioGroupControl,
  SelectControl,
  CheckboxControl,
  DatePickerControl,
  ButtonControl,
  TimePickerControl,
  AutocompleteControl
};

export default Controls;