//licenseSlice.js
import { createSlice } from "@reduxjs/toolkit";
import { URLS } from "../../configs/api_urls";
import { httpGet } from "../../utility/request";

const initialState = {
  licenses: [], // change property name from 'license' to 'licenses'
  isLoading: false,
  error: null,
};

const licenseSlice = createSlice({
  name: "license",
  initialState,
  reducers: {
    fetchLicenseStart: (state) => {
      state.isLoading = true;
    },
    fetchLicenseSuccess: (state, action) => {
      state.isLoading = false;
      state.licenses = action.payload;
    },
    fetchLicenseFailure: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

//reducer for licenseSlice.js
export default licenseSlice.reducer;


//selectors for licenseSlice.js
export const getAllLicenses = (state) => state.license.licenses;
export const getIsLoading = (state) => state.license.isLoading;
export const getError = (state) => state.license.error;

//actions for licenseSlice.js
export const { fetchLicenseStart, fetchLicenseSuccess, fetchLicenseFailure } = licenseSlice.actions;

//thunk for licenseSlice.js
export const fetchAvailableLicenses = () => {
  return async (dispatch) => {
    const url = URLS.LICENSE.GET_ALL;
    dispatch(fetchLicenseStart());

    httpGet(url)
      .then(({ data }) => {
        var activeLicenses = data.filter(l => l.isActive === true);
        dispatch(fetchLicenseSuccess(activeLicenses));
      })
      .catch((error) => {
        dispatch(fetchLicenseFailure(error));
      });
  };
};
