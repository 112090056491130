const APP = "Asset";

export const SET_ASSET_TYPES_BY_INSTANCE = `${APP}/set-asset-types-by-instance`;

export const GET_ASSET_TYPES_BY_INSTANCE = `${APP}/get-asset-types-by-instance`;

export const SET_INSTANCE_STANDARD_GAMES = `${APP}/set-instance-standard-games`;

export const GET_INSTANCE_STANDARD_GAMES = `${APP}/get-instance-standard-games`;

export const SET_ASSET_CREATION_DETAILS = `${APP}/set-asset-creation-details`;

export const SET_ASSET_CREATION_SLOTS = `${APP}/set-asset-creation-slots`;

export const SET_ASSET_PRICING_TYPE = `${APP}/set-asset-pricing-type`;

export const SET_ASSET_CREATION_PRICING = `${APP}/set-asset-creation-pricing`;

export const SET_ASSET_CREATION_SETTINGS = `${APP}/set-asset-creation-settings`;

export const RESET_ASSET_CREATION_DATA = `${APP}/reset-asset-creation-data`;

export const SET_ASSET_OFFERS_BY_INSTANCE = `${APP}/set-asset-offer-by-instance`;

export const SET_ASSET_LIST_BY_INSTANCE = `${APP}/set-asset-list-by-instance`;

export const SET_ASSET_TYPES_LIST_BY_INSTANCE = `${APP}/set-asset-types-list-by-instance`;

export const SET_IS_REQUESTING = `${APP}/set-is-asset-requesting`;

export const SET_ASSET_UPDATE_DATA = `${APP}/set-asset-update-data`;

export const SET_SLOT_UPDATE_METHOD = `${APP}/set-slot-update-mehtod`;
