import { createTheme } from "@mui/material";
import { red, blue, orange, yellow } from "@mui/material/colors";

  const playnootTheme = createTheme({
    palette: {
      playnootPrimary: {
        main: "#ffcc00",
      },
      playnootSecondary: {
        main: "#2929a3",
      },
      red: {
        main: red[300],
      },
      warning: {
        main: "#ffcc00",
      },
    },
  });

export default playnootTheme
