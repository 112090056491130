import ButtonControl from "../../../../components/Controls/ButtonControl";

const AdNavigation = ({ createNewAdClick }) => {
  return (
    <div>
      <div style={{ float: "right" }}>
        <ButtonControl
          text={"Create new Ad"}
          style={{ width: "180px", margin: "0px 5px" }}
          onClick={() => {
            createNewAdClick();
          }}
        ></ButtonControl>
      </div>
    </div>
  );
};

export default AdNavigation;
