import { useState } from "react";
import PaymentList from "./PaymetsList/payment-list.component";
import PaymentsNavigation from "./PaymentsNavigation/payment-navigation.component";

const SuperAdminPaymentDashboard = () => {
  const [selectedView, setSelectedView] = useState("");
  const [selectedFetchDate, setSelectedFetchDate] = useState(null);
  const [selectedConfirmationFilter, setSelectedConfirmationFilter] =
    useState("All");
  return (
    <div>
      <PaymentsNavigation
        selectedDate={selectedFetchDate}
        selectedConfirmationFilter={selectedConfirmationFilter}
        setSelectedDate={(date) => setSelectedFetchDate(date)}
        setSelectedConfirmationFilter={(status) =>
          setSelectedConfirmationFilter(status)
        }
      ></PaymentsNavigation>
      <div>
        {selectedView == "" && (
          <div>
            <PaymentList
              selectedDate={selectedFetchDate}
              selectedConfirmationFilter={selectedConfirmationFilter}
            ></PaymentList>
          </div>
        )}
      </div>
    </div>
  );
};

export default SuperAdminPaymentDashboard;
