import * as React from "react";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import Drawer from "@mui/material/Drawer";
import { Avatar, Chip, Input, TextField } from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import { IconButton } from "@mui/material";
import { Menu } from "@mui/material";
import { MenuItem } from "@mui/material";
import FontAwesome from "../../../components/Icons/fontawesome";
import {
  faCheckSquare,
  faEllipsisV,
  faTimesRectangle,
} from "@fortawesome/free-solid-svg-icons";
import { Pagination } from "@mui/material";
import Controls from "../../../components/Controls/Controls";
import TableSkeleton from "../../../components/Skeletons/table-skeleton.component";

import { URLS } from "../../../configs/api_urls";
import { httpDelete, httpPost } from "../../../utility/request";
import { setIsRenderedState } from "../../../modules/ModalPopup/modalPopupSlice";

import ModalPopup from "../../../components/ModalPopup/modalpopup.component";
import PlayerDetails from "../PlayerDetails/player-details.component";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "playerId",
    numeric: false,
    disablePadding: true,
    label: "Player Id",
  },
  {
    id: "user",
    numeric: false,
    disablePadding: false,
    label: "User",
  },
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Name",
  },
  {
    id: "totatexpenditure",
    numeric: true,
    disablePadding: false,
    label: "Total Expenditure",
  },
  {
    id: "totalbookings",
    numeric: true,
    disablePadding: false,
    label: "Total Bookings",
  },
  {
    id: "totalcredit",
    numeric: true,
    disablePadding: false,
    label: "Total Credit",
  },
  {
    id: "note",
    numeric: true,
    disablePadding: false,
    label: "Note",
  },
  {
    id: "action",
    numeric: true,
    disablePadding: false,
    label: "Action",
  },
];

const PaymentStatusColor = {
  Credit: "success",
  Initiated: "warning",
  Cancelled: "error",
};

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all payments",
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default function PlayerList({ selectedDate }) {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const [playersData, setPlayersData] = useState({});
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [selectedForDeatils, setSelectedForDeatils] = React.useState(null);
  const [selectedForRemove, setSelectedForRemove] = React.useState(null);
  const [page, setPage] = React.useState(1);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [isDetailsDrawOpen, setIsDetailDrawOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedNote, setSelectedNote] = React.useState(null);
  const open = Boolean(anchorEl);

  const fetchData = {
    pageRequest: {
      pageNumber: page,
      pageSize: rowsPerPage,
    },
    userName: "",
  };
  const fetchPlayers = async () => {
    setIsLoading(true);
    const { data } = await httpPost(URLS.PLAYERS.GETINSTANCEPLAYERS, fetchData);
    setPlayersData(data);
    setRows(data.items);
    setIsLoading(false);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.playerId);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, playerId) => {
    const selectedIndex = selected.indexOf(playerId);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, playerId);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (rows) => {
    setRowsPerPage(parseInt(rows, 10));
    setPage(1);
  };

  const handleDetailsClick = (player) => {
    console.log(player);
    setSelectedForDeatils(player);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (playerId) => selected.indexOf(playerId) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = 1;

  const visibleRows = React.useMemo(
    () =>
      stableSort(rows, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [order, orderBy, page, rowsPerPage]
  );

  const handleMenuClick = (event) => {
    setAnchorEl(event);
    CheckOpen(event.split("_")[1]);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const CheckOpen = (playerId) => {
    if (anchorEl !== undefined && anchorEl !== null) {
      if (anchorEl.split("_")[1] == playerId) return true;
    }
    return false;
  };

  const handleNoteChange = (playerId, event) => {
    var text = event.target.value;
    var rowsVal = rows.map((row) =>
      row.playerId === playerId ? { ...row, note: text } : row
    );
    setRows(rowsVal);
  };

  const saveNoteEdit = async (playerId) => {
    var text = rows.find((p) => p.playerId == playerId).note;
    await httpPost(
      URLS.PLAYERS.SAVEPLAYERNOTE.replace("#ID#", playerId).replace(
        "#NOTETEXT#",
        text
      )
    )
      .then((response) => {
        if (response.data == true) {
          setSelectedNote("");
        }
      })
      .catch((error) => {
        //  setFormSubmitError(
        //    error.response.data.Message || "Some error occuered!"
        //  );
      });
  };

  const CheckIsInputFocused = (playerId) => {
    if (selectedNote == "note_" + playerId) return true;
    return false;
  };

  const removePlayer = async () => {
    await httpDelete(
      URLS.PLAYERS.REMOVEPLAYER.replace("#ID#", selectedForRemove)
    ).then(({ data }) => {
      if (data == true) {
        fetchPlayers();
      }
    });
    dispatch(setIsRenderedState(false));
    setSelectedForRemove(null);
  };

  useEffect(() => {
    fetchPlayers();
  }, [page, rowsPerPage, selectedDate]);

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <React.Fragment key={"right"}>
          <Drawer
            anchor={"right"}
            open={isDetailsDrawOpen}
            onClose={(e) => setIsDetailDrawOpen(false)}
          >
            <Box
              sx={{
                width: "100%",
                minWidth: 360,
                bgcolor: "background.paper",
                p: 2,
                mt: 5,
              }}
            >
              <PlayerDetails
                player={selectedForDeatils}
                onCreditChange={() => fetchPlayers()}
              ></PlayerDetails>
            </Box>
          </Drawer>
        </React.Fragment>
        {selectedForRemove != null && (
          <ModalPopup
            heading={"Are you sure that you want to Remove this Player?"}
            subHeadhing="Removing player will remove player related data as well such as notes, credit records"
            isConfirmButton={true}
            confirmButtonText={"Yes"}
            onConfirmClick={removePlayer}
            isCancelButton={true}
            cancelButtontext={"No"}
            onCancelClick={() => {
              dispatch(setIsRenderedState(false));
              setSelectedForRemove(null);
            }}
          ></ModalPopup>
        )}
        {isLoading && (
          <TableSkeleton
            pageNumber={page}
            pageSize={rowsPerPage}
          ></TableSkeleton>
        )}
        {!isLoading && (
          <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={dense ? "small" : "medium"}
            >
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
              />
              <TableBody>
                {rows.map((row, index) => {
                  const isItemSelected = isSelected(row.playerId);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      onClick={(event) => handleClick(event, row.playerId)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.playerId}
                      selected={isItemSelected}
                      sx={{ cursor: "pointer" }}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          inputProps={{
                            "aria-labelledby": labelId,
                          }}
                        />
                      </TableCell>
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                      >
                        {"#" + row.playerId}
                      </TableCell>
                      <TableCell align="right" padding="none">
                        <div className="display-flex">
                          <Avatar
                            src={row.profilePictureUrl}
                            alt="Avatar"
                            style={{
                              borderRadius: "50%",
                              height: "50px",
                              width: "50px",
                            }}
                          ></Avatar>
                          <span
                            style={{
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              width: "120px",
                              textAlign: "left",
                            }}
                            className="m-auto ml-10"
                          >
                            {row.userName}
                          </span>
                        </div>
                      </TableCell>
                      <TableCell align="left">
                        {row.firstName || ""}&nbsp;
                        {row.middleName || ""}&nbsp;
                        {row.lastName || ""}
                      </TableCell>
                      <TableCell align="right">
                        {row.totalExpenditureHere / 100}
                      </TableCell>
                      <TableCell align="right">{row.totalGamesHere}</TableCell>
                      <TableCell align="right">{row.totalCredits}</TableCell>
                      <TableCell align="right">
                        <TextField
                          id={"note_" + row.playerId}
                          value={row.note}
                          variant={"outlined"}
                          onFocus={({ currentTarget }) => {
                            setSelectedNote(currentTarget.id);
                          }}
                          onChange={(event) => {
                            handleNoteChange(row.playerId, event);
                          }}
                        ></TextField>
                        {CheckIsInputFocused(row.playerId) && (
                          <div>
                            <IconButton
                              aria-label="save"
                              size="small"
                              onClick={() => saveNoteEdit(row.playerId)}
                            >
                              <FontAwesome
                                icon={faCheckSquare}
                                iconSize={"30px"}
                                iconcolor={"green"}
                              ></FontAwesome>
                            </IconButton>
                            <IconButton
                              aria-label="cancel"
                              size="small"
                              onClick={() => setSelectedNote("")}
                            >
                              <FontAwesome
                                icon={faTimesRectangle}
                                iconSize={"30px"}
                                iconcolor={"red"}
                              ></FontAwesome>
                            </IconButton>
                          </div>
                        )}
                      </TableCell>
                      <TableCell align="right">
                        <IconButton
                          id={"button_" + row.playerId}
                          aria-label={"button_" + row.playerId}
                          aria-controls={
                            CheckOpen(row.playerId)
                              ? "menu_" + row.playerId
                              : undefined
                          }
                          aria-haspopup="true"
                          aria-expanded={
                            CheckOpen(row.playerId) ? "true" : undefined
                          }
                          onClick={(e) => {
                            handleMenuClick(e.currentTarget.ariaLabel);
                            e.stopPropagation();
                          }}
                        >
                          <FontAwesome
                            icon={faEllipsisV}
                            iconSize={"20px"}
                            className={"info-icon-margin"}
                          ></FontAwesome>
                        </IconButton>
                        <Menu
                          aria-label={"menu_" + row.playerId}
                          id={"menu_" + row.playerId}
                          anchorEl={document.getElementById(
                            "button_" + row.playerId
                          )}
                          open={CheckOpen(row.playerId)}
                          onClose={handleMenuClose}
                          sx={{ boxShadow: 10, color: "gray" }}
                          MenuListProps={{
                            "aria-labelledby": "button_" + row.playerId,
                          }}
                        >
                          <MenuItem
                            onClick={(e) => {
                              handleMenuClose(e);
                              handleDetailsClick(row);
                              setIsDetailDrawOpen(true);
                            }}
                          >
                            Details
                          </MenuItem>
                          <MenuItem
                            onClick={(e) => {
                              handleMenuClose(e);
                              setSelectedForRemove(row.playerId);
                              dispatch(setIsRenderedState(true));
                            }}
                          >
                            Remove
                          </MenuItem>
                        </Menu>
                      </TableCell>
                    </TableRow>
                  );
                })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: (dense ? 33 : 53) * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        {!isLoading && (
          <div className="display-flex" style={{ padding: "10px" }}>
            <Pagination
              count={playersData.totalPages}
              page={page}
              variant="outlined"
              shape="rounded"
              showFirstButton={playersData.hasPreviousPage}
              showLastButton={playersData.hasNextPage}
              color="secondary"
              style={{ marginLeft: "auto" }}
              onChange={(event, value) => {
                handleChangePage(value);
              }}
            />
            <Controls.SelectControl
              name="pageSize"
              style={{ width: "100%", marginLeft: "20px" }}
              options={[
                { id: 5, title: "5" },
                { id: 10, title: "10" },
                { id: 50, title: "50" },
              ]}
              value={rowsPerPage}
              onChange={(e) => {
                handleChangeRowsPerPage(Number(e.target.value));
              }}
            ></Controls.SelectControl>
          </div>
        )}
      </Paper>
    </Box>
  );
}
