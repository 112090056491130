import cookieStorage from "store/storages/cookieStorage";
import { decodedApplicationId } from "../utility/common";

const token_cookie_key = `access_${process.env.NODE_ENV}`;
const application_id_cookie_key = `application_${process.env.NODE_ENV}`;

export const setApplicationId = async (application_id) => {
  cookieStorage.write(application_id_cookie_key, application_id);
};

export const set_access_token = async (accessToken) => {
  cookieStorage.write(token_cookie_key, `${accessToken}`);

  setApplicationId(decodedApplicationId(accessToken));
};

export const getAccessToken = async () => {
  return cookieStorage.read(token_cookie_key);
};

export const getApplicationId = () => {
  return cookieStorage.read(application_id_cookie_key);
};

export const getAccessTokenV2 = () => {
  return cookieStorage.read(token_cookie_key);
};

export const signOut = async () => {
  cookieStorage.remove(token_cookie_key);
  cookieStorage.remove(application_id_cookie_key);
};
