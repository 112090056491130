import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

import ImageProcessor from "../../../components/ImageProcessor/image-processor.component";
import Controls from "../../../components/Controls/Controls";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FontAwesome from "../../../components/Icons/fontawesome";
import { faTrash, faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import ModalPopup from "../../../components/ModalPopup/modalpopup.component";

import { URLS } from "../../../configs/api_urls";
import { httpPut, httpPost, httpDelete } from "../../../utility/request";
import { fireEvent } from "../../../modules/Events/eventSlice";
import { setIsRenderedState } from "../../../modules/ModalPopup/modalPopupSlice";

import "./addedit-brand.styles.scss";

const AddEditBrand = ({ selectedBrand, cancelClick }) => {
  const dispatch = useDispatch();
  const [selectedBrandItem, setSelectedBrandItem] = useState(selectedBrand);

  const ecommerceplatformOptions = [
    { id: "Flipkart", title: "Flipkart" },
    { id: "Amazone", title: "Amazone" },
    { id: "Myntra", title: "Myntra" },
    { id: "Custom", title: "Custom" },
  ];

  const createBrand = async () => {
    await httpPost(URLS.BRANDING.CREATE_BRAND, {
      ...selectedBrandItem,
    })
      .then((response) => {
        if (response.data == true) {
          dispatch(fireEvent({ eventName: "refresh-branding-list" }));
        }
      })
      .catch((error) => {
        //  setFormSubmitError(
        //    error.response.data.Message || "Some error occuered!"
        //  );
      })
      .finally(() => {
        cancelClick();
      });
  };

  const updateBrand = async () => {
    // API CAll here
    await httpPut(URLS.BRANDING.UPDATE_BRAND, {
      ...selectedBrandItem,
    })
      .then((response) => {
        if (response.data == true) {
          dispatch(fireEvent({ eventName: "refresh-branding-list" }));
        }
      })
      .catch((error) => {
        //  setFormSubmitError(
        //    error.response.data.Message || "Some error occuered!"
        //  );
      })
      .finally(() => {
        cancelClick();
      });
  };

  const handelDeleteClick = async () => {
    await httpDelete(
      URLS.BRANDING.DELETE_BRAND.replace(
        "#ID#",
        selectedBrand.instanceBrandingId
      )
    )
      .then((response) => {
        if (response.data == true) {
          dispatch(fireEvent({ eventName: "refresh-branding-list" }));
          dispatch(setIsRenderedState(false));
          cancelClick();
        }
      })
      .catch((error) => {
        //  setFormSubmitError(
        //    error.response.data.Message || "Some error occuered!"
        //  );
      });
  };

  const handleSaveClick = () => {
    if (selectedBrandItem.instanceBrandingId > 0) {
      updateBrand();
    } else {
      createBrand();
    }
  };

  const handleDeleteButtonClick = () => {
    dispatch(setIsRenderedState(true));
  };

  useEffect(() => {
    setSelectedBrandItem(selectedBrand);
  }, [selectedBrand]);

  return (
    <div className="edit-container w-100">
      <div className="display-flex">
        <Controls.ButtonControl
          text={"Cancel"}
          style={{
            width: "100px",
            margin: "10px 10px",
            backgroundColor: "gray",
          }}
          onClick={() => cancelClick()}
          //color={grey[700]}
        ></Controls.ButtonControl>
        <label className="edit-panel-title">
          {selectedBrand.instanceBrandingId > 0 ? "Edit Brand" : "Add Brand"}
        </label>
        <Controls.ButtonControl
          onClick={() => handleSaveClick()}
          text={"Save"}
        ></Controls.ButtonControl>
        {selectedBrand.instanceBrandingId > 0 && (
          <Controls.ButtonControl
            onClick={handleDeleteButtonClick}
            style={{
              width: "40px",
              margin: "10px 5px",
              backgroundColor: "red",
            }}
            innercomponent={<FontAwesomeIcon icon={faTrash}></FontAwesomeIcon>}
          ></Controls.ButtonControl>
        )}
      </div>
      <div className="mt-50" style={{ padding: "10px" }}>
        {/* <div
          className="fixedAspect"
          style={{
            backgroundImage: "url(" + selectedBrandItem.brandingImageUrl + ")",
          }}
        ></div> */}

        <ModalPopup
          heading={"Are you sure that you want to Delete this Branding?"}
          isConfirmButton={true}
          confirmButtonText={"Yes"}
          onConfirmClick={handelDeleteClick}
          isCancelButton={true}
          cancelButtontext={"No"}
          onCancelClick={() => dispatch(setIsRenderedState(false))}
        ></ModalPopup>

        <div className="display-flex">
          <div style={{ width: "320px", height: "180px", margin: "auto" }}>
            <ImageProcessor
              alt="asset_image"
              style={{
                borderRadius: "25px",
                width: "320px",
                height: "180px",
                objectFit: "cover",
              }}
              imageUrl={selectedBrandItem.brandingImageUrl}
              uploadUrl={URLS.FILES.BRAND_IMAGE_UPLOAD}
              setImageData={(e) => {
                setSelectedBrandItem({
                  ...selectedBrandItem,
                  brandingImageUrl: e,
                });
              }}
            ></ImageProcessor>
          </div>
          {/* <span className="upload-image-title ">Upload Image</span> */}
        </div>
        <div className="display-flex mt-10">
          <FontAwesome
            icon={faCircleInfo}
            iconSize={"20px"}
            className={"info-icon-margin"}
          ></FontAwesome>
          <span className="field-title">
            <i>
              The brand images are shown in 16:9 ratio on the app, So make sure
              you upload it with the size that fits this ratio
            </i>
          </span>
        </div>
        <div className="edit-section-margin">
          <label className="field-title">Brand Title</label>
          <Controls.InputControl
            name="title"
            other={{ placeholder: "Enter title for the brand" }}
            style={{ width: "100%", marginTop: "5px" }}
            onChange={(e) =>
              setSelectedBrandItem({
                ...selectedBrandItem,
                instanceBrandingTitle: e.target.value,
              })
            }
            value={selectedBrandItem.instanceBrandingTitle}
            //error={validation.planName}
          ></Controls.InputControl>
        </div>
        <div className="edit-section-margin">
          <label className="field-title">Brand Description</label>
          <Controls.InputControl
            name="title"
            isMultiLine={true}
            rows={3}
            other={{ placeholder: "Enter description for the brand" }}
            style={{ width: "100%", marginTop: "5px" }}
            onChange={(e) =>
              setSelectedBrandItem({
                ...selectedBrandItem,
                instanceBrandingDescription: e.target.value,
              })
            }
            value={selectedBrandItem.instanceBrandingDescription}
            //error={validation.planName}
          ></Controls.InputControl>
        </div>
        <div className="edit-section-margin" style={{ display: "grid" }}>
          <label className="field-title">Select seller platform</label>
          <Controls.SelectControl
            name="isVeg"
            style={{ width: "100%", marginTop: "5px" }}
            options={ecommerceplatformOptions}
            value={selectedBrandItem.brandSeller}
            onChange={(e) =>
              setSelectedBrandItem({
                ...selectedBrandItem,
                brandSeller: e.target.value,
              })
            }
          ></Controls.SelectControl>
        </div>
        <div className="edit-section-margin">
          <label className="field-title">Seller website item url</label>
          <Controls.InputControl
            name="brandSellerItemUrl"
            other={{ placeholder: "Enter url to the item on seller platform" }}
            style={{ width: "100%", marginTop: "5px" }}
            onChange={(e) =>
              setSelectedBrandItem({
                ...selectedBrandItem,
                brandSellerItemUrl: e.target.value,
              })
            }
            value={selectedBrandItem.brandSellerItemUrl}
            //error={validation.planName}
          ></Controls.InputControl>
        </div>
        <div className="edit-section-margin">
          <label className="field-title">
            Expiry date (after this date this branding will not be visible to
            end user)
          </label>
          <Controls.DatePickerControl
            id={"brandExpiry"}
            name="expirtyDate"
            value={selectedBrandItem.expiryDateTime}
            //style={{ width: "100%", marginTop: "5px" }}
            onChange={(date) =>
              setSelectedBrandItem({
                ...selectedBrandItem,
                expiryDateTime: date,
              })
            }
          ></Controls.DatePickerControl>
        </div>
      </div>
    </div>
  );
};

export default AddEditBrand;
