import { CART_ACTION_CONSTANTS } from "./cart.constants";

const CART_INITIAL_STATE = {
  isCartOpen: false,
  isPaymentScreenOpen: false,
  isLoading: false,
  cartItems: [],
  error: null,
};

export const cartReducer = (state = CART_INITIAL_STATE, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case CART_ACTION_CONSTANTS.SET_CART_ITEMS:
      return {
        ...state,
        cartItems: payload,
      };
    case CART_ACTION_CONSTANTS.SET_IS_CART_OPEN:
      return {
        ...state,
        isCartOpen: payload,
      };
    case CART_ACTION_CONSTANTS.CARTITEMS_REQUEST_START:
      return {
        ...state,
        isLoading: payload,
      };
    case CART_ACTION_CONSTANTS.CARTITEMS_REQUEST_SUCCESS:
       return { ...state, isLoading: false, cartItems: payload };
    case CART_ACTION_CONSTANTS.CARTITEMS_REQUEST_FAILURE:
      return { ...state, isLoading: false, error: payload };
    case CART_ACTION_CONSTANTS.SET_IS_PAYMENT_WINDOW_OPEN:
      return { ...state, isPaymentScreenOpen: payload };
    default:
      return state;
  }
};

export default cartReducer;