import { createAction } from "../../utility/reducer.utils";

import { SIGNALR_CONEECTION_REQUEST_START, SIGNALR_CONEECTION_REQUEST_PAYLOAD } from "./baseSignalR.constant";

export const initiateSignalRConnectionRequest = () => {
  return createAction(SIGNALR_CONEECTION_REQUEST_START, true);
};

export const setSignalRPayload = (signalRPayload) => {
  return createAction(SIGNALR_CONEECTION_REQUEST_PAYLOAD, signalRPayload);
};