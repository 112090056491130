import { useDispatch, useSelector } from "react-redux";

import { httpPut, httpDelete } from "../../../utility/request";
import { URLS } from "../../../configs/api_urls";

import { Select, MenuItem } from "@mui/material";

import { selectCartItems } from "../../../modules/cart/cart.selector";
import {
  addItemToCart,
  updateItemInCart,
  removeItemFromCart,
  clearItemFromCart,
} from "../../../modules/cart/cart.action";

import "./checkout-item.styles.scss";

const CheckoutItem = ({ cartItem }) => {
  const {adminCartId, name, imageUrl, price, quantity, param1, param2 } = cartItem;
  const dispatch = useDispatch();
  const cartItems = useSelector(selectCartItems);

  const addItemToCartHandler = () => {
 httpPut(
   URLS.CART.UPDATE_CART_ITEM
    .replace("#ADMIN_CART_ID#", adminCartId)
    .replace("#PRICING_ID#", param1)
    .replace("#QUANTITY#", quantity + 1)
 ).then(({data}) => {
      if (data == true) {
        dispatch(addItemToCart(cartItems, cartItem))
      }
    })
  }

  async function updateCartItem(pricingId, quantity) {
    await httpPut(
      URLS.CART.UPDATE_CART_ITEM
      .replace("#ADMIN_CART_ID#", adminCartId)
      .replace("#PRICING_ID#", pricingId)
      .replace("#QUANTITY#", quantity)
    ).then(({data}) => {
      if (data == true) {
        dispatch(updateItemInCart(cartItems, cartItem, pricingId, quantity));
      }
    })
  }

  const removeItemFromCartHandler = () => {
    if (quantity - 1 > 0) 
    {
      httpPut(
        URLS.CART.UPDATE_CART_ITEM.replace("#ADMIN_CART_ID#", adminCartId)
          .replace("#PRICING_ID#", param1)
          .replace("#QUANTITY#", quantity - 1)
      ).then(({ data }) => {
        if (data == true) {
          dispatch(removeItemFromCart(cartItems, cartItem));
        }
      });
    } else {
      clearItemFromCartHandler();
    }
  }

  const clearItemFromCartHandler = () => { 
    httpDelete(URLS.CART.DELETE_CART_ITEM.replace("#ID#", adminCartId)).then(
      dispatch(clearItemFromCart(cartItems, cartItem))
    );
  }

  function getPricingName (pricingtypeId) {
    switch(pricingtypeId){
      case 1:
        return "Years";
      case 2:
        return "Months"
      case 3: 
      return "Weeks"
      default:
        return "Nothing"
    }
  }

  function perMonthPrice () {
    var selectedPricing  = param2.planPricings.find(
      (x) => x.licensePlanPricingId == param1
    );
    if(selectedPricing.licensePlanPricingType == 1)
    {
      return Math.round(selectedPricing.planPrice / (selectedPricing.planDuration * 12)) ;
    } else if(selectedPricing.licensePlanPricingType == 2) 
    {
      return Math.round(selectedPricing.planPrice / selectedPricing.planDuration);
    }
  }

  return (
    <div className="checkout-item-container">
      <div className="img-container">
        <img src={imageUrl} alt={`${name}`} />
        <span className="item-name">{"License"}</span>
      </div>
      <div className="name-span">
        <span>{name}</span>
        <br />
        <span className="pricing-per-month">{perMonthPrice()} / per month</span>
      </div>
      <div className="name-span">
        <Select
          value={param1}
          onChange={(e) => updateCartItem(e.target.value, quantity)}
          className="pricing-type"
        >
          {param2.planPricings.filter(x => x.isActive == true).map((price) => {
            return (
              <MenuItem
                value={price.licensePlanPricingId}
                key={`price-${price.licensePlanPricingId}`}
              >
                {price.planDuration +
                  " " +
                  getPricingName(price.licensePlanPricingType)}
              </MenuItem>
            );
          })}
        </Select>
      </div>
      <div className="quantity">
        <div className="arrow" onClick={removeItemFromCartHandler}>
          &#10094;
        </div>
        <div className="value">{quantity}</div>
        <div className="arrow" onClick={addItemToCartHandler}>
          &#10095;
        </div>
      </div>
      <div className="name-span"> {price}</div>
      <div className="remove-button" onClick={clearItemFromCartHandler}>
        &#10005;
      </div>
    </div>
  );
};

export default CheckoutItem;
