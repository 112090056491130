import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { flushEvent } from "../../../../modules/Events/eventSlice";

import SelectControl from "../../../../components/Controls/SelectControl";
import AssetOfferItem from "./AssetOfferItem/asset-offer-item.component";

import { URLS } from "../../../../configs/api_urls";
import { httpGet } from "../../../../utility/request";
import AssetListItemSkeleton from "../../Skeletons/asset-list-item-skeleton.component";

const assetOffersAvailability = [
  { id: 0, title: "Active", isArchieved: false },
  { id: 1, title: "Archived", isArchieved: true },
];

const AssetOffersList = ({
  selectedAssetOfferList,
  clickSelectAssetOffer,
  addToSelectedList,
}) => {
    const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState(0);
  const [assetOffers, setAssetOffers] = useState([]);
  const [selectedFilterItems, setSelectedFilterItems] = useState([]);

  useSelector((onEvent) => {
    var eventName = onEvent.events.eventName;
    if (eventName === "refresh-asset-offer-list") {
      fetchAssetOffers();
      dispatch(flushEvent());
    }
    //reset event listner
  });

  const fetchAssetOffers = async () => {
    setIsLoading(true);
    const { data } = await httpGet(URLS.ASSET.GETASSETOFFERS);
    setAssetOffers(data);
    var isArchieved = assetOffersAvailability.find(
      (x) => x.id == selectedFilter
    ).isArchieved;
    var assetOfferItems = data.filter((p) => p.isArchieved === isArchieved);
    setSelectedFilterItems(assetOfferItems);
    setIsLoading(false);
  };

  const handleFilterChange = (filter) => {
    var isArchieved = assetOffersAvailability.find(
      (x) => x.id == filter
    ).isArchieved;
    setSelectedFilter(filter);
    var assetOffers = assetOffers.filter((p) => p.isArchieved === isArchieved);
    setSelectedFilterItems(assetOffers);
  };

  useEffect(() => {
    fetchAssetOffers();
  }, []);

  return (
    <div>
      <div className="list-filter-header">
        <SelectControl
          name="asset-availability"
          style={{ width: "120px", height: "35px" }}
          options={assetOffersAvailability}
          value={selectedFilter}
          onChange={(e) => handleFilterChange(e.target.value)}
        ></SelectControl>
      </div>
      <div className="list-container scrollbar">
        {selectedFilterItems.length == 0 &&
          selectedFilter == 0 &&
          !isLoading && (
            <div className="no-item-section">
              <span className="no-item-message">
                You have not created assets Offer yet. Assets offers are
                applicable during booking. Create valid offers to increase your
                booking sale!!
              </span>
            </div>
          )}
        {selectedFilterItems.length == 0 &&
          selectedFilter == 1 &&
          !isLoading && (
            <div className="no-item-section">
              <span className="no-item-message">
                Archived offers are not visible to players during booking.
              </span>
            </div>
          )}
        {isLoading && <AssetListItemSkeleton></AssetListItemSkeleton>}
        {selectedFilterItems.map((assetOffer) => {
          return (
            <div key={assetOffer.assetOfferId}>
              <AssetOfferItem
                assetOffer={assetOffer}
                isSelected={selectedAssetOfferList.includes(
                  assetOffer.assetOfferId
                )}
                addToSelectedList={addToSelectedList}
                clickSelectAssetOffer={() => {
                  clickSelectAssetOffer(assetOffer);
                }}
              ></AssetOfferItem>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default AssetOffersList;
