import { useState } from "react";
import AdNavigation from "./AdNavigation/ad-navigation.component";
import AdvertisementList from "./AdvertisementList/advertisement-list.component";
import AddEditAdvertisement from "./AddEditAdvertisement/add-edit-advertisement.component";
import GuideAdvertisementOperationComponent from "./GuideAdvertisementOperations/guide-advertisement-operation.component";

const AdvertisementDashboard = () => {
  const [selectedAd, setSelectedAd] = useState(null);
  const [selectedView, setSelectedView] = useState("");
  const [selectedAdList, setSelectedAdList] = useState([]);

  var newAd = {
    advertisementId: 0,
    latitude: 28.606952,
    longitude: 77.208283,
    visibiltyDistance: 20,
    bannerUrl: "",
    advertisementType: 0,
    clickEventPostJsonData: "",
  };

  const handleAdSelected = (ad) => {
    setSelectedAd(ad);
    setSelectedView("addeditview");
    setSelectedAdList([ad.advertisementId]);
  };

  return (
    <div>
      <div>
        <AdNavigation createNewAdClick={() => handleAdSelected(newAd)} />
      </div>
      <div>
        <div className="display-inline-flex w-100">
          <div className="items-list-container w-50">
            <div className="items-list">
              <AdvertisementList
                selectedAdList={selectedAdList}
                clickSelectAd={(ad) => {
                  handleAdSelected(ad);
                }}
              ></AdvertisementList>
            </div>
          </div>
          <div className="items-edit-container  w-50">
            {selectedView == "" && <GuideAdvertisementOperationComponent />}
            {selectedView == "addeditview" && (
              <AddEditAdvertisement
                selectedAd={selectedAd}
                cancelClick={() => {
                  setSelectedView("");
                  setSelectedAdList([]);
                }}
              ></AddEditAdvertisement>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdvertisementDashboard;
