import { Box, Checkbox, Chip, Typography } from "@mui/material";

const LicenseItem = ({
  license,
  clickSelectLicense,
  isSelected,
  isDisabled,
  addToSelectedList,
}) => {
  return (
    <Box>
      <Box
        className={
          "asset-item cursor-pointer " +
          (isSelected ? "seleted-asset-background" : "")
        }
        onClick={clickSelectLicense}
      >
        <Box className="w-100 display-inline-flex" style={{ height: "60px" }}>
          <Box>
            <Checkbox
              id={license.licenseId}
              checked={isSelected}
              onChange={addToSelectedList}
            />
          </Box>
          <Box style={{ marginLeft: "20px" }} className="w-100">
            <Typography sx={{ fontWeight: "bold" }}>
              {license.licenseName}
            </Typography>
            <Box className="row">
              <Typography className="col-md-4">
                Maximum Centers : {license.maximumInstancesAllowed}
              </Typography>
              <Typography className="col-md-4">
                Maxumn Admins : {license.maximumAdminsAllowed}
              </Typography>
              <Typography className="col-md-4">
                Storgae Years : {license.numberOfYearsDataStorage}
              </Typography>
            </Box>
          </Box>
          <Box style={{margin: "auto 10px"}}>{license.isDefault && <Chip label={"Default"} />}</Box>
        </Box>
      </Box>
    </Box>
  );
};

export default LicenseItem;
