import { Checkbox } from "@mui/material";

const TaskItem = ({
  task,
  clickSelectTask,
  isSelected,
  isDisabled,
  addToSelectedList,
}) => {
  return (
    <div>
      <div
        className={
          "asset-item cursor-pointer " +
          (isSelected ? "seleted-asset-background" : "")
        }
        onClick={clickSelectTask}
      >
        <div className="w-100 display-inline-flex">
          <div>
            <Checkbox
              id={task.taskId}
              checked={isSelected}
              onChange={addToSelectedList}
            />
          </div>
          <div style={{margin: "auto 5px"}}>
            <label>{task.taskTitle}</label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TaskItem;