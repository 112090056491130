import { useState } from "react";

import ButtonControl from "../../../components/Controls/ButtonControl";
import ApplicationList from "./ApplicationList/application-list.component";
import CreateApplication from "./CreateApplication/create-application.component";

const ApplicationDashboard = () => {
  const [selectedView, setSelectedView] = useState("");
  return (
    <div className="display-grid">
      <div style={{ marginLeft: "auto", marginBottom: "10px" }}>
        {selectedView == "" && (
          <ButtonControl
            text={"Create New App"}
            style={{ width: "180px", color: "white", fontWeight: "bold" }}
            color={"playnootPrimary"}
            onClick={() => setSelectedView("create-app")}
          ></ButtonControl>
        )}
        {selectedView == "create-app" && (
          <ButtonControl
            text={"App List"}
            style={{ width: "180px", color: "white", fontWeight: "bold" }}
            color={"playnootPrimary"}
            onClick={() => setSelectedView("")}
          ></ButtonControl>
        )}
      </div>
      {selectedView == "" && <ApplicationList />}
      {selectedView == "create-app" && (
        <CreateApplication createSuccessCallBack={() => setSelectedView("")} />
      )}
    </div>
  );
};

export default ApplicationDashboard;
