import React from "react";
import { useNavigate } from "react-router-dom";

const ErrorPage = (props) => {
  const { error_message = "Connection error" } = props;
  const router = useNavigate();

  return (
    <>
      <div
        style={{
          position: "absolute",
          width: "100%",
          top: "50%",
          textAlign: "center",
        }}>
        <h1 style={{ color: "var(--app-primary-color)" }}>
          {error_message}

          <button
            className='theme-btn'
            onClick={() => {
              router("/admin");
            }}>
            {" "}
            Reload{" "}
          </button>
        </h1>
      </div>
    </>
  );
};

export default ErrorPage;
