import { useState } from "react";
import BankDetailsNavigation from "./BankingDetailsNavigation/banking-details-navigation.component";
import AllBankingDetails from "./AllBankingDetails/all-banking-details.components";

const BankingDetailsDashboard = () => {
    const [selectedView, setSelectedView] = useState("");
    const [selectedVerificationFilter, setSelectedVerificationFilter] = useState(0);
    return (
      <div>
        <BankDetailsNavigation
          selectedVerificationFilter={selectedVerificationFilter}
          setVerificationFilter={(status) => {
            setSelectedVerificationFilter(status);
          }}
        />
        <div>
          {selectedView == "" && (
            <div>
              <AllBankingDetails
                // selectedDate={selectedFetchDate}
                selectedVerificationFilter={selectedVerificationFilter}
              ></AllBankingDetails>
            </div>
          )}
        </div>
      </div>
    );
}

export default BankingDetailsDashboard;