import React from "react";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { IconButton, Typography } from "@mui/material";
import Icon from "../../components/Icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell } from "@fortawesome/free-solid-svg-icons";

import { httpGet } from "../../utility/request";
import { URLS } from "../../configs/api_urls";

import {
  fetchNotifications,
  fetchNotificationCount,
} from "../../modules/Notification/notificationSlice";
import {
  getAllNotifications,
  getIsLoading,
  getNotificationsCount,
} from "../../modules/Notification/notificationSlice";
import { getStandardGamesDto } from "../../modules/GameCenter/selector";

const NotificationsIcon = () => {
  const dispatch = useDispatch();

  const games = useSelector(getStandardGamesDto());
  var isLoading = useSelector(getIsLoading);
  var notifications = useSelector(getAllNotifications);
  var notificationCount = useSelector(getNotificationsCount);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);

    // mark all notifs read
    httpGet(URLS.NOTIFICATIONS.MARKUSERNOTIFICATIONREAD).then(({ data }) => {
      if (data == true) {
        // setTimeout(() => {
        //   var notifs = notifications.map((i) => (i.isRead = true));
        //   setNotifications(notifs);
        // }, 3000);
      }
    });
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const getRenderLogo = (item) => {
    if (item.notificationType == 5) {
      if (
        item.clickEventPostJsonData != "" &&
        item.clickEventPostJsonData != undefined
      ) {
        var booking = JSON.parse(item.clickEventPostJsonData);
        if (booking.Asset.StandardGame.StandardGameName != undefined) {
          return (
            <div style={{ padding: "5px", marginRight: "10px" }}>
              <Icon
                color="black"
                category="games"
                icon={booking.Asset.StandardGame.StandardGameName}
              ></Icon>
            </div>
          );
        }
      }
    }
  };

  useEffect(() => {
    dispatch(fetchNotifications());
    dispatch(fetchNotificationCount());
  }, []);

  return (
    <div>
      <IconButton
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        className="notification"
      >
        <FontAwesomeIcon icon={faBell} />
        {notificationCount > 0 && (
          <div
            style={{
              position: "absolute",
              top: 1,
              right: 3,
              background: "green",
              borderRadius: 13,
              width: "25px",
              height: "25px",
              fontSize: "15px",
            }}
          >
            <span style={{ color: "white", fontWeight: "bold" }}>
              {notificationCount}
            </span>
          </div>
        )}
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{ zIndex: 500000, maxHeight: "500px" }}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {!isLoading &&
          notifications.map((item) => {
            return (
              <MenuItem
                sx={{
                  maxWidth: "420px",
                  borderBottom: "1px solid lightgray",
                  background: item.isRead ? "" : "lightgray",
                }}
                onClick={handleClose}
              >
                <div className="display-flex">
                  <div>{getRenderLogo(item)}</div>
                  <div>
                    <span style={{ fontWeight: "bold" }}>{item.title}</span>
                    <Typography
                      className="text-wrap"
                      color={"gray"}
                      paragraph={true}
                    >
                      {item.description}
                    </Typography>
                  </div>
                </div>
              </MenuItem>
            );
          })}
      </Menu>
    </div>
  );
};

export default NotificationsIcon;
