import { createSelector } from "reselect";

const selectAppCommonReducer = (state) =>  state.AppCommon;


  export const selectAppAlert = createSelector(
    [selectAppCommonReducer],
    (AppCommon) => AppCommon.alert
  );

  export const selectIsLoadingApp = createSelector(
    [selectAppCommonReducer],
    (AppCommon) => AppCommon.isLoading
  );

    export const selectApplicationInfo = createSelector(
      [selectAppCommonReducer],
      (AppCommon) => AppCommon.applicationInfo
    );

  export const selectApplicationLicense = createSelector(
    [selectAppCommonReducer],
    (AppCommon) =>  AppCommon.applicationLicense
  ); 

  export const selectApplicationLicenseConsumption = createSelector(
    [selectAppCommonReducer],
    (AppCommon) => AppCommon.applicationLicenseConsumption
  ); 