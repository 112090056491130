import React from 'react'
import TextField  from '@mui/material/TextField';
import Box from '@mui/material/Box';
import InputAdornment from "@mui/material/InputAdornment";
import { useState } from 'react';

 const InputControl = (props) => {

    const {
      name,
      label,
      value,
      error = null,
      style,
      onChange,
      isMultiLine,
      rows,
      disabled,
      maxLength,
      placeholder,
      other,
      containerClass,
      minNumber,
      maxNumber
    } = props;

    const defautlSx = { padding: "10px 0px 10px" };

    return (
      <Box className={containerClass}>
        <TextField
          variant="outlined"
          label={label}
          name={name}
          value={value}
          onChange={onChange}
          multiline={isMultiLine}
          disabled={disabled}
          placeholder={placeholder}
          inputProps={{ maxLength: maxLength, min: minNumber, max: maxNumber }}
          rows={rows}
          helperText={error ? "Name needs to be 'a'" : ""}
          sx={style}
          {...other}
          {...(error && { error: true, helperText: error })}
        />
      </Box>
    );
}

export default InputControl;
