import React, { useContext, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FontAwesome from "../../components/Icons/fontawesome";
import {
  faArrowLeft,
  faArrowsRotate,
  faArrowUpRightFromSquare,
  faChartLine,
  faCircle,
  faEye,
  faPaperPlane,
  faStar,
  faUserPlus,
  faUsersRectangle,
} from "@fortawesome/free-solid-svg-icons";
import {
  faCalendar,
  faSquare,
  faCheckSquare,
} from "@fortawesome/free-regular-svg-icons";
import {
  IconButton,
  TextField,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  TableContainer,
  Paper,
  CircularProgress,
  Avatar,
} from "@mui/material";
import { Card, Rating } from "@mui/material";

import ProgressBar from "react-bootstrap/ProgressBar";

import { useNavigate } from "react-router-dom";

import Highcharts from "highcharts";

import { GameCenterViewContext } from "../../utility/context";
import userPlaceholder from "../../static/user_placeholder.png";
import dollar from "../../static/dollar.png";
import increase from "../../static/increase.png";
import savings from "../../static/savings.png";
import graph from "../../static/graph.png";
import placeHolderImage from "../../static/placeHolderImage.png";
import themeVariables from "../../styles/variables.scss";
import moment from "moment/moment";

import { URLS } from "../../configs/api_urls";
import { httpGet } from "../../utility/request";

import "./module.scss";
import CheckboxControl from "../../components/Controls/CheckboxControl";

// function DenseTable({ rows, headings }) {
//   return (
//     <TableContainer component={Paper}>
//       <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
//         <TableHead>
//           <TableRow>
//             {headings.map((head, index) => {
//               return (
//                 <TableCell
//                   align={head == "Remind" ? "center" : "left"}
//                   width={index == 0 ? "55" : "auto"}
//                   key={`table_head_${index}`}
//                 >
//                   {head}
//                 </TableCell>
//               );
//             })}
//           </TableRow>
//         </TableHead>
//         <TableBody>
//           {rows.map((row) => (
//             <TableRow
//               key={row.name}
//               sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
//             >
//               <TableCell align="center">
//                 <div className="image">
//                   <img src={userPlaceholder} alt="" />
//                 </div>
//               </TableCell>
//               <TableCell align="left">{row.name}</TableCell>
//               <TableCell align="left">{row.duration}</TableCell>
//               <TableCell align="left">{row.subdueDate}</TableCell>
//               <TableCell align="left">{row.Amount}</TableCell>
//               <TableCell align="center">
//                 <IconButton className="bg-warn-light text-center">
//                   <FontAwesomeIcon
//                     icon={faPaperPlane}
//                     className="text-theme display-7"
//                   />
//                 </IconButton>
//               </TableCell>
//             </TableRow>
//           ))}
//         </TableBody>
//       </Table>
//     </TableContainer>
//   );
// }

const Dashboard = () => {
  const router = useNavigate();
  const { GameCenter } = useContext(GameCenterViewContext);

  const [isLoadingBookingChartData, setIsLoadingBookingChartData] =
    useState(true);
  const [bookingStatisticsChartData, setBookingStatisticsChartData] = useState([
    {
      numberOfBookings: 0,
      bookingDate: new Date(),
    },
  ]);

  const [isLoadingUpcomingGameSessions, setIsLoadingUpcomingGameSessions] =
    useState(false);
  const [upcomingGameSessions, setUpcomingGameSessions] = useState([]);

  const [isLoadingTasks, setIsLoadingTasks] = useState(false);
  const [tasks, setTasks] = useState([]);

  const [isLoadingRatingDetails, setIsLoadingRatingDetails] = useState(true);
  const [ratingReviewDetails, setRatingReviewDetails] = useState(null);

  const handleBack = () => {
    router("/admin");
  };

  const fetchUpcomingGameSessions = async () => {
    setIsLoadingUpcomingGameSessions(true);
    const { data } = await httpGet(URLS.DASHBOARD.GET_UPCOMING_GAME_SESSION);
    setUpcomingGameSessions(data);
    setIsLoadingUpcomingGameSessions(false);
  };

  const fetchBookingStatisticsChartData = async () => {
    setIsLoadingBookingChartData(true);
    await httpGet(URLS.DASHBOARD.GET_BOOKING_STATISTICS_CHART_DATA)
      .then(({ data }) => {
        setBookingStatisticsChartData(data);
      })
      .finally(() => {
        setIsLoadingBookingChartData(false);
        createBookingStatisticChart();
      });
  };

  const fetchTasks = async () => {
    setIsLoadingTasks(true);
    const { data } = await httpGet(URLS.TASKS.GET_ALL_TASKS);
    var activeTasks = data.filter((t) => t.isActive == true);
    setTasks(activeTasks);
    setIsLoadingTasks(false);
  };

  const fetchRatingAndReviewDetails = async () => {
    setIsLoadingRatingDetails(true);
    if (GameCenter.applicationInstanceId != undefined) {
      const { data } = await httpGet(
        URLS.RATING_AND_REVIEWS.GET_APPLICATIONINSTANCE_RATINGREVIEWS_DETAILS.replace(
          "#ID#",
          GameCenter.applicationInstanceId
        )
      );
      setRatingReviewDetails(data);
      setIsLoadingRatingDetails(false);
    }
  };

  const markTaskCompleted = async ({ target }) => {
    if (target.value == false) {
      return;
    }
    await httpGet(URLS.TASKS.MARK_TASK_COMPLETED.replace("#ID#", target.name))
      .then((response) => {
        if (response.data == true) {
          fetchTasks();
        }
      })
      .catch((error) => {
        //  setFormSubmitError(
        //    error.response.data.Message || "Some error occuered!"
        //  );
      });
  };

  const createBookingStatisticChart = () => {
    Highcharts.chart("dashboard-chart", {
      chart: {
        type: "line",
      },
      colors: [themeVariables.PrimaryColor, themeVariables.BackgroundColor],
      plotOptions: { areaspline: { opacity: 0.6 } },
      caption: "Booking Statatics",
      title: {
        text: "",
      },
      xAxis: {
        type: "datetime",
        dateTimeLabelFormats: {
          day: "%d %b %Y", //ex- 01 Jan 2016
        },
      },
      yAxis: {
        title: {
          text: "Number of bookings",
        },
        tickInterval: 1,
      },
      series: [
        {
          name: "No. of Bookings",
          data: bookingStatisticsChartData.map((b) => b.numberOfBookings),
          pointInterval: 24 * 3600 * 1000, // one day
          pointStart: moment(
            bookingStatisticsChartData[0].bookingDate
          ).valueOf(),
        },
      ],
    });
  };

  useEffect(() => {
    createBookingStatisticChart();
  }, [isLoadingBookingChartData]);

  useEffect(() => {
    fetchUpcomingGameSessions();
    fetchBookingStatisticsChartData();
    fetchRatingAndReviewDetails();
    fetchTasks();

    // Highcharts.chart("dashboard-chart-barchart", {
    //   chart: {
    //     type: "column",
    //   },
    //   title: {
    //     text: "Booking Made",
    //   },
    //   xAxis: {
    //     crosshair: false,
    //     title: null,
    //   },
    //   yAxis: { title: "Days" },
    //   plotOptions: {
    //     column: {
    //       pointPadding: 0.2,
    //       borderWidth: 0,
    //       borderRadius: 5,
    //     },
    //   },
    //   series: [
    //     {
    //       data: [13, 10, 14, 18, 5, 8, 6, 8, 9],
    //     },
    //   ],
    // });

    // Highcharts.chart("dashboard-chart-earnings", {
    //   chart: {
    //     type: "area",
    //   },
    //   title: {
    //     text: "Booking Made",
    //   },
    //   xAxis: {
    //     crosshair: false,
    //     title: null,
    //   },
    //   yAxis: { title: "Days" },
    //   plotOptions: {
    //     column: {
    //       pointPadding: 0.2,
    //       borderWidth: 0,
    //       borderRadius: 5,
    //     },
    //   },
    //   series: [
    //     {
    //       data: [13, 10, 14, 18, 5, 8, 6, 10, 9],
    //     },
    //     {
    //       data: [10, 20, 12, 14, 15, 10, 6, 8, 9],
    //     },
    //   ],
    // });

    // Highcharts.chart("dashboard-chart-age-group", {
    //   chart: {
    //     type: "column",
    //   },
    //   title: {
    //     text: "Age Groups",
    //   },
    //   subtitle: {
    //     text: "",
    //   },
    //   xAxis: {
    //     categories: ["Adults", "Teenagers", "Children", "Sinors"],
    //     crosshair: true,
    //   },
    //   yAxis: {
    //     min: 0,
    //     title: {
    //       text: "",
    //     },
    //   },
    //   tooltip: {
    //     headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
    //     pointFormat:
    //       '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
    //       '<td style="padding:0"><b>{point.y:.1f} </b></td></tr>',
    //     footerFormat: "</table>",
    //     shared: true,
    //     useHTML: true,
    //   },
    //   plotOptions: {
    //     column: {
    //       pointPadding: 0,
    //       borderWidth: 0,
    //       groupPadding: 0,
    //       pointWidth: 25,
    //       shadow: false,
    //     },
    //   },
    //   series: [
    //     {
    //       name: "Adults",
    //       data: [100],
    //     },
    //     {
    //       name: "Teenagers",
    //       data: [40],
    //     },
    //     {
    //       name: "Children",
    //       data: [20],
    //     },
    //     {
    //       name: "Seniors",
    //       data: [5],
    //     },
    //   ],
    // });

    // const versionsData = [
    //   {
    //     name: "Badminton Court 1",
    //     y: 61.04,
    //     color: "rgb(195,255,176)",
    //   },
    //   {
    //     name: "Badminton Court Juniors",
    //     y: 9.47,
    //     color: "rgb(255,214,143)",
    //   },
    //   {
    //     name: "Badminton Court 4X4",
    //     y: 9.32,
    //     color: "rgb(255,143,179)",
    //   },
    //   {
    //     name: "Badminton Court Doubles",
    //     y: 8.15,
    //     color: "rgb(118,118,123)",
    //   },
    //   {
    //     name: "Badminton Court Women",
    //     y: 11.02,
    //     color: "rgb(255,255,135)",
    //   },
    // ];

    // Create the chart
    // Highcharts.chart("dashboard-charts-asset-performance", {
    //   chart: {
    //     type: "pie",
    //   },
    //   title: {
    //     text: "Asset Performance",
    //   },
    //   plotOptions: {
    //     pie: {
    //       shadow: false,
    //       center: ["50%", "50%"],
    //     },
    //   },
    //   tooltip: {
    //     valueSuffix: "%",
    //   },
    //   series: [
    //     {
    //       name: "Performance",
    //       data: versionsData,
    //       size: "80%",
    //       innerSize: "60%",
    //       dataLabels: {
    //         formatter: function () {
    //           // display only if larger than 1
    //           return this.y > 1
    //             ? "<b>" + this.point.name + ":</b> " + this.y + "%"
    //             : null;
    //         },
    //       },
    //       id: "Asset",
    //     },
    //   ],
    //   responsive: {
    //     rules: [
    //       {
    //         condition: {
    //           maxWidth: 400,
    //         },
    //         chartOptions: {
    //           series: [
    //             {},
    //             {
    //               id: "versions",
    //               dataLabels: {
    //                 enabled: false,
    //               },
    //             },
    //           ],
    //         },
    //       },
    //     ],
    //   },
    // });
  }, []);

  const myTasks = [
    {
      id: "1",
      date: "10 OCT",
      details: "Purchase New net for turf 2",
      isCompleted: true,
    },
    {
      id: "2",
      date: "12 OCT",
      details: "Launch Diwali opffers",
      isCompleted: false,
    },
    {
      id: "3",
      date: "13 OCT",
      details: "Subscriber offers to be created",
      isCompleted: false,
    },
    {
      id: "4",
      date: "14 OCT",
      details: "Make Payments",
      isCompleted: false,
    },
    {
      id: "5",
      date: "15 OCT",
      details: "Admin licnense renewl",
      isCompleted: false,
    },
  ];

  const overdues = [
    {
      id: "1001",
      profile: "",
      name: "Ajay Kumar",
      duration: "1 Months",
      subdueDate: "12-02-2023",
      Amount: "400",
    },
    {
      id: "1002",
      profile: "",
      name: "Diljit Singh",
      duration: "3 Months",
      subdueDate: "12-04-2022",
      Amount: "500",
    },
    {
      id: "1003",
      profile: "",
      name: "Akash Kumar",
      duration: "4 Months",
      subdueDate: "10-07-2023",
      Amount: "4866",
    },
    {
      id: "1004",
      profile: "",
      name: "Rajan Sharma",
      duration: "12 Months",
      subdueDate: "14-06-2023",
      Amount: "8622",
    },
    {
      id: "1005",
      profile: "",
      name: "Suresh",
      duration: "24 Months",
      subdueDate: "14-12-2023",
      Amount: "458",
    },
  ];

  const mostBookedTurf = [
    {
      name: "Badminton Court 1",
      bookings: 61,
    },
    {
      name: "Badminton Court Juniors",
      bookings: 9,
    },
    {
      name: "Badminton Court 4X4",
      bookings: 9,
    },
    {
      name: "Badminton Court Doubles",
      bookings: 8,
    },
  ];

  const getTaskItem = (task) => {
    return (
      <div className="d-flex align-items-center my-2 border-bottom">
        <div className="w-100">
          <p className="mb-0">{task.taskTitle}</p>
        </div>
        <div className="text-theme ml-auto pr-2 display-7">
          <CheckboxControl
            name={task.taskId}
            onChange={(event) => markTaskCompleted(event)}
          ></CheckboxControl>
        </div>
      </div>
    );
  };

  const getSessionStatus = (session) => {
    var nowTime = moment.utc();
    var nowDate = nowTime.utc().format("DD/MM/YYYY");

    var sessionStartTime = moment.utc(session.startTime);
    var sesstionStartTimeDate = moment
      .utc(session.startTime)
      .format("DD/MM/YYYY");

    if (session.bookingStatus == "Confirmed") {
      if (nowDate == sesstionStartTimeDate) {
        if (nowTime.valueOf() > sessionStartTime.valueOf()) {
          return "On going";
        } else {
          return "Upcoming Today";
        }
      } else return "Upcoming on " + sesstionStartTimeDate;
    } else return session.bookingStatus;
  };

  const getSessionStatusColor = (session) => {
    var nowTime = moment.utc();
    var nowDate = nowTime.utc().format("DD/MM/YYYY");

    var sessionStartTime = moment.utc(session.startTime);
    var sesstionStartTimeDate = moment
      .utc(session.startTime)
      .format("DD/MM/YYYY");

    if (session.bookingStatus == "Confirmed") {
      if (nowDate == sesstionStartTimeDate) {
        if (nowTime.valueOf() > sessionStartTime.valueOf()) {
          return "green";
        } else {
          return "gold";
        }
      } else return "orange";
    } else return "red";
  };

  const getRadialProgression = (percent = 100, color = "blue") => {
    return (
      <div className="d-inline-flex align-items-center position-relative">
        <CircularProgress
          variant="determinate"
          className="p-2"
          value={percent}
          size="7rem"
          style={{
            color: { color },
            zIndex: "2",
          }}
        />
        <CircularProgress
          variant="determinate"
          className="p-2 position-absolute"
          value={100}
          size="7rem"
          style={{
            color: "lightgrey",
          }}
        />
        <div id="dashboard-chart-return-rate"></div>
        <h5 className="mb-0 radial-progress"> {percent}%</h5>
      </div>
    );
  };

  const getThumbnailImage = () => {
    const images = GameCenter.instancePictures || [];
    if (images.length) return images[0].imageFullUrl || placeHolderImage;
    return placeHolderImage;
  };

  return (
    <div className="dashboard px-3 dashboard-overflow scrollbar">
      {/* <div className="row my-2">
        <div className="w-100 bg-light py-2">
          <div className="d-flex items-align-center">
            <IconButton onClick={handleBack} className="mx-2">
              <FontAwesomeIcon icon={faArrowLeft}></FontAwesomeIcon>
            </IconButton>
            <h3 className="mb-0">
              {GameCenter.applicationInstanceName || "Overview"}
            </h3>
            <div className="d-flex align-items-center ml-auto text-capital my-2">
              <span className="ml-auto mx-2">
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DesktopDatePicker
                    label="Date"
                    inputFormat="MM/DD/YYYY"
                    // value={value}
                    onChange={() => {}}
                    renderInput={(params) => (
                      <TextField size="small" {...params} />
                    )}
                  />
                </LocalizationProvider>
              </span>
            </div>
          </div>
        </div>
      </div> */}

      <div className="row my-2">
        <div className="col-md-8">
          <div className="container">
            {/*growth point divs*/}
            {/* <div className="d-grid mt-2 cc-top">
              <div className="m-1">
                <Card className="c1 px-2 py-3 cc">
                  <div className="d-flex align-items-center">
                    <div className="info">
                      <h3>
                        43 <sup className="text-small ml-2">+ 5%</sup>
                      </h3>
                      <p> Booking Today</p>
                    </div>
                    <div className="icon">
                      {" "}
                      <FontAwesomeIcon
                        icon={faUsersRectangle}
                      ></FontAwesomeIcon>{" "}
                    </div>
                  </div>
                </Card>
              </div>
              <div className="m-1">
                <Card className="c2 px-2 py-3 cc">
                  <div className="d-flex align-items-center">
                    <div className="info">
                      <h3>
                        36 <sup className="text-small ml-2">+ 41%</sup>
                      </h3>
                      <p> Upcomming</p>
                    </div>
                    <div className="icon">
                      <FontAwesomeIcon icon={faCalendar}></FontAwesomeIcon>
                    </div>
                  </div>
                </Card>
              </div>
              <div className="m-1 ">
                <Card className="c3 px-2 py-3 cc">
                  <div className="d-flex align-items-center">
                    <div className="info">
                      <h3>
                        22 <sup className="text-small ml-2">+ 5%</sup>
                      </h3>
                      <p> New Visitors</p>
                    </div>
                    <div className="icon">
                      <FontAwesomeIcon icon={faUserPlus}></FontAwesomeIcon>
                    </div>
                  </div>
                </Card>
              </div>
              <div className="m-1">
                <Card className="c4 px-2 py-3 cc">
                  <div className="d-flex align-items-center">
                    <div className="info">
                      <h3>
                        19 <sup className="text-small ml-2">+ 23%</sup>
                      </h3>
                      <p> Growth</p>
                    </div>
                    <div className="icon">
                      {" "}
                      <FontAwesomeIcon icon={faChartLine}></FontAwesomeIcon>
                    </div>
                  </div>
                </Card>
              </div>
            </div> */}
            <div className="row mt-2">
              <div className="container bg-light">
                <h5>Booking Statatics</h5>
                {
                  <div
                    id="dashboard-chart"
                    style={{ width: "100%", height: "490px" }}
                  ></div>
                }
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-4 my-2">
          <div className="bg-light" style={{ padding: "0px 15px" }}>
            <h5 className="mt-2">Current session</h5>
            <hr />
            <div
              className="py-2 text-dark scrollbar"
              style={{
                minHeight: "460px",
                maxHeight: "460px",
                overflowY: "auto",
              }}
            >
              {upcomingGameSessions.length > 0 ? (
                upcomingGameSessions.map((session) => {
                  return (
                    <div className="display-flex">
                      <div className="image m-auto">
                        <Avatar
                          src={session.profilePictureUrl || userPlaceholder}
                          alt=""
                        />
                      </div>
                      <div className="info p-2 w-100">
                        <div className="d-flex align-items-center">
                          <h6 className="name mb-0">{session.userName}</h6>
                          <span className="ml-auto">
                            <FontAwesomeIcon
                              className="text-small mr-2"
                              icon={faCircle}
                              color={getSessionStatusColor(session)}
                            />
                            {getSessionStatus(session)}
                          </span>
                        </div>
                        <div className="">
                          <p className="payments text-small text-muted mb-0">
                            Payment:
                            {session.paymentStatus == "PAYMENT_SUCCESS"
                              ? "Completed"
                              : "Pending"}
                            <br />
                            Amount : {session.paymentAmount / 100} /-
                          </p>
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className="m-auto text-align-center">
                  <span className="text-small text-muted px-2 font-600">
                    No Booking found
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="row my-2">
        <div className="col-md-8">
          {/*Earning tiles*/}
          {/* <div>
            <div className="row">
              <div className="col-md-3 p-2">
                <Card className="text-center py-4">
                  <img src={dollar} alt="" className="py-4" /> <br />
                  <b> &#8377; 23,554</b>
                  <p className="text-small text-muted py-2">
                    Earning This Month
                  </p>
                </Card>
              </div>
              <div className="col-md-3 p-2">
                <Card className="text-center py-4">
                  <img src={graph} alt="" className="py-4" />
                  <br />
                  <b> &#8377; 57,554</b>
                  <p className="text-small text-muted py-2">
                    Earning Growth <b> 33 %</b>
                  </p>
                </Card>
              </div>
              <div className="col-md-6 p-2">
                <Card>
                  <div
                    id="dashboard-chart-barchart"
                    style={{ width: "100%", height: "240px" }}
                  ></div>
                </Card>
              </div>
            </div>
          </div> 
          <div>
            <div className="row">
              <div className="col-md-3 p-2">
                <Card className="text-center py-4">
                  <img src={savings} alt="" className="py-4" /> <br />
                  <b> &#8377; 10,554</b>
                  <p className="text-small text-muted py-2">
                    Expenditure of the month
                  </p>
                </Card>
              </div>
              <div className="col-md-3 p-2">
                <Card className="text-center py-4">
                  <img src={increase} alt="" className="py-4" />
                  <br />
                  <b> &#8377; 99,223</b>
                  <p className="text-small text-muted py-2">
                    Growth Profit Margins <b> 33 %</b>
                  </p>
                </Card>
              </div>
              <div className="col-md-6 p-2">
                <Card>
                  <div
                    id="dashboard-chart-earnings"
                    style={{ width: "100%", height: "240px" }}
                  ></div>
                </Card>
              </div>
            </div>
          </div>*/}
        </div>
        {/* <div className="col-md-4">
          <div className="py-2">
            <Card>
              <div
                style={{ width: "100%", height: "495px" }}
                id="dashboard-charts-asset-performance"
              ></div>
            </Card>
          </div>
        </div> */}
      </div>

      <div className="row my-2">
        <div className="col-md-8">
          <Card sx={{ height: "350px" }} className="p-2">
            <h5>My Tasks </h5> <hr />
            {tasks.map((task) => {
              return getTaskItem(task);
            })}
          </Card>
        </div>
        <div className="col-md-4">
          <Card className="py-3 px-2" sx={{ height: "350px" }}>
            <h5 className="mb-0 p-2">
              Customer Ratings
              <FontAwesomeIcon
                icon={faArrowsRotate}
                onClick={() => fetchRatingAndReviewDetails()}
                className="text-theme ml-auto float-end"
              />
            </h5>
            {!isLoadingRatingDetails && ratingReviewDetails && (
              <div>
                <div className="align-items-center">
                  <div className="display-flex align-items-center">
                    <Rating
                      name="read-only"
                      precision={0.5}
                      size="large"
                      value={ratingReviewDetails.averageRating.toFixed(1)}
                      readOnly
                    />
                    <h1 className="float-end mb-0 ml-auto">
                      {ratingReviewDetails.averageRating.toFixed(1)}
                    </h1>
                    <span className="text-small text-muted px-2 font-600">
                      Out of 5 Stars
                    </span>
                  </div>
                </div>

                <div className="my-3 px-2">
                  <div className="row align-items-center mt-10">
                    <div className="col-md-2 display-flex">
                      <span className="mx-2">5</span>
                      <FontAwesome iconcolor={"#ffc107"} icon={faStar} />
                    </div>
                    <div className="col-md-10">
                      <ProgressBar
                        style={{ height: "15px" }}
                        now={
                          ratingReviewDetails.ratingBreakdown[0]
                            .ratingScorePercentage
                        }
                        variant="success"
                      />
                    </div>
                  </div>

                  <div className="row align-items-center mt-10">
                    <div className="col-md-2">
                      <div className="display-flex">
                        <span className="mx-2">4</span>
                        <FontAwesome iconcolor={"#ffc107"} icon={faStar} />
                      </div>
                    </div>
                    <div className="col-md-10">
                      <ProgressBar
                        style={{ height: "15px" }}
                        now={
                          ratingReviewDetails.ratingBreakdown[1]
                            .ratingScorePercentage
                        }
                        variant="primary"
                      />
                    </div>
                  </div>

                  <div className="row align-items-center mt-10">
                    <div className="col-md-2">
                      <div className="display-flex">
                        <span className="mx-2">3</span>
                        <FontAwesome iconcolor={"#ffc107"} icon={faStar} />
                      </div>
                    </div>
                    <div className="col-md-10">
                      <ProgressBar
                        style={{ height: "15px" }}
                        now={
                          ratingReviewDetails.ratingBreakdown[2]
                            .ratingScorePercentage
                        }
                        variant="warning"
                      />
                    </div>
                  </div>

                  <div className="row align-items-center mt-10">
                    <div className="col-md-2">
                      <div className="display-flex">
                        <span className="mx-2">2</span>
                        <FontAwesome iconcolor={"#ffc107"} icon={faStar} />
                      </div>
                    </div>
                    <div className="col-md-10">
                      <ProgressBar
                        style={{ height: "15px" }}
                        now={
                          ratingReviewDetails.ratingBreakdown[3]
                            .ratingScorePercentage
                        }
                        variant="danger"
                      />
                    </div>
                  </div>

                  <div className="row align-items-center mt-10">
                    <div className="col-md-2">
                      <div className="display-flex">
                        <span className="mx-2">1</span>
                        <FontAwesome iconcolor={"#ffc107"} icon={faStar} />
                      </div>
                    </div>
                    <div className="col-md-10">
                      <ProgressBar
                        style={{ height: "15px" }}
                        now={
                          ratingReviewDetails.ratingBreakdown[4]
                            .ratingScorePercentage
                        }
                        variant="secondary"
                      />
                    </div>
                  </div>
                </div>

                <div className="mt-3 text-center">
                  <button
                    onClick={() => router("my_reviews")}
                    className="theme-btn px-2"
                  >
                    See all Customer Reviews
                  </button>
                </div>
              </div>
            )}
            {isLoadingRatingDetails && (
              <div className="text-align-center">
                <CircularProgress></CircularProgress>
              </div>
            )}
          </Card>
        </div>
        {/* <div className="col-md-8">
          <DenseTable
            rows={overdues}
            headings={[
              "Profile",
              "Name",
              "Duration",
              "Sub Date",
              "Amount",
              "Remind",
            ]}
          />
        </div> */}
      </div>

      <div className="row my-2">
        {/* <div className="col-md-4">
          <div>
            <Card>
              <div id="dashboard-chart-age-group"></div>
            </Card>
          </div>
          <div className="div">
            <div className="row">
              <div className="col-6 text-center">
                <Card className="p-2 my-2">
                  <h5> Return Customers</h5>
                  {getRadialProgression(73, "sky-blue")}
                </Card>
              </div>
              <div className="col-6 text-center">
                <Card className="p-2 my-2">
                  <h5> Group Booking</h5>
                  {getRadialProgression(25, "orange")}
                </Card>
              </div>
            </div>
          </div>
        </div> */}
        <div className="col-md-4">
          {/* <div className="row">
            <Card className="py-3">
              <h5>My Views</h5>
              <div className="row my-views">
                <div className="col-lg-3 my-1 text-center">
                  <img src={getThumbnailImage()} alt="" />
                </div>
                <div className="col-lg-9 my-1">
                  <h1 className="text-align-left">
                    <FontAwesomeIcon
                      icon={faEye}
                      className="text-theme mx-auto"
                    />
                    <span className="ml-2"> 3.2K</span>
                  </h1>
                </div>
              </div>
            </Card>
          </div> */}

          {/* Most booked */}
          {/* <div className="row mt-3">
            <Card className="py-3">
              <h5>Most Booked Turf</h5>
              <TableContainer component={Paper}>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <b> Turf</b>
                      </TableCell>
                      <TableCell align="right">
                        <b>Bookings</b>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {mostBookedTurf.map((row) => (
                      <TableRow
                        key={row.name}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {row.name}
                        </TableCell>
                        <TableCell align="right">{row.bookings}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Card>
          </div> */}
        </div>

        {/* Ratings */}
        <div className="col-md-4">
          {/* <Card className="py-3 px-2">
            <h5 className="mb-0 p-2">
              Customer Ratings
              <FontAwesomeIcon
                icon={faArrowsRotate}
                className="text-theme ml-auto float-end"
              />
            </h5>
            <div className="row align-items-center">
              <div className="col-md-6 text-theme">
                <FontAwesomeIcon icon={faStar} className="mx-2" />
                <FontAwesomeIcon icon={faStar} className="mx-2" />
                <FontAwesomeIcon icon={faStar} className="mx-2" />
                <FontAwesomeIcon icon={faStar} className="mx-2" />
                <FontAwesomeIcon icon={faStar} className="mx-2" />
              </div>
              <div className="col-md-6 d-flex align-items-center">
                <h2 className="float-end mb-0 "> 4.8 </h2>
                <span className="text-small text-muted px-2 font-600">
                  Out of 5 Stars
                </span>
              </div>
            </div>
            <div className="my-3 px-2">
              <div className="row align-items-center my-3">
                <div className="col-lg-3">
                  <h6 className="mb-0"> Excellent</h6>
                </div>
                <div className="col-lg-9">
                  <ProgressBar now={60} variant="success" />
                </div>
              </div>

              <div className="row align-items-center my-3">
                <div className="col-lg-3">
                  <h6 className="mb-0"> Good</h6>
                </div>
                <div className="col-lg-9">
                  <ProgressBar now={40} variant="primary" />
                </div>
              </div>

              <div className="row align-items-center my-3">
                <div className="col-lg-3">
                  <h6 className="mb-0"> Average</h6>
                </div>
                <div className="col-lg-9">
                  <ProgressBar now={30} variant="warning" />
                </div>
              </div>

              <div className="row align-items-center my-3">
                <div className="col-lg-3">
                  <h6 className="mb-0"> Below Avg</h6>
                </div>
                <div className="col-lg-9">
                  <ProgressBar now={10} variant="danger" />
                </div>
              </div>

              <div className="row align-items-center my-3">
                <div className="col-lg-3">
                  <h6 className="mb-0"> Poor</h6>
                </div>
                <div className="col-lg-9">
                  <ProgressBar now={13} variant="secondary" />
                </div>
              </div>
            </div>

            <div className="mt-3 text-center">
              <button className="theme-btn px-2">
                {" "}
                See all Customer Reviews
              </button>
            </div>
          </Card> */}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
