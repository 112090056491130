import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import { Card } from "@mui/material";
import InputControl from "../../../components/Controls/InputControl";
import SelectControl from "../../../components/Controls/SelectControl";
import ContrastIcon from "@mui/icons-material/Contrast";

import { getThemesDto } from "../../../modules/GameCenter/selector";

const AdvancedDetails = ({ newCenterDetails, updateValues }) => {
  const [localCenterState, setLocalCenterState] = useState(newCenterDetails);
  const [themeOptions, setThemeOptions] = useState([]);
  const themes = useSelector(getThemesDto());

  const onChangeInput = ({ currentTarget }) => {
    var name = currentTarget.name;
    var value = currentTarget.value;
    updateValues({ ...localCenterState, [name]: value });
  };

  useEffect(() => {
    setLocalCenterState(newCenterDetails);
    var availableThemes = themes.map((type) => ({
      id: type.applicationThemeId,
      title: type.themeName,
    }));
    setThemeOptions(availableThemes);
  }, [newCenterDetails]);

  return (
    <div>
      <Card sx={{ maxWidth: "500px", margin: "auto" }}>
        <div className="mt-20">
          <label className="field-title">Future Active days</label>
          <InputControl
            name="futureActiveDays"
            other={{ placeholder: "Enter Future Active Days", type: "number" }}
            style={{ width: "100%", marginTop: "5px" }}
            onChange={onChangeInput}
            value={localCenterState.futureActiveDays}
            //error={validation.planName}
          ></InputControl>
        </div>
        <div className="mt-20">
          <label className="field-title">Select Facility Time Zone</label>
          <InputControl
            name="timeZone"
            other={{ placeholder: "Select timezone" }}
            style={{ width: "100%", marginTop: "5px" }}
            onChange={onChangeInput}
            value={localCenterState.timeZone}
            disabled={true}
            //error={validation.planName}
          ></InputControl>
        </div>
        <div className="mt-20 display-grid">
          <label className="field-title">Theme</label>
          <SelectControl
            name="themeId"
            style={{ width: "100%" }}
            showDefaultOption={true}
            defaultOptionText="None"
            options={themeOptions}
            icon={ContrastIcon}
            value={localCenterState.themeId}
            onChange={({ target }) => {
              var name = target.name;
              var value = target.value;
              updateValues({ ...localCenterState, [name]: value });
            }}
          ></SelectControl>
        </div>
      </Card>
    </div>
  );
};

export default AdvancedDetails;
