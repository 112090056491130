import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { httpGet } from "../../../utility/request";
import { URLS } from "../../../configs/api_urls";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import axios from "axios";
import { getAccessToken } from "../../../hoc/helper";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Loader from "../../../components/Loader";
import Paper from "@mui/material/Paper";
import Controls from "../../../components/Controls/Controls";
import { amber } from "@mui/material/colors";

import CircularProgress from "@mui/material/CircularProgress";

import moment from "moment";

import "./orderdetails.styles.scss";

const OrderDetails = () => 
{
    const { adminOrderId } = useParams();
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(true);
    const [isLoadingReceiptActions, setIsLoadingReceiptActions] = useState(false);
    const [order, setAdminOrderDetials] = useState(null);
    const [item, setPurchasedItem] = useState(null);

    const fetchAdminOrderDetails = async () => {
      setIsLoading(true);
      const { data } = await httpGet(URLS.ADMIN_ORDERS.GETADMINORDERDETAILS.replace("#ID#", adminOrderId));
      setAdminOrderDetials(data);
      setPurchasedItem(JSON.parse(data.jsonProductData));
      setIsLoading(false);
    };

    const getOrderType = (typeId) => 
    {
        return "License" + " (" + item.licenseName + ")";
    }

    const generateReceipt = async() => 
    {
      setIsLoadingReceiptActions(true);
       const access_token = await getAccessToken();
         axios
           .get(
             URLS.CHECKOUT.GETPAYMENTRECEIPT.replace("#ID#", order.orderId),
             {
               responseType: "blob",
               headers: {
                 Accept: "application/pdf",
                 Authorization: `Bearer ${access_token}`,
               },
             }
           )
           .then((response) => {
             const url = URL.createObjectURL(response.data);
               var a = document.createElement("a");
               a.href = url;
               a.download = "receipt";
               a.click();
               a.remove();
               setTimeout(() => window.URL.revokeObjectURL(url), 100);
           }).finally(() => 
           {
             setIsLoadingReceiptActions(false);
           })
           .catch(function (error) {
             console.log(error);
           });
    }

    useEffect(() => {
      fetchAdminOrderDetails();
    }, []);

    return (
      <div>
        <Loader isLoading={isLoading}></Loader>
        {!isLoading && (
          <div>
            {order && (
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 400 }} aria-label="spanning table">
                  <TableBody>
                    <TableRow>
                      <TableCell>Order Id</TableCell>
                      <TableCell>{order.orderId}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Item</TableCell>
                      <TableCell>{"License"}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Order Status</TableCell>
                      <TableCell>{order.orderStatus}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Payment Amount</TableCell>
                      <TableCell>{+order.productPrice}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Ordered on</TableCell>
                      <TableCell>
                        {moment
                          .utc(order.createdOn)
                          .local()
                          .format("MMM DD, yyyy, hh:mm A") || "-"}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            )}
            <div className="order-details-control">
              {(order.orderStatus == "Success" ||
                order.orderStatus == "Credit") && (
                <Controls.ButtonControl
                  type="submit"
                  text="Download Receipt"
                  style={{ width: "200px", marginRight: "10px" }}
                  onClick={generateReceipt}
                  disabled={isLoadingReceiptActions}
                  innercomponent={
                    isLoadingReceiptActions && (
                      <CircularProgress
                        size={20}
                        sx={{
                          color: amber[500],
                          position: "absolute",
                          top: "50%",
                          left: "90%",
                          marginTop: "-12px",
                          marginLeft: "-12px",
                        }}
                      />
                    )
                  }
                ></Controls.ButtonControl>
              )}
              <Controls.ButtonControl
                type="submit"
                text="Back"
                style={{ width: "100px" }}
                onClick={() => {
                  navigate("/admin/admin-orders");
                }}
              ></Controls.ButtonControl>
            </div>
          </div>
        )}
      </div>
    );
}

export default OrderDetails;