import { Checkbox } from "@mui/material";

const AssetTypeItem = ({
  assetType,
  clickSelectAssetType,
  isSelected,
  isDisabled,
  addToSelectedList,
}) => {
  return (
    <div>
      <div
        className={
          "asset-item cursor-pointer " +
          (isSelected ? "seleted-asset-background" : "")
        }
        onClick={clickSelectAssetType}
      >
        <div className="w-100 display-inline-flex">
          <div className="w-10" style={{ margin: "auto" }}>
            <Checkbox
              id={assetType.assetTypeId}
              checked={isSelected}
              onChange={addToSelectedList}
            />
          </div>
          <div className="w-80 m-auto">
            <label>{assetType.assetTypeName}</label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssetTypeItem;
