import { useState, useEffect } from "react";

import { httpPost } from "../../../utility/request";
import { URLS } from "../../../configs/api_urls";

import { FontAwesome } from "../../../components/Icons/fontawesome";
import { Pagination } from "@mui/material";
import Controls from "../../../components/Controls/Controls";

import { Typography } from "@mui/material";
import {
  faCheckCircle,
  faTimesCircle,
  faInfoCircle,
  faWarning,
} from "@fortawesome/free-solid-svg-icons";
import Loader from "../../../components/Loader";

import moment from "moment-timezone";

import "./orderlist.styles.scss";
import { useNavigate } from "react-router-dom";
import NoContentMessage from "../../../components/NoRowMessage";

const OrderList = () => {
  var navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [adminOrders, setAdminOrdersData] = useState([]);
  const [paginationResult, setPaginationResult] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(5);

  const getLocalDate = (date) => {
    return moment.utc(date).local().format("MMM DD, yyyy, hh:mm A");
  };

  const fetchAdminOrders = async () => {
    setIsLoading(true);
    const body = {
      pageNumber: pageNumber,
      pageSize: pageSize,
    };
    await httpPost(URLS.ADMIN_ORDERS.GETADMINORDERS, body).then(({ data }) => {
      setPaginationResult(data);
      setAdminOrdersData(data.items);
    }).finally(() =>{
      setIsLoading(false);
    });
  };

  useEffect(() => {
    fetchAdminOrders();
  }, [pageNumber, pageSize]);

  return (
    <div>
      <Typography variant="h4">Admin orders and Payment</Typography>
      <Loader isLoading={isLoading}></Loader>
      <div>
        {!isLoading &&
          adminOrders.length > 0 &&
          adminOrders.map((item) => (
            <div key={item.adminOrderId}>
              <div className="order-item-container">
                <div className="row">
                  <div className="col-md-2">
                    {/* HDFC CC AVENUE Status Coded */}
                    {(item.orderStatus == "PAYMENT_INITIATED" ||
                      item.orderStatus == "Initiated") && (
                      <div>
                        <FontAwesome
                          icon={faWarning}
                          iconSize={"30px"}
                          iconcolor={"#eec13b"}
                          iconText={"Initiated"}
                        ></FontAwesome>
                      </div>
                    )}
                    {item.orderStatus == "PAYMENT_SUCCESS" && (
                      <div>
                        <FontAwesome
                          icon={faCheckCircle}
                          iconSize={"30px"}
                          iconcolor={"green"}
                          iconText={"Success"}
                        ></FontAwesome>
                      </div>
                    )}
                    {(item.orderStatus == "PAYMENT_FAILED" ||
                      item.orderStatus == "Failed") && (
                      <div>
                        <FontAwesome
                          icon={faTimesCircle}
                          iconSize={"30px"}
                          iconcolor={"red"}
                          iconText={"Failed"}
                        ></FontAwesome>
                      </div>
                    )}
                    {item.orderStatus == "Aborted" && (
                      <div>
                        <FontAwesome
                          icon={faTimesCircle}
                          iconSize={"30px"}
                          iconcolor={"orange"}
                          iconText={"Cancelled"}
                        ></FontAwesome>
                      </div>
                    )}
                  </div>
                  <div className="col-md-4">
                    <span>
                      <b>Product Name</b> :
                    </span>
                    <span>{item.cartItem.jsonItemDetails}</span>
                    <br />
                    <span>
                      <b>Order Id</b> :
                    </span>
                    <span>{item.orderId}</span>
                    <br />
                    <span>
                      {" "}
                      <b>Ordered On</b> :{" "}
                    </span>
                    <span>{getLocalDate(item.createdOn)}</span>
                  </div>
                  <div className="col-md-3">
                    <span>
                      <b>Product Quantity</b> :{" "}
                    </span>
                    <span>{item.cartItem.quantity}</span>
                    <br />
                    <span>
                      <b>Order Price</b> :{" "}
                    </span>
                    <span>{item.cartItem.totalPrice} Rs.</span>
                    <br />
                    {item.payment && (
                      <div>
                        <span>
                          <b>Bank Ref no.</b> :{" "}
                        </span>
                        <span>{item.payment.bank_ref_no}</span>
                      </div>
                    )}
                    {item.payment && (
                      <div>
                        <span>
                          <b>ReferenceNumber.</b> :{" "}
                        </span>
                        <span>{item.payment.bank_ref_no}</span>
                      </div>
                    )}
                  </div>
                  <div className="col-md-3">
                    <div style={{ textAlign: "right" }}>
                      <button
                        className="theme-btn my-2 p-2 ml-auto"
                        onClick={() => {
                          navigate(
                            `/admin/admin-order-details/${item.adminOrderId}`
                          );
                        }}
                      >
                        Details
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        {!isLoading && adminOrders.length > 0 && (
          <div
            style={{ bottom: "40px", right: "40px", position: "absolute" }}
            className="display-flex"
          >
            <Pagination
              count={paginationResult.totalPages}
              page={pageNumber}
              variant="outlined"
              shape="rounded"
              showFirstButton={paginationResult.hasPreviousPage}
              showLastButton={paginationResult.hasNextPage}
              color="secondary"
              style={{ margin: "auto" }}
              onChange={(event, value) => {
                setPageNumber(value);
              }}
            />
            <Controls.SelectControl
              name="pageSize"
              style={{ width: "100%" }}
              options={[
                { id: 5, title: "5" },
                { id: 10, title: "10" },
                { id: 50, title: "50" },
              ]}
              value={pageSize}
              onChange={(e) => {
                setPageNumber(1);
                setPageSize(Number(e.target.value));
              }}
            ></Controls.SelectControl>
          </div>
        )}
        {!isLoading && adminOrders.length == 0 && (
          <div>
            <NoContentMessage
              message={
                "You have not placed any orders yet!. Brows through Playnoot value adding plans."
              }
            ></NoContentMessage>
          </div>
        )}
      </div>
    </div>
  );
};

export default OrderList;
