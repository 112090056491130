import { Chip, Checkbox } from "@mui/material";

const AssetOfferItem = ({
  assetOffer,
  clickSelectAssetOffer,
  isSelected,
  isDisabled,
  addToSelectedList,
}) => {
  return (
    <div>
      <div
        className={
          "asset-item cursor-pointer " +
          (isSelected ? "seleted-asset-background" : "")
        }
        onClick={clickSelectAssetOffer}
      >
        <div className="w-100 display-inline-flex">
          <div className="w-10" style={{ margin: "auto" }}>
            <Checkbox
              id={assetOffer.assetOfferId}
              checked={isSelected}
              onChange={addToSelectedList}
            />
          </div>
          <div className="w-80">
            <label>{assetOffer.assetOfferName}</label>
            <br />
            <span style={{ color: "dimgray" }}>
              {assetOffer.assetOfferDescription}
            </span>
            <div className="row">
              <span className="col-md-6">
                Offer Type :{" "}
                {assetOffer.offerType == 1 ? "discount" : "price off"}
              </span>
              <span className="col-md-6">
                Offer Amount : {assetOffer.offerAmount}
              </span>
            </div>
          </div>
          <div className="w-10" style={{ margin: "auto" }}>
            <Chip label={assetOffer.assetOfferCode} />
          </div>
        </div>
      </div>
    </div>
  );
};
export default AssetOfferItem;