import { useState } from "react";
import { useContext, useEffect } from "react";
import { useDispatch } from "react-redux";

import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Loader from "../../components/Loader/index";
import SettingsNavigation from "./SettingsNavigation/settings-navigation.component";
import MyProfileUpdate from "./MyProfileUpdate/myprofile-update.component";
import BasicDetails from "./BasicDetails/basic-details.component";
import AddressUpdate from "./AddressUpdate/address-update.component";
import ImagesUpdate from "./Images/images.component";
import StandardGameUpdate from "./StandardGameUpdate/standard-game-update.component";
import AmenityUpdate from "./AmenityUpdate/amenity-update.component";
import PolicyUpdate from "./PolicyUpdate/policy-update.component";
import AppearenceUpdate from "./AppearanceUpdate/appearence-update.component";
import Help from "./Help/help.component";

import { fetchStandardData } from "../../modules/GameCenter/actions";
import ContactDetailsUpdate from "./ContactDetailsUpdate/contact-details-update.component";

const SettingsDashboard = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [selectedView, setSelectedView] = useState("facility_details");

  const fetchStandardDateForSettings = () => {
    setIsLoading(true);
    Promise.resolve(dispatch(fetchStandardData())).then((val) => {
      setIsLoading(false);
    });
  };

  useEffect(() => {
    fetchStandardDateForSettings();
  }, []);
  return (
    <div style={{ padding: "10px" }}>
      {isLoading && <Loader isLoading={isLoading}></Loader>}
      {!isLoading && (
        <div className="row" style={{ width: "100vw" }}>
          <div className="col-md-2">
            <SettingsNavigation
              selectedView={(value) => setSelectedView(value)}
              selectedNavItem={selectedView}
            ></SettingsNavigation>
          </div>
          <div className="col-md-8">
            <Box>
              <Paper
                style={{ height: "calc(100vh - 90px)", padding: "10px" }}
                elevation={3}
              >
                {selectedView === "my_profile" && (
                  <MyProfileUpdate></MyProfileUpdate>
                )}
                {selectedView === "facility_details" && (
                  <BasicDetails></BasicDetails>
                )}
                {selectedView === "address" && <AddressUpdate></AddressUpdate>}
                {selectedView === "images" && <ImagesUpdate></ImagesUpdate>}
                {selectedView === "stnadard_games" && (
                  <StandardGameUpdate></StandardGameUpdate>
                )}
                {selectedView === "amenity" && <AmenityUpdate></AmenityUpdate>}
                {selectedView === "my_policies" && (
                  <PolicyUpdate></PolicyUpdate>
                )}
                {selectedView === "advanced" && (
                  <AppearenceUpdate></AppearenceUpdate>
                )}
                {selectedView === "contact" && (
                  <ContactDetailsUpdate></ContactDetailsUpdate>
                )}
                {selectedView === "help" && (
                  <Help />
                )}
              </Paper>
            </Box>
          </div>
        </div>
      )}
    </div>
  );
};

export default SettingsDashboard;
