import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import { Container } from "@mui/material";
import Controls from "../../../components/Controls/Controls";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import { FontAwesome } from "../../../components/Icons/fontawesome";
import {
  faWarning,
  faCheckCircle,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";

import { getGameCenterList } from "../../../modules/GameCenter/selector";

import Highcharts from "highcharts";

import "./bank-details-item.styles.scss";

const BankDetailsItem = ({ bankDetail, editBankDetail }) => {
  const gamecenters = useSelector(getGameCenterList());

  const getCenterName = (id) => {
    return gamecenters.find((x) => x.applicationInstanceId == id)
      .applicationInstanceName;
  };

  const getStatusString = (status) => {
    if (status == 1) {
      return (
        <div>
          <FontAwesome
            icon={faWarning}
            iconSize={"15px"}
            iconcolor={"#eec13b"}
            iconText={"Submitted"}
          ></FontAwesome>
        </div>
      );
    } else if (status == 2) {
      return (
        <div>
          <FontAwesome
            icon={faCheckCircle}
            iconSize={"15px"}
            iconcolor={"green"}
            iconText={"Verified"}
          ></FontAwesome>
        </div>
      );
    } else if (status == 3) {
      return (
        <div>
          <FontAwesome
            icon={faTimesCircle}
            iconSize={"15px"}
            iconcolor={"red"}
            iconText={"Failed"}
          ></FontAwesome>
        </div>
      );
    }
  };

  var charId = "dashboard-chart-earnings" + bankDetail.adminBankDetailId;

  useEffect(() => {
    Highcharts.chart(charId, {
      chart: {
        type: "area",
      },
      title: {
        text: "Earning graph",
      },
      xAxis: {
        crosshair: false,
        title: null,
        tickInterval: 1,
      },
      yAxis: { title: "Days" },
      plotOptions: {
        column: {
          pointPadding: 1,
          borderWidth: 0,
          borderRadius: 5,
        },
      },
      series: [
        {
          data: [1300, 4000, 2500, 4500, 5340, 8000, 6224, 10000, 2000, 4500],
          name: "Last month",
        },
        {
          data: [1100, 5000, 1500, 4300, 1340, 2000, 8224, 1000, 8000, 6703],
          name: "This month",
        },
      ],
    });
  }, []);
  return (
    <div
      style={{ boxShadow: 2, background: "white" }}
      className="border-light-gray border-radius-10 p-20 mt-20"
    >
      <div
        className="display-inline-flex w-100"
        style={{
          justifyContent: "space-between",
          padding: "10px 0px  13px",
        }}
      >
        <TableContainer sx={{ maxWidth: 400 }}>
          <Table sx={{ maxWidth: 400 }} aria-label="simple table">
            <TableBody>
              <TableRow>
                <TableCell align="left">For Center</TableCell>
                <TableCell align="left">
                  {getCenterName(bankDetail.appicationInstanceId)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="left">Bank Name</TableCell>
                <TableCell align="left">{bankDetail.bankName}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="left">Account Type</TableCell>
                <TableCell align="left">{bankDetail.accountType}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="left">Account Number</TableCell>
                <TableCell align="left">{bankDetail.accountNumber}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="left">IFSC Code</TableCell>
                <TableCell align="left">{bankDetail.ifscCode}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="left">Bank Address</TableCell>
                <TableCell align="left">{bankDetail.bankAddress}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="left">Verification Status</TableCell>
                <TableCell align="left">
                  {getStatusString(bankDetail.verificationStatus)}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <div id={charId} style={{ width: "500px", height: "350px" }}></div>
      </div>
      <div className="ml-auto w-90">
        <Controls.ButtonControl
          type="submit"
          text="Earnings"
          className="theme-btn"
        ></Controls.ButtonControl>
        <Controls.ButtonControl
          type="submit"
          text="Update"
          className="theme-btn"
          onClick={editBankDetail}
        ></Controls.ButtonControl>
      </div>
    </div>
  );
};

export default BankDetailsItem;
