import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fireEvent } from "../../../modules/Events/eventSlice";

import SelectControl from "../../../components/Controls/SelectControl";
import ExternalProductItem from "./ExternProductItem/external-product-item.component";

import { URLS } from "../../../configs/api_urls";
import { httpGet } from "../../../utility/request";
import ExternalProductListSkeleton from "../Skeleton/externalproduct-list-skeleton.component";

const externalProductAvailability = [
  { id: 0, title: "Active", isActive: true },
  { id: 1, title: "Archived", isActive: false },
];

const ExternalProductList = ({
  selectedExternalProductList,
  clickSelectExternalProduct,
  addToSelectedList,
  cancelClick,
}) => {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState(0);
  const [externalProducts, setExternalProducts] = useState([]);
  const [selectedFilterItems, setSelectedFilterItems] = useState([]);

  const event = useSelector((onEvent) => {
    var eventName = onEvent.events.eventName;
    if (eventName === "refresh-external-product-list") {
      fetchExternalProducts();
      dispatch(fireEvent({ eventName: "" }));
    }
    //reset event listner
  });

  const fetchExternalProducts = async () => {
    setIsLoading(true);
    const { data } = await httpGet(URLS.External_PRODUCTS.GET_ALL);
    setExternalProducts(data);
    var isActive = externalProductAvailability.find(
      (x) => x.id == selectedFilter
    ).isActive;
    var products = data.filter((p) => p.isActive === isActive);
    setSelectedFilterItems(products);
    setIsLoading(false);
  };

  const handleFilterChange = (filter) => {
    var isActive = externalProductAvailability.find(
      (x) => x.id == filter
    ).isActive;
    setSelectedFilter(filter);
    var products = externalProducts.filter((p) => p.isActive === isActive);
    setSelectedFilterItems(products);
  };

  useEffect(() => {
    fetchExternalProducts();
  }, []);

  return (
    <div>
      <div className="list-filter-header">
        <SelectControl
          name="asset-availability"
          style={{ width: "120px", height: "35px" }}
          options={externalProductAvailability}
          value={selectedFilter}
          onChange={(e) => {
            handleFilterChange(e.target.value);
            cancelClick();
          }}
        ></SelectControl>
      </div>
      <div className="list-container scrollbar">
        {isLoading && (
          <ExternalProductListSkeleton></ExternalProductListSkeleton>
        )}
        {selectedFilterItems.length == 0 &&
          selectedFilter == 0 &&
          !isLoading && (
            <div className="no-item-section">
              <span className="no-item-message">
                You have not created any products yet. Create Products like
                Food, Beverage, Sports Eduipments or other amenities which
                players can select during booking.
              </span>
            </div>
          )}
        {selectedFilterItems.length == 0 &&
          selectedFilter == 1 &&
          !isLoading && (
            <div className="no-item-section">
              <span className="no-item-message">
                Archived products are not visible to players during booking.
              </span>
            </div>
          )}
        {!isLoading &&
          selectedFilterItems.map((product) => {
            return (
              <div key={product.externalProductId}>
                <ExternalProductItem
                  externalProduct={product}
                  isSelected={selectedExternalProductList.includes(
                    product.externalProductId
                  )}
                  addToSelectedList={addToSelectedList}
                  clickSelectExternalProduct={() => {
                    clickSelectExternalProduct(product);
                  }}
                ></ExternalProductItem>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default ExternalProductList;
