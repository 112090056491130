import {
  SIGNALR_CONEECTION_REQUEST_PAYLOAD,
  SIGNALR_CONEECTION_REQUEST_START,
  SIGNALR_CONEECTION_SUCCESS,
} from "./baseSignalR.constant";

const SIGNALR_INITIAL_STATE = {
isSignalRConnectionRequestStart: false,
isSignalRConnected: false,
signalRPayload: {}
};

export function SignalRReducer(state = { ...SIGNALR_INITIAL_STATE }, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SIGNALR_CONEECTION_REQUEST_START:
      return {
        ...state,
        isSignalRConnectionRequestStart: payload,
      };
    case SIGNALR_CONEECTION_SUCCESS:
      return { ...state, isSignalRConnected: payload };
    case SIGNALR_CONEECTION_REQUEST_PAYLOAD:
      return { ...state, signalRPayload : payload};
    default:
      return state;
  }
}

export default SignalRReducer;