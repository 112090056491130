const GuideExternalProductOperations = () => 
{
      return (
        <div className="edit-container">
          <div style={{ display: "inherit" }} className="operations-title">
            <div
              style={{ margin: "auto", textAlign: "center", marginTop: "30px" }}
            >
              Sell refreshment and snacks on the Playnoot app
            </div>
          </div>
          <div className="mt-50 p-20">
            <div className="row">
              <div className="col-md-6">
                <h5 className="text-theme font-600 cursor-pointer">
                  Create a Product
                </h5>
                <span>
                  create a product to be sold put a nice description.
                </span>
              </div>
              <div className="col-md-6">
                <h5 className="text-theme font-600 cursor-pointer">
                  Increase your earning
                </h5>
                <span>
                  Increase your earning by listing beverages and food on the Playnoot app.
                </span>
              </div>
            </div>
            <div className="row mt-20">
              <div className="col-md-6">
                <h5 className="text-theme font-600 cursor-pointer">
                  Check selling matrics
                </h5>
                <span>See selling matrics of you listed products.</span>
              </div>
              <div className="col-md-6">
                <h5 className="text-theme font-600 cursor-pointer">
                  Pricings
                </h5>
                <span>
                  Put resonable price for each product to increase sale
                </span>
              </div>
            </div>
          </div>
        </div>
      );
}

export default GuideExternalProductOperations;