import React, { useContext } from "react";
import NavDropdown from "react-bootstrap/NavDropdown";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import Avatar from "@mui/material/Avatar";
import CartIcon from "../../components/Cart/CartIcon/carticon.component";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faBell,
  faCog,
  faSearch,
  faSignOut,
  faUser,
} from "@fortawesome/free-solid-svg-icons";

import { UserContext } from "../../utility/context";
import { GameCenterViewContext } from "../../utility/context";
import { signOut } from "../../hoc/helper";
import { LOGIN_PAGE, ADMIN_ROUTE } from "../../configs/routes";
import { selectIsCartOpen } from "../../modules/cart/cart.selector";
import { selectApplicationInfo } from "../../modules/App/selectors";

import default_user_pic from "../../static/user_avatar.jpg";
import playpool_icon from "../../static/playpool2.png";

import "./header.scss";
import { Typography } from "@mui/material";
import NotificationsIcon from "../Notification/notificationsIcon.component";

const Header = (props) => {
  const router = useNavigate();
  const user = useContext(UserContext) || { user: { userName: "..." } };
  const isCartOpen = useSelector(selectIsCartOpen);
  const applicationInfo = useSelector(selectApplicationInfo);

  const gameCenterDetails = useContext(GameCenterViewContext);

  return (
    <div className="header">
      <div className="logo">
        <img
          src={playpool_icon}
          alt=""
          className="mx-2 cursor-pointer"
          onClick={() => router(ADMIN_ROUTE)}
        />
        <h5>{applicationInfo.applicationName}</h5>
      </div>
      <div className="search">
        {gameCenterDetails != null && (
          <Typography variant="h5" color={"gray"}>
            {gameCenterDetails.GameCenter.applicationInstanceName || ""}
          </Typography>
        )}
      </div>
      <div className="display-flex ml-auto">
        <NotificationsIcon></NotificationsIcon>

        <CartIcon />

        <div className="user">
          <NavDropdown
            id="user-drop-down"
            title={user.firstName + " " + user.lastName || user.emailId}
            menuVariant="dark"
          >
            <NavDropdown.Item
              onClick={() => {
                router("./user-profile");
              }}
            >
              <FontAwesomeIcon icon={faUser} />
              <span className="text-small ml-4"> User Profile </span>
            </NavDropdown.Item>
            {/*<NavDropdown.Item href="#action/3.2">
              <FontAwesomeIcon icon={faCog} />
              <span className="text-small ml-4"> Settings </span>
            </NavDropdown.Item> */}
            <NavDropdown.Item
              onClick={() => {
                signOut();
                router(LOGIN_PAGE);
              }}
            >
              <FontAwesomeIcon icon={faSignOut} />
              <span className="text-small ml-4"> Sign Out </span>
            </NavDropdown.Item>
          </NavDropdown>
          <Avatar
            style={{
              borderRadius: "50%",
              height: "50px",
              width: "50px",
            }}
            src={user.profilePictureUrl || default_user_pic}
            alt=""
          />
        </div>
      </div>
    </div>
  );
};

export default Header;
