import { ADD_ALERT, MAIN_APP_ACTION_CONSTANTS } from "./constants";

const INITAL_STATE = {
  alert: {},
  isLoading: true,
  applicationInfo: {},
  applicationLicense: null,
  applicationLicenseConsumption: {},
  error: null,
};

function appCommonReducer(state = { ...INITAL_STATE }, action) {
  const { type, payload } = action;
  switch (type) {
    case ADD_ALERT:
      return { ...state, alert: { ...action.data } };
    case MAIN_APP_ACTION_CONSTANTS.MAIN_APP_REQUEST_START:
      return { ...state, isLoading: payload };
    case MAIN_APP_ACTION_CONSTANTS.MAIN_APP_REQUEST_SUCCESS:
      return { ...state, isLoading: payload };
    case MAIN_APP_ACTION_CONSTANTS.MAIN_APP_REQUEST_FAILURE:
      return { ...state, isLoading: false, error: payload };
    case MAIN_APP_ACTION_CONSTANTS.SET_APPLICATION_INFO:
      return { ...state, applicationInfo: payload };
    case MAIN_APP_ACTION_CONSTANTS.SET_APPLICATION_LICENSE:
      return { ...state, applicationLicense: payload };
    case MAIN_APP_ACTION_CONSTANTS.SET_APPLICATION_LICENSE_CONSUMPTION:
      return { ...state, applicationLicenseConsumption: payload };
    default:
      return { ...state };
  }
}

export default appCommonReducer
