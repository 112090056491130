//from redux toolkit
import { combineReducers } from '@reduxjs/toolkit';
import GameCenterReducer from "../modules/GameCenter/reducer";
import AssetReducer from "../modules/Asset/reducer";
import cartReducer from "../modules/cart/cart.reducer";
import appCommonReducer from "../modules/App/reducer";
import licenseReducer from "../modules/License/licenseSlice";
import eventReducer from "../modules/Events/eventSlice"
import modalPopupReducer from "../modules/ModalPopup/modalPopupSlice";
import SignalRReducer from '../modules/SignalR/baseSignalR.reducer';
import notificationReducer from '../modules/Notification/notificationSlice';

const rootReducer = combineReducers({
  AppCommon: appCommonReducer,
  GameCenter: GameCenterReducer,
  Asset: AssetReducer,
  cart: cartReducer,
  license: licenseReducer,
  modalPopup: modalPopupReducer,
  signalR: SignalRReducer,
  events: eventReducer,
  notifications: notificationReducer,
});

export default rootReducer;
