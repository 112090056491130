import React, { useEffect, useState } from "react";
import {
  Route,
  Routes,
  useLocation,
  matchRoutes,
  useNavigate,
  useParams,
} from "react-router-dom";
import withUser from "../../../hoc/withUser";

import { UserContext, GameCenterViewContext } from "../../../utility/context";

import AppLayout from "../../../components/AppLayout";
import Loader from "../../../components/Loader";

import { APP_GAME_CENTER_ROUTES } from "../../../configs/routes";
import { GAME_CENTER_NAV_ITEMS } from "../../../configs/app_configs";
import { httpGet } from "../../../utility/request";
import { URLS } from "../../../configs/api_urls";
import { setThemeColors } from "../../../utility/common";

const GameCenter = ({ user }) => {
  const location = useLocation();
  const router = useNavigate();
  const { applicationId } = useParams();
  const [GameCenter, setGameCenter] = useState({});
  const [isLoading, setisLoading] = useState(true);

  const paths = GAME_CENTER_NAV_ITEMS.map(({ link }) => {
    return { path: link };
  });

  const useCurrentPath = () => {
    try {
      const [{ route }] = matchRoutes(paths, location);
      return route.path;
    } catch (err) {
      return "";
    }
  };

  const current_path = useCurrentPath();

  const index = GAME_CENTER_NAV_ITEMS.findIndex((item) => {
    return item.link === current_path;
  });

  const handleOnLinkClick = (item_id) => {
    const item = GAME_CENTER_NAV_ITEMS.find((item) => {
      return item.id === item_id;
    });

    if (item.link) {
      router(item.link.replaceAll(":applicationId", applicationId));
    }
  };

  const fetchGameCenter = async () => {
    setisLoading(true);
    try {
      const url = URLS.GAME_CENTERS.GET_CENTER;
      const { data, status } = await httpGet(url);
      if (status == 200) {
        setGameCenter(data);
        if (data.applicationTheme) {
          setThemeColors(data.applicationTheme);
        }
      }
    } catch (err) {
      console.error(err);
    } finally {
      setisLoading(false);
    }
  };

  useEffect(() => {
    fetchGameCenter();
  }, []);

  return (
    <UserContext.Provider value={user}>
      <Loader isLoading={isLoading}></Loader>
      {!isLoading && (
        <GameCenterViewContext.Provider
          value={{ GameCenter, isLoading, setGameCenter }}
        >
          <AppLayout
            selected_nav_item={index >= 0 ? index + 1 : 0}
            nav_items={GAME_CENTER_NAV_ITEMS}
            handleOnLinkClick={handleOnLinkClick}
            theme={GameCenter.applicationTheme}
          >
            <Routes>
              {APP_GAME_CENTER_ROUTES.map((route, index) => {
                return <Route {...route} key={`game-center-route-${index}`} />;
              })}
            </Routes>
          </AppLayout>
        </GameCenterViewContext.Provider>
      )}
    </UserContext.Provider>
  );
};

export default withUser(GameCenter);
