import * as React from "react";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";

const RatingReviewListSkeleton = () => {
  return (
    <Stack spacing={1}>
      <div>
        <div className="row">
          <div className="col-md-4">
            <Skeleton variant="circular" height={60} width={60} />
            <Skeleton variant="text" width={80} />
          </div>
          <div className="col-md-6">
            <Skeleton variant="text" />
            <Skeleton variant="text" />
            <Skeleton variant="rectangular" height={70} />
          </div>
        </div>
      </div>
      <div>
        <div className="row">
          <div className="col-md-4">
            <Skeleton variant="circular" height={60} width={60} />
            <Skeleton variant="text" width={80} />
          </div>
          <div className="col-md-6">
            <Skeleton variant="text" />
            <Skeleton variant="text" />
            <Skeleton variant="rectangular" height={70} />
          </div>
        </div>
      </div>
      <div>
        <div className="row">
          <div className="col-md-4">
            <Skeleton variant="circular" height={60} width={60} />
            <Skeleton variant="text" width={80} />
          </div>
          <div className="col-md-6">
            <Skeleton variant="text" />
            <Skeleton variant="text" />
            <Skeleton variant="rectangular" height={70} />
          </div>
        </div>
      </div>
      <div>
        <div className="row">
          <div className="col-md-4">
            <Skeleton variant="circular" height={60} width={60} />
            <Skeleton variant="text" width={80} />
          </div>
          <div className="col-md-6">
            <Skeleton variant="text" />
            <Skeleton variant="text" />
            <Skeleton variant="rectangular" height={70} />
          </div>
        </div>
      </div>
      <div>
        <div className="row">
          <div className="col-md-4">
            <Skeleton variant="circular" height={60} width={60} />
            <Skeleton variant="text" width={80} />
          </div>
          <div className="col-md-6">
            <Skeleton variant="text" />
            <Skeleton variant="text" />
            <Skeleton variant="rectangular" height={70} />
          </div>
        </div>
      </div>
    </Stack>
  );
};
export default RatingReviewListSkeleton;
