import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import { ListItemIcon, ListItemText } from "@mui/material";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import InfoIcon from "@mui/icons-material/Info";
import PersonIcon from "@mui/icons-material/Person";
import BusinessIcon from "@mui/icons-material/Business";
import ImageIcon from "@mui/icons-material/Image";
import SportsBaseballIcon from "@mui/icons-material/SportsBaseball";
import BeachAccessIcon from "@mui/icons-material/BeachAccess";
import PolicyIcon from "@mui/icons-material/Policy";
import MiscellaneousServicesIcon from "@mui/icons-material/MiscellaneousServices";
import PhoneIcon from "@mui/icons-material/Phone";
import HelpIcon from "@mui/icons-material/Help";

const SettingsNavigationItems = [
  //{ id: "my_profile", title: "My Profile", icon: <PersonIcon /> },
  { id: "facility_details", title: "Facility Details", icon: <InfoIcon /> },
  { id: "address",title: "Address", icon: <BusinessIcon /> },
  { id: "images",title: "Images", icon: <ImageIcon /> },
  { id: "stnadard_games",title: "Standard games", icon: <SportsBaseballIcon /> },
  { id: "amenity",title: "Amenity", icon: <BeachAccessIcon /> },
  { id: "my_policies",title: "My Policies", icon: <PolicyIcon /> },
  { id: "contact",title: "Contact", icon: <PhoneIcon /> },
  { id: "advanced",title: "Advanced", icon: <MiscellaneousServicesIcon /> },
  { id: "help",title: "Help", icon: <HelpIcon /> },
];

const SettingsNavigation = ({selectedNavItem, selectedView}) => {
  return (
    <div>
      <Box>
        <Paper elevation={3} style={{ height: "calc(100vh - 90px)" }}>
          <List>
            {SettingsNavigationItems.map((item) => {
              return (
                <div key={item.id}>
                  <ListItem
                    disablePadding
                    onClick={() => selectedView(item.id)}
                    style={{
                      background:
                        selectedNavItem == item.id ? "lightgray" : "white",
                    }}
                  >
                    <ListItemButton>
                      <ListItemIcon sx={{minWidth: "30px"}}>{item.icon}</ListItemIcon>
                      <ListItemText primary={item.title} />
                    </ListItemButton>
                  </ListItem>
                </div>
              );
            })}
          </List>
        </Paper>
      </Box>
    </div>
  );
};

export default SettingsNavigation;
