import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import BasicDetails from "./BasicDetails/basic-details.component";
import Carousel from "react-bootstrap/Carousel";
import { Paper } from "@mui/material";
import ButtonControl from "../../components/Controls/ButtonControl";
import Address from "./Address/address.component";
import CircularProgress from "@mui/material/CircularProgress";
import StandardGames from "./StandardGames/standard-games.component";
import FacilityAmenity from "./Amenity/amenity.component";
import UploadImage from "./Images/upload-images.component";
import ContactDetails from "./ContactDetails/contactdetails.component";
import AdvancedDetails from "./AdvancedDetails/advanced-details.component";
import Policies from "./Policies/policies.component";

import moment from "moment/moment";

import { URLS } from "../../configs/api_urls";
import { httpPost } from "../../utility/request";
import { addAlert } from "../../modules/App/actions";
import { fetchStandardData } from "../../modules/GameCenter/actions";
import { getApplicationId } from "../../hoc/helper";

const CreateCenterDashboard = () => {
  const steps = [
    "Basic Details",
    "Address",
    "Games",
    "Amenities",
    "Images",
    "Policies",
    "Contact Details",
    "Advanced",
  ];
  var dateNow = moment(new Date()).format("YYYY-MM-DD");
  const newCenter = {
    applicationInstanceName: "",
    applicationId: getApplicationId(),
    timeZone: "Asia/Kolkata",
    themeId: 0,
    contactPhoneNumber: "",
    contactEmailId: "",
    futureActiveDays: 1,
    webSiteLink: "",
    availabilityStartTime: dateNow + "T03:30:00.237",
    availabilityEndTime: dateNow + "T03:30:00.237",
    standardGames: [],
    facilityAmenities: [],
    images: [],
    applicationInstanceCenterPolicies: [],
    gameDetails: {
      gameDetailsId: 0,
      tagLine: "",
      gameDescription: "",
      gameLogoUrl: "",
    },
    address: {
      addressId: 0,
      addressLane1: "",
      addressLane2: "",
      city: "",
      state: "",
      country: "",
      pincode: "",
      latitude: "",
      longitude: "",
      googleMapId: "",
      googleMapAddress: "",
    },
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [newCenterDetails, setNewCenterDetails] = useState(newCenter);

  const onChange = (changedDetails) => {
    setNewCenterDetails({ ...changedDetails });
  };

  const updateAddress = (address) => {
    setNewCenterDetails({ ...newCenterDetails, address: address });
  };

  const updateStandardGames = (games) => {
    setNewCenterDetails({ ...newCenterDetails, standardGames: games });
  };

  const updateAmenities = (aminities) => {
    setNewCenterDetails({ ...newCenterDetails, facilityAmenities: aminities });
  };

  const updateImageList = (images) => {
    setNewCenterDetails({ ...newCenterDetails, images: images });
  };

  const updatePolicies = (policies) => {
    setNewCenterDetails({
      ...newCenterDetails,
      applicationInstanceCenterPolicies: policies,
    });
  };
  const fetchStandardDateForSettings = () => {
    setIsLoading(true);
    Promise.resolve(dispatch(fetchStandardData())).then((val) => {
      setIsLoading(false);
    });
  };

  const showAlert = (type, message) => {
    dispatch(
      addAlert({
        type: type,
        message: message,
      })
    );
  };

  const createApplicationInstance = async () => {
    setIsSaving(true);
    await httpPost(URLS.GAME_CENTERS.CREATE, newCenterDetails)
      .then((response) => {
        if (response.data == true) {
          showAlert("success", "New play center created successfully");
          navigate("../");
        }
      })
      .catch((error) => {
        showAlert("error", error);
      }).finally(() => {
        setIsSaving(false);
      });
  };

  useEffect(() => {
    fetchStandardDateForSettings();
  }, []);

  console.log(newCenterDetails);
  return (
    <div>
      <Box sx={{ width: "100%" }}>
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map((label, index) => (
            <Step
              key={label}
              id={index}
              onClick={(step) => {
                setActiveStep(Number(step.currentTarget.id));
              }}
            >
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>
      {!isLoading && (
        <Box>
          <Paper sx={{ width: "100%", mb: 2, p: 2 }}>
            <Carousel
              controls={false}
              interval={null}
              indicators={false}
              activeIndex={activeStep}
            >
              {/*basic details form  */}
              <Carousel.Item>
                <BasicDetails
                  newCenterDetails={newCenterDetails}
                  updateValues={onChange}
                />
              </Carousel.Item>

              {/* Fill Address form  */}
              <Carousel.Item>
                <Address
                  addressInput={newCenterDetails.address}
                  updateAddress={updateAddress}
                ></Address>
              </Carousel.Item>

              {/* Fill Standard games form  */}
              <Carousel.Item>
                <StandardGames
                  newCenterGames={newCenterDetails.standardGames}
                  updateStandardGames={updateStandardGames}
                ></StandardGames>
              </Carousel.Item>

              {/* Fill Amenity form  */}
              <Carousel.Item>
                <FacilityAmenity
                  newCenterAmenities={newCenterDetails.facilityAmenities}
                  updateAmenities={updateAmenities}
                ></FacilityAmenity>
              </Carousel.Item>

              {/* Fill Image form  */}
              <Carousel.Item>
                <UploadImage
                  images={newCenterDetails.images}
                  updateImageList={updateImageList}
                ></UploadImage>
              </Carousel.Item>

              {/* Fill Policy form  */}
              <Carousel.Item>
                <Policies
                  newCenterPolicies={
                    newCenterDetails.applicationInstanceCenterPolicies
                  }
                  updatePolicies={updatePolicies}
                ></Policies>
              </Carousel.Item>

              {/* Fill Contact form  */}
              <Carousel.Item>
                <ContactDetails
                  newCenterDetails={newCenterDetails}
                  updateValues={onChange}
                ></ContactDetails>
              </Carousel.Item>

              {/* Fill Advanced Detail form  */}
              <Carousel.Item>
                <AdvancedDetails
                  newCenterDetails={newCenterDetails}
                  updateValues={onChange}
                ></AdvancedDetails>
              </Carousel.Item>
            </Carousel>
          </Paper>
        </Box>
      )}
      <Box>
        {activeStep > 0 && (
          <ButtonControl
            text={"Previous"}
            onClick={() => setActiveStep(activeStep - 1)}
          ></ButtonControl>
        )}
        {activeStep !== 7 && (
          <ButtonControl
            text={"Next"}
            style={{ float: "right", margin: "10px" }}
            onClick={() => setActiveStep(activeStep + 1)}
          ></ButtonControl>
        )}
        {activeStep == 7 && (
          <ButtonControl
            text={"Submit"}
            color={"warning"}
            disabled={isSaving}
            innercomponent={
              isSaving && (
                <CircularProgress
                  size={25}
                  style={{ padding: "2px", marginLeft: "10px" }}
                  color="inherit"
                ></CircularProgress>
              )
            }
            onClick={() => {
              createApplicationInstance();
            }}
            style={{ float: "right", margin: "10px" }}
          ></ButtonControl>
        )}
      </Box>
    </div>
  );
};
export default CreateCenterDashboard;
