import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { IconButton, Menu, MenuItem, Typography, Box } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBagShopping } from "@fortawesome/free-solid-svg-icons";

import {
  selectCartCount,
  selectIsCartOpen,
  selectIsLoading,
  selectCartItems,
} from "../../../modules/cart/cart.selector";
import { fetchCartItems } from "../../../modules/cart/cart.action";

import "./carticon.styles.scss";
import ButtonControl from "../../Controls/ButtonControl";

const CartIcon = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

  const isCartOpen = useSelector(selectIsCartOpen);
  const cartCount = useSelector(selectCartCount);
  const isLoading = useSelector(selectIsLoading);
  const cartItems = useSelector(selectCartItems);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const goToCheckoutHandler = () => {
    navigate("/admin/checkout");
    handleClose();
  };

  useEffect(() => {
    dispatch(fetchCartItems());
  }, []);

  return (
    <div>
      <IconButton
        id="cart-button"
        aria-controls={open ? "cart-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        className="notification"
      >
        <FontAwesomeIcon icon={faBagShopping} />
        {cartCount > 0 && (
          <div
            style={{
              position: "absolute",
              top: 1,
              right: 3,
              background: "green",
              borderRadius: 13,
              width: "25px",
              height: "25px",
              fontSize: "15px",
            }}
          >
            <span style={{ color: "white", fontWeight: "bold" }}>
              {cartCount}
            </span>
          </div>
        )}
      </IconButton>
      <Menu
        id="cart-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{ zIndex: 500000, maxHeight: "500px" }}
        MenuListProps={{
          "aria-labelledby": "cart-button",
        }}
      >
        {!isLoading &&
          cartItems.map((item) => {
            return (
              <MenuItem
                sx={{
                  minWidth: "300px",
                  borderBottom: "1px solid lightgray",
                }}
                onClick={handleClose}
              >
                <div className="display-flex">
                  <div>
                    <img src={item.imageUrl} />
                  </div>
                  <Box className="ml-1">
                    <Typography style={{ fontWeight: "bold" }}>
                      {item.name}
                    </Typography>
                    <Typography
                      className="text-wrap"
                      color={"gray"}
                      paragraph={true}
                    >
                      {item.quantity} x {item.price} ₹
                    </Typography>
                  </Box>
                </div>
              </MenuItem>
            );
          })}
        {cartItems.length > 0 && (
          <MenuItem sx={{ float: "inline-end" }}>
            <ButtonControl
              customClass={"theme-btn"}
              text={"Checkout"}
              onClick={goToCheckoutHandler}
            ></ButtonControl>
          </MenuItem>
        )}
        {cartItems.length === 0 && (
          <MenuItem>
            <Box sx={{ maxWidth: "300px" }}>
              <Typography variant="body1" className="text-wrap">
                Nothing in the cart for now. Browse Playnoot store to buy
                License, Integrations and other services
              </Typography>
            </Box>
          </MenuItem>
        )}
      </Menu>
    </div>
  );
};

export default CartIcon;
