import * as React from "react";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import { FormControl } from "@mui/material";
import Controls from "../../../components/Controls/Controls";

import { URLS } from "../../../configs/api_urls";
import { httpPost, httpPut } from "../../../utility/request";

import { getGameCenterList } from "../../../modules/GameCenter/selector";

import "./addupdate-bank-details.styles.scss";

export default function AddUpdateBankDetails({
  bankDetail,
  bankDetailList,
  bankDetailClose,
}) {
  const gamecenters = useSelector(getGameCenterList());
  const navigate = useNavigate();

  var useCase = bankDetail.adminBankDetailId == 0 ? "Create" : "Update";

  var gamecenterList = gamecenters.map((center) => ({
    id: center.applicationInstanceId,
    title: center.applicationInstanceName,
  }));

  if (useCase == "Create") {
    // remove centers from drop down for which account is already there;
    const removedCenters = bankDetailList.map(
      (bankDetail) => bankDetail.appicationInstanceId
    );
    // refactor center list for select drop down
    gamecenterList = gamecenterList.filter((center) => {
      return removedCenters.indexOf(center.id) === -1;
    });
  }

  // Account types data
  const accountTypeItems = [
    { id: "Savings", title: "Savings" },
    { id: "Current", title: "Current" },
  ];

  // Defaults
  const bankDetailsIntialState = {
    bankName: "",
    accountNumber: "",
    ifscCode: "",
    accountType: "",
    bankAddress: "",
    appicationInstanceId: 0,
  };

  const validationIntialState = {
    bankName: "",
    accountNumber: "",
    ifscCode: "",
    accountType: "",
    bankAddress: "",
    appicationInstanceId: "",
  };

  // States
  const [inputValues, setInputValue] = useState({ ...bankDetail });
  const [open, setOpen] = React.useState(false);
  const [validation, setValidation] = useState({ validationIntialState });
  const [formSubmitError, setFormSubmitError] = useState("");

  const handleCenterSelectChange = (e) => {
    var selectedPlayCenterId = e.target.value;
    if (!gamecenterList.find((x) => x.id == selectedPlayCenterId)) {
      setValidation({
        ...validation,
        appicationInstanceId: "Please select one center from the list",
      });
    }
    setInputValue({
      ...inputValues,
      appicationInstanceId: selectedPlayCenterId,
    });
  };

  const handleAccountTypeChange = (e) => {
    var selectedAccountType = e.target.value;
    if (!accountTypeItems.find((x) => x.id == selectedAccountType)) {
      setValidation({
        ...validation,
        accountType: "Please select account Type",
      });
    }
    setInputValue({ ...inputValues, accountType: selectedAccountType });
  };

  const handleIfscCodeChange = (e) => {
    var ifscCodeVal = e.target.value;
    if (ifscCodeVal.length < 7) {
      setValidation({ ...validation, ifscCode: "ifsc code is not correct" });
    } else {
      setValidation({ ...validation, ifscCode: "" });
    }
    setInputValue({ ...inputValues, ifscCode: ifscCodeVal });
  };

  const handleAccountNUmberChange = (e) => {
    var accountNumberVal = e.target.value;
    if (accountNumberVal.length < 12) {
      setValidation({
        ...validation,
        accountNumber: "Enter valid Bank account",
      });
    } else {
      setValidation({ ...validation, accountNumber: "" });
    }
    setInputValue({ ...inputValues, accountNumber: accountNumberVal });
  };

  const handleBankNameChange = (e) => {
    var bankNameVal = e.target.value;
    if (bankNameVal.length < 8) {
      setValidation({
        ...validation,
        bankName: "Enter a valid Bank Name",
      });
    } else {
      setValidation({ ...validation, bankName: "" });
    }
    setInputValue({ ...inputValues, bankName: bankNameVal });
  };

  const handleBankAddressChange = (e) => {
    var bankAddressVal = e.target.value;
    if (bankAddressVal.length < 8) {
      setValidation({
        ...validation,
        bankAddress: "Enter valid bank address",
      });
    } else {
      setValidation({ ...validation, bankAddress: "" });
    }
    setInputValue({ ...inputValues, bankAddress: bankAddressVal });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setInputValue({ bankDetailsIntialState });
    setValidation({ validationIntialState });
    setFormSubmitError("");
    bankDetailClose();
    setOpen(false);
  };

  const handleSubmit = async () => {
    useCase == "Create" ? saveBankingDetails() : updateBankingDetails();
  };

  const saveBankingDetails = async () => {
    await httpPost(URLS.ADMIN_BANKING.ADDBANKDETAILS, inputValues)
      .then((response) => {
        if (response.data == true) {
          navigate("/admin/admin-bank-details");
          handleClose();
        }
      })
      .catch((error) => {
        setFormSubmitError(
          error.response.data.Message || "Some error occuered!"
        );
      });
  };

  const updateBankingDetails = async () => {
    await httpPut(URLS.ADMIN_BANKING.UPDATEBANKDETAILS, inputValues)
      .then((response) => {
        if (response.data == true) {
          handleClose();
          navigate("/admin/admin-bank-details");
        }
      })
      .catch((error) => {
        setFormSubmitError(
          error.response.data.Message || "Some error occuered!"
        );
      });
  };

  useEffect(() => {
    bankDetail !== null ? handleClickOpen() : handleClose();
  }, [bankDetail]);

  return (
    <div>
      <Dialog maxWidth="md" open={open} onClose={handleClose}>
        <DialogTitle>{useCase} Bank Account</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Bank accounts must be attached to each play center.
          </DialogContentText>
          <div>
            <div className="row mt-20">
              <div className="col-md-12">
                <FormControl>
                  <Controls.SelectControl
                    name="centerselector"
                    label="For Center"
                    options={gamecenterList}
                    style={{ width: "700px" }}
                    value={inputValues.appicationInstanceId}
                    onChange={(e) => handleCenterSelectChange(e)}
                    error={validation.appicationInstanceId}
                    disabled={useCase == "Update"}
                  ></Controls.SelectControl>
                </FormControl>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 mt-20">
                <FormControl>
                  <Controls.SelectControl
                    name="accounttype"
                    label="Account Type"
                    style={{ width: "340px" }}
                    options={accountTypeItems}
                    value={inputValues.accountType}
                    onChange={(e) => handleAccountTypeChange(e)}
                    error={validation.accountType}
                  ></Controls.SelectControl>
                </FormControl>
              </div>
              <div className="col-md-6">
                <FormControl>
                  <Controls.InputControl
                    name="bankname"
                    label="Bank Name"
                    style={{ width: "340px", marginTop: "20px" }}
                    onChange={(e) => handleBankNameChange(e)}
                    value={inputValues.bankName}
                    error={validation.bankName}
                  ></Controls.InputControl>
                </FormControl>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <FormControl>
                  <Controls.InputControl
                    name="ifsccode"
                    label="IFSC Code"
                    style={{ width: "340px", marginTop: "20px" }}
                    onChange={(e) => handleIfscCodeChange(e)}
                    value={inputValues.ifscCode}
                    error={validation.ifscCode}
                  ></Controls.InputControl>
                </FormControl>
              </div>
              <div className="col-md-6">
                <FormControl>
                  <Controls.InputControl
                    name="accountnumber"
                    label="Account Number"
                    style={{ width: "340px", marginTop: "20px" }}
                    onChange={(e) => handleAccountNUmberChange(e)}
                    value={inputValues.accountNumber}
                    error={validation.accountNumber}
                  ></Controls.InputControl>
                </FormControl>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <FormControl>
                  <Controls.InputControl
                    name="bankaddress"
                    label="Bank Address"
                    style={{ width: "700px", marginTop: "20px" }}
                    onChange={(e) => handleBankAddressChange(e)}
                    value={inputValues.bankAddress}
                    error={validation.bankAddress}
                  ></Controls.InputControl>
                </FormControl>
              </div>
            </div>
          </div>
          {formSubmitError !== "" && (
            <div className="add-bank-detail-error">{formSubmitError}</div>
          )}
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSubmit}>Submit</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
