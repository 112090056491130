import { Avatar, Checkbox } from "@mui/material";

const AdvertisementItem = ({
  ad,
  clickSelectAd,
  isSelected,
  isDisabled,
  addToSelectedList,
}) => {

    const getTitleAdString = (adType) => {
        if (adType === 0) {
          return "Flash poster";
        } else if (adType === 1) {
          return "On click of this Ad will lead to center detail page";
        } else if (adType === 2) {
          return "User will see the filter screen for these ads";
        } else if (adType === 3) {
          return "Lead users to blog urls or websites";
        }

    }

  return (
    <div>
      <div
        className={
          "asset-item cursor-pointer " +
          (isSelected ? "seleted-asset-background" : "")
        }
        onClick={clickSelectAd}
      >
        <div className="w-100 display-inline-flex" style={{height: "85px"}}>
          <div style={{ margin: "auto" }}>
            <Checkbox
              id={ad.advertisementId}
              checked={isSelected}
              onChange={addToSelectedList}
            />
          </div>
          <div className="w-10" style={{ margin: "auto" }}>
            <Avatar
              src={ad.bannerUrl}
              style={{ borderRadius: "50%", height: "70px", width: "70px" }}
            />
          </div>
          <div className="w-80" style={{ marginLeft: "20px" }}>
            <label sx={{ fontWeight: 500 }}>
              {getTitleAdString(ad.advertisementType)}
            </label>
            <br />
            <span style={{ color: "dimgray" }}>
              This advertisement will be visible on the mobile app depending
              upon the location of the user.
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdvertisementItem;
