import { useNavigate } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card, Divider, Switch } from "@mui/material";
import { faLocationDot, faStar } from "@fortawesome/free-solid-svg-icons";

import "./module.scss";
import { httpGet } from "../../utility/request";
import { URLS } from "../../configs/api_urls";
import { set_access_token } from "../../hoc/helper";

const GameCenterView = ({ gameCenter, onClickLiveSwitch }) => {
  const router = useNavigate();

  const handleViewCenter = async (id) => {
    const url = URLS.ADMIN.SWITCH_APPLICATION.replace("#ID#", id);
    const { data = {} } = await httpGet(url);
    const { accessToken } = data;
    if (accessToken) await set_access_token(accessToken);
    router(`center/${id}`);
  };

  return (
    <div className="game_center_container my-3">
      <Card id={gameCenter.applicationInstanceId} className="p-2">
        <div className="d-flex">
          <div className="preview">
            <img src={gameCenter.thumbnailUrl} alt="" />
            <div className="ratings">
              <FontAwesomeIcon icon={faStar}></FontAwesomeIcon>
              <FontAwesomeIcon icon={faStar}></FontAwesomeIcon>
              <FontAwesomeIcon icon={faStar}></FontAwesomeIcon>
              <FontAwesomeIcon icon={faStar}></FontAwesomeIcon>
              <FontAwesomeIcon icon={faStar}></FontAwesomeIcon>
            </div>
          </div>
          <div className="details">
            <div className="d-flex ">
              <h5>{gameCenter.applicationInstanceName}</h5>
              <div className="ml-auto">
                <Switch
                  onClick={onClickLiveSwitch}
                  checked={gameCenter.isLive}
                />
                <button
                  className="theme-btn"
                  onClick={() =>
                    handleViewCenter(gameCenter.applicationInstanceId)
                  }
                >
                  view
                </button>
              </div>
            </div>
            <Divider style={{ borderColor: "rgba(0, 0, 0, 0.5)" }} />
            <p className="mt-2">
              <FontAwesomeIcon
                icon={faLocationDot}
                className="px-2"
              ></FontAwesomeIcon>
              {gameCenter.addressText}
            </p>
            <div className="d-flex">
              <div className="performance_scale">
                <span>{gameCenter.totalFollwers}</span> <p>Subscriber</p>
              </div>
              <div className="performance_scale">
                <span>{gameCenter.todaysBookings}</span> <p>Bookings Today</p>
              </div>
              <div className="performance_scale">
                <span>{gameCenter.averageBookingsperWeek}</span>
                <p>Average booking per/Week</p>
              </div>
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default GameCenterView;
