import * as React from "react";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";

export default function EditSubscriptionPlanOfferSkeleton() {
  return (
    <Stack spacing={1}>
      <div className="display-flex">
        <div style={{ width: "10%" }}>
          <Skeleton variant="text" width={"10%"} sx={{ fontSize: "1rem" }} />
        </div>
        <div style={{ width: "60%" }}>
          <Skeleton variant="rounded" width={"50%"} sx={{ fontSize: "1rem" }} />
        </div>
        <div style={{ width: "30%" }}>
          <Skeleton variant="rounded" width={"100%"} height={60} />
        </div>
      </div>
      <div className="display-flex">
        <div style={{ width: "10%" }}>
          <Skeleton variant="text" width={"10%"} sx={{ fontSize: "1rem" }} />
        </div>
        <div style={{ width: "60%" }}>
          <Skeleton variant="rounded" width={"50%"} sx={{ fontSize: "1rem" }} />
        </div>
        <div style={{ width: "30%" }}>
          <Skeleton variant="rounded" width={"100%"} height={60} />
        </div>
      </div>
      <div className="display-flex">
        <div style={{ width: "10%" }}>
          <Skeleton variant="text" width={"10%"} sx={{ fontSize: "1rem" }} />
        </div>
        <div style={{ width: "60%" }}>
          <Skeleton variant="rounded" width={"50%"} sx={{ fontSize: "1rem" }} />
        </div>
        <div style={{ width: "30%" }}>
          <Skeleton variant="rounded" width={"100%"} height={60} />
        </div>
      </div>
      <div className="display-flex">
        <div style={{ width: "10%" }}>
          <Skeleton variant="text" width={"10%"} sx={{ fontSize: "1rem" }} />
        </div>
        <div style={{ width: "60%" }}>
          <Skeleton variant="rounded" width={"50%"} sx={{ fontSize: "1rem" }} />
        </div>
        <div style={{ width: "30%" }}>
          <Skeleton variant="rounded" width={"100%"} height={60} />
        </div>
      </div>
      <div className="display-flex">
        <div style={{ width: "10%" }}>
          <Skeleton variant="text" width={"10%"} sx={{ fontSize: "1rem" }} />
        </div>
        <div style={{ width: "60%" }}>
          <Skeleton variant="rounded" width={"50%"} sx={{ fontSize: "1rem" }} />
        </div>
        <div style={{ width: "30%" }}>
          <Skeleton variant="rounded" width={"100%"} height={60} />
        </div>
      </div>
    </Stack>
  );
}
