import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";
import Paper from "@mui/material/Paper";

import "./license-item-details.styles.scss";
import { useSelector } from "react-redux";
import { selectApplicationLicenseConsumption } from "../../../modules/App/selectors";

const LicenseItemDetails = ({ license }) => {
  const licenseDetails = { ...license };
  const applicationLicenseConsumption = useSelector(selectApplicationLicenseConsumption);
  return (
    <div className="license-details-container">
      <div>
        <TableContainer component={Paper}>
          <Table
            sx={{ minWidth: 400, backgroundColor: "lightgray" }}
            aria-label="spanning table"
          >
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: "bold" }}>Details</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>
                  Limits/Features
                </TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>Consumption</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow className="license-item-detail-row">
                <TableCell className="w-50">Plan Name</TableCell>
                <TableCell className="w-50">
                  {licenseDetails.licenseName}
                </TableCell>
                <TableCell className="w-50"></TableCell>
              </TableRow>
              <TableRow className="license-item-detail-row">
                <TableCell className="w-50">Booking Platform Charges</TableCell>
                <TableCell className="w-50">
                  {licenseDetails.bookingPlatformChargesPercentage}
                  {"%"}
                </TableCell>
                <TableCell className="w-50"></TableCell>
              </TableRow>
              <TableRow className="license-item-detail-row">
                <TableCell className="w-50">
                  Subscription Platform Charges
                </TableCell>
                <TableCell className="w-50">
                  {licenseDetails.subscriptionPlatformChargesPercentage}
                  {"%"}
                </TableCell>
                <TableCell className="w-50"></TableCell>
              </TableRow>
              <TableRow className="license-item-detail-row">
                <TableCell className="w-50">Payment Gateway Charges</TableCell>
                <TableCell className="w-50">
                  {licenseDetails.paymentGatewayChargesPercentage}
                  {"%"}
                  {"**"}
                </TableCell>
                <TableCell className="w-50"></TableCell>
              </TableRow>
              <TableRow className="license-item-detail-row">
                <TableCell className="w-50">Maximum Facilities</TableCell>
                <TableCell className="w-50">
                  {licenseDetails.maximumInstancesAllowed}
                </TableCell>
                <TableCell className="w-50">
                  {
                    applicationLicenseConsumption.numberOfCurrentApplicationInstances
                  }
                </TableCell>
              </TableRow>
              <TableRow className="license-item-detail-row">
                <TableCell className="w-50">Maximum Admin Users</TableCell>
                <TableCell className="w-50">
                  {licenseDetails.maximumAdminsAllowed}
                </TableCell>
                <TableCell className="w-50">
                  {applicationLicenseConsumption.numberOfDeptAdmins}
                </TableCell>
              </TableRow>
              <TableRow className="license-item-detail-row">
                <TableCell className="w-50">Maximum Allowed Players</TableCell>
                <TableCell className="w-50">
                  {licenseDetails.maximumAllowedPlayers}
                </TableCell>
                <TableCell className="w-50">
                  {applicationLicenseConsumption.numberOfPlayers}
                </TableCell>
              </TableRow>
              <TableRow className="license-item-detail-row">
                <TableCell className="w-50">Data Storage Validity</TableCell>
                <TableCell className="w-50">
                  {licenseDetails.numberOfYearsDataStorage}
                </TableCell>
                <TableCell className="w-50"> 2 % </TableCell>
              </TableRow>
              <TableRow className="license-item-detail-row">
                <TableCell sx={{ verticalAlign: "top" }} className="w-50">
                  Plan Features
                </TableCell>
                <TableCell className="w-50">
                  <List sx={{ padding: "0px" }}>
                    {licenseDetails.planFeatures.map((feature) => {
                      return (
                        <ListItemText>
                          {feature.licensePlanFeatureName}
                        </ListItemText>
                      );
                    })}
                  </List>
                </TableCell>
                <TableCell className="w-50"></TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

export default LicenseItemDetails;
