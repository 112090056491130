import { useState } from "react";
import ExternalProductNavigation from "./ExternalProductNavigation/external-product-navigation.component";
import ExternalProductList from "./ExternalProductList/external-product-list.component";
import GuideExternalProductOperations from "./GuideExternalProductOperations/guide-external-product-operations.component";
import AddEditExternalProduct from "./AddEditExternalProduct/addedit-external-product.component";

const newExternalProduct = {
  externalProductId: 0,
  productName: "",
  productPrice: 0,
  productDescription: "",
  productPictureUrl: "",
  isActive: true,
  isVeg: true,
};

const ExternalProductsDashboard = () => {
  const [selectedExternalProduct, setSelectedExternalProduct] = useState(null);
  const [selectedView, setSelectedView] = useState("");
  const [selectedExternalProductList, setSelectedExternalProductList] =
    useState([]);

  const handleExternalProductSelected = (product) => {
    setSelectedExternalProduct(product);
    setSelectedView("addeditview");
    setSelectedExternalProductList([product.externalProductId]);
  };

  const cancelClick = () => {
    setSelectedView("");
    setSelectedExternalProductList([]);
  };

  return (
    <div>
      <div>
        <ExternalProductNavigation
          createNewProductClick={() => {
            handleExternalProductSelected(newExternalProduct);
          }}
        ></ExternalProductNavigation>
      </div>
      <div className="display-inline-flex w-100">
        <div className="items-list-container w-50">
          <div className="items-list">
            <ExternalProductList
              selectedExternalProductList={selectedExternalProductList}
              clickSelectExternalProduct={(product) => {
                handleExternalProductSelected(product);
              }}
              cancelClick={cancelClick}
            ></ExternalProductList>
          </div>
        </div>
        <div className="items-edit-container  w-50">
          {selectedView == "" && (
            <GuideExternalProductOperations></GuideExternalProductOperations>
          )}
          {selectedView == "addeditview" && (
            <AddEditExternalProduct
              selectedExternalProduct={selectedExternalProduct}
              cancelClick={cancelClick}
            ></AddEditExternalProduct>
          )}
        </div>
      </div>
    </div>
  );
};

export default ExternalProductsDashboard;
