import React from "react";
import Card from "react-bootstrap/Card";

import "./module.scss";

const ToggleCardSwitch = (props) => {
  const { isSelected, iconOn, iconOff, title, onClick } = props;
  return (
    <div className='cursor-pointer mx-2 my-2  switch'>
      <Card onClick={onClick} className={`${isSelected ? "active_switch" : ""}`}>
        <Card.Body>{isSelected ? iconOn : iconOff}</Card.Body>
      </Card>
      <p className='mb-0 text-small'>{title}</p>
    </div>
  );
};

export default ToggleCardSwitch;
