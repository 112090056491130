import React from "react";
import { isEmpty } from "lodash";

import { URLS } from "../configs/api_urls";

import Loader from "../components/Loader";
import { getAccessToken } from "./helper";
import withRouter from "./withRouter";
import { httpGet } from "../utility/request";
import { LOGIN_PAGE } from "../configs/routes";

const withUser = (WrappedComponent) => {
  class EnhanceUser extends React.Component {
    state = {
      user_data: {},
      loading_user: true,
    };

    async componentDidMount() {
      const access = await getAccessToken();

      if (!access) {
        this.redirect_to_login();
        return;
      }

      await this.get_user_details();
    }

    redirect_to_login = async () => {
      const { router } = this.props;
      // let redirect_url = `${LOGIN_Pa}/?redirect_url=${router.pathname}`;
      await router(LOGIN_PAGE);
    };

    get_user_details = async () => {
      this.setState({ loading_user: true });

      try {
        const { data } = await httpGet(URLS.ADMIN.GET_USER_DETAILS);
        this.setState({ user_data: data, loading_user: false });
      } catch (error) {
        console.error(error);
        if (error.toJSON().status === 401) {
          console.error("invalid token");
          this.redirect_to_login();
        }
        else
        this.props.router("/error")

      }
    };

    render() {
      const { user_data, loading_user } = this.state;

      return (
        <div>
          <Loader isLoading={loading_user || isEmpty(user_data)} />
          {!loading_user && !isEmpty(user_data) && (
            <WrappedComponent user={user_data} {...this.props} />
          )}
        </div>
      );
    }
  }

  return withRouter(EnhanceUser);
};

export default withUser;
