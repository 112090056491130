import { useEffect, useState } from "react";

import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { Avatar, Box, Paper, Typography } from "@mui/material";

import { URLS } from "../../../../configs/api_urls";
import { httpPost } from "../../../../utility/request";

const AllFollowers = () => {
  const [isLoading, setIsLoading] = useState([]);
  const [followers, setFollowers] = useState([]);
  const [followersData, setFollowersData] = useState(null);
  const fetchInsatnceFollowers = async () => {
    setIsLoading(true);
    httpPost(URLS.RATING_AND_REVIEWS.GET_INSTANCE_FOLLOWERS, {
      pageNumber: 1,
      pageSize: 100,
    })
      .then(({ data }) => {
        setFollowers(data.items);
        setFollowersData(data);
      })
      .error(() => {});
  };

  useEffect(() => {
    fetchInsatnceFollowers();
  }, []);

  return (
    <Box>
      <Paper sx={{ width: "100%", mb: 2, p: 2, display: "flex" }}>
        {followers.map((user) => (
          <div style={{padding: "10px"}}>
            <Card sx={{ maxWidth: 345 }}>
              <div style={{ display: "flex" }}>
                <Avatar
                  sx={{ height: 140, width: 140 }}
                  src={user.profilePictureUrl}
                  title="green iguana"
                />
              </div>

              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  {user.userName}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {user.userName} user have subscribed to your facility profile
                  and will receive the notifications sent by you
                </Typography>
              </CardContent>
            </Card>
          </div>
        ))}
      </Paper>
    </Box>
  );
};

export default AllFollowers;
