import ButtonControl from "../../../components/Controls/ButtonControl";

const PlayersNavigation = ({
  selectedView,
  playerListClick,
  addPlayerClick,
}) => {
  return (
    <div style={{ marginBottom: "10px", display: "flex" }}>
      <div style={{ marginLeft: "auto" }}>
        {selectedView == "addplayer" && (
          <ButtonControl
            text={"Players"}
            onClick={playerListClick}
          ></ButtonControl>
        )}
        {selectedView == "list" && (
          <ButtonControl
            text={"Add Player"}
            onClick={addPlayerClick}
          ></ButtonControl>
        )}
      </div>
    </div>
  );
};

export default PlayersNavigation;
