import { Box, Link, Typography } from "@mui/material";

const Help = () => {
    return (
      <Box sx={{ display: "grid" }}>
        <Typography>
          Please raise request on <b>info@playnoot.in</b> for any kind of
          quiers. Most queries are addressed within 48 hours{" "}
        </Typography>
        <Link
          sx={{ cursor: "pointer" }}
          onClick={() =>
            (window.location.href = "http://playnoot.com/terms_and_conditions")
          }
        >
          Terms and Conditions
        </Link>
        <Link
          sx={{ cursor: "pointer" }}
          onClick={() =>
            (window.location.href = "http://playnoot.com/privacy_policy")
          }
        >
          User policies
        </Link>
        <Link
          sx={{ cursor: "pointer" }}
          onClick={() =>
            (window.location.href = "https://playnoot.com/refund_policy")
          }
        >
          Refund policies
        </Link>
      </Box>
    );
}

export default Help;