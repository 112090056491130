import { createContext, useContext } from "react";

export const UserContext = createContext(null);
export const GameCenterViewContext = createContext(null);

export const useAuth = () => {
  return useContext(UserContext);
};

export const useGameCenterContext = () => {
  return GameCenterViewContext(GameCenterViewContext);
};
