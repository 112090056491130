import * as React from "react";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";

export default function EditAssetSkeleton()
{
    return (
      <Stack spacing={1}>
        {/* For variant="text", adjust the height via font-size */}
        <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
        <Skeleton variant="rounded" height={60} />

        <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
        <Skeleton variant="rounded" height={60} />
        {/* For other variants, adjust the size with `width` and `height` */}
        <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
        <div className="display-flex">
          <Skeleton variant="circular" width={60} height={60} />
          <Skeleton variant="rounded" width={"70%"} height={60} />
          <Skeleton variant="rounded" width={60} height={60} />
        </div>
        <div className="display-flex">
          <Skeleton variant="circular" width={60} height={60} />
          <Skeleton variant="rounded" width={"70%"} height={60} />
          <Skeleton variant="rounded" width={60} height={60} />
        </div>
        <div className="display-flex">
          <Skeleton variant="circular" width={60} height={60} />
          <Skeleton variant="rounded" width={"70%"} height={60} />
          <Skeleton variant="rounded" width={60} height={60} />
        </div>
      </Stack>
    );
}