import * as React from "react";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";

import { visuallyHidden } from "@mui/utils";
import { IconButton } from "@mui/material";
import { Drawer, Chip } from "@mui/material";
import { Menu } from "@mui/material";
import { MenuItem } from "@mui/material";
import FontAwesome from "../../../../components/Icons/fontawesome";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import { Pagination } from "@mui/material";
import Controls from "../../../../components/Controls/Controls";
import TableSkeleton from "../../../../components/Skeletons/table-skeleton.component";
import BankAccountDetails from "../AccountDetails/bank-account-details.component";

import { URLS } from "../../../../configs/api_urls";
import { httpPost } from "../../../../utility/request";
import { fireEvent } from "../../../../modules/Events/eventSlice";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "adminBankDetailId",
    numeric: false,
    disablePadding: true,
    label: "Rquest Id",
  },
  {
    id: "applicationName",
    numeric: false,
    disablePadding: false,
    label: "Application Name",
  },
  {
    id: "applicationInstanceName",
    numeric: false,
    disablePadding: false,
    label: "App Instance",
  },
  {
    id: "bankName",
    numeric: false,
    disablePadding: false,
    label: "Bank Name",
  },
  {
    id: "bankAddress",
    numeric: true,
    disablePadding: false,
    label: "Bank Address",
  },
  {
    id: "status",
    numeric: true,
    disablePadding: false,
    label: "Verification Status",
  },
  {
    id: "active",
    numeric: true,
    disablePadding: false,
    label: "Active",
  },
  {
    id: "action",
    numeric: true,
    disablePadding: false,
    label: "Action",
  },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all payments",
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const AllBankingDetails = ({ selectedVerificationFilter }) => {
    const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const [bankDetailsData, setBankDetailsData] = useState({});
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [selectedForDeatils, setSelectedForDeatils] = React.useState(null);
  const [page, setPage] = React.useState(1);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [isDetailsDrawOpen, setIsDetailDrawOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const fetchData = {
    pageRequest: {
      pageNumber: page,
      pageSize: rowsPerPage,
    },
    status: selectedVerificationFilter,
  };

  const fetchAllBankingDetails = async () => {
    setIsLoading(true);
    const { data } = await httpPost(
      URLS.BANKING_DETAILS.GET_ALL_SUBMITTED,
      fetchData
    );
    setBankDetailsData(data);
    setRows(data.items);
    setIsLoading(false);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.phonepePaymentId);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, adminBankDetailId) => {
    const selectedIndex = selected.indexOf(adminBankDetailId);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, adminBankDetailId);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (rows) => {
    setRowsPerPage(parseInt(rows, 10));
    setPage(1);
  };

  const handleDetailsClick = (bankDetail) => {
    setSelectedForDeatils(bankDetail);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (adminBankDetailId) =>
    selected.indexOf(adminBankDetailId) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = 1;

  const visibleRows = React.useMemo(
    () =>
      stableSort(rows, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [order, orderBy, page, rowsPerPage]
  );

  const handleMenuClick = (event) => {
    setAnchorEl(event);
    CheckOpen(event.split("_")[1]);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const CheckOpen = (adminBankDetailId) => {
    if (anchorEl !== undefined && anchorEl !== null) {
      if (anchorEl.split("_")[1] == adminBankDetailId) return true;
    }
    return false;
  };

  const getStatusString = (status) => {
    if (status == 1) return "Submitted";
    else if (status == 2) return "Verified";
    else if (status == 3) return "Failed";
  };

  const StatusColor = {
    1: "warning",
    2: "success",
    3: "error",
  };

  const event = useSelector((onEvent) => {
    var { eventName, eventData } = onEvent.events;
    if (eventName === "update-bank-detail-status") {
     var newBankDetailsROws = rows.map((item) => item.adminBankDetailId == eventData.adminBankDetailId
     ? {...item, verificationStatus : eventData.verificationStatus} : item)
     setRows(newBankDetailsROws);
     setIsDetailDrawOpen(false);
      dispatch(fireEvent({ eventName: "" }));
    }
    //reset event listner
  });

  useEffect(() => {
    fetchAllBankingDetails();
  }, [page, rowsPerPage, selectedVerificationFilter]);

  return (
    <div>
      <Box sx={{ width: "100%" }}>
        <Paper sx={{ width: "100%", mb: 2 }}>
          <React.Fragment key={"right"}>
            <Drawer
              anchor={"right"}
              open={isDetailsDrawOpen}
              onClose={(e) => setIsDetailDrawOpen(false)}
            >
              <Box
                sx={{
                  width: "100%",
                  minWidth: 360,
                  bgcolor: "background.paper",
                  p: 2,
                  mt: 5,
                }}
              >
                <BankAccountDetails bankAccountDetails={selectedForDeatils} />
              </Box>
            </Drawer>
          </React.Fragment>
          {isLoading && (
            <TableSkeleton
              pageNumber={page}
              pageSize={rowsPerPage}
            ></TableSkeleton>
          )}
          {!isLoading && (
            <TableContainer>
              <Table
                sx={{ minWidth: 750 }}
                aria-labelledby="tableTitle"
                size={dense ? "small" : "medium"}
              >
                <EnhancedTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={rows.length}
                />
                <TableBody>
                  {rows.map((row, index) => {
                    const isItemSelected = isSelected(row.adminBankDetailId);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        hover
                        onClick={(event) =>
                          handleClick(event, row.adminBankDetailId)
                        }
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.adminBankDetailId}
                        selected={isItemSelected}
                        sx={{ cursor: "pointer" }}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            color="primary"
                            checked={isItemSelected}
                            inputProps={{
                              "aria-labelledby": labelId,
                            }}
                          />
                        </TableCell>
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                        >
                          {"#" + row.adminBankDetailId}
                        </TableCell>
                        <TableCell align="left">
                          {row.application.applicationName}
                        </TableCell>
                        <TableCell align="left">
                          {row.applicationInstance.applicationInstanceName}
                        </TableCell>
                        <TableCell align="right" padding="none">
                          {row.bankName}
                        </TableCell>
                        <TableCell align="right">{row.bankAddress}</TableCell>
                        <TableCell align="right">
                          <Chip
                            label={getStatusString(row.verificationStatus)}
                            color={StatusColor[row.verificationStatus]}
                          />
                        </TableCell>
                        <TableCell align="right">
                          <Chip
                            label={row.isActive ? "Active" : "InActive"}
                            color={row.isActive ? "success" : "warning"}
                          />
                        </TableCell>
                        <TableCell align="right">
                          <IconButton
                            id={"button_" + row.adminBankDetailId}
                            aria-label={"button_" + row.adminBankDetailId}
                            aria-controls={
                              CheckOpen(row.adminBankDetailId)
                                ? "menu_" + row.adminBankDetailId
                                : undefined
                            }
                            aria-haspopup="true"
                            aria-expanded={
                              CheckOpen(row.adminBankDetailId)
                                ? "true"
                                : undefined
                            }
                            onClick={(e) => {
                              handleMenuClick(e.currentTarget.ariaLabel);
                              e.stopPropagation();
                            }}
                          >
                            <FontAwesome
                              icon={faEllipsisV}
                              iconSize={"20px"}
                              className={"info-icon-margin"}
                            ></FontAwesome>
                          </IconButton>
                          <Menu
                            aria-label={"menu_" + row.adminBankDetailId}
                            id={"menu_" + row.adminBankDetailId}
                            anchorEl={document.getElementById(
                              "button_" + row.adminBankDetailId
                            )}
                            open={CheckOpen(row.adminBankDetailId)}
                            onClose={handleMenuClose}
                            sx={{ boxShadow: 10, color: "gray" }}
                            MenuListProps={{
                              "aria-labelledby":
                                "button_" + row.adminBankDetailId,
                            }}
                          >
                            <MenuItem
                              onClick={(e) => {
                                handleMenuClose(e);
                                handleDetailsClick(row);
                                setIsDetailDrawOpen(true);
                              }}
                            >
                              Details
                            </MenuItem>
                          </Menu>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow
                      style={{
                        height: (dense ? 33 : 53) * emptyRows,
                      }}
                    >
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          )}
          {!isLoading && (
            <div className="display-flex" style={{ padding: "10px" }}>
              <Pagination
                count={bankDetailsData.totalPages}
                page={page}
                variant="outlined"
                shape="rounded"
                showFirstButton={bankDetailsData.hasPreviousPage}
                showLastButton={bankDetailsData.hasNextPage}
                color="secondary"
                style={{ marginLeft: "auto" }}
                onChange={(event, value) => {
                  handleChangePage(value);
                }}
              />
              <Controls.SelectControl
                name="pageSize"
                style={{ width: "100%", marginLeft: "20px" }}
                options={[
                  { id: 5, title: "5" },
                  { id: 10, title: "10" },
                  { id: 50, title: "50" },
                ]}
                value={rowsPerPage}
                onChange={(e) => {
                  handleChangeRowsPerPage(Number(e.target.value));
                }}
              ></Controls.SelectControl>
            </div>
          )}
        </Paper>
      </Box>
    </div>
  );
};

export default AllBankingDetails;
