import { useState } from "react";
import { useDispatch } from "react-redux";
import { useContext } from "react";

import {
  Box,
  Paper,
  FormControl,
  TextA,
  CircularProgress,
  Typography,
} from "@mui/material";
import Controls from "../../../../components/Controls/Controls";
import ImageProcessor from "../../../../components/ImageProcessor/image-processor.component";

import { httpPost } from "../../../../utility/request";
import { URLS } from "../../../../configs/api_urls";

import { addAlert } from "../../../../modules/App/actions";
import AnnouncementList from "./announcement-list.component";

const BroadcastAnnouncement = () => {
  const defaultMessageState = {
    notificationId: 0,
    imageUrl:
      "https://playnootstorage.blob.core.windows.net/playnootadvertisementpictures/mainlogo.png",
    title: "",
    description: "",
    notificationType: 0,
  };
  const defaultMessageErrorState = {
    title: "",
    description: "",
  };
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [broadcastMessage, setBroadcastMessage] = useState(defaultMessageState);
  const [messageError, setMessageError] = useState(defaultMessageErrorState);

  const ckeckValidations = (e) => {
    const { name, value } = e.target;
    setMessageError({ ...messageError, [name]: "" });
    if (name == "title") {
      var result =
        value === ""
          ? setMessageError({ ...messageError, [name]: "Title is required" })
          : value.length <= 10
          ? setMessageError({
              ...messageError,
              [name]: "Message length too short",
            })
          : "";
    } else if (name == "description") {
      var result =
        value === ""
          ? setMessageError({
              ...messageError,
              [name]: "Description is required",
            })
          : value.length <= 10
          ? setMessageError({
              ...messageError,
              [name]: "Message Description length too short",
            })
          : "";
    } else {
      setMessageError({ ...messageError, [name]: "" });
    }
  };

  const checkFormSumbitDisabled = () => {
    if (broadcastMessage.title == "") return true;
    else if (broadcastMessage.description == "") return true;
    return false;
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    // API CAll here
    await httpPost(URLS.ANNOUNCEMENTS.SUPER_ADMIN_ANNOUNCEMENT, {
      ...broadcastMessage,
    })
      .then((response) => {
        if (response.data > 0) {
          setBroadcastMessage(defaultMessageState);
          dispatch(
            addAlert({
              type: "success",
              message: "Message sent successfully!!",
            })
          );
        }
      })
      .catch((error) => {
        dispatch(
          addAlert({
            type: "error",
            message: "Failed to send message!!",
          })
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <div>
      <Box sx={{ width: "100%" }}>
        <Paper sx={{ width: "100%", mb: 2, padding: "20px" }}>
          <div className="row">
            <div className="col-md-6">
              <div className="display-grid">
                <label className="field-title">Message Title</label>
                <FormControl>
                  <Controls.InputControl
                    name="title"
                    placeholder={"Message Title"}
                    value={broadcastMessage.title}
                    error={messageError.title}
                    style={{ width: "100%", marginTop: "5px" }}
                    isMultiLine={true}
                    maxLength={30}
                    onChange={(e) => {
                      setBroadcastMessage({
                        ...broadcastMessage,
                        title: e.target.value,
                      });
                      ckeckValidations(e);
                    }}
                  ></Controls.InputControl>
                  <Typography style={{ color: "gray" }}>
                    <i>
                      Playnoot will be appended with every notification
                    </i>
                  </Typography>
                </FormControl>
                <label className="field-title mt-20">Message Description</label>
                <FormControl>
                  <Controls.InputControl
                    name="description"
                    placeholder={"Description about the message."}
                    style={{ width: "100%", marginTop: "5px" }}
                    isMultiLine={true}
                    maxLength={100}
                    rows={3}
                    onChange={(e) => {
                      setBroadcastMessage({
                        ...broadcastMessage,
                        description: e.target.value,
                      });
                      ckeckValidations(e);
                    }}
                    value={broadcastMessage.description}
                    error={messageError.description}
                  ></Controls.InputControl>
                </FormControl>
              </div>
              <Controls.ButtonControl
                text={"Send"}
                onClick={handleSubmit}
                disabled={isLoading || checkFormSumbitDisabled()}
                innercomponent={
                  isLoading && (
                    <CircularProgress
                      size={25}
                      style={{ padding: "2px", marginLeft: "10px" }}
                      color="inherit"
                    ></CircularProgress>
                  )
                }
                style={{
                  marginTop: "20px",
                  float: "right",
                }}
              ></Controls.ButtonControl>
            </div>
            <div
              className="col-md-6"
              style={{ height: "180px", width: "200px", margin: "auto" }}
            >
              <ImageProcessor
                alt="pushnotification_image"
                style={{ height: "100%", borderRadius: "15px" }}
                imageUrl={broadcastMessage.imageUrl}
                uploadUrl={URLS.FILES.PUSHNOTIFICATION_IMAGE_UPLOAD}
                setImageData={(imageUrl) => {
                  setBroadcastMessage({
                    ...broadcastMessage,
                    imageUrl: imageUrl,
                  });
                }}
              ></ImageProcessor>
            </div>
          </div>
        </Paper>
      </Box>
      <AnnouncementList></AnnouncementList>
    </div>
  );
};

export default BroadcastAnnouncement;
