import { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import Grid from '@mui/material/Grid'; // Grid version 1
import { Chip, Stack, Typography } from '@mui/material';
import TableHead from '@mui/material/TableHead'
import MenuItem from '@mui/material/MenuItem';
import FontAwesome from '../../../../components/Icons/fontawesome';
import Menu from '@mui/material/Menu';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';

import moment from 'moment/moment';

function TablePaginationActions(props) {
  const theme = useTheme();
  const { hasNextPage, hasPreviousPage , page, rowsPerPage, setPage } = props;


  const handleBackButtonClick = (event) => {
    setPage(page - 1);
  };

  const handleNextButtonClick = (event) => {
    setPage(page + 1);
  };

  return (
    <Box sx={{ flexShrink: 0 }}>
      
      <IconButton
        onClick={handleBackButtonClick}
        disabled={hasPreviousPage === false}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={hasNextPage === false}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      
    </Box>
  );
}



function createData(booking_id, booked_by, booked_date, app_name, app_instance_name, asset_name, status, total_bill) {
    return {
      booking_id,
      booked_by,
      booked_date,
      app_name,
      app_instance_name,
      asset_name,
      status,
      total_bill
    };
}


const BookingStatus = {
    0 : '-',
    1 : 'Confirmed',
    2 : 'Cancelled',
}
const BookingStatusColor = {
  'Confirmed' : 'success',
  'Cancelled' : 'error',
  'Initiated' : 'warning',
}

const PaymentStatus = {
  1 : 'Success',
  0 : 'Failed',
}


export default function BookingsTable({
  bookings,
  page,
  rowsPerPage,
  setPage,
  setRowsPerPage,
  handleDetailsClick,
}) {
  // Avoid a layout jump when reaching the last page with empty rows.
  // const emptyRows =
  //   page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const [rows, setRows] = useState([]);
  const [isDetailsDrawOpen, setIsDetailDrawOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const [emptyRows, setEmptyRows] = useState(0);

  const handleMenuClick = (event) => {
    setAnchorEl(event);
    CheckOpen(event.split("_")[1]);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const getStatusString = (status) => 
  {
     if(status === 'PAYMENT_SUCCESS') 
     {
      return 'Success';
     } else if  (status === 'PAYMENT_PENDING') 
     {
      return 'Pending'
     }
  }

  const CheckOpen = (bookingId) => {
    if (anchorEl !== undefined && anchorEl !== null) {
      if (anchorEl.split("_")[1] == bookingId) return true;
    }
    return false;
  };

  useEffect(() => {
    let rowData = [];
    if (bookings !== null) {
      bookings.forEach((booking) => {
        rowData.push(
          createData(
            booking.bookingId,
            booking.userName,
            booking.bookingDateUtc,
            booking.applicationName,
            booking.applicationInstanceName,
            booking.assetName,
            booking.status,
            booking.totalBill
          )
        );
      });
    }
    setRows(rowData);
    //setEmptyRows(page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rowData.length) : 0);
  }, [bookings]);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  return (
    <TableContainer component={Paper}>
      <Table
        sx={{ minWidth: 500 }}
        className="scrollbar"
        style={{
          minHeight: "calc(100vh - 240px)",
          maxHeight: "calc(100vh - 240px)",
          overflowY: "scroll",
          display: "block",
        }}
        aria-label="custom pagination table"
      >
        <TableHead>
          <TableRow>
            <TableCell>
              <Typography
                variant="subtitle2"
                component="div"
                color="text.secondary"
              >
                Booking#
              </Typography>
            </TableCell>

            <TableCell>
              <Typography
                variant="subtitle2"
                component="div"
                color="text.secondary"
              >
                Booked By
              </Typography>
            </TableCell>
            <TableCell align="left">
              <Typography
                variant="subtitle2"
                component="div"
                color="text.secondary"
              >
                Booked for
              </Typography>
            </TableCell>
            <TableCell align="center">
              <Typography
                variant="subtitle2"
                component="div"
                color="text.secondary"
              >
                App Name
              </Typography>
            </TableCell>
            <TableCell align="left">
              <Typography
                variant="subtitle2"
                component="div"
                color="text.secondary"
              >
                Center Name
              </Typography>
            </TableCell>

            <TableCell align="left">
              <Typography
                variant="subtitle2"
                component="div"
                color="text.secondary"
              >
                Asset Name
              </Typography>
            </TableCell>

            <TableCell align="center">
              <Typography
                variant="subtitle2"
                component="div"
                color="text.secondary"
              >
                Status
              </Typography>
            </TableCell>

            <TableCell align="center">
              <Typography
                variant="subtitle2"
                component="div"
                color="text.secondary"
              >
                Tota Bill
              </Typography>
            </TableCell>

            <TableCell align="center">
              <Typography
                variant="subtitle2"
                component="div"
                color="text.secondary"
              >
                Actions
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.booking_id}>
              <TableCell align="left" width={50}>
                {"#" + row.booking_id}
              </TableCell>
              <TableCell align="left">{row.booked_by}</TableCell>
              <TableCell align="left">
                {moment
                  .utc(row.booked_for)
                  .local()
                  .format("DD/MM/yyyy")}
              </TableCell>
              <TableCell align="left">{row.app_name}</TableCell>
              <TableCell align="left">
                {row.app_instance_name}
              </TableCell>
              <TableCell align="center">
                <span className="text-yellow-600 font-bold">{row.asset_name}</span>
              </TableCell>
              <TableCell align="center">{row.status}</TableCell>
              <TableCell align="center">{row.total_bill}</TableCell>
              <TableCell align="right">
                <IconButton
                  id={"button_" + row.booking_id}
                  aria-label={"button_" + row.booking_id}
                  aria-controls={
                    CheckOpen(row.booking_id)
                      ? "menu_" + row.booking_id
                      : undefined
                  }
                  aria-haspopup="true"
                  aria-expanded={CheckOpen(row.booking_id) ? "true" : undefined}
                  onClick={(e) => {
                    handleMenuClick(e.currentTarget.ariaLabel);
                    //e.stopPropagation();
                  }}
                >
                  <FontAwesome
                    icon={faEllipsisV}
                    iconSize={"20px"}
                    className={"info-icon-margin"}
                  ></FontAwesome>
                </IconButton>
                <Menu
                  aria-label={"menu_" + row.booking_id}
                  id={"menu_" + row.booking_id}
                  anchorEl={document.getElementById("button_" + row.booking_id)}
                  open={CheckOpen(row.booking_id)}
                  onClose={handleMenuClose}
                  sx={{ boxShadow: 10, color: "gray" }}
                  MenuListProps={{
                    "aria-labelledby": "button_" + row.booking_id,
                  }}
                >
                  <MenuItem
                    onClick={(e) => {
                      handleMenuClose(e);
                      handleDetailsClick(row);
                    }}
                  >
                    Details
                  </MenuItem>
                </Menu>
              </TableCell>
            </TableRow>
          ))}

          {emptyRows > 0 && (
            <TableRow style={{ height: 53 * emptyRows }}>
              <TableCell colSpan={6} />
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}