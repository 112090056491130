import { useState, useEffect } from "react";

import { Card } from "@mui/material";
import InputControl from "../../../components/Controls/InputControl";

const ContactDetails = ({ newCenterDetails, updateValues }) => {
  const [localCenterState, setLocalCenterState] = useState(newCenterDetails);

  const onChangeInput = ({ currentTarget }) => {
    var name = currentTarget.name;
    var value = currentTarget.value;
    updateValues({ ...localCenterState, [name]: value });
  };

  useEffect(() => {
    setLocalCenterState(newCenterDetails);
  }, [newCenterDetails]);

  return (
    <div>
      <Card sx={{ maxWidth: "500px", margin: "auto" }}>
        <div className="mt-20">
          <label className="field-title">Contact Phone Number</label>
          <InputControl
            name="contactPhoneNumber"
            other={{ placeholder: "Enter Mobile Number" }}
            style={{ width: "100%", marginTop: "5px" }}
            onChange={onChangeInput}
            value={localCenterState.contactPhoneNumber}
            //error={validation.planName}
          ></InputControl>
        </div>
        <div className="mt-20">
          <label className="field-title">Contact Email Id</label>
          <InputControl
            name="contactEmailId"
            other={{ placeholder: "Enter Email Id" }}
            style={{ width: "100%", marginTop: "5px" }}
            onChange={onChangeInput}
            value={localCenterState.contactEmailId}
            //error={validation.planName}
          ></InputControl>
        </div>
        <div className="mt-20">
          <label className="field-title">Website Url</label>
          <InputControl
            name="webSiteLink"
            other={{ placeholder: "Enter Your Website Url" }}
            style={{ width: "100%", marginTop: "5px" }}
            onChange={onChangeInput}
            value={localCenterState.webSiteLink}
            //error={validation.planName}
          ></InputControl>
        </div>
      </Card>
    </div>
  );
};

export default ContactDetails;
