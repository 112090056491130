import * as React from "react";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";

const RatingReviewDetailsSkeleton = () => {
  return (
    <Stack spacing={1}>
      <div>
        <div className="row">
          <div className="col-md-4">
            <div className="rating-detail-section">
              <Skeleton variant="rectangular" />
            </div>
            <div className="rating-detail-section">
              <Skeleton variant="rectangular" />
            </div>
            <div className="rating-detail-section">
              <Skeleton variant="rectangular" />
            </div>
            <div className="rating-detail-section">
              <Skeleton variant="rectangular" />
            </div>
          </div>
          <div className="col-md-4">
            <div className="rating-detail-section">
              <Skeleton variant="rectangular" />
            </div>
            <div className="rating-detail-section">
              <Skeleton variant="rectangular" />
            </div>
            <div className="rating-detail-section">
              <Skeleton variant="rectangular" />
            </div>
            <div className="rating-detail-section">
              <Skeleton variant="rectangular" />
            </div>
          </div>
          <div className="col-md-4">
            <div className="rating-detail-section">
              <Skeleton variant="rectangular" />
            </div>
            <div className="rating-detail-section">
              <Skeleton variant="rectangular" />
            </div>
            <div className="rating-detail-section">
              <Skeleton variant="rectangular" />
            </div>
            <div className="rating-detail-section">
              <Skeleton variant="rectangular" />
            </div>
          </div>
        </div>
      </div>
    </Stack>
  );
};

export default RatingReviewDetailsSkeleton;
