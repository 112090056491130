import { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableSkeleton from "../../../components/Skeletons/table-skeleton.component";
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid'; // Grid version 1
import { Chip, Stack, Typography } from '@mui/material';
import TableHead from '@mui/material/TableHead'
import MenuItem from '@mui/material/MenuItem';
import FontAwesome from '../../../components/Icons/fontawesome';
import Menu from '@mui/material/Menu';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';

import moment from 'moment/moment';


function createData(booking_id, booked_by, booked_for, assetName, booked_slots, created_on, add_ord, payment_amt, payment_mode, payment_status, booking_status) {
    return { booking_id, booked_by, booked_for, assetName, booked_slots, created_on, add_ord, payment_amt, payment_mode, payment_status, booking_status };
}

const BookingStatusColor = {
  'Confirmed' : 'success',
  'Cancelled' : 'error',
  'Initiated' : 'warning',
}

export default function BookingsTable({
  bookings,
  isLoading,
  handleDetailsClick,
}) {
  // Avoid a layout jump when reaching the last page with empty rows.
  // const emptyRows =
  //   page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const [rows, setRows] = useState([]);
  const [isDetailsDrawOpen, setIsDetailDrawOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const [emptyRows, setEmptyRows] = useState(0);

  const handleMenuClick = (event) => {
    setAnchorEl(event);
    CheckOpen(event.split("_")[1]);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const getStatusString = (status) => 
  {
     if(status === 'PAYMENT_SUCCESS') 
     {
      return 'Success';
     } else if  (status === 'PAYMENT_PENDING') 
     {
      return 'Pending'
     }
  }

  const CheckOpen = (bookingId) => {
    if (anchorEl !== undefined && anchorEl !== null) {
      if (anchorEl.split("_")[1] == bookingId) return true;
    }
    return false;
  };

  useEffect(() => {
    let rowData = [];
    if (bookings && bookings.items) {
      bookings.items.forEach((booking) => {
        rowData.push(
          createData(
            booking.bookingId,
            booking.userName,
            booking.bookingDate,
            booking.assetName,
            booking.timeSlots.map((slot) => slot.slotDisplayText),
            booking.createdOn,
            booking.externalProductsOrderCount,
            booking.paymentAmount,
            booking.paymentMode != null ? booking.paymentMode : "-",
            booking.paymentStatus != null ? booking.paymentStatus : "-",
            booking.status
          )
        );
      });
    }
    setRows(rowData);
    //setEmptyRows(page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rowData.length) : 0);
  }, [bookings]);

  return (
    <Box>
      {isLoading && (
        <TableSkeleton pageNumber={1} pageSize={10}></TableSkeleton>
      )}
      <TableContainer component={Paper}>
        <Table
          sx={{ minWidth: 500 }}
          className="scrollbar"
          style={{
            minHeight: "calc(100vh - 240px)",
            maxHeight: "calc(100vh - 240px)",
            overflowY: "scroll",
            display: "block",
          }}
          aria-label="custom pagination table"
        >
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography
                  variant="subtitle2"
                  component="div"
                  color="text.secondary"
                >
                  Booking#
                </Typography>
              </TableCell>

              <TableCell>
                <Typography
                  variant="subtitle2"
                  component="div"
                  color="text.secondary"
                >
                  Booked By
                </Typography>
              </TableCell>
              <TableCell align="left">
                <Typography
                  variant="subtitle2"
                  component="div"
                  color="text.secondary"
                >
                  Booked for
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography
                  variant="subtitle2"
                  component="div"
                  color="text.secondary"
                >
                  Asset Name
                </Typography>
              </TableCell>
              <TableCell align="left">
                <Typography
                  variant="subtitle2"
                  component="div"
                  color="text.secondary"
                >
                  Booked Slots
                </Typography>
              </TableCell>

              <TableCell align="left">
                <Typography
                  variant="subtitle2"
                  component="div"
                  color="text.secondary"
                >
                  Booked on
                </Typography>
              </TableCell>

              <TableCell align="center">
                <Typography
                  variant="subtitle2"
                  component="div"
                  color="text.secondary"
                >
                  Additional Orders
                </Typography>
              </TableCell>

              <TableCell align="center">
                <Typography
                  variant="subtitle2"
                  component="div"
                  color="text.secondary"
                >
                  Payment Amount
                </Typography>
              </TableCell>

              <TableCell align="center">
                <Typography
                  variant="subtitle2"
                  component="div"
                  color="text.secondary"
                >
                  Payment Mode
                </Typography>
              </TableCell>

              <TableCell align="center">
                <Typography
                  variant="subtitle2"
                  component="div"
                  color="text.secondary"
                >
                  Payment Status
                </Typography>
              </TableCell>

              <TableCell align="center">
                <Typography
                  variant="subtitle2"
                  component="div"
                  color="text.secondary"
                >
                  Booking Status
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography
                  variant="subtitle2"
                  component="div"
                  color="text.secondary"
                >
                  Actions
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow key={row.booking_id}>
                <TableCell align="left" width={50}>
                  {"#" + row.booking_id}
                </TableCell>
                <TableCell align="left">{row.booked_by}</TableCell>
                <TableCell align="left">
                  {moment.utc(row.booked_for).local().format("DD/MM/yyyy")}
                </TableCell>
                <TableCell align="left">{row.assetName}</TableCell>
                <TableCell align="left" width={350}>
                  <Grid container columns={1}>
                    {row.booked_slots.map((slot) => (
                      <Grid xs={"auto"} m={1}>
                        <Chip label={slot} />
                      </Grid>
                    ))}
                  </Grid>
                </TableCell>
                <TableCell align="left">
                  {moment
                    .utc(row.created_on)
                    .local()
                    .format("DD/MM/yyyy, hh:mm A")}
                </TableCell>
                <TableCell align="center">
                  <span className="text-yellow-600 font-bold">
                    {row.add_ord}
                  </span>
                </TableCell>
                <TableCell align="center">{row.payment_amt}</TableCell>
                <TableCell align="center">{row.payment_mode}</TableCell>
                <TableCell align="center">
                  <span
                    className={`${
                      row.payment_status === "PAYMENT_SUCCESS"
                        ? "text-green-600"
                        : "text-red-600"
                    } 
                    font-bold`}
                  >
                    {getStatusString(row.payment_status)}
                  </span>
                </TableCell>
                <TableCell align="center">
                  {row.booking_status && (
                    <Chip
                      label={row.booking_status}
                      color={`${
                        BookingStatusColor[row.booking_status]
                          ? BookingStatusColor[row.booking_status]
                          : "default"
                      }`}
                    />
                  )}
                </TableCell>
                <TableCell align="right">
                  <IconButton
                    id={"button_" + row.booking_id}
                    aria-label={"button_" + row.booking_id}
                    aria-controls={
                      CheckOpen(row.booking_id)
                        ? "menu_" + row.booking_id
                        : undefined
                    }
                    aria-haspopup="true"
                    aria-expanded={
                      CheckOpen(row.booking_id) ? "true" : undefined
                    }
                    onClick={(e) => {
                      handleMenuClick(e.currentTarget.ariaLabel);
                      //e.stopPropagation();
                    }}
                  >
                    <FontAwesome
                      icon={faEllipsisV}
                      iconSize={"20px"}
                      className={"info-icon-margin"}
                    ></FontAwesome>
                  </IconButton>
                  <Menu
                    aria-label={"menu_" + row.booking_id}
                    id={"menu_" + row.booking_id}
                    anchorEl={document.getElementById(
                      "button_" + row.booking_id
                    )}
                    open={CheckOpen(row.booking_id)}
                    onClose={handleMenuClose}
                    sx={{ boxShadow: 10, color: "gray" }}
                    MenuListProps={{
                      "aria-labelledby": "button_" + row.booking_id,
                    }}
                  >
                    <MenuItem
                      onClick={(e) => {
                        handleMenuClose(e);
                        handleDetailsClick(row);
                      }}
                    >
                      Details
                    </MenuItem>
                  </Menu>
                </TableCell>
              </TableRow>
            ))}

            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}