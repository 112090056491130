import { store } from "../../store/make-store";
import { SubModule }  from "../Constants/SubModules";

import { clearItemFromCart, setIsPaymentScreenOpen } from "../../modules/cart/cart.action";
import { setIsRenderedState } from "../../modules/ModalPopup/modalPopupSlice";
import { setSignalRPayload } from "../../modules/SignalR/baseSignalR.actions";
import { fetchAppInformation } from "../../modules/App/actions";

const PaymentSignalRHandlers = (signalrResponse) => {
  const state = store.getState();

  switch (signalrResponse.SubModule) {
    case SubModule.Create:
      // hide payment component
      store.dispatch(setIsPaymentScreenOpen(false));

      //set SignalR Payload received
      store.dispatch(setSignalRPayload(signalrResponse));

      if (signalrResponse.isSuccess) {
        // delete cart items
        const itemsToDelete = state.cart.cartItems.filter((item) =>
          signalrResponse.SelectedIds.includes(item.adminCartId)
        );

        if (itemsToDelete !== null) {
          itemsToDelete.forEach((item) =>
            store.dispatch(clearItemFromCart(state.cart.cartItems, item))
          );
        }
      }

      //show completed payment Checkout popup
      store.dispatch(setIsRenderedState(true));
      store.dispatch(fetchAppInformation());
      break;
  }

};

export default PaymentSignalRHandlers;
