import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  Box,
  Card,
  CardContent,
  CardActions,
  IconButton,
  Typography,
  CircularProgress,
} from "@mui/material";
import {
  PlayArrowRounded,
  PauseRounded,
  StopRounded,
  EditRounded,
} from "@mui/icons-material";

import moment from "moment-timezone";
import { httpPatch } from "../../../../utility/request";
import { URLS } from "../../../../configs/api_urls";
import { addAlert } from "../../../../modules/App/actions";

const GameSessionItem = ({ session, registerTimer, pauseTimer, setSelectedStop }) => {
  const dispatch = useDispatch();
  const [gameSession, setGameSession] = useState(session);
  const [isLoading, setIsLoading] = useState(false);
  const [timerId, setTimerId] = useState(0);

  const setTimer = (startTime) => {
    var sessionTimerId = setInterval(() => {
      var now = new Date();
      var timenow = new Date(
        now.getUTCFullYear(),
        now.getUTCMonth(),
        now.getUTCDate(),
        now.getUTCHours(),
        now.getUTCMinutes(),
        now.getUTCSeconds(),
        now.getUTCMilliseconds()
      ).getTime();
      var time = timenow - startTime;
      var seconds = Math.floor((time / 1000) % 60).toString(),
        minutes = Math.floor((time / (1000 * 60)) % 60).toString(),
        hours = Math.floor((time / (1000 * 60 * 60))).toString();
      if (hours.length < 2) {
        hours = "0" + hours;
      }

      if (minutes.length < 2) {
        minutes = "0" + minutes;
      }

      if (seconds.length < 2) {
        seconds = "0" + seconds;
      }
      var clockStr = hours + " : " + minutes + " : " + seconds;

      const timer = document.getElementById(
        "timer_" + gameSession.gameSessionId
      );
      timer.innerHTML = clockStr;
      //    console.log("Timer " + sessionTimerId);
    }, 1000);
    //  console.log("New timer created with id " + sessionTimerId);

    // register timer in parent component so that it can be cleared on unloading
    registerTimer(sessionTimerId, gameSession.gameSessionId);
    setTimerId(sessionTimerId);
  };

  const setPausedTimer = () => {
    var timer;
    var sessionStartTime = new Date(gameSession.startTime).getTime();
    var sessionPausedTime = Number(gameSession.elapsedTime);
    var time = sessionPausedTime - sessionStartTime;
    var seconds = Math.floor((time / 1000) % 60).toString(),
      minutes = Math.floor((time / (1000 * 60)) % 60).toString(),
      hours = Math.floor((time / (1000 * 60 * 60))).toString();
    if (hours.length < 2) {
      hours = "0" + hours;
    }

    if (minutes.length < 2) {
      minutes = "0" + minutes;
    }

    if (seconds.length < 2) {
      seconds = "0" + seconds;
    }
    var clockStr = hours + " : " + minutes + " : " + seconds;
    setTimeout(() => {
      timer = document.getElementById("timer_" + gameSession.gameSessionId);
      timer.innerHTML = clockStr;
    }, 100);
  };

  const pauseSession = async () => {
    setIsLoading(true);
    var currentTime = new Date().toISOString().replace("Z", "");
    var elapsedTime = new Date(currentTime).getTime().toString();
    await httpPatch(
      URLS.GAME_SESSIONS.PAUSE_RESUME_GAME_SESSION.replace(
        "#GAME_SESSIONID#",
        gameSession.gameSessionId
      )
        .replace("#ELAPSED_TIME#", elapsedTime)
        .replace("#FLAG#", true)
        .replace("#START_TIME#", gameSession.startTime),
      {}
    )
      .then(({ data }) => {
        dispatch(
          addAlert({
            type: "success",
            message: "Session Paused !!",
          })
        );
        pauseTimer(timerId);
        setGameSession({
          ...gameSession,
          isPaused: true,
          elapsedTime: elapsedTime,
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const resumeSession = async () => {
    setIsLoading(true);
    var currentTime = new Date().toISOString().replace("Z", "");
    var elapsedTime = new Date(currentTime).getTime().toString();
    var newstartTime =
      new Date(gameSession.startTime).getTime() +
      Number(elapsedTime) -
      Number(gameSession.elapsedTime);

      var dateToSave = new Date(newstartTime);
      const offsetMs = dateToSave.getTimezoneOffset() * 60 * 1000;
      const msLocal = dateToSave.getTime() - offsetMs;
      const dateLocal = new Date(msLocal);
      const iso = dateLocal.toISOString();
      
    await httpPatch(
      URLS.GAME_SESSIONS.PAUSE_RESUME_GAME_SESSION.replace(
        "#GAME_SESSIONID#",
        gameSession.gameSessionId
      )
        .replace("#ELAPSED_TIME#", 0)
        .replace("#FLAG#", false)
        .replace("#START_TIME#", iso),
      {}
    )
      .then(({ data }) => {
        dispatch(
          addAlert({
            type: "success",
            message: "Session resumed !!",
          })
        );
        setTimer(newstartTime);
        setGameSession({ ...gameSession, isPaused: false });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    //setTimer();
    if (gameSession.isPaused) {
      setPausedTimer();
    } else {
      setTimer(new Date(gameSession.startTime));
    }
  }, []);

  return (
    <Card>
      <CardContent>
        <Box>
          <Typography>
            <i>Elapsed Time</i>
          </Typography>
          <Box className="display-flex">
            <Typography
              variant="h4"
              id={"timer_" + gameSession.gameSessionId}
            >00 : 00 : 00</Typography>
            <Typography
              variant="h5"
              className="ml-auto"
              id={"gameSessionId"}
            >#{gameSession.gameSessionId}</Typography>
          </Box>
          <Box sx={{ mt: 2 }}>
            <Typography>
              Asset : <b>{gameSession.asset.assetName}</b>
            </Typography>
            <Typography>Comment : {gameSession.comment}</Typography>
            <Typography>
              Started Date Time :{" "}
              {moment
                .utc(gameSession.originalStartTime)
                .local()
                .format("MMM DD, yyyy, ")}
              <b>
                {moment
                  .utc(gameSession.originalStartTime)
                  .local()
                  .format("hh:mm A")}
              </b>
            </Typography>
          </Box>
        </Box>
      </CardContent>
      <CardActions>
        {isLoading && <CircularProgress size={30} />}
        <Box className="ml-auto">
          {gameSession.isPaused === true ? (
            <IconButton aria-label="play/pause" onClick={() => resumeSession()}>
              <PlayArrowRounded
                sx={{ height: 38, width: 38, color: "green" }}
              />
            </IconButton>
          ) : (
            <IconButton aria-label="play/pause" onClick={() => pauseSession()}>
              <PauseRounded sx={{ height: 38, width: 38, color: "#dfb11f" }} />
            </IconButton>
          )}
          <IconButton aria-label="play/pause" onClick={() => setSelectedStop(gameSession)}>
            <StopRounded sx={{ height: 38, width: 38, color: "red" }} />
          </IconButton>
        </Box>
      </CardActions>
    </Card>
  );
};

export default GameSessionItem;
{
}
