import { useState } from "react";

import { Box, Paper } from "@mui/material";
import Controls from "../../../../components/Controls/Controls";
import CircularProgress from "@mui/material/CircularProgress";

import { URLS } from "../../../../configs/api_urls";
import { httpPost } from "../../../../utility/request";

var newApp = {
  applicationName: "",
  applicationUser: {
    emailId: "",
    password: "",
    firstName: "",
    middleName: "",
    lastName: "",
    gender: "",
    dateOfBirth: "",
    primaryPhoneNumber: "",
    secondaryPhoneNumber: "",
    profilePictureUrl: "",
    applicationType: "2",
  },
};

var newAppError = {
  applicationName: "",
  applicationUser: {
    emailId: "",
    password: "",
    firstName: "",
    middleName: "",
    lastName: "",
    gender: "",
    dateOfBirth: "",
    primaryPhoneNumber: "",
    secondaryPhoneNumber: "",
    profilePictureUrl: "",
    applicationType: "2",
  },
};

const userGenderOptions = [
  { id: "Male", title: "Male" },
  { id: "Female", title: "Female" },
];

const regEx =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const CreateApplication = ({createSuccessCallBack}) => {
  const [appInfo, setAppInfo] = useState(newApp);
  const [appInfoErrors, setAppInfoErrors] = useState(newAppError);
  const [isLoading, setIsLoading] = useState(false);

  const createApplication = async () => {
    setIsLoading(true);
    await httpPost(URLS.APPLICATION.CREATE_APPLICATION, {
      ...appInfo,
    })
      .then((response) => {
        if (response.data == true) {
          createSuccessCallBack();
        }
      })
      .catch((error) => {
        debugger;
        alert("Some error occurerd")
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onInputAppName = (e) => {
    const { name, value } = e.target;
    appValidationHandler({ name, value });
    setAppInfo({ ...appInfo, [name]: value });
  };

  const appValidationHandler = ({ name, value }) => {
    setAppInfoErrors({ ...appInfoErrors, [name]: "" });
    if (value === "") {
      setAppInfoErrors({
        ...appInfoErrors,
        [name]: "App name is required",
      });
    } else if (value.length <= 8) {
      setAppInfoErrors({
        ...appInfoErrors,
        [name]: "App name must be grater than 8 charcters",
      });
    } else {
      setAppInfoErrors({ ...appInfoErrors, [name]: "" });
    }
  };

  const onInputUserDetails = (e) => {
    const { name, value } = e.target;
    userDetailsValidationHandler({ name, value });
    setAppInfo({
      ...appInfo,
      applicationUser: { ...appInfo.applicationUser, [name]: value },
    });
  };

  const userDetailsValidationHandler = ({ name, value }) => {
    setAppInfoErrors({
      ...appInfoErrors,
      applicationUser: {
        ...appInfoErrors.applicationUser,
        [name]: "",
      },
    });
    if (name === "firstName") {
      if (value === "") {
        setAppInfoErrors({
          ...appInfoErrors,
          applicationUser: {
            ...appInfoErrors.applicationUser,
            [name]: "First Name is required",
          },
        });
      }
    } else if (name === "lastName") {
      if (value === "") {
        setAppInfoErrors({
          ...appInfoErrors,
          applicationUser: {
            ...appInfoErrors.applicationUser,
            [name]: "Last Name is required",
          },
        });
      }
    } else if (name === "emailId") {
      if (value === "") {
        setAppInfoErrors({
          ...appInfoErrors,
          applicationUser: {
            ...appInfoErrors.applicationUser,
            [name]: "Email is required",
          },
        });
      } else {
        var valid = regEx.test(value);
        if (!valid) {
            setAppInfoErrors({
              ...appInfoErrors,
              applicationUser: {
                ...appInfoErrors.applicationUser,
                [name]: "Email is not valid",
              },
            });
        }
      }
    } else {
      setAppInfoErrors({
        ...appInfoErrors,
        applicationUser: {
          ...appInfoErrors.applicationUser,
          [name]: "",
        },
      });
    }
  };

  return (
    <div>
      <Box sx={{ width: "100%" }}>
        <Paper
          sx={{
            width: "100%",
            mb: 2,
            padding: "15px",
          }}
        >
          <div>
            <Controls.InputControl
              name="applicationName"
              label={"Application Name"}
              style={{ width: "100%", marginTop: "5px" }}
              onChange={onInputAppName}
              value={appInfo.applicationName}
              error={appInfoErrors.applicationName}
            ></Controls.InputControl>
          </div>
          <div className="mt-20 row">
            <div className="col-md-4">
              <Controls.InputControl
                name="firstName"
                label={"First Name"}
                style={{ width: "100%", marginTop: "5px" }}
                onChange={onInputUserDetails}
                value={appInfo.applicationUser.firstName}
                error={appInfoErrors.applicationUser.firstName}
              ></Controls.InputControl>
            </div>
            <div className="col-md-4">
              <Controls.InputControl
                name="middleName"
                label={"Middle Name"}
                style={{ width: "100%", marginTop: "5px" }}
                onChange={onInputUserDetails}
                value={appInfo.applicationUser.middleName}
                error={appInfoErrors.applicationUser.middleName}
              ></Controls.InputControl>
            </div>
            <div className="col-md-4">
              <Controls.InputControl
                name="lastName"
                label={"Last Name"}
                style={{ width: "100%", marginTop: "5px" }}
                onChange={onInputUserDetails}
                value={appInfo.applicationUser.lastName}
                error={appInfoErrors.applicationUser.lastName}
              ></Controls.InputControl>
            </div>
          </div>
          <div className="row mt-20">
            <div className="col-md-6 display-grid">
              <Controls.SelectControl
                name="gender"
                style={{ minWidth: "100%", marginTop: "5px" }}
                options={userGenderOptions}
                value={appInfo.applicationUser.gender}
                onChange={onInputUserDetails}
              ></Controls.SelectControl>
            </div>
            <div className="col-md-6 display-grid">
              <Controls.DatePickerControl
                id={"dateOfBirth"}
                name="dateOfBirth"
                value={appInfo.applicationUser.dateOfBirth}
                style={{ width: "100%", marginTop: "10px" }}
                onChange={(date) =>
                  onInputUserDetails({
                    target: { name: "dateOfBirth", value: date },
                  })
                }
              ></Controls.DatePickerControl>
            </div>
          </div>
          <div className="row mt-20">
            <div className="col-md-4">
              <Controls.InputControl
                name="emailId"
                label={"Email Id"}
                style={{ width: "100%", marginTop: "5px" }}
                onChange={onInputUserDetails}
                value={appInfo.applicationUser.emailId}
                error={appInfoErrors.applicationUser.emailId}
              ></Controls.InputControl>
            </div>
            <div className="col-md-4">
              <Controls.InputControl
                name="primaryPhoneNumber"
                label={"Primary Phone Number"}
                style={{ width: "100%", marginTop: "5px" }}
                onChange={onInputUserDetails}
                value={appInfo.applicationUser.primaryPhoneNumber}
                error={appInfoErrors.applicationUser.primaryPhoneNumber}
              ></Controls.InputControl>
            </div>
            <div className="col-md-4">
              <Controls.InputControl
                name="secondaryPhoneNumber"
                label={"Secondary Phone Number"}
                style={{ width: "100%", marginTop: "5px" }}
                onChange={onInputUserDetails}
                value={appInfo.applicationUser.secondaryPhoneNumber}
                error={appInfoErrors.applicationUser.secondaryPhoneNumber}
              ></Controls.InputControl>
            </div>
          </div>
          <div className="mt-20 row">
            <div className="col-md-4">
              <Controls.InputControl
                name="password"
                other={{ type: "password", placeholder: "Password" }}
                style={{ width: "100%", marginTop: "5px" }}
                label={"Password"}
                onChange={onInputUserDetails}
                value={appInfo.applicationUser.password}
                error={appInfoErrors.applicationUser.password}
              ></Controls.InputControl>
            </div>
            <div className="col-md-4"></div>
            <div className="col-md-4 text-align-right">
              <Controls.ButtonControl
                text={"Submit"}
                style={{
                  width: "100px",
                  margin: "10px 10px",
                  backgroundColor: "playnootPrimary",
                }}
                onClick={() => createApplication()}
                innercomponent={
                  isLoading && (
                    <CircularProgress
                      size={25}
                      style={{ padding: "2px", marginLeft: "10px" }}
                      color="inherit"
                    ></CircularProgress>
                  )
                }
                //color={grey[700]}
              ></Controls.ButtonControl>
            </div>
          </div>
        </Paper>
      </Box>
    </div>
  );
};

export default CreateApplication;
