import { useState, useContext, useEffect } from "react";

import FontAwesome from "../../../components/Icons/fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { ProgressBar } from "react-bootstrap";
import { Rating } from "@mui/material";
import { GameCenterViewContext } from "../../../utility/context";

import { URLS } from "../../../configs/api_urls";
import { httpGet } from "../../../utility/request";

import "./rating-review-details.styles.scss";
import RatingReviewDetailsSkeleton from "../Skeletons/rating-review-details-skeleton.component";

const RatingReviewDetails = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [ratingReviewDetails, setRatingReviewDetails] = useState(null);

  const gameCenterDetails = useContext(GameCenterViewContext);

  const fetchRatingAndReviewDetails = async () => {
    setIsLoading(true);
    const { data } = await httpGet(
      URLS.RATING_AND_REVIEWS.GET_APPLICATIONINSTANCE_RATINGREVIEWS_DETAILS.replace(
        "#ID#",
        gameCenterDetails.GameCenter.applicationInstanceId
      )
    );
    setRatingReviewDetails(data);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchRatingAndReviewDetails();
  }, []);
  return (
    <div style={{ background: "white", padding: "30px", borderRadius: "15px" }}>
      {isLoading && <RatingReviewDetailsSkeleton></RatingReviewDetailsSkeleton>}
      {!isLoading && ratingReviewDetails == "" && (
        <div>
          <span className="font-italic text-sub-info">
            No player has published any ratings and reviews yet. Incourage
            visitors of your facility to publish their rating and reviews about
            the service on the Playnoot app.
          </span>
        </div>
      )}
      {!isLoading && ratingReviewDetails && (
        <div className="row">
          <div className="col-md-4 rating-detail-section">
            <h3>Total Reviews</h3>
            <span className="rating-detail-largetext">
              {ratingReviewDetails.totalReviews}
            </span>
          </div>
          <div className="col-md-4 avg-rating-divider rating-detail-section">
            <h3>Average Rating</h3>
            <span className="rating-detail-largetext">
              {ratingReviewDetails.averageRating.toFixed(1)}
            </span>
            <Rating
              name="read-only"
              precision={0.5}
              size="large"
              value={ratingReviewDetails.averageRating}
              readOnly
            />
          </div>
          <div className="col-md-4 rating-detail-section">
            <div className="my-3 px-2">
              <div className="row align-items-center">
                <div className="col-md-2 display-flex">
                  <span className="mx-2">5</span>
                  <FontAwesome iconcolor={"#ffc107"} icon={faStar} />
                </div>
                <div className="col-md-10">
                  <ProgressBar
                    style={{ height: "10px" }}
                    now={
                      ratingReviewDetails.ratingBreakdown[0]
                        .ratingScorePercentage
                    }
                    variant="success"
                  />
                </div>
              </div>

              <div className="row align-items-center">
                <div className="col-md-2">
                  <div className="display-flex">
                    <span className="mx-2">4</span>
                    <FontAwesome iconcolor={"#ffc107"} icon={faStar} />
                  </div>
                </div>
                <div className="col-md-10">
                  <ProgressBar
                    style={{ height: "10px" }}
                    now={
                      ratingReviewDetails.ratingBreakdown[1]
                        .ratingScorePercentage
                    }
                    variant="primary"
                  />
                </div>
              </div>

              <div className="row align-items-center">
                <div className="col-md-2">
                  <div className="display-flex">
                    <span className="mx-2">3</span>
                    <FontAwesome iconcolor={"#ffc107"} icon={faStar} />
                  </div>
                </div>
                <div className="col-md-10">
                  <ProgressBar
                    style={{ height: "10px" }}
                    now={
                      ratingReviewDetails.ratingBreakdown[2]
                        .ratingScorePercentage
                    }
                    variant="warning"
                  />
                </div>
              </div>

              <div className="row align-items-center">
                <div className="col-md-2">
                  <div className="display-flex">
                    <span className="mx-2">2</span>
                    <FontAwesome iconcolor={"#ffc107"} icon={faStar} />
                  </div>
                </div>
                <div className="col-md-10">
                  <ProgressBar
                    style={{ height: "10px" }}
                    now={
                      ratingReviewDetails.ratingBreakdown[3]
                        .ratingScorePercentage
                    }
                    variant="danger"
                  />
                </div>
              </div>

              <div className="row align-items-center">
                <div className="col-md-2">
                  <div className="display-flex">
                    <span className="mx-2">1</span>
                    <FontAwesome iconcolor={"#ffc107"} icon={faStar} />
                  </div>
                </div>
                <div className="col-md-10">
                  <ProgressBar
                    style={{ height: "10px" }}
                    now={
                      ratingReviewDetails.ratingBreakdown[4]
                        .ratingScorePercentage
                    }
                    variant="secondary"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default RatingReviewDetails;
