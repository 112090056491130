import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Controls from "../../../../../components/Controls/Controls";
import RichEditor from "../../../../../components/RichTextEditor";
import { FormControl, Checkbox } from "@mui/material";

import { URLS } from "../../../../../configs/api_urls";
import { httpGet, httpPost, httpPut } from "../../../../../utility/request";
import { fireEvent } from "../../../../../modules/Events/eventSlice";
import EditAssetOfferSkeleton from "../../../Skeletons/edit-asset-offer-skeleton.component";

const assetOfferTypeItems = [
  { id: 1, title: "Discount" },
  { id: 2, title: "Price Off" },
];

const EditAssetOffer = ({ selectedAssetOffer, closeEdit }) => {
  const dispatch = useDispatch();
  const [assetOffer, setAssetOffer] = useState(selectedAssetOffer);
  const [isLoadingLinkedAsset, setIsLoadingLinkedAsset] = useState(true);
  const [linkedAssets, setLinkedAssets] = useState([]);

  const event = useSelector((onEvent) => {
    var eventName = onEvent.events.eventName;
    if (eventName === "save-asset-offer") {
      if (assetOffer.assetOfferId > 0) {
        updateAssetOffer();
      } else {
        createAssetOffer();
      }

      dispatch(fireEvent({ eventName: "" }));
    }
    //reset event listner
  });

  const getAssetLinkedPlans = async () => {
    setIsLoadingLinkedAsset(true);
    await httpGet(
      URLS.ASSET.GET_ASSET_LINKED_OFFER_LIST.replace(
        "#ID#",
        selectedAssetOffer.assetOfferId
      )
    )
      .then((response) => {
        setLinkedAssets(response.data);
      })
      .catch((error) => {
        //  setFormSubmitError(
        //    error.response.data.Message || "Some error occuered!"
        //  );
      })
      .finally(() => {
        setIsLoadingLinkedAsset(false);
      });
  };

  const updateAssetOffer = async () => {
    // API CAll here
    await httpPut(URLS.ASSET.UPDATE_ASSET_OFFER, {
      ...assetOffer,
      linkedAssets: linkedAssets,
    })
      .then((response) => {
        if (response.data == true) {
          dispatch(fireEvent({ eventName: "refresh-asset-offer-list" }));
        }
      })
      .catch((error) => {
        //  setFormSubmitError(
        //    error.response.data.Message || "Some error occuered!"
        //  );
      })
      .finally(() => {
        closeEdit();
      });
  };

  const createAssetOffer = async () => {
    // API CAll here
    await httpPost(URLS.ASSET.CREATE_ASSET_OFFER, {
      ...assetOffer,
    })
      .then((response) => {
        if (response.data == true) {
          dispatch(fireEvent({ eventName: "refresh-asset-offer-list" }));
        }
      })
      .catch((error) => {
        //  setFormSubmitError(
        //    error.response.data.Message || "Some error occuered!"
        //  );
      })
      .finally(() => {
        closeEdit();
      });
  };

  const handleAssetCheckedChange = (e, assetId) => {
    var value = e.target.checked;
    var linkedAssetsVal = linkedAssets.map((asset) =>
      asset.assetId === assetId ? { ...asset, isLinked: value } : asset
    );
    setLinkedAssets(linkedAssetsVal);
  };

  const handleOfferExpiryDateChange = (value, assetId) => {
    var linkedAssetsVal = linkedAssets.map((asset) =>
      asset.assetId === assetId ? { ...asset, expiryDate: value } : asset
    );
    setLinkedAssets(linkedAssetsVal);
  };

  useEffect(() => {
    setAssetOffer(selectedAssetOffer);
    getAssetLinkedPlans();
  }, [selectedAssetOffer]);

  return (
    <div>
      <div className="display-grid">
        <label className="field-title">Offer Type</label>
        <Controls.SelectControl
          name="plantype"
          style={{ width: "100%", marginTop: "5px" }}
          options={assetOfferTypeItems}
          value={assetOffer.offerType}
          onChange={(e) =>
            setAssetOffer({
              ...assetOffer,
              offerType: e.target.value,
            })
          }
        ></Controls.SelectControl>

        <label className="field-title" style={{ marginTop: "20px" }}>
          Title
        </label>
        <FormControl>
          <Controls.InputControl
            name="title"
            other={{ placeholder: "Offer name" }}
            style={{ width: "100%", marginTop: "5px" }}
            onChange={(e) =>
              setAssetOffer({
                ...assetOffer,
                assetOfferName: e.target.value,
              })
            }
            value={assetOffer.assetOfferName}
            //error={validation.planName}
          ></Controls.InputControl>
        </FormControl>

        <label className="field-title" style={{ marginTop: "20px" }}>
          Description
        </label>
        <FormControl>
          <Controls.InputControl
            name="description"
            other={{ placeholder: "Offer Description" }}
            style={{ width: "100%", marginTop: "5px" }}
            onChange={(e) =>
              setAssetOffer({
                ...assetOffer,
                assetOfferDescription: e.target.value,
              })
            }
            value={assetOffer.assetOfferDescription}
            //error={validation.planName}
          ></Controls.InputControl>
        </FormControl>
        <label className="field-title" style={{ marginTop: "20px" }}>
          Offer code
        </label>
        <Controls.InputControl
          name="code"
          other={{
            placeholder: "Offer Code",
          }}
          style={{ width: "100%", marginTop: "5px" }}
          onChange={(e) =>
            setAssetOffer({
              ...assetOffer,
              assetOfferCode: e.target.value,
            })
          }
          value={assetOffer.assetOfferCode}
          //error={validation.bankName}
        ></Controls.InputControl>
        <label className="field-title" style={{ marginTop: "20px" }}>
          Offer amount
        </label>
        <Controls.InputControl
          name="amount"
          other={{
            type: "number",
            placeholder: "Amount",
          }}
          minNumber={0}
          style={{ width: "100%", marginTop: "5px" }}
          onChange={(e) =>
            setAssetOffer({
              ...assetOffer,
              offerAmount: Number(e.target.value),
            })
          }
          value={assetOffer.offerAmount}
          //error={validation.bankName}
        ></Controls.InputControl>

        <label className="field-title" style={{ marginTop: "20px" }}>
          Terms and Conditions
        </label>
        <RichEditor
          editorValue={assetOffer.assetOfferTermsAndConditions}
          setEditorValue={(value) =>
            setAssetOffer({
              ...assetOffer,
              assetOfferTermsAndConditions: value,
            })
          }
        ></RichEditor>
      </div>
      {assetOffer.assetOfferId > 0 && (
        <div>
          <label className="field-title" style={{ marginTop: "60px" }}>
            Linked Assets
          </label>
          {isLoadingLinkedAsset && (
            <EditAssetOfferSkeleton></EditAssetOfferSkeleton>
          )}
          {!isLoadingLinkedAsset && (
            <div>
              <div className="display-flex">
                <div className="w-70">
                  <span>Asset name</span>
                </div>
                <div className="w-30">
                  <span>Expiry date</span>
                </div>
              </div>
              {linkedAssets.map((item) => {
                return (
                  <div className="display-flex offer-link-item">
                    <div className="w-70" style={{ marginTop: "10px" }}>
                      <Checkbox
                        id={item.assetId}
                        checked={item.isLinked}
                        onChange={(e) =>
                          handleAssetCheckedChange(e, item.assetId)
                        }
                      />
                      <span>{item.assetOfferName}</span>
                    </div>
                    <div className="w-30">
                      <Controls.DatePickerControl
                        id={item.assetId}
                        name="expirtyDate"
                        value={item.expiryDate}
                        disabled={!item.isLinked}
                        onChange={(e) =>
                          handleOfferExpiryDateChange(e, item.assetId)
                        }
                      ></Controls.DatePickerControl>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default EditAssetOffer;
