import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fireEvent } from "../../../../modules/Events/eventSlice";

import { URLS } from "../../../../configs/api_urls";
import { httpGet } from "../../../../utility/request";

import AssetTypeItem from "./AssetTypeItem/asset-type-item.component";
import Loader from "../../../../components/Loader";

const AssetTypeList = ({
  selectedAssetTypeList,
  clickSelectAssetType,
  addToSelectedList,
}) => {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [assetTypes, setAssetTypess] = useState([]);

  const event = useSelector((onEvent) => {
    var eventName = onEvent.events.eventName;
    if (eventName === "refresh-asset-type-list") {
      fetchAssetTypes();
      dispatch(fireEvent({ eventName: "" }));
    }
    //reset event listner
  });

  const fetchAssetTypes = async () => {
    setIsLoading(true);
    const { data } = await httpGet(URLS.ASSET.ASSET_TYPES_LIST);
    setAssetTypess(data);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchAssetTypes();
  }, []);
  return (
    <div>
      <Loader isLoading={isLoading}></Loader>
      <div className="list-filter-header"></div>
      <div className="list-container scrollbar">
        {assetTypes.map((assetType) => {
          return (
            <div key={assetType.assetTypeId}>
              <AssetTypeItem
                assetType={assetType}
                isSelected={selectedAssetTypeList.includes(
                  assetType.assetTypeId
                )}
                addToSelectedList={addToSelectedList}
                clickSelectAssetType={() => {
                  clickSelectAssetType(assetType);
                }}
              ></AssetTypeItem>
            </div>
          );
        })}
      </div>
    </div>
  );
};
export default AssetTypeList;
