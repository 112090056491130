/* eslint-disable no-undef */
import React, { useEffect, useRef, useState } from "react";
import { Loader } from "@googlemaps/js-api-loader";

function GoogleMap(props) {
  const {
    latitude,
    longitude,
    zoomVal,
    onClickCallback = null,
  } = props;

  const googlemap = useRef(null);
  const [mapSettings, setMapSettings] = useState({
    center: { lat: latitude, lng: longitude },
    zoom: zoomVal,
  });

  const loader = new Loader({
    apiKey: "AIzaSyBx96vhe0mAKzjq0nIUTq8alOD5Sur8UEk",
    version: "weekly",
  });
  let map;
  let marker;
  let geocoder;

  loader.load().then(() => {
    map = new google.maps.Map(googlemap.current, {
      zoom: zoomVal,
      center: { lat: latitude, lng: longitude },
    });

    marker = new google.maps.Marker({
      position: { lat: latitude, lng: longitude },
      title: "my location",
    });
    marker.setMap(map);

    geocoder = new google.maps.Geocoder();

    map.addListener("click", (e) => {
      // get lat long
      let lat = e.latLng.lat();
      let lng = e.latLng.lng();

      //remove marker
      marker.setMap(null);

      // set marker
      marker.setPosition({ lat, lng });
      marker.setMap(map);

      // run callback
      if (onClickCallback) {
        let address_components;
        // decode lat long
        geocoder.geocode(
          {
            latLng: e.latLng,
          },
          function (results, status) {
            if (status == google.maps.GeocoderStatus.OK) {
              if (results[0]) {
                var pointedLocation = {
                  googleMapId: results[0].place_id,
                  googleMapAddress: results[0].formatted_address,
                  addressLane1: results[0].formatted_address,
                  addressLane2: results[1].formatted_address,
                  latitude: lat,
                  longitude: lng
                };
                 onClickCallback(pointedLocation);
              }
            }
          }
        );

        // onClickCallback({ latLng: { lat, lng }, address_components: null });
      }

      //
    });
  });

  useEffect(() => {
    //setMapSettings({ ...mapSettings, zoom: zoomVal });
  }, [props]);
  return <div id="map" ref={googlemap} />;
}

export default GoogleMap;
