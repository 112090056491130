import { useState, useEffect } from "react";
import React from "react";
import { useDispatch } from "react-redux";

import { FormControl, Link } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as AllIcons from "@fortawesome/free-solid-svg-icons";

import Controls from "../../../../components/Controls/Controls";
import { faTrash, faFileArchive } from "@fortawesome/free-solid-svg-icons";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import IconSelector from "../../../../components/IconSelector/icon-selector.component";
import Badge from "@mui/material/Badge";
import EditSubscriptionPlanSkeleton from "../../Skeletons/edit-subscription-plan-skeletons.component";

import { fireEvent } from "../../../../modules/Events/eventSlice";

import { URLS } from "../../../../configs/api_urls";
import { httpGet, httpPost, httpPut } from "../../../../utility/request";

import "./edit-subscription-plan.styles.scss";

const planTypeItems = [
  { id: 1, title: "Yearly" },
  { id: 2, title: "Monthly" },
  { id: 3, title: "Weekly" },
];

const validationIntialState = {
  subscriptionPlanId: 0,
  planName: "",
  planDescription: "",
  planFeatures: "",
  planPricings: "",
};

const EditSubscriptionPlan = React.forwardRef(
  ({ selectedPlan, closeEdit }, ref) => {
    const dispatch = useDispatch();

    const [isLoading, setIsLoading] = useState(false);

    const [isIconSelectorOpen, setIsIconSelectorOpen] = useState(false);
    const [selectedIconId, setSelectedIconId] = useState("");
    const [expendedAccordian, setExpendedAccordian] = useState("effective");
    const [newItemId, setNewItemId] = useState(-1);
    const [errors, setValidationErrors] = useState([]);

    const [subscriptionPlanItem, setSubscriptionPlanItem] =
      useState(selectedPlan);
    const [validation, setValidation] = useState({ validationIntialState });

    const fetchSubscriptionPlanById = async () => {
      setIsLoading(true);
      const { data } = await httpGet(
        URLS.SUBSCRIPTION_PLANS.GETSUBSCRIPTIONPLANBYID.replace(
          "#ID#",
          selectedPlan.subscriptionPlanId
        )
      );
      if (selectedPlan.subscriptionPlanId !== data.subscriptionPlanId) return;

      setSubscriptionPlanItem(data);
      setIsLoading(false);
    };

    const handleExpendedAccordianChange = (panel) => {
      setExpendedAccordian(panel);
    };

    // plan name validation functions
    const handleTitleChange = (e) => {
      var planNameVal = e.target.value;
      if (planNameVal == "") {
        setValidation({ ...validation, planName: "Title is required" });
      } else {
        setValidation({ ...validation, planName: "" });
      }
      setSubscriptionPlanItem({
        ...subscriptionPlanItem,
        planName: planNameVal,
      });
    };

    //plan description validation change
    const handleDescriptionChange = (e) => {
      var descriptionChangeVal = e.target.value;
      if (descriptionChangeVal == "") {
        setValidation({
          ...validation,
          planDescription: "Description is required",
        });
      } else {
        setValidation({ ...validation, planDescription: "" });
      }
      setSubscriptionPlanItem({
        ...subscriptionPlanItem,
        planDescription: descriptionChangeVal,
      });
    };

    // following function are for feature prop changes

    //show icon selector component
    const showIconSelector = (event) => {
      var iconId = event.currentTarget.id.split("_")[1];
      setSelectedIconId(iconId);
      setIsIconSelectorOpen(true);
    };

    // feature icon set
    const setFeatureIconValue = (event) => {
      var featureIconVal = event.currentTarget.id;
      var planFeaturesVal = subscriptionPlanItem.planFeatures.map((feature) =>
        feature.subscriptionPlanFeatureId == selectedIconId
          ? { ...feature, subscriptionPlanFeatureIconUrl: featureIconVal }
          : feature
      );
      setSubscriptionPlanItem({
        ...subscriptionPlanItem,
        planFeatures: planFeaturesVal,
      });
      setIsIconSelectorOpen(false);
    };

    // plan feature name change
    const handleFeatureNameChange = (name, featureId) => {
      var featureNameVal = name.target.value;
      var planFeaturesVal = subscriptionPlanItem.planFeatures.map((feature) =>
        feature.subscriptionPlanFeatureId === featureId
          ? { ...feature, subscriptionPlanFeatureName: featureNameVal }
          : feature
      );
      setSubscriptionPlanItem({
        ...subscriptionPlanItem,
        planFeatures: planFeaturesVal,
      });
    };

    // remove feature
    const removeFeature = (event) => {
      var featureId = event.currentTarget.id.split("_")[1];
      var planFeaturesVal = subscriptionPlanItem.planFeatures.filter(
        (feature) => feature.subscriptionPlanFeatureId != featureId
      );
      setSubscriptionPlanItem({
        ...subscriptionPlanItem,
        planFeatures: planFeaturesVal,
      });
    };

    // add feature
    const addFeature = (event) => {
      var newfeature = {
        subscriptionPlanFeatureId: newItemId,
        subscriptionPlanFeatureName: "",
        subscriptionPlanFeatureIconUrl: "faInfo",
      };
      var planFeaturesVal = [
        ...subscriptionPlanItem.planFeatures,
        { ...newfeature },
      ];
      setSubscriptionPlanItem({
        ...subscriptionPlanItem,
        planFeatures: planFeaturesVal,
      });
      setNewItemId(newItemId - 1);
    };

    // following function are for feature prop changes

    const handlePricingTypeChange = (
      pricingType,
      subscriptionPlanPricingId
    ) => {
      var planPricingsVal = subscriptionPlanItem.planPricings.map((pricing) =>
        pricing.subscriptionPlanPricingId === subscriptionPlanPricingId
          ? { ...pricing, subscriptionPlanPricingType: pricingType }
          : pricing
      );
      setSubscriptionPlanItem({
        ...subscriptionPlanItem,
        planPricings: planPricingsVal,
      });
    };

    const handlePricingDurationChange = (
      pricingDuration,
      subscriptionPlanPricingId
    ) => {
      var planPricingsVal = subscriptionPlanItem.planPricings.map((pricing) =>
        pricing.subscriptionPlanPricingId === subscriptionPlanPricingId
          ? { ...pricing, planDuration: pricingDuration }
          : pricing
      );
      setSubscriptionPlanItem({
        ...subscriptionPlanItem,
        planPricings: planPricingsVal,
      });
    };

    const handlePricingPriceChange = (price, subscriptionPlanPricingId) => {
      var planPricingsVal = subscriptionPlanItem.planPricings.map((pricing) =>
        pricing.subscriptionPlanPricingId === subscriptionPlanPricingId
          ? { ...pricing, planPrice: price }
          : pricing
      );
      setSubscriptionPlanItem({
        ...subscriptionPlanItem,
        planPricings: planPricingsVal,
      });
    };

    const addPricing = () => {
      var newPricing = {
        subscriptionPlanPricingId: newItemId,
        subscriptionPlanPricingType: 1,
        planDuration: 0,
        planPrice: 0,
        isNew: true,
      };
      var planPricingsVal = [
        ...subscriptionPlanItem.planPricings,
        { ...newPricing },
      ];
      setSubscriptionPlanItem({
        ...subscriptionPlanItem,
        planPricings: planPricingsVal,
      });
      setNewItemId(newItemId - 1);
    };

    const archivePricing = (event) => {
      var pricingId = event.currentTarget.id.split("_")[1];
      var planPricingsVal = subscriptionPlanItem.planPricings.map((pricing) =>
        pricing.subscriptionPlanPricingId == pricingId
          ? { ...pricing, isArchieved: true }
          : pricing
      );
      setSubscriptionPlanItem({
        ...subscriptionPlanItem,
        planPricings: planPricingsVal,
      });
    };

    const removePricing = (event) => {
      var pricingId = event.currentTarget.id.split("_")[1];
      var planPricingsVal = subscriptionPlanItem.planPricings.filter(
        (pricing) => pricing.subscriptionPlanPricingId != pricingId
      );
      setSubscriptionPlanItem({
        ...subscriptionPlanItem,
        planPricings: planPricingsVal,
      });
    };

    const handleOfferExpiryDateChange = (event, offermapId) => {
      var planOfferMapVal = subscriptionPlanItem.planOfferMap.map((offer) =>
        offer.subscriptionPlanOfferMapId === offermapId
          ? { ...offer, expiryDate: event }
          : offer
      );
      setSubscriptionPlanItem({
        ...subscriptionPlanItem,
        planOfferMap: planOfferMapVal,
      });
    };
    // rerset validation fields
    const resetValidation = () => {
      setValidation(validationIntialState);
    };

    const updateSubscriptionPlan = async () => {
      if (
        selectedPlan.subscriptionPlanId ===
        subscriptionPlanItem.subscriptionPlanId
      ) {
        // API CAll here
        await httpPut(
          URLS.SUBSCRIPTION_PLANS.UPDATESUBSCRIPTIONPLAN,
          subscriptionPlanItem
        )
          .then((response) => {
            if (response.data == true) {
              dispatch(
                fireEvent({ eventName: "refresh-subscriptionplan-list" })
              );
              closeEdit();
            }
          })
          .catch((error) => {
            //  setFormSubmitError(
            //    error.response.data.Message || "Some error occuered!"
            //  );
          });
        // close after saving
      }
    };

    const createSubscriptionPlan = async () => {
      if (
        selectedPlan.subscriptionPlanId ===
        subscriptionPlanItem.subscriptionPlanId
      ) {
        // API CAll here
        await httpPost(
          URLS.SUBSCRIPTION_PLANS.CREATESUBSCRIPTIONPLAN,
          subscriptionPlanItem
        )
          .then((response) => {
            if (response.data == true) {
              dispatch(
                fireEvent({ eventName: "refresh-subscriptionplan-list" })
              );
            }
          })
          .catch((error) => {
            //  setFormSubmitError(
            //    error.response.data.Message || "Some error occuered!"
            //  );
          });
        // close after saving
      }
    };

    React.useImperativeHandle(ref, () => ({
      saveSubscriptionPlan() {
        if (selectedPlan.subscriptionPlanId == 0) {
          createSubscriptionPlan();
        } else {
          updateSubscriptionPlan();
        }
      },
    }));

    useEffect(() => {
      resetValidation();
      setSubscriptionPlanItem(selectedPlan);
      setExpendedAccordian("effective");
      if (selectedPlan.subscriptionPlanId > 0) {
        fetchSubscriptionPlanById();
      }
    }, [selectedPlan]);

    return (
      <div>
        {isLoading && (
          <div>
            <EditSubscriptionPlanSkeleton></EditSubscriptionPlanSkeleton>
          </div>
        )}

        {!isLoading && (
          <div>
            <IconSelector
              isOpen={isIconSelectorOpen}
              setIconVal={(e) => setFeatureIconValue(e)}
              cancelClick={() => setIsIconSelectorOpen(false)}
            ></IconSelector>
            {errors.length > 0 && (
              <div className="validation-section">
                <ul style={{ listStyleType: "disc", color: "red" }}>
                  {errors.map((err) => {
                    <li>err</li>;
                  })}
                </ul>
              </div>
            )}
            <div className="display-grid">
              <label className="field-title">Title</label>
              <FormControl>
                <Controls.InputControl
                  name="title"
                  other={{ placeholder: "Plan name" }}
                  style={{ width: "100%", marginTop: "5px" }}
                  onChange={(e) => handleTitleChange(e)}
                  value={subscriptionPlanItem.planName}
                  error={validation.planName}
                ></Controls.InputControl>
              </FormControl>

              <label className="field-title" style={{ marginTop: "20px" }}>
                Description
              </label>

              <Controls.InputControl
                name="title"
                other={{ placeholder: "Plan Description" }}
                style={{ width: "100%", marginTop: "5px" }}
                onChange={(e) => handleDescriptionChange(e)}
                value={subscriptionPlanItem.planDescription}
                isMultiLine={true}
                rows={3}
                error={validation.planDescription}
              ></Controls.InputControl>

              <label className="field-title" style={{ marginTop: "20px" }}>
                {" "}
                Plan features (minimum 2){" "}
                <Link onClick={addFeature}>(Add a feature)</Link>
              </label>
              <div>
                {subscriptionPlanItem.planFeatures &&
                  subscriptionPlanItem.planFeatures.map((item) => {
                    return (
                      <div
                        key={item.subscriptionPlanFeatureId}
                        className="display-flex w-100 mt-10"
                      >
                        <div className="w-20">
                          <div
                            id={
                              "fontawesomeicon_" +
                              item.subscriptionPlanFeatureId
                            }
                            onClick={(e) => showIconSelector(e)}
                            className="plan-feature-icon"
                          >
                            <FontAwesomeIcon
                              icon={
                                AllIcons[item.subscriptionPlanFeatureIconUrl]
                              }
                              style={{ fontSize: "25px" }}
                            />
                          </div>
                        </div>
                        <div className="w-80">
                          <Controls.InputControl
                            name="featurename"
                            other={{ placeholder: "Feature Name" }}
                            style={{ marginTop: "5px", width: "100%" }}
                            onChange={(e) =>
                              handleFeatureNameChange(
                                e,
                                item.subscriptionPlanFeatureId
                              )
                            }
                            value={item.subscriptionPlanFeatureName}
                            // error={validation.bankName}
                          ></Controls.InputControl>
                        </div>
                        <div className="w-20 text-align-center m-auto">
                          <Controls.ButtonControl
                            id={
                              "featureremove_" + item.subscriptionPlanFeatureId
                            }
                            style={{ background: "gray" }}
                            onClick={(e) => removeFeature(e)}
                            innercomponent={
                              <FontAwesomeIcon icon={faTrash}></FontAwesomeIcon>
                            }
                          ></Controls.ButtonControl>
                        </div>
                      </div>
                    );
                  })}
              </div>
              <label className="field-title" style={{ marginTop: "20px" }}>
                Plan Pricing
                <Link onClick={addPricing}> (Add new effective pricing)</Link>
              </label>
              <div>
                {subscriptionPlanItem.planPricings &&
                  subscriptionPlanItem.planPricings
                    .filter((p) => p.isNew == true)
                    .map((item) => {
                      return (
                        <div
                          key={item.subscriptionPlanPricingId}
                          className="display-block mt-10 w-100"
                        >
                          <div className="row">
                            <div className="col-md-3">
                              <Controls.SelectControl
                                name="plantype"
                                style={{ width: "130px" }}
                                options={planTypeItems}
                                value={item.subscriptionPlanPricingType}
                                onChange={(e) =>
                                  handlePricingTypeChange(
                                    e.target.value,
                                    item.subscriptionPlanPricingId
                                  )
                                }
                              ></Controls.SelectControl>
                            </div>
                            <div className="col-md-3">
                              <Controls.InputControl
                                name="title"
                                other={{
                                  type: "number",
                                  placeholder: "Duration",
                                }}
                                minNumber={0}
                                style={{ width: "100%" }}
                                onChange={(e) =>
                                  handlePricingDurationChange(
                                    e.target.value,
                                    item.subscriptionPlanPricingId
                                  )
                                }
                                value={item.planDuration}
                                // error={validation.bankName}
                              ></Controls.InputControl>
                            </div>
                            <div className="col-md-3">
                              <Controls.InputControl
                                name="title"
                                other={{
                                  type: "number",
                                  placeholder: "Price",
                                }}
                                minNumber={0}
                                style={{ width: "100%" }}
                                onChange={(e) =>
                                  handlePricingPriceChange(
                                    e.target.value,
                                    item.subscriptionPlanPricingId
                                  )
                                }
                                value={item.planPrice}
                                // error={validation.bankName}
                              ></Controls.InputControl>
                            </div>
                            <div className="col-md-3">
                              <Controls.ButtonControl
                                id={
                                  "archivePricing_" +
                                  item.subscriptionPlanPricingId
                                }
                                style={{ background: "gray" }}
                                innercomponent={
                                  <FontAwesomeIcon
                                    icon={faTrash}
                                  ></FontAwesomeIcon>
                                }
                                onClick={(e) => removePricing(e)}
                              ></Controls.ButtonControl>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                {subscriptionPlanItem.planPricings &&
                  subscriptionPlanItem.planPricings.filter(
                    (p) => p.isArchieved == false
                  ).length > 0 && (
                    <Accordion
                      id="effective"
                      expanded={expendedAccordian === "effective"}
                      sx={{ marginTop: "20px" }}
                      onChange={() =>
                        handleExpendedAccordianChange("effective")
                      }
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography>Effective Pricings</Typography>
                        <Badge color="success" variant="dot"></Badge>
                      </AccordionSummary>
                      <AccordionDetails>
                        {subscriptionPlanItem.planPricings &&
                          subscriptionPlanItem.planPricings
                            .filter((p) => p.isArchieved == false)
                            .map((item) => {
                              return (
                                <div
                                  key={item.subscriptionPlanPricingId}
                                  className="display-block mt-10 w-100"
                                >
                                  <div className="row">
                                    <div className="col-md-3">
                                      <Controls.SelectControl
                                        name="plantype"
                                        style={{ width: "100%" }}
                                        options={planTypeItems}
                                        value={item.subscriptionPlanPricingType}
                                        disabled={true}
                                        onChange={(e) =>
                                          handlePricingTypeChange(
                                            e.target.value,
                                            item.subscriptionPlanPricingId
                                          )
                                        }
                                      ></Controls.SelectControl>
                                    </div>
                                    <div className="col-md-3">
                                      <Controls.InputControl
                                        name="title"
                                        other={{
                                          type: "number",
                                          placeholder: "Duration",
                                          disabled: true,
                                        }}
                                        minNumber={0}
                                        style={{ width: "100%" }}
                                        onChange={(e) =>
                                          handlePricingDurationChange(
                                            e.target.value,
                                            item.subscriptionPlanPricingId
                                          )
                                        }
                                        value={item.planDuration}
                                        // error={validation.bankName}
                                      ></Controls.InputControl>
                                    </div>
                                    <div className="col-md-3">
                                      <Controls.InputControl
                                        name="title"
                                        other={{
                                          type: "number",
                                          placeholder: "Price",
                                          disabled: true,
                                        }}
                                        minNumber={0}
                                        style={{ width: "100%" }}
                                        onChange={(e) =>
                                          handlePricingPriceChange(
                                            e.target.value,
                                            item.subscriptionPlanPricingId
                                          )
                                        }
                                        value={item.planPrice}
                                        // error={validation.bankName}
                                      ></Controls.InputControl>
                                    </div>
                                    <div className="col-md-3">
                                      <Controls.ButtonControl
                                        id={
                                          "archivePricing_" +
                                          item.subscriptionPlanPricingId
                                        }
                                        style={{ background: "gray" }}
                                        innercomponent={
                                          <FontAwesomeIcon
                                            icon={faFileArchive}
                                          ></FontAwesomeIcon>
                                        }
                                        onClick={(e) => archivePricing(e)}
                                      ></Controls.ButtonControl>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                      </AccordionDetails>
                    </Accordion>
                  )}
                {subscriptionPlanItem.planPricings &&
                  subscriptionPlanItem.planPricings.filter(
                    (p) => p.isArchieved == true
                  ).length > 0 && (
                    <Accordion
                      id="archived"
                      expanded={expendedAccordian === "archived"}
                      onChange={() => handleExpendedAccordianChange("archived")}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                      >
                        <Typography>Archieved Pricings</Typography>
                        <Badge color="warning" variant="dot"></Badge>
                      </AccordionSummary>
                      <AccordionDetails>
                        {subscriptionPlanItem.planPricings &&
                          subscriptionPlanItem.planPricings
                            .filter((p) => p.isArchieved == true)
                            .map((item) => {
                              return (
                                <div
                                  key={item.subscriptionPlanPricingId}
                                  className="display-block mt-10 w-100"
                                >
                                  <div className="row">
                                    <div className="col-md-3">
                                      <Controls.SelectControl
                                        name="plantype"
                                        style={{ width: "120px" }}
                                        options={planTypeItems}
                                        value={item.subscriptionPlanPricingType}
                                        disabled={true}
                                        onChange={(e) =>
                                          handlePricingTypeChange(
                                            e.target.value,
                                            item.subscriptionPlanPricingId
                                          )
                                        }
                                      ></Controls.SelectControl>
                                    </div>
                                    <div className="col-md-3">
                                      <Controls.InputControl
                                        name="title"
                                        other={{
                                          type: "number",
                                          placeholder: "Duration",
                                          disabled: true,
                                        }}
                                        minNumber={0}
                                        style={{ width: "100%" }}
                                        onChange={(e) =>
                                          handlePricingDurationChange(
                                            e.target.value,
                                            item.subscriptionPlanPricingId
                                          )
                                        }
                                        value={item.planDuration}
                                        // error={validation.bankName}
                                      ></Controls.InputControl>
                                    </div>
                                    <div className="col-md-3">
                                      <Controls.InputControl
                                        name="title"
                                        other={{
                                          type: "number",
                                          placeholder: "Price",
                                          disabled: true,
                                        }}
                                        minNumber={0}
                                        style={{ width: "100%" }}
                                        onChange={(e) =>
                                          handlePricingPriceChange(
                                            e.target.value,
                                            item.subscriptionPlanPricingId
                                          )
                                        }
                                        value={item.planPrice}
                                        // error={validation.bankName}
                                      ></Controls.InputControl>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                      </AccordionDetails>
                    </Accordion>
                  )}
              </div>
              <label className="field-title" style={{ marginTop: "20px" }}>
                Linked Plan Offers
              </label>
              <div>
                {subscriptionPlanItem.planOfferMap &&
                  subscriptionPlanItem.planOfferMap.map((offer) => {
                    return (
                      <div
                        key={offer.subscriptionPlanOfferMapId}
                        className="display-block"
                      >
                        <div className="row">
                          <div className="col-md-8 m-auto">
                            {offer.subscriptionPlanOffer.offerName}
                          </div>
                          <div className="col-md-4">
                            <Controls.DatePickerControl
                              id={offer.subscriptionPlanOfferMapId}
                              name="expirtyDate"
                              value={offer.expiryDate}
                              onChange={(e) =>
                                handleOfferExpiryDateChange(
                                  e,
                                  offer.subscriptionPlanOfferMapId
                                )
                              }
                            ></Controls.DatePickerControl>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                {subscriptionPlanItem.planOfferMap == undefined ||
                  (subscriptionPlanItem.planOfferMap.length == 0 && (
                    <div>
                      <Typography>No offers are linked yet.</Typography>
                    </div>
                  ))}
              </div>

              {/* <Controls.ButtonControl
              id={"submit_button"}
              onClick={saveSubscriptionPlan}
              innercomponent={
                <FontAwesomeIcon icon={faTrash}></FontAwesomeIcon>
              }
            ></Controls.ButtonControl> */}
            </div>
          </div>
        )}
      </div>
    );
  }
);

export default EditSubscriptionPlan;
