import * as React from "react";
import Skeleton from "@mui/material/Skeleton";
import { Grid } from "@mui/material";

export default function AssetSlotListSkeleton() {
  return (
    <Grid container>
      <Grid item md={4} sm={4}>
        <Skeleton variant="text" height={60} sx={{ borderRadius: "20px" }} />
      </Grid>
      <Grid item md={4} sm={4}>
        <Skeleton variant="text" height={60} sx={{ borderRadius: "20px" }} />
      </Grid>
      <Grid item md={4} sm={4}>
        <Skeleton variant="text" height={60} sx={{ borderRadius: "20px" }} />
      </Grid>
      <Grid item md={4} sm={4}>
        <Skeleton variant="text" height={60} sx={{ borderRadius: "20px" }} />
      </Grid>
      <Grid item md={4} sm={4}>
        <Skeleton variant="text" height={60} sx={{ borderRadius: "20px" }} />
      </Grid>
      <Grid item md={4} sm={4} >
        <Skeleton variant="text" height={60} sx={{ borderRadius: "20px" }} />
      </Grid>
    </Grid>
  );
}
