import { store } from "../../store/make-store";
import { SubModule } from "../Constants/SubModules";

import { fetchNotifications, fetchNotificationCount } from "../../modules/Notification/notificationSlice";

const BookingSignalRHandler = (signalrResponse) => {

  switch (signalrResponse.SubModule) {
    case SubModule.Create:
      //refresh list and count
      store.dispatch(fetchNotificationCount());
      store.dispatch(fetchNotifications());
      break;
  }
};

export default BookingSignalRHandler;
