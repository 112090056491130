import React, { useState, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { isEmpty } from "lodash";
import { Alert } from "@mui/material";

import Spinner from "react-bootstrap/Spinner";

import CustomTextField from "../../../components/InputField";
import { URLS } from "../../../configs/api_urls";
import { httpPost } from "../../../utility/request";

const INITIAL_TIMER = "00:00";

const ForgotPasswordForm = (props) => {
  const [email, setEmail] = useState("");
  const [isSubmit, setIsSubmit] = useState(false);
  const [message, setMessage] = useState({});
  const [timer, setTimer] = useState(INITIAL_TIMER);
  const Ref = useRef(null);

  /**
   * Get Remaining Time
   *
   * @param {Object} e
   * @return {Object}
   */
  const getTimeRemaining = (e) => {
    const total = Date.parse(e) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    // const hours = Math.floor((total / 1000 / 60 / 60) % 24);
    return {
      total,
      minutes,
      seconds,
    };
  };

  /**
   * Start Timer
   *
   * @param {Object} e
   */
  const startTimer = (e) => {
    let { total, minutes, seconds } = getTimeRemaining(e);
    if (total >= 0) {
      setTimer(
        (minutes > 9 ? minutes : "0" + minutes) +
          ":" +
          (seconds > 9 ? seconds : "0" + seconds)
      );
    }
  };

  /**
   * Clear Timer
   *
   * @param {Object} e
   */
  const clearTimer = (e) => {
    setTimer("2:00");

    if (Ref.current) clearInterval(Ref.current);
    const id = setInterval(() => {
      startTimer(e);
    }, 1000);
    Ref.current = id;
  };

  /**
   * Return how much time left in Date object
   *
   * @returns {Object}
   */
  const getDeadTime = () => {
    let deadline = new Date();

    deadline.setSeconds(deadline.getSeconds() + 120);
    return deadline;
  };

  /**
   * verify > Email
   *
   * @return {Boolean}
   */
  const validateEmail = () => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  /**
   * Handle Forgot password submission form
   *
   * @param {Object} evt
   */
  const handleForgotPassword = async (evt) => {
    evt.preventDefault();

    if (!validateEmail()) return "";

    setIsSubmit(true);

    try {
      const { data } = await httpPost(URLS.ADMIN.SEND_RESET_PASSWORD_LINK, {
        emailId: email,
        resetLink: "",
      });

      setMessage({
        success: true,
        text: "We send a reset-password link on your registered email.",
      });

      clearTimer(getDeadTime());
    } catch (err) {
      if (err.response.status == 499) {
        setMessage({
          success: true,
          text: err.response.data.Message,
        });
      } else {
        setMessage({
          success: false,
          text: "We are facing technical difficulties please try later.",
        });
      }
    } finally {
      setIsSubmit(false);
    }
  };

  /**
  Render Forgot Password Form
  */
  return (
    <>
      <h5
        className={`font-600 text-dark-2 text-bold mt-1 mb-0 font-medium`}
        component='h6'>
        Forgot Password ?
      </h5>
      <p className='text-muted-2 mt-1 d-block mb-3'>
        Enter your email and we&apos;ll send you instructions to reset your
        password.
      </p>

      {/* Alert box */}
      {!isEmpty(message) && (
        <Alert
          severity={message.success ? "info" : "error"}
          className='my-2 text-align-start'>
          {message.text}
        </Alert>
      )}

      {/* Login Form */}
      <form action='' onSubmit={handleForgotPassword}>
        <CustomTextField
          error={isSubmit && !email}
          id='email'
          name='email'
          onChange={(e) => setEmail(e.target.value)}
          label='Email'
          variant='outlined'
          className={`w-100 my-2 text-theme`}
          autoComplete='off'
        />

        <button
          className='theme-btn w-100 my-2 py-2'
          disabled={timer != INITIAL_TIMER || isSubmit}
          type='submit'>
          {timer == INITIAL_TIMER ? (
            isSubmit ? (
              <Spinner animation='border' role='status'></Spinner>
            ) : (
              "Send reset link"
            )
          ) : (
            `Resend in ${timer}`
          )}
        </button>
      </form>
      <div className='mt-4'>
        <span
          className='theme-link text-theme cursor-pointer letter-spacing-1'
          onClick={props.backToLogin}>
          <FontAwesomeIcon icon={faChevronLeft} /> Back to Login
        </span>
      </div>
    </>
  );
};

export default ForgotPasswordForm;
