import { Box, Typography } from "@mui/material";

export default function GuideLicenseOperations () {
  return (
    <Box className="edit-container">
      <Box style={{ display: "inherit" }} className="operations-title">
        <Box style={{ margin: "auto", textAlign: "center", marginTop: "30px" }}>
          Manage License
        </Box>
      </Box>
      <Box className="mt-50 p-20">
        <Box className="row">
          <Box className="col-md-6">
            <h5 className="text-theme font-600 cursor-pointer">
              Create License for Admins
            </h5>
            <Typography>
              License are for subscription management to Playnoot Admin
            </Typography>
          </Box>
          <Box className="col-md-6">
            <h5 className="text-theme font-600 cursor-pointer">
              Add Flexible charges
            </h5>
            <Typography>
              Add flexible charges applicable on Booking, Subsciption,
              Transections.
            </Typography>
          </Box>
        </Box>
        <Box className="row mt-20">
          <Box className="col-md-6">
            <h5 className="text-theme font-600 cursor-pointer">
              Manage Limits
            </h5>
            <Typography>
              Manage limits on no. of centers, admins, players
            </Typography>
          </Box>
          <Box className="col-md-6">
            {/* <h5 className="text-theme font-600 cursor-pointer">Pricings</h5>
              <Typography>Put resonable price for each product to increase sale</Typography> */}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
