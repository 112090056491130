import { useState } from "react";

import { httpPost } from "../../utility/request";

import FontAwesome from "../Icons/fontawesome";
import { faCamera, faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import "./image-processor.styles.scss";
import { CircularProgress } from "@mui/material";
import BlankImage from "../../static/blank-image.png"

const ImageProcessor = ({
  id = "image_processor",
  imageUrl,
  style,
  alt,
  uploadUrl,
  setImageData,
  showDeleteButton,
  showImageUploadButton = true,
  deleteImage
}) => {
  const [isImageUploading, setImageUploading] = useState(false);

  const handleImageUploadClick = () => {
    const input_el = document.getElementById(id);
    input_el.click();
  };

  const handleImageChange = async (e) => {
    setImageUploading(true);
    // upload image
    let form = new FormData();
    let image_file = e.target;
    form.append("formFiles", image_file.files[0]);

    try {
      const { data } = await httpPost(uploadUrl, form, {
        "Content-Type": "multipart/form-data",
      });
      const new_images_data = data.images[0];
      setImageData(new_images_data);
    } catch (err) {
      console.error(err);
    } finally {
      setImageUploading(false);
    }
  };

  return (
    <div
      className="container"
      style={{
        height: "100%",
        position: "relative",
        padding: "initial",
        background: "lightgray",
        borderRadius: "15px",
      }}
    >
      {!isImageUploading && (
        <div style={{ height: "100%" }}>
          {showDeleteButton && (
            <div onClick={deleteImage}>
              <FontAwesome
                className="delete-button"
                icon={faCircleXmark}
              ></FontAwesome>
            </div>
          )}
          <img
            alt={alt}
            src={imageUrl || BlankImage}
            style={style}
            className="image-section"
          />
          {showImageUploadButton && (
            <div className="middle" onClick={handleImageUploadClick}>
              <div className="text">
                <FontAwesome
                  icon={faCamera}
                  iconSize={"40px"}
                  //className={"info-icon-margin"}
                ></FontAwesome>
              </div>
            </div>
          )}
          <input
            type="file"
            name={"image_processor"}
            id={id}
            hidden={true}
            //className="d-none"
            accept="image/*"
            onChange={handleImageChange}
          />
        </div>
      )}
      {isImageUploading && (
        <div className="middle-progress">
          <CircularProgress></CircularProgress>
        </div>
      )}
    </div>
  );
};

export default ImageProcessor;
