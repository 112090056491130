import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import SelectControl from "../../../components/Controls/SelectControl";
import TaskItem from "./TaskItem/task-item.component";
import TaskListSkeleton from "../Skeleton/task-list-skeleton.component";

import { URLS } from "../../../configs/api_urls";
import { httpGet } from "../../../utility/request";
import { fireEvent } from "../../../modules/Events/eventSlice";

const taskAvailability = [
  { id: 0, title: "Active", isActive: true },
  { id: 1, title: "Completed", isActive: false },
];

const TaskList = ({
  selectedTaskList,
  clickSelectTask,
  addToSelectedList,
  cancelClick,
}) => {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState(0);
  const [tasks, setTasks] = useState([]);
  const [selectedFilterItems, setSelectedFilterItems] = useState([]);

  const event = useSelector((onEvent) => {
    var eventName = onEvent.events.eventName;
    if (eventName === "refresh-task-list") {
      fetchTasks();
      dispatch(fireEvent({ eventName: "" }));
    }
    //reset event listner
  });

  const fetchTasks = async () => {
    setIsLoading(true);
    const { data } = await httpGet(URLS.TASKS.GET_ALL_TASKS);
    setTasks(data);
    var isActive = taskAvailability.find(
      (x) => x.id == selectedFilter
    ).isActive;
    var tasks = data.filter((p) => p.isActive === isActive);
    setSelectedFilterItems(tasks);
    setIsLoading(false);
  };

  const handleFilterChange = (filter) => {
    var isActive = taskAvailability.find((x) => x.id == filter).isActive;
    setSelectedFilter(filter);
    var newtaskList = tasks.filter((p) => p.isActive === isActive);
    setSelectedFilterItems(newtaskList);
  };

  useEffect(() => {
    fetchTasks();
  }, []);

  return (
    <div>
      <div className="list-filter-header">
        <SelectControl
          name="asset-availability"
          style={{ width: "150px", height: "35px" }}
          options={taskAvailability}
          value={selectedFilter}
          onChange={(e) => {
            handleFilterChange(e.target.value);
            cancelClick();
          }}
        ></SelectControl>
      </div>
      <div className="list-container scrollbar">
        {isLoading && <TaskListSkeleton></TaskListSkeleton>}
        {selectedFilterItems.length == 0 &&
          selectedFilter == 0 &&
          !isLoading && (
            <div className="no-item-section">
              <span className="no-item-message">
                You have not created any Task yet. Create Tasks and reminder to
                stay most productive.
              </span>
            </div>
          )}
        {selectedFilterItems.length == 0 &&
          selectedFilter == 1 &&
          !isLoading && (
            <div className="no-item-section">
              <span className="no-item-message">
                All completed tasks will be visible here.
              </span>
            </div>
          )}
        {!isLoading &&
          selectedFilterItems.map((task) => {
            return (
              <div key={task.taskId}>
                <TaskItem
                  task={task}
                  isSelected={selectedTaskList.includes(task.taskId)}
                  addToSelectedList={addToSelectedList}
                  clickSelectTask={() => {
                    clickSelectTask(task);
                  }}
                ></TaskItem>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default TaskList;
