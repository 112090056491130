import { useContext, useState } from "react";
import { useDispatch } from "react-redux";
import { GameCenterViewContext } from "../../../utility/context";

import Controls from "../../../components/Controls/Controls";
import ImageProcessor from "../../../components/ImageProcessor/image-processor.component";
import { CircularProgress } from "@mui/material";
import { amber } from "tailwindcss/colors";

import { URLS } from "../../../configs/api_urls";
import { httpPost } from "../../../utility/request";

import { addAlert } from "../../../modules/App/actions";

const BasicDetails = () => {
  const dispatch = useDispatch();
  const gameCenterDetails = useContext(GameCenterViewContext);

  const [isSaving, setIsSaving] = useState(false);
  const [basicDetails, setBasicDetails] = useState(
    gameCenterDetails.GameCenter.gameDetails
  );

  const handleSubmit = async () => {
    // API CAll here
    setIsSaving(true);
    await httpPost(
      URLS.APPLICATION_INSTANCE_SETTINGS.UPDATE_CENTER_DETAILS,
      basicDetails
    )
      .then((response) => {
        if (response.data == true) {
          gameCenterDetails.setGameCenter({
            ...gameCenterDetails.GameCenter,
            gameDetails: basicDetails,
          });
          dispatch(
            addAlert({
              type: "success",
              message: "Center deatils updated Successfully",
            })
          );
        }
      })
      .catch((error) => {
        dispatch(
          addAlert({
            type: "error",
            message: error,
          })
        );
      })
      .finally(() => {
        setIsSaving(false);
      });
  };

  return (
    <div>
      <div className="w-100">
        <div style={{ width: "80px" }}>
          <ImageProcessor
            alt="logo_image"
            style={{ borderRadius: "15px", height: "90px" }}
            imageUrl={basicDetails.gameLogoUrl}
            uploadUrl={URLS.FILES.FACILITES_IMAGES}
            setImageData={(e) => {
              setBasicDetails({
                ...basicDetails,
                gameLogoUrl: e.imageFullUrl,
              });
            }}
          ></ImageProcessor>
        </div>
        <div className="edit-section-margin">
          <label className="field-title">Tag Line</label>
          <Controls.InputControl
            name="tagline"
            other={{ placeholder: "Tag Line" }}
            style={{ width: "100%", marginTop: "5px" }}
            onChange={(e) =>
              setBasicDetails({
                ...basicDetails,
                tagLine: e.target.value,
              })
            }
            value={basicDetails.tagLine}
            //error={validation.planName}
          ></Controls.InputControl>
        </div>
        <div className="edit-section-margin">
          <label className="field-title">Facility Description</label>
          <Controls.InputControl
            name="description"
            other={{ placeholder: "Facility Description" }}
            style={{ width: "100%", marginTop: "5px" }}
            onChange={(e) =>
              setBasicDetails({
                ...basicDetails,
                gameDescription: e.target.value,
              })
            }
            value={basicDetails.gameDescription}
            //error={validation.planName}
          ></Controls.InputControl>
        </div>
        <div className="mt-20 text-align-right">
          <Controls.ButtonControl
            type="submit"
            text="Submit"
            onClick={handleSubmit}
            disabled={isSaving}
            innercomponent={
              isSaving && (
                <CircularProgress
                  size={20}
                  sx={{
                    color: amber[500],
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    marginTop: "-12px",
                    marginLeft: "-12px",
                  }}
                />
              )
            }
          ></Controls.ButtonControl>
        </div>
      </div>
    </div>
  );
};

export default BasicDetails;
