import { useState } from "react";

import AssetNavigation from "../AssetNavigation/asset-navigation.component";
import AssetOffersList from "./AssetOffersList/asset-offers-list.component";
import AddEditAssetOffer from "./AddEditAssetOffer/addedit-asset-offer.component";
import GuideAssetOperations from "../Assets/GuideAssetOperations/guide-asset-operations.component";

const newassetOffer = {
  assetOfferId: 0,
  assetOfferName: "",
  offerType: 1,
  offerAmount: 0,
  assetOfferCode: "",
  assetOfferDescription: "",
  assetOfferTermsAndConditions: "",
};

const AssetOfferDashboard = () => {
  const [selectedAssetOffer, setSelectedAssetOffer] = useState(null);
  const [selectedView, setSelectedView] = useState("");
  const [selectedAssetOffersList, setSelectedAssetOffersList] = useState([]);

  const handleAssetOfferSelected = (assetOffer) => {
    setSelectedAssetOffer(assetOffer);
    setSelectedView("addeditview");
    setSelectedAssetOffersList([assetOffer.assetOfferId]);
  };

  return (
    <div>
      <div>
        <AssetNavigation
          isCreateAssetVisible={false}
          isCreateNewAssetOfferVisible={true}
          isCreateNewAssetTypeVisible={false}
          isAssetDashboardVisible={true}
          isAssetOffersDashboardVisible={false}
          isAssetTypeDashboardVisible={true}
          checkBookingPerformence={true}
          checkBookingEarningPerformence={true}
          createNewAssetOfferClick={() => handleAssetOfferSelected(newassetOffer)}
        ></AssetNavigation>
      </div>
      <div className="display-inline-flex w-100">
        <div className="items-list-container w-50">
          <div className="items-list">
            <AssetOffersList
              selectedAssetOfferList={selectedAssetOffersList}
              clickSelectAssetOffer={(assetoffer) => {
                handleAssetOfferSelected(assetoffer);
              }}
            ></AssetOffersList>
          </div>
        </div>
        <div className="items-edit-container  w-50">
          {selectedView == "" && <GuideAssetOperations></GuideAssetOperations>}
          {selectedView == "addeditview" && (
            <AddEditAssetOffer
              selectedAssetOffer={selectedAssetOffer}
              cancelClick={() => {
                setSelectedView("");
                setSelectedAssetOffersList([]);
              }}
            ></AddEditAssetOffer>
          )}
        </div>
      </div>
    </div>
  );
};

export default AssetOfferDashboard;
