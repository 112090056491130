const GuideBrandingOperations = () => 
{
    return (
      <div className="edit-container">
        <div style={{ display: "inherit" }} className="operations-title">
          <div
            style={{ margin: "auto", textAlign: "center", marginTop: "30px" }}
          >
            Show and advertise sports merchandise/articles/events on the Playnoot app
          </div>
        </div>
        <div className="mt-50 p-20">
          <div className="row">
            <div className="col-md-6">
              <h5 className="text-theme font-600 cursor-pointer">
                Create a Brand
              </h5>
              <span>
                create a brand poster to be advertise anything on the playnoot app.
              </span>
            </div>
            <div className="col-md-6">
              <h5 className="text-theme font-600 cursor-pointer">
                Add URLs
              </h5>
              <span>Add urls to particular site to point take user to your content.</span>
            </div>
          </div>
          <div className="row mt-20">
            <div className="col-md-6">
              <h5 className="text-theme font-600 cursor-pointer">
                Make branding Auto Expire
              </h5>
              <span>
                Brand posters can auto expire if you spedify expiry date.
              </span>
            </div>
            <div className="col-md-6">
              {/* <h5 className="text-theme font-600 cursor-pointer">Pricings</h5>
              <span>Put resonable price for each product to increase sale</span> */}
            </div>
          </div>
        </div>
      </div>
    );
}

export default GuideBrandingOperations;