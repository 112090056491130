import RatingReviewDetails from "./RatingReviewDetails/rating-review-details.component";
import RatingReviewList from "./RatingReviewList/rating-review-list.component";
const ReviewDashBoard = () => {
  return (
    <div>
      <RatingReviewDetails></RatingReviewDetails>
      <RatingReviewList></RatingReviewList>
    </div>
  );
};

export default ReviewDashBoard;
