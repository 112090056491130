import ButtonControl from "../../../components/Controls/ButtonControl";

const ExternalProductNavigation = ({
  checkBookingPerformence,
  createNewProductClick,
}) => {
  return (
    <div>
      <div style={{ float: "right" }}>
        <ButtonControl
          text={"Selling Performence"}
          style={{ width: "220px", margin: "0px 5px", background: "gray" }}
          onClick={() => checkBookingPerformence()}
        ></ButtonControl>
        <ButtonControl
          text={"Create new Product"}
          style={{ width: "220px", margin: "0px 5px" }}
          onClick={() => {
            createNewProductClick();
          }}
        ></ButtonControl>
      </div>
    </div>
  );
};

export default ExternalProductNavigation;
