import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const FontAwesome = ({ icon, iconcolor, iconSize, iconText, iconTextStyle, className }) => {
  return (
    <div className={className}>
      <FontAwesomeIcon
        fontSize={iconSize}
        color={iconcolor}
        icon={icon}
      />
      <span style={iconTextStyle}>{iconText}</span>
    </div>
  );
};
export default FontAwesome;
