import React from "react";
import "./loader-styles.scss";

const Loader = ({ isLoading, type = "" }) => {
  if (!isLoading) return <div className='div'></div>;

  switch (type) {
    case "wave":
      return (
        <div className='loaderContainer'>
          <div className='lds-ripple loaderPos'>
            <div></div>
            <div></div>
          </div>
        </div>
      );
    default:
      return (
        <div className='loaderContainer'>
          <div className='loaderCustom'>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      );
  }
};

Loader.defaultProps = {
  isLoading: false,
  simple: "simple",
};

export default Loader;
