import React from "react";
import { connect } from "react-redux";
import { store } from "../../store/make-store";
import { flowRight, isEmpty } from "lodash";
import { createStructuredSelector } from "reselect";
import withRouter from "../../hoc/withRouter";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faCrown } from "@fortawesome/free-solid-svg-icons";
import { Typography, Pagination } from "@mui/material";

import { CREATE_GAME_CENTER } from "../../configs/routes";

import GameCenterView from "../../components/GameCenterPreview";
import OfferView from "../../components/OfferView";
import ModalPopup from "../../components/ModalPopup/modalpopup.component";

import { fetchGameCenterList } from "../../modules/GameCenter/actions";
import { setIsRenderedState } from "../../modules/ModalPopup/modalPopupSlice";
import {
  getGameCenterList,
  getIsRequesting,
} from "../../modules/GameCenter/selector";
import { selectIsModalPopupRendered } from "../../modules/ModalPopup/selector";

import { URLS } from "../../configs/api_urls";
import { httpGet } from "../../utility/request";

import {
  selectApplicationInfo,
  selectApplicationLicense,
  selectApplicationLicenseConsumption,
  selectIsLoading,
  selectIsLoadingApp,
} from "../../modules/App/selectors";

import "./module.scss";
import CenterContentLoader from "../../components/CenterLoader";
import NoContentMessage from "../../components/NoRowMessage";
import { Button } from "@mui/material";

const dummyOffers = [
  {
    image:
      "https://img.freepik.com/free-vector/special-offer-modern-sale-banner-template_1017-20667.jpg?w=2000",
    name: "Welcome Offer",
    desc: "Welcoming offer with 6 month of absolutely free services",
  },
  {
    image:
      "https://yt3.ggpht.com/ytc/AMLnZu9yL2KvkEw74kOMeaaV0Cap37NAiMrS_3wK6YGmpg=s900-c-k-c0x00ffffff-no-rj",
    name: "One year subscription",
    desc: "Get one year subscription at as low as 1000 rupess",
  },
  {
    image:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRUsW31ne3FjxSyjhEoFjH0CLZA0cm_KuW7Og&usqp=CAU",
    name: "Referal Offer",
    desc: "Refer playnoot services and get one year of free subscription",
  }
];
const CENTER_PER_PAGE = 5;

class GameCenterHomePage extends React.Component {
  state = {
    currentPage: 1,
    centerSliceBeg: 0,
    centerSliceEnd: CENTER_PER_PAGE,
    offerLoading: true,
  };

  componentDidMount() {
    const { fetchGameCenterList } = this.props;
    fetchGameCenterList();
    setTimeout(() => this.setState({ offerLoading: false }), 1000);
  }

  handleSetLiveClick = async (id) => {
    try {
      var res = await httpGet(URLS.ADMIN.MARKCENTERLIVE.replace("#ID#", id));
      if (res) {
        if (res.data === true) {
          store.dispatch(setIsRenderedState(false));
          store.dispatch(fetchGameCenterList());
        }
      }
    } catch (err) {
      this.setState({ setLiveError: err.response.data.Message });
    }
  };

  handleSetOfflineClick = async (id) => {
    try {
      var res = await httpGet(URLS.ADMIN.MARKCENTEROFFLINE.replace("#ID#", id));
      if (res) {
        if (res.data === true) {
          store.dispatch(setIsRenderedState(false));
          store.dispatch(fetchGameCenterList());
        }
      }
    } catch (err) {
      this.setState({ setLiveError: err.response.data.Message });
    }
  };

  handlePageChange = (e, page) => {
    this.setState({
      currentPage: page,
      centerSliceBeg: CENTER_PER_PAGE * (page - 1),
      centerSliceEnd: CENTER_PER_PAGE * page,
    });
  };

  render() {
    const {
      gameCenterList = [],
      isRequesting,
      applicationInfo,
      applicationLicense,
      applicationConsumption,
      isAppInfoLoading,
    } = this.props;
    const {
      currentPage,
      centerSliceBeg,
      centerSliceEnd,
      offerLoading,
      setLiveModalPopupType,
      setLiveCenterId,
      setLiveError,
    } = this.state;

    const handlePopupClick = (event, id) => {
      this.setState({ setLiveModalPopupType: event.target.checked });
      this.setState({ setLiveCenterId: id });
      store.dispatch(setIsRenderedState(true));
    };

    return (
      <div
        className="container-fluid scrollbar"
        style={{ maxHeight: "calc(100vh - 80px)", overflowY: "scroll"}}
      >
        {setLiveModalPopupType == true && (
          <ModalPopup
            heading={"Setting your center live?"}
            subHeadhing={
              "Following action will make this center live on the Playnoot mobile app thus prople will be able to book online."
            }
            isConfirmButton={true}
            confirmButtonText={"Set Live"}
            body={
              <div>
                <ul className="set-live-popup">
                  <li>
                    Make sure this center have active bank account attached to
                    it.
                  </li>
                  <li>Make sure the bank account is verified.</li>
                  <li>
                    Make sure you have bookable assets created under this
                    center.
                  </li>
                </ul>
              </div>
            }
            onConfirmClick={() => {
              this.handleSetLiveClick(setLiveCenterId);
            }}
            isCancelButton={true}
            cancelButtontext={"Cancel"}
            onCancelClick={() => {
              store.dispatch(setIsRenderedState(false));
              this.setState({ setLiveError: null });
            }}
            errorContent={setLiveError}
          ></ModalPopup>
        )}
        {setLiveModalPopupType == false && (
          <ModalPopup
            heading={"Making this center Offline?"}
            subHeadhing={
              "Following action will set this center offline and make it no longer bookable through mobile app."
            }
            body={
              <div>
                <ul className="set-live-popup">
                  <li>No new booking will be made.</li>
                  <li>
                    All existing booking will stay there and needs to be
                    fullfilled.
                  </li>
                  <li>
                    In order to cancel existing bookings you will have to refund
                    booking amount if paid.
                  </li>
                </ul>
              </div>
            }
            isConfirmButton={true}
            confirmButtonText={"Set Offline"}
            onConfirmClick={() => {
              this.handleSetOfflineClick(setLiveCenterId);
            }}
            isCancelButton={true}
            cancelButtontext={"Cancel"}
            onCancelClick={() => {
              store.dispatch(setIsRenderedState(false));
              this.setState({ setLiveError: null });
            }}
          ></ModalPopup>
        )}
        <div className="row">
          {applicationLicense && (
            <div className="col-lg-8 p-2 px-lg-3">
              <div className="d-flex mb-2">
                <Typography variant="h4" className="font-family-unset">
                  Game Centers
                </Typography>
                {applicationLicense.license.maximumInstancesAllowed -
                  applicationConsumption.numberOfCurrentApplicationInstances >
                0 ? (
                  <button
                    className="theme-btn create_btn d-flex px-2 ml-auto"
                    onClick={() => {
                      this.props.router(CREATE_GAME_CENTER);
                    }}
                  >
                    <FontAwesomeIcon icon={faPlus} className="mx-2" />
                    Create Center
                  </button>
                ) : (
                  <Button
                    sx={{ float: "right", marginLeft: "100px" }}
                    onClick={() => {
                      this.props.router("/admin/license-plans");
                    }}
                    variant="contained"
                  >
                    <FontAwesomeIcon
                      className="text-small mr-2"
                      icon={faCrown}
                    />
                    Upgrade to add more Centers
                  </Button>
                )}
              </div>

              {isRequesting && gameCenterList.length == 0 && (
                <>
                  <CenterContentLoader />
                  <CenterContentLoader />
                  <CenterContentLoader />
                  <CenterContentLoader />
                  <CenterContentLoader />
                </>
              )}

              {!isRequesting && gameCenterList.length == 0 && (
                <NoContentMessage message="Sorry! No Center Found. Please Create First" />
              )}

              {/* Game Center list */}
              {!isEmpty(gameCenterList) && (
                <div className="my-2">
                  {[...gameCenterList]
                    .slice(centerSliceBeg, centerSliceEnd)
                    .map((center) => {
                      return (
                        <GameCenterView
                          gameCenter={center}
                          key={center.applicationInstanceId}
                          onClickLiveSwitch={(event) => {
                            handlePopupClick(
                              event,
                              center.applicationInstanceId
                            );
                          }}
                        />
                      );
                    })}

                  <div className="row">
                    <Pagination
                      page={currentPage}
                      count={
                        Math.ceil(gameCenterList.length / CENTER_PER_PAGE) || 1
                      }
                      onChange={this.handlePageChange}
                    />
                  </div>
                </div>
              )}
            </div>
          )}
          {/* d☺ummy list */}
          <div className="col-lg-4 vh-75 mt-lg-2 px-2">
            <Typography variant="h4" className="font-family-unset mx-4">
              Offers
            </Typography>
            {offerLoading ? (
              <>
                <CenterContentLoader />
                <CenterContentLoader />
              </>
            ) : (
              <div className="my-2 pl-4">
                {dummyOffers.map((offer, index) => {
                  return <OfferView offer={offer} key={index} />;
                })}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  gameCenterList: getGameCenterList(),
  isRequesting: getIsRequesting(),
  applicationInfo: selectApplicationInfo,
  applicationLicense: selectApplicationLicense,
  applicationConsumption: selectApplicationLicenseConsumption,
  isAppInfoLoading: selectIsLoadingApp,
});

const compose = flowRight(
  withRouter,
  connect(mapStateToProps, { fetchGameCenterList })
);

export default compose(GameCenterHomePage);
