import { URLS } from "../../configs/api_urls";
import { httpGet } from "../../utility/request";

import { createAction } from "../../utility/reducer.utils";
import { MAIN_APP_ACTION_CONSTANTS } from "./constants"

import { ADD_ALERT } from "./constants";

import { fetchCartItemsSuccess } from "../cart/cart.action";


export const addAlert = (data) => {
  return { type: ADD_ALERT, data };
};

export const fetchAppInformation = () => {

  return async (dispatch) => {
    const url1 = URLS.LICENSE.GET_APPLICATION_LICENSE;
    const url2 = URLS.LICENSE.GET_APPLICATION_LICENSE_CONSUMPTION;
    const url3 = URLS.CART.GET_CART_ITEMS;
    const url4 = URLS.APPLICATION.APPLICATION_INFO;
    
    dispatch(mainAppRequestStart());

    Promise.all([httpGet(url1), httpGet(url2), httpGet(url3), httpGet(url4)])
      .then(function (results) {
        dispatch(mainAppSetApplicationLicense(results[0].data));
        dispatch(mainAppSetApplicationLicenseConsumption(results[1].data));
        dispatch(fetchCartItemsSuccess(results[2].data));
        dispatch(mainAppSetApplicationInfo(results[3].data));
        dispatch(mainAppRequestSuccess());
      })
      .catch((error) => {
        dispatch(mainAppRequestFailure(error));
      });
  };
};

// #region

export const mainAppRequestStart = () => {
  return createAction(MAIN_APP_ACTION_CONSTANTS.MAIN_APP_REQUEST_START, true);
}

export const mainAppRequestSuccess = () => {
  return createAction(MAIN_APP_ACTION_CONSTANTS.MAIN_APP_REQUEST_SUCCESS, false);
};

export const mainAppRequestFailure = (error) => {
  return createAction(MAIN_APP_ACTION_CONSTANTS.MAIN_APP_REQUEST_FAILURE, error);
};

export const mainAppSetApplicationInfo = (data) => {
  return createAction(MAIN_APP_ACTION_CONSTANTS.SET_APPLICATION_INFO, data);
};

export const mainAppSetApplicationLicense = (data) => {
  return createAction(MAIN_APP_ACTION_CONSTANTS.SET_APPLICATION_LICENSE, data);
};

export const mainAppSetApplicationLicenseConsumption = (data) => {
  return createAction(MAIN_APP_ACTION_CONSTANTS.SET_APPLICATION_LICENSE_CONSUMPTION, data);
};
// #endregion