import "./module.scss";
import { Card } from "@mui/material";

const OfferView = (props) => {
  const { offer = {} } = props;

  return (
    <div className="offer_container my-3">
      <Card className='p-2'>
        <div className='text-align-left d-flex'>
          <div className='pic'>
            <div className="preview">
              <img src={offer.image} alt='' />
            </div>
          </div>
          <div className='info'>
            <div className="details">
              <div className='d-flex '>
                <h6 className="mb-0 pb-0">{offer.name}</h6>
              </div>
              <p className='mt-0 text-small'>{offer.desc}</p>
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default OfferView;
