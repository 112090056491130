import React, { useState } from "react";
import { Card } from "@mui/material";
import Carousel from "react-bootstrap/Carousel";

import LoginForm from "./LoginForm";
import ForgotPasswordForm from "./ForgotPasswordForm";

import Logo from "../../static/logo.png";

import "./login-styles.scss";

const Login = () => {
  /**
   * Set inital State
   */
  const [activeForm, setActiveForm] = useState(0);

  /**
   * Switch to Login
   */
  const backToLogin = () => {
    setActiveForm(0);
  };

  /**
   * Switch to Forgot Password form
   */
  const goToForgotPassword = () => {
    setActiveForm(1);
  };

  /**
   * Render View
   *
   * @return JSX
   */
  return (
    <div>
      {/* Background Image */}
      <div className='login_background'></div>

      {/* Card Container */}
      <div className='form_container'>
        <Card className='login_form mx-auto py-4 px-4 border-radius-10'>
          <h5
            variant='h5'
            className={`letter-spacing-2 font-600 text-dark-2 mb-2`}
            component='h5'>
            <img src={Logo} alt='' className='logo' /> PLAYNOOT
          </h5>
          <Carousel
            controls={false}
            interval={null}
            indicators={false}
            activeIndex={activeForm}>
            {/* Login Form  */}
            <Carousel.Item>
              <LoginForm goToForgotPassword={goToForgotPassword}></LoginForm>
            </Carousel.Item>

            {/* Forgot Password Form  */}
            <Carousel.Item>
              <ForgotPasswordForm
                backToLogin={backToLogin}></ForgotPasswordForm>
            </Carousel.Item>
          </Carousel>
        </Card>
      </div>
    </div>
  );
};

export default Login;
