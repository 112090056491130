import { useState } from "react";

import AddEditBrand from "./AddEditBrand/addedit-brand.component";
import BrandList from "./BrandList/brand-list.component";
import BrandingNavigation from "./BrandingNavigation/branding-navigation.component";
import GuideBrandingOperations from "./GuideBrandingOperations/guide-branding-operations.component";

const BrandingsDashboard = () => 
{
      const [selectedBrand, setSelectedBrand] = useState(null);
      const [selectedView, setSelectedView] = useState("");
      const [selectedBrandList, setSelectedBrandList] = useState([]);

      var newBrand = {
        instanceBrandingId: 0,
        instanceBrandingTitle: "",
        instanceBrandingDescription: "",
        brandingImageUrl: "",
        brandSeller: "",
        brandSellerItemUrl: "",
        expiryDateTime: new Date()
      };

      const handleBrandSelected = (brand) => {
        setSelectedBrand(brand);
        setSelectedView("addeditview");
        setSelectedBrandList([brand.instanceBrandingId]);
      };

    return (
      <div>
        <div>
          <BrandingNavigation
            createNewBrandClick={() => handleBrandSelected(newBrand)}
          ></BrandingNavigation>
        </div>
        <div>
          <div className="display-inline-flex w-100">
            <div className="items-list-container w-50">
              <div className="items-list">
                <BrandList
                  selectedBrandList={selectedBrandList}
                  clickSelectBrand={(brand) => {
                    handleBrandSelected(brand);
                  }}
                ></BrandList>
              </div>
            </div>
            <div className="items-edit-container  w-50">
              {selectedView == "" && (
                <GuideBrandingOperations></GuideBrandingOperations>
              )}
              {selectedView == "addeditview" && (
                <AddEditBrand
                  selectedBrand={selectedBrand}
                  cancelClick={() => {
                    setSelectedView("");
                    setSelectedBrandList([]);
                  }}
                ></AddEditBrand>
              )}
            </div>
          </div>
        </div>
      </div>
    );
}

export default BrandingsDashboard;