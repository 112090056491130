import ButtonControl from "../../../../components/Controls/ButtonControl";
import SelectControl from "../../../../components/Controls/SelectControl";

const BankDetailsNavigation = ({
    selectedVerificationFilter,
    setVerificationFilter
}) => {

    const verificationFilterOptions = [
      { id: 0, title: "All" },
      { id: 1, title: "Submitted" },
      { id: 2, title: "Verified" },
      { id: 3, title: "Failed" },
    ];

  return (
    <div>
      <div style={{ marginBottom: "10px", display: "flex" }}>
        <div>
          <SelectControl
            name="confirmationFilter"
            showDefaultOption={false}
            options={verificationFilterOptions}
            value={selectedVerificationFilter}
            style={{ width: "250px", marginLeft: "10px" }}
            onChange={(e) => setVerificationFilter(e.target.value)}
          ></SelectControl>
        </div>
        <div style={{ marginLeft: "auto" }}>
          <ButtonControl
            text={"Payment Analytics"}
            style={{ width: "220px", margin: "0px 5px", background: "gray" }}
            //onClick={() => checkBookingPerformence()}
          ></ButtonControl>
          <ButtonControl
            text={"Daily Payments"}
            style={{ width: "220px", margin: "0px 5px" }}
            //    onClick={() => {
            //      createNewProductClick();
            //    }}
          ></ButtonControl>
        </div>
      </div>
    </div>
  );
}

export default BankDetailsNavigation;