import { useState, useEffect } from "react";

import { Chip, Table, Typography, Box } from "@mui/material";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import FontAwesome from "../../../components/Icons/fontawesome";
import {
  faCheckCircle,
  faClock,
  faShare,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";

import { URLS } from "../../../configs/api_urls";
import { httpGet } from "../../../utility/request";

import moment from "moment-timezone";

const BookingDetails = ({ booking }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [bookingDetail, setBookingDetails] = useState(null);

  const GetBookingDetails = async (bookingId) => {
    setIsLoading(true);
    await httpGet(URLS.BOOKING.GETBOOKINGDETAILSBYID.replace("#ID#", bookingId))
      .then((response) => {
        var data = response.data;
        setBookingDetails(data);
      })
      .catch((err) => {
        //err
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    GetBookingDetails(booking.booking_id);
  }, [booking]);

  return (
    <div>
      <div style={{ textAlign: "center", marginTop: "10px" }}>
        {booking.booking_status == "Confirmed" && (
          <div>
            <FontAwesome
              icon={faCheckCircle}
              iconSize={"60px"}
              iconcolor={"green"}
            ></FontAwesome>
            <Typography variant="h5" sx={{ textAlign: "center" }}>
              Booking Confirmed
            </Typography>
          </div>
        )}
        {booking.booking_status == "Initiated" && (
          <div>
            <FontAwesome
              icon={faClock}
              iconSize={"60px"}
              iconcolor={"gray"}
            ></FontAwesome>
            <Typography variant="h5" sx={{ textAlign: "center" }}>
              Booking Initiated
            </Typography>
          </div>
        )}
        {booking.booking_status == "Cancelled" && (
          <div>
            <FontAwesome
              icon={faTimesCircle}
              iconSize={"60px"}
              iconcolor={"gray"}
            ></FontAwesome>
            <Typography variant="h5" sx={{ textAlign: "center" }}>
              Booking Cancelled
            </Typography>
          </div>
        )}
      </div>
      {!isLoading && (
        <div className="mt-20">
          <Typography variant="h6" sx={{ textAlign: "center" }}>
            Booking Details
          </Typography>
          <Table sx={{ minWidth: "500px", fontFamily: "Kanit" }}>
            <TableRow>
              <TableCell sx={{ border: "none" }}>Booking Id</TableCell>
              <TableCell sx={{ float: "right", border: "none" }}>
                #{bookingDetail.bookingId}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ border: "none" }}>Booked Asset</TableCell>
              <TableCell sx={{ float: "right", border: "none" }}>
                {bookingDetail.asset.assetName}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ border: "none" }}>Booking Date</TableCell>
              <TableCell sx={{ float: "right", border: "none" }}>
                {moment
                  .utc(bookingDetail.bookingDate)
                  .local()
                  .format("MMM DD, yyyy")}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ border: "none" }}>Slots</TableCell>
              <TableCell
                sx={{ float: "right", border: "none", maxWidth: "400px" }}
              >
                {bookingDetail.bookingTimeSlots.map((slot, index) => (
                  <Chip
                    variant="outlined"
                    key={`slot-${index}`}
                    label={slot.timeSlot.slotDisplayText}
                    style={{
                      height: "40px",
                      margin: "5px 2px",
                      fontSize: "16px",
                    }}
                    clickable
                    //handleClick={handleDisableSlot}
                  />
                ))}
              </TableCell>
            </TableRow>
          </Table>
        </div>
      )}

      {!isLoading && (
        <Box className="mt-20">
          {bookingDetail.externalProductOrders.length > 0 && (
            <Box>
              <Typography variant="h6" sx={{ textAlign: "center" }}>
                Product Orders
              </Typography>
              <Table sx={{ minWidth: "500px", fontFamily: "Kanit" }}>
                {bookingDetail.externalProductOrders.map((order) => {
                  return (
                    <TableRow>
                      <TableCell sx={{ border: "none" }}>
                        {order.externalProduct.productName}
                      </TableCell>
                      <TableCell sx={{ float: "right", border: "none" }}>
                        {order.countOrdered} Qty.
                      </TableCell>
                    </TableRow>
                  );
                })}
              </Table>
            </Box>
          )}
        </Box>
      )}

      {!isLoading && (
        <div className="mt-20">
          <Typography variant="h6" sx={{ textAlign: "center" }}>
            Payment Breakup
          </Typography>
          <Table sx={{ minWidth: "500px", fontFamily: "Kanit" }}>
            <TableRow>
              <TableCell>Payment Id</TableCell>
              <TableCell sx={{ float: "right" }}>
                #{bookingDetail.instaMojoPaymentId}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ border: "none" }}>Slot Bill</TableCell>
              <TableCell sx={{ float: "right", border: "none" }}>
                {bookingDetail.slotTotalPrice + " Rs."}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ border: "none" }}>Products Bill</TableCell>
              <TableCell sx={{ float: "right", border: "none" }}>
                {bookingDetail.totalExternalProductBill + " Rs."}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ border: "none" }}>
                Advance Booking charges
              </TableCell>
              <TableCell sx={{ float: "right", border: "none" }}>
                {bookingDetail.bookingCharges + " Rs."}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ border: "none" }}>Platform charges</TableCell>
              <TableCell sx={{ float: "right", border: "none" }}>
                {bookingDetail.platFormCharges + " Rs."}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Offer reduction</TableCell>
              <TableCell sx={{ float: "right" }}>
                {" - " + bookingDetail.offerReduction + " Rs."}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ border: "none", fontWeight: "bold" }}>
                Total Bill
              </TableCell>
              <TableCell
                sx={{ float: "right", border: "none", fontWeight: "bold" }}
              >
                {bookingDetail.totalBill + " Rs."}
              </TableCell>
            </TableRow>
          </Table>
        </div>
      )}
    </div>
  );
};

export default BookingDetails;
