import React, { useEffect, useState } from "react";

import Header from "../Header";
import SideNavBar from "../SideNavBar";
import Notify from "../Notify";

import "./layout.scss";
import { useSelector, useDispatch } from "react-redux";
import {
  selectApplicationLicense,
  selectIsLoadingApp,
} from "../../modules/App/selectors";
import { fetchAppInformation } from "../../modules/App/actions";

import WelcomeFlyer from "../WelcomeFlyer/welcome-flyer.component";
import Loader from "../Loader";

const AppLayout = ({
  children,
  selected_nav_item = 1,
  nav_items,
  handleOnLinkClick = null,
  theme = {},
}) => {
  const dispatch = useDispatch();
  const applicationLicense = useSelector(selectApplicationLicense);
  const applicationInfoLoading = useSelector(selectIsLoadingApp);

  useEffect(() => {
    dispatch(fetchAppInformation());
  }, []);
  
  return (
    <div>
      {applicationInfoLoading == false ? (
        <div>
          <Header theme={theme} />
          <Notify />

          {applicationLicense && (
            <SideNavBar
              selected_nav_item={selected_nav_item}
              nav_items={nav_items}
              handleOnLinkClick={handleOnLinkClick}
              theme={theme}
            ></SideNavBar>
          )}
          <div
            style={{ maxHeight: "calc(100vh - 80px)", overflowY: "scroll" }}
            className={`main scrollbar ${
              applicationLicense ? "main_normal" : ""
            }`}
          >
            {!applicationLicense && <WelcomeFlyer></WelcomeFlyer>}
            {children}
          </div>
          {/* Footer */}
        </div>
      ) : (
        <Loader isLoading={applicationInfoLoading}></Loader>
      )}
    </div>
  );
};

export default AppLayout;
