import { createSelector } from "reselect";

export const selectGameCenterReducer = () => (state) => state.GameCenter;

export const getGameCenterList = () =>
  createSelector(selectGameCenterReducer(), (state) => {
    const data = state.game_center_list;
    return data;
  });

export const getStandardGamesDto = () =>
  createSelector(selectGameCenterReducer(), (state) => {
    const data = state.standard_games_dto;
    return data;
  });

export const getFacilityAmenitiesDto = () =>
  createSelector(selectGameCenterReducer(), (state) => {
    const data = state.facilities_amenities;
    return data;
  });

export const getAllPolicyTypes = () =>
  createSelector(selectGameCenterReducer(), (state) => {
    const data = state.all_policies;
    return data;
  });

export const getCreateCenterData = () =>
  createSelector(selectGameCenterReducer(), (state) => {
    const data = state.create_center_data;
    return data;
  });

export const getActiveForm = () =>
  createSelector(selectGameCenterReducer(), (state) => {
    const data = state.active_form;
    return data;
  });

export const getCenterImages = () =>
  createSelector(selectGameCenterReducer(), (state) => {
    const data = state.images;
    return data;
  });

export const getAddressDto = () =>
  createSelector(selectGameCenterReducer(), (state) => {
    const data = state.create_center_data.addressDto;
    return data;
  });

export const getThemesDto = () =>
  createSelector(selectGameCenterReducer(), (state) => {
    const data = state.themes_dto;
    return data;
  });

export const getCenterPolicy = () =>
  createSelector(selectGameCenterReducer(), (state) => {
    const data = state.create_center_data.applicationInstanceCenterPolicies;
    return data || [];
  });

export const getIsRequesting = () =>
  createSelector(selectGameCenterReducer(), (state) => {
    const data = state.isRequesting;
    return data;
  });
