import { Chip, Checkbox } from "@mui/material";

import "./asset-list-item.styles.scss"

const AssetListItem = ({
  asset,
  clickSelectAsset,
  isSelected,
  isDisabled,
  addToSelectedList,
}) => {
  return (
    <div>
      <div
        className={
          "asset-item cursor-pointer " +
          (isSelected ? "seleted-asset-background" : "")
        }
        onClick={clickSelectAsset}
      >
        <div className="w-100 display-inline-flex">
          <div className="w-10" style={{ margin: "auto" }}>
            <Checkbox
              id={asset.assetId}
              checked={isSelected}
              onChange={addToSelectedList}
            />
          </div>
          <div className="w-80">
            <label>{asset.assetName}</label>
            <br />
            <span style={{ color: "dimgray" }}>{asset.assetDescription}</span>
            <div className="row">
              <span className="col-md-4">
                Today's Bookings : {asset.todaysBookings}
              </span>
              <span className="col-md-4">
                Upcoming : {asset.upcomingBookings}
              </span>
              <span className="col-md-4">
                Pricing : {asset.pricingType}
              </span>
            </div>
          </div>
          <div className="w-10" style={{ margin: "auto" }}>
            <Chip label={asset.totalBookings} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssetListItem;
