import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { URLS } from "../../../../configs/api_urls";
import { httpGet } from "../../../../utility/request";
import { fireEvent } from "../../../../modules/Events/eventSlice";
import { Typography, Box } from "@mui/material";
import SelectControl from "../../../../components/Controls/SelectControl";
import LicenseItem from "./LicenseItem/license-item.component";
import LicenseListSkeleton from "../Skeleton/license-list-skeleton.component";

const licenseAvailability = [
  { id: 0, title: "Active", isActive: true },
  { id: 1, title: "InActive", isActive: false },
];

const LicenseList = ({
  selectedLicenseList,
  clickSelectLicense,
  addToSelectedList,
}) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [licenses, setLicenses] = useState([]);
    const [selectedFilter, setSelectedFilter] = useState(0);
    const [selectedFilterItems, setSelectedFilterItems] = useState([]);

  const fetchAllLicensess = async () => {
    setIsLoading(true);
    const { data } = await httpGet(URLS.SUPER_ADMIN.LICENSE.GET_LICENSE_PLANS);
    setLicenses(data);
    var plans = data.filter((p) => p.isActive === true);
    setSelectedFilterItems(plans);
    setIsLoading(false);
  };

  const event = useSelector((onEvent) => {
    var eventName = onEvent.events.eventName;
    if (eventName === "refresh-licenses-list") {
      fetchAllLicensess();
      dispatch(fireEvent({ eventName: "" }));
    }
    //reset event listner
  });

    const handleFilterChange = (filter) => {
      var isActive = licenseAvailability.find((x) => x.id == filter).isActive;
      setSelectedFilter(filter);
      var plans = licenses.filter((p) => p.isActive === isActive);
      setSelectedFilterItems(plans);
    };

  useEffect(() => {
    fetchAllLicensess();
  }, []);

  return (
    <Box>
      <Box className="list-filter-header">
        <SelectControl
          name="asset-availability"
          style={{ width: "120px", height: "35px" }}
          options={licenseAvailability}
          value={selectedFilter}
          onChange={(e) => {
            handleFilterChange(e.target.value);
            //cancelClick();
          }}
        ></SelectControl>
      </Box>
      <Box className="list-container scrollbar">
        {isLoading && <LicenseListSkeleton />}
        {licenses.length == 0 && !isLoading && (
          <Box className="no-item-section">
            <Typography className="no-item-message">
              You have not created any Ads yet. Add Ads to promote facility,
              blog or filters
            </Typography>
          </Box>
        )}
        {selectedFilterItems.map((license) => {
          return (
            <Box key={license.licenseId}>
              <LicenseItem
                license={license}
                isSelected={selectedLicenseList.includes(license.licenseId)}
                addToSelectedList={addToSelectedList}
                clickSelectLicense={() => {
                  clickSelectLicense(license);
                }}
              ></LicenseItem>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default LicenseList;
