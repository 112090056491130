import { useState } from "react";

import { Box } from "@mui/material";
import ButtonControl from "../../../components/Controls/ButtonControl";
import LicenseList from "./LicenseList/license-list.component";
import AddEditLicense from "./AddEditLicense/add-edit-license.component";
import GuideLicenseOperations from "./GuideLicenseOperations/guide-license-operations.component";

const newLicense = {
  licenseId: 0,
  licenseName: "",
  maximumInstancesAllowed: 0,
  maximumAdminsAllowed:0,
  maximumAllowedPlayers: 0,
  numberOfYearsDataStorage: 0,
  planFeatures: [
    {
      licensePlanFeatureName: "",
    },
  ],
  planPricings: [
    {
      licensePlanPricingType: 1,
      planDuration: 1,
      planPrice: 1000,
    },
  ],
  licensePlanOffers: [],
};

const LicenseDashboard = () => {
  const [selectedLicense, setSelectedLicense] = useState(null);
  const [selectedView, setSelectedView] = useState("");
  const [selectedLicenseList, setSelectedLicenseList] = useState([]);

    const handleLicenseSelected = (license) => {
      setSelectedLicense(license);
      setSelectedView("addeditview");
      setSelectedLicenseList([license.licenseId]);
    };

  return (
    <Box>
      <Box  className="text-align-right">
        <ButtonControl
          text={"Create new License"}
          style={{ width: "200px", margin: "0px 5px" }}
          onClick={() => {
            handleLicenseSelected(newLicense);
          }}
        ></ButtonControl>
      </Box>
      <Box>
        <Box>
          <Box className="display-inline-flex w-100">
            <Box className="items-list-container w-50">
              <Box className="items-list">
                <LicenseList
                  selectedLicenseList={selectedLicenseList}
                  clickSelectLicense={(license) => {
                    handleLicenseSelected(license);
                  }}
                ></LicenseList>
              </Box>
            </Box>
            <Box className="items-edit-container  w-50">
              {selectedView == "" && <GuideLicenseOperations />}
              {selectedView == "addeditview" && (
                <AddEditLicense
                  selectedLicense={selectedLicense}
                  cancelClick={() => {
                    setSelectedView("");
                    setSelectedLicenseList([]);
                  }}
                ></AddEditLicense>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default LicenseDashboard;
