import React from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

const withRouter = (WrapperComponent) => {
  return (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const params = useParams();
    return <WrapperComponent router={navigate} location={location} params={params} />;
  };
};

export default withRouter;
