import { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";

import { URLS } from "../../../../configs/api_urls";
import { httpDelete } from "../../../../utility/request";
import { fireEvent } from "../../../../modules/Events/eventSlice";
import { setIsRenderedState } from "../../../../modules/ModalPopup/modalPopupSlice";

import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import ButtonControl from "../../../../components/Controls/ButtonControl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileArchive } from "@fortawesome/free-solid-svg-icons";
import EditSubscriptionPlanOffer from "./EditSubscriptionPlanOffer/edit-subscriptionplan-offer.component";
import SubscriptionPlanOfferAnalytics from "./SubscriptionPlanOfferAnalytics/subscriptionplan-offer-analytics.components";
import ModalPopup from "../../../../components/ModalPopup/modalpopup.component";

const AddEditSubscriptionPlanOffer = ({ selectedOffer, cancelClick }) => {
  const dispatch = useDispatch();

  const handleSaveClick = () => {
    dispatch(fireEvent({ eventName: "save-subscription-plan-offer" }));
  };

  const handleArchieveButtonClick = () => {
    dispatch(setIsRenderedState(true));
  };
  const handlePopupCancelClick = () => {
    dispatch(setIsRenderedState(false));
  };

  const handelArchiveClick = async () => {
    await httpDelete(
      URLS.SUBSCRIPTION_PLAN_OFFERS.ARCHIVESUBSCRIPTIONPLANOFFER.replace(
        "#ID#",
        selectedOffer.subscriptionPlanOfferId
      )
    )
      .then((response) => {
        if (response.data == true) {
          dispatch(
            fireEvent({ eventName: "refresh-subscriptionplanoffer-list" })
          );
          dispatch(setIsRenderedState(false));
          cancelClick();
        }
      })
      .catch((error) => {
        //  setFormSubmitError(
        //    error.response.data.Message || "Some error occuered!"
        //  );
      });
  };

  const [activeTab, setActiveTab] = useState("settings");
  return (
    <div className="edit-container w-100">
      <div className="display-flex">
        <ButtonControl
          text={"Cancel"}
          onClick={() => cancelClick()}
        ></ButtonControl>
        <label className="edit-panel-title">
          {selectedOffer.subscriptionPlanOfferId > 0
            ? "Edit Offer"
            : "Add Offer"}
        </label>
        <ButtonControl text={"Save"} onClick={handleSaveClick}></ButtonControl>
        {selectedOffer.subscriptionPlanOfferId > 0 && (
          <ButtonControl
            onClick={handleArchieveButtonClick}
            style={{
              width: "40px",
              margin: "10px 5px",
              backgroundColor: "red",
            }}
            innercomponent={
              <FontAwesomeIcon icon={faFileArchive}></FontAwesomeIcon>
            }
          ></ButtonControl>
        )}
      </div>
      <ModalPopup
        heading={"Are you sure that you want to Arhive this offer?"}
        subHeadhing={"This will remove the linking of this offer with any of the Subscription plans. Hence, Mobile users will no longer be able to see this offer."}
        isConfirmButton={true}
        confirmButtonText={"Yes"}
        onConfirmClick={handelArchiveClick}
        isCancelButton={true}
        cancelButtontext={"No"}
        onCancelClick={handlePopupCancelClick}
      ></ModalPopup>
      <Tabs
        defaultActiveKey={activeTab}
        activeKey={activeTab}
        onSelect={(key) => setActiveTab(key)}
        id="fill-tab-example"
        className="mb-3"
        fill
      >
        <Tab eventKey="settings" title="Settings">
          <EditSubscriptionPlanOffer
            selectedOffer={selectedOffer}
            closeEdit={() => cancelClick()}
          ></EditSubscriptionPlanOffer>
        </Tab>
        <Tab eventKey="analytics" title="Analytics">
          <SubscriptionPlanOfferAnalytics></SubscriptionPlanOfferAnalytics>
        </Tab>
      </Tabs>
    </div>
  );
};

export default AddEditSubscriptionPlanOffer;
