import { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";

import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import ButtonControl from "../../../components/Controls/ButtonControl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileArchive } from "@fortawesome/free-solid-svg-icons";

import { URLS } from "../../../configs/api_urls";
import { httpDelete } from "../../../utility/request";
import { fireEvent } from "../../../modules/Events/eventSlice";
import { setIsRenderedState } from "../../../modules/ModalPopup/modalPopupSlice";

import EditSubscriptionPlan from "./EditSubscriptionPlan/edit-subscription-plan.component";
import SubscriptionPlanAnalytics from "./SubscriptionPlanAnalytics/subscription-plan-analytics.component";
import ModalPopup from "../../../components/ModalPopup/modalpopup.component";

import "./addedit-subscription-plan.styles.scss";
import { useState } from "react";

const AddEditSubscriptionPlan = ({ selectedPlan, cancelClick }) => {
  const dispatch = useDispatch();

  const clickSubmitRef = useRef();
  const [activeTab, setActiveTab] = useState("settings");

  const handelSaveClick = () => {
    setActiveTab("settings");
    if (clickSubmitRef.current) {
      clickSubmitRef.current.saveSubscriptionPlan();
    }
  };

  const handelArchiveClick = async () => {
    await httpDelete(
      URLS.SUBSCRIPTION_PLANS.ARCHIVESUBSCRIPTIONPLAN.replace(
        "#ID#",
        selectedPlan.subscriptionPlanId
      )
    )
      .then((response) => {
        if (response.data == true) {
          dispatch(fireEvent({ eventName: "refresh-subscriptionplan-list" }));
          dispatch(setIsRenderedState(false));
          cancelClick();
        }
      })
      .catch((error) => {
        //  setFormSubmitError(
        //    error.response.data.Message || "Some error occuered!"
        //  );
      });
  };

  const handleArchieveButtonClick = () => {
    dispatch(setIsRenderedState(true));
  };

  const handlePopupCancelClick = () => {
    dispatch(setIsRenderedState(false));
  };

  useEffect(() => {
    setActiveTab("settings");
  }, [selectedPlan]);
  return (
    <div className="edit-container w-100">
      <div className="display-flex">
        <ButtonControl
          text={"Cancel"}
          onClick={() => cancelClick()}
          style={{
            width: "100px",
            margin: "10px 10px",
            backgroundColor: "gray",
          }}
        ></ButtonControl>
        <label className="edit-panel-title">
          {selectedPlan.subscriptionPlanId > 0 ? "Edit plan" : "Add Plan"}
        </label>
        <ButtonControl onClick={handelSaveClick} text={"Save"}></ButtonControl>
        {selectedPlan.subscriptionPlanId > 0 && (
          <ButtonControl
            onClick={handleArchieveButtonClick}
            style={{
              width: "40px",
              margin: "10px 5px",
              backgroundColor: "red",
            }}
            innercomponent={
              <FontAwesomeIcon icon={faFileArchive}></FontAwesomeIcon>
            }
          ></ButtonControl>
        )}
      </div>
      <ModalPopup
        heading={"Are you sure that you want to Arhive this plan?"}
        isConfirmButton={true}
        confirmButtonText={"Yes"}
        onConfirmClick={handelArchiveClick}
        isCancelButton={true}
        cancelButtontext={"No"}
        onCancelClick={handlePopupCancelClick}
      ></ModalPopup>

      <Tabs
        defaultActiveKey={activeTab}
        activeKey={activeTab}
        variant="pills"
        onSelect={(key) => setActiveTab(key)}
        id="fill-tab-example"
        className="tab-margins edit-subscription-tabs"
        fill
      >
        <Tab
          eventKey="settings"
          title="Settings"
          tabClassName={
            "settings-tab " + (activeTab != "settings" ? "inactive-tab" : "")
          }
        >
          <EditSubscriptionPlan
            selectedPlan={selectedPlan}
            closeEdit={() => cancelClick()}
            ref={clickSubmitRef}
          ></EditSubscriptionPlan>
        </Tab>
        <Tab
          eventKey="analytics"
          title="Analytics"
          tabClassName={"analytics-tab " + (
            activeTab != "analytics" ? "inactive-tab" : ""
          )}
        >
          <SubscriptionPlanAnalytics></SubscriptionPlanAnalytics>
        </Tab>
      </Tabs>
    </div>
  );
};

export default AddEditSubscriptionPlan;
