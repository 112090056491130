import { Avatar, Checkbox } from "@mui/material";

const CoachItem = ({
  coach,
  clickSelectCoach,
  isSelected,
  isDisabled,
  addToSelectedList,
}) => {
  return (
    <div>
      <div
        className={
          "asset-item cursor-pointer " +
          (isSelected ? "seleted-asset-background" : "")
        }
        onClick={clickSelectCoach}
      >
        <div className="w-100 display-inline-flex">
          <div style={{ margin: "auto" }}>
            <Checkbox
              id={coach.coachMasterId}
              checked={isSelected}
              onChange={addToSelectedList}
            />
          </div>
          <div className="w-10" style={{ margin: "auto" }}>
            <Avatar
              src={coach.user.profilePictureUrl}
              style={{ borderRadius: "50%", height: "70px", width: "70px" }}
            />
          </div>
          <div className="w-80" style={{marginLeft: "20px"}}>
            <label sx={{fontWeight: 500}}>{coach.user.firstName + " " + coach.user.lastName}</label>
            <br />
            <span style={{ color: "dimgray" }}>{coach.coachBio}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CoachItem;
