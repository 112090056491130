import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

import { selectCartItems } from "../../../modules/cart/cart.selector";

import { URLS } from "../../../configs/api_urls";
import { httpPost } from "../../../utility/request";
import Loader from "../../../components/Loader";

const PaymentGateway = () => {
    const location = useLocation();
    var selectedAddress  = location.state;

  const [isPaymentTokenLoading, setIsPaymentTokenLoading] = useState(true);

  const [PaymentToken, setPaymentToken] = useState("");

  const cartItems = useSelector(selectCartItems);

  useEffect(() => {
    if (selectedAddress)
   getPaymentToken();
  }, []);

  const submitPaymentForm = () => 
  {
     setTimeout(() => {
       if (document.getElementById("nonseamless") != null) {
         document.getElementById("nonseamless").submit();
       }
     }, 100);
  }

  const getPaymentToken = async () => {
    if (selectedAddress == null || selectedAddress == "") {
      return;
    }
    setIsPaymentTokenLoading(true);
    var adminCartItems = [];
    cartItems.forEach((ci) => {
      adminCartItems.push(ci.adminCartId);
    });

    const { data } = await httpPost(URLS.CHECKOUT.PAYMENTTOKEN, {
      ...selectedAddress,
      adminCartItems,
    });

    if (data) {
      setPaymentToken(data);
      setIsPaymentTokenLoading(false);
      submitPaymentForm();
    }
  };

  return (
    <div>
      {isPaymentTokenLoading == false ?  (<div>
        <form
          id="nonseamless"
          method="post"
          name="redirect"
          action="https://test.ccavenue.com/transaction/transaction.do?command=initiateTransaction"
        >
          <input
            id="encRequest"
            name="encRequest"
            type="hidden"
            value={PaymentToken}
          />
          <input
            name="access_code"
            id="Hidden1"
            type="hidden"
            value={"AVCT12KA03BP74TCPB"}
          />
        </form>
      </div>) : <Loader isLoading={isPaymentTokenLoading}></Loader>}
    </div>
  );
};

export default PaymentGateway;