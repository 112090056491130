import jwt_decode from "jwt-decode";
import variables from "../styles/variables.scss";

export const decodedApplicationId = (token) => {
  try {
    const { application_id } = jwt_decode(token);

    if (application_id) return application_id;
  } catch (err) {
    return "";
  }
};

export const setThemeColors = (theme) => {
  const root = document.documentElement;
  root.style.setProperty("--app-background-color", theme.foreGroundColor);
  root.style.setProperty("--app-primary-color", theme.backGroundColor);
};

export const resetThemeColors = () => {
  const root = document.documentElement;
  root.style.setProperty(
    "--app-background-color",
    variables.DefaultBackgroundColor
  );
  root.style.setProperty("--app-primary-color", variables.DefaultPrimaryColor);
};
