import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { green, amber } from "@mui/material/colors";
import { Button, Card, CardHeader, CardContent, CardActions } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

import { URLS } from "../../../configs/api_urls";
import { httpPost } from "../../../utility/request";

import { addItemToCart } from "../../../modules/cart/cart.action";
import { selectCartItems } from "../../../modules/cart/cart.selector";

import LicenseImag from "../../../static/license.png";

const LicenseItem = ({ license }) => {
  const {
    licenseName,
    maximumInstancesAllowed,
    maximumAdminsAllowed,
    maximumAllowedPlayers,
    numberOfYearsDataStorage,
    planFeatures,
  } = license;

  const cartItems = useSelector(selectCartItems);
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();

    const buttonSx = {
      ...(!isLoading && {
        bgcolor: amber[400],
        "&:hover": {
          bgcolor: amber[500],
        },
      }),
    };

  const addProductToCart = async () => {
    setIsLoading(true);
    var dafaultPricing = 
    license.planPricings.find((pf) => pf.licensePlanPricingType == 1);
    var transformedItem = {
      // representational parms
      id: license.licenseId,
      name: license.licenseName,
      imageUrl: LicenseImag,
      price: Math.round(dafaultPricing.planPrice / 12),

      // post params
      itemId: license.licenseId,
      itemType: 1, // license
      offerCode: "",
      quantity: 1,
      param1: dafaultPricing.licensePlanPricingId,
      param2: license,
    };
    try {
      await httpPost(URLS.CART.ADD_TO_CART, {...transformedItem, param2: ""})
    } catch (err) {
      console.log(err);
      this.props.addAlert({
        type: "error",
        message: "Something went wronge please try later",
      });
    } finally {
       setIsLoading(false);
      dispatch(addItemToCart(cartItems, transformedItem));
    }
  };

  return (
    <div className="d-inline-flex h-full">
      <Card className="mx-2">
        <div className="mx-2 flex flex-col align-bottom h-full">
          <div>
            <CardHeader
              title={licenseName}
              subheader="Multiple pricing options available"
            ></CardHeader>
            <CardContent>
              <div>
                <FontAwesomeIcon
                  className="mr-5"
                  icon={faCheck}
                  color="green"
                />
                <span>Number of Places</span> :{" "}
                <span>{maximumInstancesAllowed}</span>
              </div>
              <div>
                <FontAwesomeIcon
                  className="mr-5"
                  icon={faCheck}
                  color="green"
                />
                <span>Maximum Admins allowed</span> :{" "}
                <span>{maximumAdminsAllowed}</span>
              </div>
              <div>
                <FontAwesomeIcon
                  className="mr-5"
                  icon={faCheck}
                  color="green"
                />
                <span>Maximum Players allowed</span> :{" "}
                <span>{maximumAllowedPlayers}</span>
              </div>
              <div>
                <FontAwesomeIcon
                  className="mr-5"
                  icon={faCheck}
                  color="green"
                />
                <span>Data retention period</span> :{" "}
                <span>{numberOfYearsDataStorage}</span>
              </div>
              {planFeatures.map((feature) => (
                <div key={feature.licensePlanFeatureId}>
                  <FontAwesomeIcon
                    className="mr-5"
                    icon={faCheck}
                    color="green"
                  />
                  {feature.licensePlanFeatureName}
                </div>
              ))}
            </CardContent>
          </div>
          <CardActions
            sx={{
              flex: 1,
              justifyContent: "flex-end",
            }}
          >
            <Box sx={{ m: 1, position: "relative" }}>
              <Button
                variant="contained"
                sx={buttonSx}
                disabled={isLoading}
                onClick={addProductToCart}
              >
                Add to cart
              </Button>
              {isLoading && (
                <CircularProgress
                  size={24}
                  sx={{
                    color: amber[500],
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    marginTop: "-12px",
                    marginLeft: "-12px",
                  }}
                />
              )}
            </Box>
          </CardActions>
        </div>
      </Card>
    </div>
  );
};

export default LicenseItem;