import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { Pagination } from "@mui/material";
import Controls from "../../../../components/Controls/Controls";
import LoginIcon from "@mui/icons-material/Login";
import CircularProgress from "@mui/material/CircularProgress";

import { URLS } from "../../../../configs/api_urls";
import { httpPost, httpGet } from "../../../../utility/request";
import { set_access_token } from "../../../../hoc/helper";

import "./application-list.styles.scss";

const ApplicationList = () => {
    const router = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [appsData, setAppsData] = useState({});
  const [apps, setApps] = useState([]);

  const fetchApplications = async () => {
    setIsLoading(true);
    await httpPost(URLS.APPLICATION.GET_APPLICATIONS, {
      pageNumber: page,
      pageSize: rowsPerPage,
    })
      .then(({ data }) => {
        setAppsData(data);
        var appItems = data.items.map((app) => ({ ...app, isLoading: false }));
        setApps(appItems);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const switchApplication = async (applicationId) => {
    var appItems = apps.map((app) =>
      app.applicationId == applicationId ? { ...app, isLoading: true } : app
    );
    setApps(appItems);
    const url = URLS.APPLICATION.SWITCH_APPLICATION.replace(
      "#ID#",
      applicationId
    );
    await httpGet(url).then(({ data }) => {
      const { accessToken } = data;
      if (accessToken) set_access_token(accessToken);
    }).finally(() => {
        var appItems = apps.map((app) =>
          app.applicationId == applicationId ? { ...app, isLoading: false } : app
        );
        setApps(appItems);
        router(`../admin`);
    });
  };

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (rows) => {
    setRowsPerPage(parseInt(rows, 10));
    setPage(1);
  };

  useEffect(() => {
    fetchApplications();
  }, []);

  return (
    <div>
      <div>
        {apps.map((app) => {
          return (
            <div key={app.applicationId} className="app-list-item display-flex">
              <div>
                <span>{app.applicationName}</span>
              </div>
              <div className="ml-auto">
                <Controls.ButtonControl
                  text={"Log in"}
                  onClick={() => switchApplication(app.applicationId)}
                  variant="outlined"
                  color={"playnootSecondary"}
                  style={{ width: "130px" }}
                  endIcon={!app.isLoading && <LoginIcon />}
                  innercomponent={
                    app.isLoading && (
                      <CircularProgress
                        size={25}
                        style={{ padding: "2px", marginLeft: "10px" }}
                        color="playnootPrimary"
                      ></CircularProgress>
                    )
                  }
                ></Controls.ButtonControl>
              </div>
            </div>
          );
        })}
      </div>
      {!isLoading && (
        <div className="display-flex" style={{ padding: "10px" }}>
          <Pagination
            count={appsData.totalPages}
            page={page}
            variant="outlined"
            shape="rounded"
            showFirstButton={appsData.hasPreviousPage}
            showLastButton={appsData.hasNextPage}
            color="secondary"
            style={{ marginLeft: "auto" }}
            onChange={(event, value) => {
              handleChangePage(value);
            }}
          />
          <Controls.SelectControl
            name="pageSize"
            style={{ width: "100%", marginLeft: "20px" }}
            options={[
              { id: 5, title: "5" },
              { id: 10, title: "10" },
              { id: 50, title: "50" },
            ]}
            value={rowsPerPage}
            onChange={(e) => {
              handleChangeRowsPerPage(Number(e.target.value));
            }}
          ></Controls.SelectControl>
        </div>
      )}
    </div>
  );
};

export default ApplicationList;
