import { useState, useContext } from "react";
import { GameCenterViewContext } from "../../../utility/context";
import { useDispatch } from "react-redux";

import ImageProcessor from "../../../components/ImageProcessor/image-processor.component";
import placeHolderImage from "../../../static/placeHolderImage.png";

import { URLS } from "../../../configs/api_urls";
import { httpPost, httpDelete } from "../../../utility/request";
import { addAlert } from "../../../modules/App/actions";
import { Typography } from "@mui/material";

const ImagesUpdate = () => {
  const dispatch = useDispatch();
  const gameCenterDetails = useContext(GameCenterViewContext);
  const [imagesList, setImagesList] = useState(
    gameCenterDetails.GameCenter.instancePictures
  );

  const addImageToList = async (imgObject) => {
    debugger;
    await httpPost(
      URLS.APPLICATION_INSTANCE_SETTINGS.ADD_INSTANCE_PICTURE,
      imgObject
    )
      .then((response) => {
        if (response.data) {
          var newList = [...imagesList, { ...response.data }];
          gameCenterDetails.setGameCenter({
            ...gameCenterDetails.GameCenter,
            instancePictures: newList,
          });
          setImagesList(newList);
          dispatch(
            addAlert({
              type: "success",
              message: "Image uploaded succesfully",
            })
          );
        }
      })
      .catch((error) => {
        dispatch(
          addAlert({
            type: "error",
            message: error,
          })
        );
      })
      .finally(() => {
        //setIsSaving(false);
      });
  };
  const deleteImageFromList = async (imgIndex) => {
    var instancePictureId = imagesList[imgIndex].instancePicturesId;
    await httpDelete(
      URLS.APPLICATION_INSTANCE_SETTINGS.DELETE_INSTANCE_PICTURE.replace(
        "#ID#",
        instancePictureId
      )
    )
      .then((response) => {
        if (response.data == true) {
          dispatch(
            addAlert({
              type: "success",
              message: "Image deleted succesfully",
            })
          );
          var newlist = imagesList.filter((i, index) => index != imgIndex);
           gameCenterDetails.setGameCenter({
             ...gameCenterDetails.GameCenter,
             instancePictures: newlist,
           });
          setImagesList(newlist);
        }
      })
      .catch((error) => {
        dispatch(
          addAlert({
            type: "error",
            message: "Error while deleting Image",
          })
        );
      });
  };
  return (
    <div>
      <div className="row">
        <Typography>Thumbnail Image</Typography>
        <div className="col-md-4">
          <ImageProcessor
            alt="profile_image_0"
            id="profile_image_0"
            style={{ borderRadius: "15px" }}
            imageUrl={
              imagesList[0] ? imagesList[0].imageFullUrl : placeHolderImage
            }
            showImageUploadButton={true}
            showDeleteButton={false}
            uploadUrl={URLS.FILES.FACILITES_IMAGES}
            setImageData={(e) =>
              addImageToList({ ...e, isThumbnailImage: true })
            }
            deleteImage={() => {
              deleteImageFromList(0);
            }}
          ></ImageProcessor>
        </div>
        <div className="col-md-4">
          <ImageProcessor
            alt="profile_image_1"
            id="profile_image_1"
            style={{ borderRadius: "15px" }}
            imageUrl={
              imagesList[1] ? imagesList[1].imageFullUrl : placeHolderImage
            }
            showImageUploadButton={imagesList[1] == undefined ? true : false}
            uploadUrl={URLS.FILES.FACILITES_IMAGES}
            showDeleteButton={true}
            setImageData={(e) => addImageToList(e)}
            deleteImage={() => {
              deleteImageFromList(1);
            }}
          ></ImageProcessor>
        </div>
        <div className="col-md-4">
          <ImageProcessor
            alt="profile_image_2"
            id="profile_image_2"
            style={{ borderRadius: "15px" }}
            imageUrl={
              imagesList[2] ? imagesList[2].imageFullUrl : placeHolderImage
            }
            showImageUploadButton={imagesList[2] == undefined ? true : false}
            uploadUrl={URLS.FILES.FACILITES_IMAGES}
            showDeleteButton={true}
            setImageData={(e) => addImageToList(e)}
            deleteImage={() => {
              deleteImageFromList(2);
            }}
          ></ImageProcessor>
        </div>
      </div>
      <div className="row mt-20">
        <div className="col-md-4">
          <ImageProcessor
            alt="profile_image_3"
            id="profile_image_3"
            style={{ borderRadius: "15px" }}
            imageUrl={
              imagesList[3] ? imagesList[3].imageFullUrl : placeHolderImage
            }
            showImageUploadButton={imagesList[3] == undefined ? true : false}
            uploadUrl={URLS.FILES.FACILITES_IMAGES}
            showDeleteButton={true}
            setImageData={(e) => addImageToList(e)}
            deleteImage={() => {
              deleteImageFromList(3);
            }}
          ></ImageProcessor>
        </div>
        <div className="col-md-4">
          <ImageProcessor
            alt="profile_image_4"
            id="profile_image_4"
            style={{ borderRadius: "15px" }}
            imageUrl={
              imagesList[4] ? imagesList[4].imageFullUrl : placeHolderImage
            }
            showImageUploadButton={imagesList[4] == undefined ? true : false}
            uploadUrl={URLS.FILES.FACILITES_IMAGES}
            showDeleteButton={true}
            setImageData={(e) => addImageToList(e)}
            deleteImage={() => {
              deleteImageFromList(4);
            }}
          ></ImageProcessor>
        </div>
      </div>
    </div>
  );
};

export default ImagesUpdate;
