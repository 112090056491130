import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { Box, Typography } from "@mui/material";
import Controls from "../../../components/Controls/Controls";

import { httpPost } from "../../../utility/request";
import { URLS } from "../../../configs/api_urls";
import { fireEvent } from "../../../modules/Events/eventSlice";

var initialOfflinePayment = {
  amount: "",
  paymnent_for: 0,
  payingUserId: null,
  selectedUser: null,
  paymentDateTime: null,
  message: "",
};

var initialOfflinePaymentValidations = {
  amount: "",
  paymnent_for: "",
  payingUserId: null,
  paymentDateTime: new Date(),
  message: "",
};

const paymentForOptions = [
  { id: 1, title: "Booking" },
  { id: 2, title: "Subscription" },
];

const AddOfflinePayment = ({closeEdit}) => {
  const dispatch = useDispatch();
  const [offlinePayment, setOfflinePayment] = useState(initialOfflinePayment);
  const [offlinePaymentValidations, setOfflinePaymentValidations] = useState(
    initialOfflinePaymentValidations
  );
  const [searchData, setSearchData] = useState(null);
  const [userList, setUserList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [userName, setUserName] = useState("");
  const [pageNumber, setPage] = useState(1);
  const [pageSize, setRowsPerPage] = useState(10);

  const searchUsers = async () => {
    if (userName === undefined) return;
    if (userName.length < 3) return;
    setIsLoading(true);
    const { data } = await httpPost(
      URLS.USER.SEARCH_USER.replace("#NAME#", userName)
        .replace("#PAGENUMBER#", pageNumber)
        .replace("#PAGESIZE#", pageSize)
    );
    setSearchData(data);
    setUserList(data.items.map((user) => ({ ...user, label: user.userName })));
    setIsLoading(false);
  };

  const saveOfflinePayment = async () => {
    await httpPost(URLS.PHONEPEPAYMENT.ADD_OFFLINE_PAYMENT, offlinePayment)
      .then((response) => {
        if (response.data == true) {
          dispatch(fireEvent({ eventName: "refresh-payments-list" }));
        }
      })
      .catch((error) => {
        //  setFormSubmitError(
        //    error.response.data.Message || "Some error occuered!"
        //  );
      })
      .finally(() => {
        closeEdit();
      });
  };

  useEffect(() => {
    searchUsers();
  }, [userName, pageNumber, pageSize]);

  return (
    <Box>
      <Box sx={{ margin: "10px 20px", textAlign: "center" }}>
        <Typography variant="h5">Add Offline Payment</Typography>
      </Box>
      <Box>
        <Box sx={{ padding: "10px" }}>
          <Controls.SelectControl
            name="paymnent_for"
            showDefaultOption={true}
            defaultOptionText="None"
            options={paymentForOptions}
            value={offlinePayment.paymnent_for}
            onChange={(e) =>
              setOfflinePayment({
                ...offlinePayment,
                paymnent_for: e.target.value,
              })
            }
          ></Controls.SelectControl>
        </Box>
        <Box sx={{ padding: "10px" }}>
          <Controls.InputControl
            name="amount"
            placeholder={"Amount"}
            value={offlinePayment.amount}
            error={offlinePaymentValidations.amount}
            style={{ width: "100%" }}
            other={{
              type: "number",
            }}
            // isMultiLine={true}
            minNumber={0}
            onChange={(e) => {
              setOfflinePayment({
                ...offlinePayment,
                amount: e.target.value,
              });
              //ckeckValidations(e);
            }}
          ></Controls.InputControl>
        </Box>
        <Box sx={{ padding: "10px" }}>
          <Controls.AutocompleteControl
            options={userList}
            loading={isLoading}
            value={offlinePayment.selectedUser}
            label="Search Player by name"
            onInputChange={(e) => setUserName(e.value)}
            onChange={(e) => {
              if (e.selectedVal !== null) {
                setOfflinePayment({
                  ...offlinePayment,
                  selectedUser: e.selectedVal,
                  payingUserId: e.selectedVal.userId,
                });
              } else {
                setOfflinePayment({
                  ...offlinePayment,
                  selectedUser: null,
                  payingUserId: null,
                });
              }
            }}
          />
        </Box>
        <Box sx={{ padding: "10px" }}>
          <Controls.DatePickerControl
            id={""}
            name="expirtyDate"
            style={{ width: "100%" }}
            value={offlinePayment.paymentDateTime}
            onChange={(e) =>
              setOfflinePayment({
                ...offlinePayment,
                paymentDateTime: e,
              })
            }
          ></Controls.DatePickerControl>
        </Box>
        <Box sx={{ padding: "10px" }}>
          <Controls.InputControl
            name="message"
            placeholder={"Message"}
            value={offlinePayment.message}
            error={offlinePaymentValidations.message}
            style={{ width: "100%" }}
            other={{
              placeholder: "Enter the reason and purpose of this payment",
            }}
            isMultiLine={true}
            rows={3}
            //maxLength={30}
            onChange={(e) => {
              setOfflinePayment({
                ...offlinePayment,
                message: e.target.value,
              });
              //ckeckValidations(e);
            }}
          ></Controls.InputControl>
        </Box>
        <Controls.ButtonControl text="Add" onClick={saveOfflinePayment} />
        <Controls.ButtonControl text="Cancel" onClick={closeEdit} />
      </Box>
    </Box>
  );
};

export default AddOfflinePayment;
