import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fireEvent } from "../../../modules/Events/eventSlice";

import { URLS } from "../../../configs/api_urls";
import { httpGet } from "../../../utility/request";
import CoachItem from "./CoachItem/coach-item.component";
import CoachListSkeleton from "../Skeleton/coachlist-skeleton.component";

const CoachList = ({
  selectedCoachList,
  clickSelectCoach,
  addToSelectedList,
}) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [coaches, setCoaches] = useState([]);

    const event = useSelector((onEvent) => {
      var eventName = onEvent.events.eventName;
      if (eventName === "refresh-coaches-list") {
        fetchCoaches();
        dispatch(fireEvent({ eventName: "" }));
      }
      //reset event listner
    });

  const fetchCoaches = async () => {
    setIsLoading(true);
    const { data } = await httpGet(URLS.COACHES.GET_ALL);
    setCoaches(data);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchCoaches();
  }, []);
  return (
    <div>
      <div className="list-filter-header"></div>
      <div className="list-container scrollbar">
        {isLoading && <CoachListSkeleton></CoachListSkeleton>}
        {coaches.length == 0 && !isLoading && (
          <div className="no-item-section">
            <span className="no-item-message">
              You have not created any coach yet. Enlist your facility coach on
              the playnoot app to show availability of coaching at your facilty.
            </span>
          </div>
        )}
        {coaches.map((coach) => {
          return (
            <div key={coach.coachMasterId}>
              <CoachItem
                coach={coach}
                isSelected={selectedCoachList.includes(coach.coachMasterId)}
                addToSelectedList={addToSelectedList}
                clickSelectCoach={() => {
                  clickSelectCoach(coach);
                }}
              ></CoachItem>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default CoachList;
