/* eslint-disable eqeqeq */
import React  from "react";
// import { SVG_ICONS, PNG_ICONS } from "./path";

const Icon = (props) => {
  const { iconType = "svg", icon, color = "black", category = "games" } = props;
  // let path;

  // if (iconType == "svg") {
  //   if (category == "amenities") {
  //     if (color == "black") path = SVG_ICONS.BLACK.AMENITIES;
  //     if (color == "white") path = SVG_ICONS.WHITE.AMENITIES;
  //   } else if (category == "games") {
  //     if (color == "black") path = SVG_ICONS.BLACK.GAMES;
  //     if (color == "white") path = SVG_ICONS.WHITE.GAMES;
  //   }
  // } else if (iconType == "png") {
  //   if (category == "amenities") {
  //     if (color == "black") path = PNG_ICONS.BLACK.AMENITIES;
  //     if (color == "white") path = PNG_ICONS.WHITE.AMENITIES;
  //   } else if (category == "games") {
  //     if (color == "black") path = PNG_ICONS.BLACK.GAMES;
  //     if (color == "white") path = PNG_ICONS.WHITE.GAMES;
  //   }
  // }

  let iconImg = "";
  try {
    // iconImg = require(`${path}/${icon}.${iconType}`);
    iconImg = require(`../../static/icons/${
      color == "black" ? "Black" : "White"
    }/${
      category == "amenities" ? "Amenities" : "Games"
    }/${iconType}/${icon}.${iconType}`);
  } catch (err) {
    console.log(err);
  }

  return <img src={iconImg} style={{ width: "50px", height: "50px" }} alt='' />;
};

export default Icon;
