import { useState, useEffect, useContext } from "react";

import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import { Pagination, Rating, Avatar } from "@mui/material";
import { GameCenterViewContext } from "../../../utility/context";
import Controls from "../../../components/Controls/Controls";

import moment from "moment/moment";

import { URLS } from "../../../configs/api_urls";
import { httpGet } from "../../../utility/request";
import RatingReviewListSkeleton from "../Skeletons/rating-review-list-skeleton.component";

const RatingReviewList = () => 
{
        const [pageNumber, setPageNumber] = useState(1);
        const [pageSize, setPageSize] = useState(5);

        const [isLoading, setIsLoading] = useState(true);
        const [ratingReviewList, setRatingReviewList] = useState(null);

        const gameCenterDetails = useContext(GameCenterViewContext);

        const fetchRatingAndReviewDetails = async () => {
          setIsLoading(true);
          const { data } = await httpGet(
            URLS.RATING_AND_REVIEWS.GET_APPLICATIONINSTANCE_REVIEWS.concat(
              "?applicationInstanceId=",
              gameCenterDetails.GameCenter.applicationInstanceId,
              "&PageNumber=",
              pageNumber,
              "&PageSize=",
              pageSize
            )
          );
          setRatingReviewList(data);
          setIsLoading(false);
        };

        useEffect(() => {
          fetchRatingAndReviewDetails();
        }, [pageNumber, pageSize]);

    return (
      <div
        style={{
          background: "white",
          padding: "30px",
          marginTop: "20px",
          maxHeight: "calc(100vh - 350px)",
          borderRadius: "15px",
          overflow: "auto",
        }}
        className="scrollbar"
      >
        {isLoading && <RatingReviewListSkeleton></RatingReviewListSkeleton>}
        {!isLoading && ratingReviewList.totalCount == 0 && (
          <div>
            <span className="font-italic text-sub-info">
              No reviews yet.
            </span>
          </div>
        )}
        {!isLoading && ratingReviewList.totalCount > 0 && (
          <Box>
            <List>
              {ratingReviewList.items.map((review, index) => {
                return (
                  <ListItem disablePadding>
                    <ListItemButton>
                      <div className="row w-100">
                        {index !== 0 && <hr />}
                        <div className="col-md-4">
                          <Avatar
                            src={review.user.profilePictureUrl}
                            alt="Avatar"
                            style={{
                              borderRadius: "50%",
                              height: "60px",
                              width: "60px",
                            }}
                          ></Avatar>
                          <span>{review.user.userName}</span>
                        </div>
                        <div className="col-md-8">
                          <div>
                            <Rating
                              name="read-only"
                              precision={0.5}
                              size="small"
                              value={review.ratingScore}
                              readOnly
                            />
                            <span>
                              {moment(review.reviewSubmissionDate)
                                .tz("Asia/Calcutta")
                                .format("DD/MM/YYYY")}
                            </span>
                          </div>
                          <div>
                            <span className="font-600">
                              {review.reviewHeading}
                            </span>
                            <br />
                            <span>{review.reviewBody}</span>
                          </div>
                        </div>
                      </div>
                    </ListItemButton>
                  </ListItem>
                );
              })}
            </List>
            <div
              style={{ bottom: "40px", right: "40px", position: "absolute" }}
              className="display-flex"
            >
              <Pagination
                count={ratingReviewList.totalPages}
                page={pageNumber}
                variant="outlined"
                shape="rounded"
                showFirstButton={ratingReviewList.hasPreviousPage}
                showLastButton={ratingReviewList.hasNextPage}
                color="secondary"
                style={{ margin: "auto" }}
                onChange={(event, value) => {
                  setPageNumber(value);
                }}
              />
              <Controls.SelectControl
                name="pageSize"
                style={{ width: "100%" }}
                options={[
                  { id: 5, title: "5" },
                  { id: 10, title: "10" },
                  { id: 50, title: "50" },
                ]}
                value={pageSize}
                onChange={(e) => {
                  setPageNumber(1);
                  setPageSize(Number(e.target.value));
                }}
              ></Controls.SelectControl>
            </div>
          </Box>
        )}
      </div>
    );
}

export default RatingReviewList;