import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import BrandListSkeleton from "../Skeleton/brand-list-skeleton.component";
import BrandItem from "./BrandItem/brand-item.component";

import { URLS } from "../../../configs/api_urls";
import { httpGet } from "../../../utility/request";
import { fireEvent } from "../../../modules/Events/eventSlice";

const BrandList = ({
  selectedBrandList,
  clickSelectBrand,
  addToSelectedList,
}) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [brandings, setBrandings] = useState([]);

  const fetchBrandings = async () => {
    setIsLoading(true);
    const { data } = await httpGet(URLS.BRANDING.GET_ALL);
    setBrandings(data);
    setIsLoading(false);
  };

  const event = useSelector((onEvent) => {
    var eventName = onEvent.events.eventName;
    if (eventName === "refresh-branding-list") {
      fetchBrandings();
      dispatch(fireEvent({ eventName: "" }));
    }
    //reset event listner
  });

  useEffect(() => {
    fetchBrandings();
  }, []);

  return (
    <div>
      <div className="list-filter-header"></div>
      <div className="list-container scrollbar">
        {isLoading && <BrandListSkeleton></BrandListSkeleton>}
        {brandings.length == 0 && !isLoading && (
          <div className="no-item-section">
            <span className="no-item-message">
              You have not created any Branding yet. Add brandings to sell your
              marchandise or promote your facility, products, websites or even
              portfolios.
            </span>
          </div>
        )}
        {brandings.map((brand) => {
          return (
            <div key={brand.instanceBrandingId}>
              <BrandItem
                brand={brand}
                isSelected={selectedBrandList.includes(
                  brand.instanceBrandingId
                )}
                addToSelectedList={addToSelectedList}
                clickSelectBrand={() => {
                  clickSelectBrand(brand);
                }}
              ></BrandItem>
            </div>
          );
        })}
      </div>
    </div>
  );
};
export default BrandList;
