import { Box } from "@mui/material";
import ButtonControl from "../../../components/Controls/ButtonControl";
import DatePickerControl from "../../../components/Controls/DatePickerControl";
import Controls from "../../../components/Controls/Controls";

const confirmationFilterOptions = [
  { id: "All", title: "All" },
  { id: "PAYMENT_INITIATED", title: "Initiated" },
  { id: "PAYMENT_SUCCESS", title: "Success" },
  { id: "PAYMENT_PENDING", title: "Pending" },
  { id: "PAYMENT_ERROR", title: "Failed" },
];

const PaymentsNavigation = ({
  selectedView,
  selectedDate,
  setSelectedDate,
  selectedConfirmationFilter,
  setSelectedConfirmationFilter,
  clickAddOfflinePayment,
  setView,
}) => {
  return (
    <div>
      <div style={{ marginBottom: "10px", display: "flex" }}>
        {selectedView === "" && (
          <Box className="display-flex">
            <div>
              <DatePickerControl
                id="selecteddate"
                name="selecteddate"
                label={"Select Date"}
                value={selectedDate}
                onChange={(d) => {
                  setSelectedDate(d);
                }}
              ></DatePickerControl>
            </div>
            <div>
              <Controls.SelectControl
                name="confirmationFilter"
                showDefaultOption={false}
                options={confirmationFilterOptions}
                value={selectedConfirmationFilter}
                style={{ width: "250px", marginLeft: "10px" }}
                onChange={(e) => setSelectedConfirmationFilter(e.target.value)}
              ></Controls.SelectControl>
            </div>
          </Box>
        )}
        <div style={{ marginLeft: "auto" }}>
          <ButtonControl
            text={"Payment Analytics"}
            style={{ width: "220px", margin: "0px 5px", background: "gray" }}
            onClick={() => setView("paymentAnalytics")}
          ></ButtonControl>
          <ButtonControl
            text={"Payments"}
            style={{ width: "150px", margin: "0px 5px" }}
            onClick={() => setView("")}
          ></ButtonControl>
          <ButtonControl
            text={"Add Offline Payment"}
            style={{ width: "220px", margin: "0px 5px" }}
               onClick={() => {
                 clickAddOfflinePayment();
               }}
          ></ButtonControl>
        </div>
      </div>
    </div>
  );
};

export default PaymentsNavigation;
