import { useState, useEffect } from "react";
import { useContext } from "react";

import { Typography } from "@mui/material";
import Controls from "../../../../components/Controls/Controls";
import { FormControl, Chip } from "@mui/material";
import { InputLabel, Select, MenuItem, Radio } from "@mui/material";
import moment from "moment-timezone";

import { URLS } from "../../../../configs/api_urls";
import { httpGet } from "../../../../utility/request";

import "./booking-filter.styles.scss";

const paymentFilterOptions = [
  { id: 1, title: "Success", paramValue: "PAYMENT_SUCCESS" },
  { id: 2, title: "Pending", paramValue: "PAYMENT_PENDING" },
  { id: 3, title: "Failed", paramValue: "PAYMENT_FAILED" },
];

const confirmationFilterOptions = [
  { id: 1, title: "Initiated" },
  { id: 2, title: "Confirmed" },
  { id: 3, title: "Cancelled" },
];

const timeFilterOptions = [
  { id: 1, title: "Custom Time" },
  { id: 2, title: "Slots" },
];

const bookingDateOptions = [
  { id: 1, title: "All" },
  { id: 2, title: "Today" },
  { id: 3, title: "Tomorrow" },
  { id: 4, title: "Yesterday" },
  { id: 5, title: "Custom" },
];

export const BookingFilters = ({ value, onChange }) => {
  // const gameCenterDetails = useContext(GameCenterViewContext);
  // const futureActiveDays = gameCenterDetails.GameCenter.futureActiveDays;
  //const themeColor = gameCenterDetails.GameCenter.applicationTheme;

  const [isLoading, setIsLoading] = useState(false);

  // date filetr states
  const [selectedDateFilter, setSelectedDateFilter] = useState(1);
  const [selectedDate, setSelectedDate] = useState(null);
  const [isDateFilterOpen, setIsDateFilterOpen] = useState(false);

  const [selectedPaymentFilter, setSelectedPaymentFilter] = useState(0);
  const [selectedConfirmationFilter, setSelectedConfirmationFilter] =
    useState(2);
  const [selectedTimeFilter, setSelectedTimeFilter] = useState(0);
  const [assetItems, setAssetItems] = useState([{ id: 0, title: "None" }]);
  const [selectedAssetItem, setSelectedAssetItem] = useState(0);
  const [assetSlots, setAssetSlots] = useState([]);

  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [selectedAssetSlot, setSelectedAssetSlot] = useState(null);

  const [bookingFetchFilter, setBookingFetchFilter] = useState({
    date: moment(new Date()).format(),
    bookingStatus: "Confirmed",
    paymnetStatus: "Credit",
    slotId: 0,
    assetId: 0,
    startTime: null,
    endTime: null,
  });

  const fetchAssets = async () => {
    setIsLoading(true);
    const { data } = await httpGet(URLS.ASSET.GET_ASSET_LIST);
    var assetItems = data
      .filter((p) => p.isActive === true)
      .map((asset) => ({
        id: asset.assetId,
        title: asset.assetName,
      }));
    setAssetItems(assetItems);
    setIsLoading(false);
  };

  const fetchAssetSlots = async () => {
    if (selectedAssetItem > 0) {
      setIsLoading(true);
      const { data } = await httpGet(
        URLS.ASSET.GET_ASSET_SLOTS.replace(
          "#ASSETID#",
          selectedAssetItem
        ).replace("#SELECTEDDATE#", moment(selectedDate).utc().format())
      );
      setAssetSlots(data);
      setIsLoading(false);
    }
  };

  const getDateFilterRenderValue = () => {
    if (selectedDateFilter == 5) {
      return (
        bookingDateOptions[selectedDateFilter - 1].title +
        " " +
        moment(selectedDate).format("DD/MM/YYYY").toLocaleString()
      );
    }
    return bookingDateOptions[selectedDateFilter - 1].title;
  };

  const onChangeDateFilter = (event) => {
    var selectedFilterId = event.target.value;
    setSelectedDateFilter(selectedFilterId);

    if (selectedFilterId == 1) {
      setSelectedDate(null);
    } else if (selectedFilterId == 2) {
      setSelectedDate(moment(new Date()).utc().format());
    } else if (selectedFilterId == 3) {
      setSelectedDate(moment(new Date()).add(1, "d").utc().format());
    } else if (selectedFilterId == 4) {
      setSelectedDate(moment(new Date()).add(-1, "d").utc().format());
    } else if (selectedFilterId == 5) {
      setSelectedDate(moment(new Date()).utc().format());
    }
    if (selectedFilterId !== 5) {
      setIsDateFilterOpen(false);
    }
  };

  const setFetchParams = () => {
    setBookingFetchFilter({
      ...bookingFetchFilter,
      date: selectedDate,
      bookingStatus:
        selectedConfirmationFilter > 0
          ? confirmationFilterOptions.find(
              (x) => x.id == selectedConfirmationFilter
            ).title
          : null,
      paymnetStatus:
        selectedPaymentFilter > 0
          ? paymentFilterOptions.find((x) => x.id == selectedPaymentFilter)
              .paramValue
          : null,
      slotId: selectedAssetSlot,
      assetId: selectedAssetItem,
      startTime: startTime != null ? moment(startTime).utc().format() : null,
      endTime: endTime != null ? moment(endTime).utc().format() : null,
    });
  };

  useEffect(() => {
    onChange(bookingFetchFilter);
  }, [bookingFetchFilter]);

  useEffect(() => {
    setFetchParams();
    fetchAssets();
    fetchAssetSlots();
  }, [
    selectedDate,
    selectedPaymentFilter,
    selectedConfirmationFilter,
    selectedAssetSlot,
    selectedAssetItem,
    startTime,
    endTime,
  ]);

  return (
    <div className="w-full rounded-md" style={{ paddingBottom: "10px" }}>
      {/* heading select date */}
      {/* <Typography variant="md" display={"block"} fontWeight={600} mb={1}>
        Bookings at this center
      </Typography> */}

      <div className="row">
        <div className="col-md-3 display-grid">
          <label className="field-title">Selected Date</label>
          <FormControl variant="outlined">
            <Select
              open={isDateFilterOpen}
              name="dateSelection"
              value={selectedDateFilter}
              onOpen={(event) => setIsDateFilterOpen(true)}
              onChange={onChangeDateFilter}
              // clck anywhere else so select should close
              onClose={(event) => {
                if (event.currentTarget.id != 5) {
                  setIsDateFilterOpen(false);
                }
              }}
              renderValue={getDateFilterRenderValue}
            >
              {bookingDateOptions.map((item) => (
                <MenuItem
                  key={item.id}
                  id={item.id}
                  value={item.id}
                  //handle same option click which was previous selected and close menu
                  onClick={(event) =>
                    item.id !== 5 && setIsDateFilterOpen(false)
                  }
                >
                  <Radio
                    checked={selectedDateFilter == item.id}
                    name="radio-buttons"
                  />
                  <Typography>{item.title}</Typography>
                  {item.id == 5 && selectedDateFilter == 5 && (
                    <div style={{ marginLeft: "15px" }}>
                      <Controls.DatePickerControl
                        id={"selecteddate"}
                        name="selectedDate"
                        value={selectedDate}
                        onChange={(e) => {
                          setSelectedDate(e);
                          setIsDateFilterOpen(false);
                        }}
                      ></Controls.DatePickerControl>
                    </div>
                  )}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div className="col-md-3 display-grid">
          <label className="field-title">Asset</label>
          <Controls.SelectControl
            name="assetSelection"
            showDefaultOption={true}
            options={assetItems}
            value={selectedAssetItem}
            onChange={(e) => setSelectedAssetItem(e.target.value)}
          ></Controls.SelectControl>
        </div>
        <div className="col-md-3 display-grid">
          <label className="field-title">Payment Status</label>
          <Controls.SelectControl
            name="paymentFilter"
            showDefaultOption={true}
            options={paymentFilterOptions}
            value={selectedPaymentFilter}
            onChange={(e) => setSelectedPaymentFilter(e.target.value)}
          ></Controls.SelectControl>
        </div>
        <div className="col-md-3 display-grid">
          <label className="field-title">Confirmed Status</label>
          <Controls.SelectControl
            name="confirmationFilter"
            showDefaultOption={true}
            options={confirmationFilterOptions}
            value={selectedConfirmationFilter}
            onChange={(e) => setSelectedConfirmationFilter(e.target.value)}
          ></Controls.SelectControl>
        </div>
      </div>
      {/* <div className="row mt-20">
        <div className="col-md-3 display-grid">
          <label className="field-title">Time filter</label>
          <Controls.SelectControl
            name="timerFilter"
            showDefaultOption={true}
            defaultOptionText="None"
            options={timeFilterOptions}
            value={selectedTimeFilter}
            onChange={(e) => {
              var option = e.target.value;
              setSelectedTimeFilter(option);
              if (option == 1) {
                setSelectedAssetSlot(0);
                setStartTime(moment(new Date()));
                setEndTime(moment(new Date()).add(2, "hours"));
              } else if (option == 2) {
                setStartTime(null);
                setEndTime(null);
              } else {
                setSelectedAssetSlot(0);
                setStartTime(null);
                setEndTime(null);
              }
            }}
          ></Controls.SelectControl>
        </div>
        <div className="col-md-3"></div>
        <div className="col-md-3"></div>
        <div className="col-md-3">
          <div style={{ textAlign: "end" }}>
            <Controls.ButtonControl
              text="Search"
              onClick={() => {
                setFetchParams();
                onChange(bookingFetchFilter);
              }}
            ></Controls.ButtonControl>
          </div>
        </div>
        {selectedTimeFilter == 1 && (
          <div className="row mt-20">
            <div className="col-md-3 display-grid">
              <label className="field-title">Start time</label>
              <Controls.TimePickerControl
                id={"starttime"}
                name="startTime"
                value={startTime}
                onChange={(value) => {
                  setStartTime(value);
                }}
              ></Controls.TimePickerControl>
            </div>
            <div className="col-md-3 display-grid">
              <label className="field-title">End time</label>
              <Controls.TimePickerControl
                id={"endtime"}
                name="endTime"
                value={endTime}
                onChange={(value) => {
                  setEndTime(value);
                }}
              ></Controls.TimePickerControl>
            </div>
          </div>
        )}
        <div>
          {selectedTimeFilter == 2 &&
            assetSlots.map((slot, index) => (
              <Chip
                variant="outlined"
                id={slot.assetTimeSlotsId}
                label={slot.slotDisplayText}
                style={{
                  height: "40px",
                  margin: "5px 2px",
                  fontSize: "16px",
                  background:
                    selectedAssetSlot == slot.assetTimeSlotsId
                      ? themeColor.backGroundColor
                      : "",
                }}
                clickable={true}
                onClick={(e) => {
                  setSelectedAssetSlot(Number(e.currentTarget.id));
                }}
              />
            ))}
        </div>
      </div> */}
    </div>
  );
};
