import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

import Controls from "../../../components/Controls/Controls";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTrash } from "@fortawesome/free-solid-svg-icons";
import ModalPopup from "../../../components/ModalPopup/modalpopup.component";

import { URLS } from "../../../configs/api_urls";
import { httpPost, httpPut, httpDelete, httpGet } from "../../../utility/request";
import { fireEvent } from "../../../modules/Events/eventSlice";
import { setIsRenderedState } from "../../../modules/ModalPopup/modalPopupSlice";

const AddEditTask = ({ selectedTask, cancelClick }) => {
  const dispatch = useDispatch();
  const [selectedTaskItem, setselectedTaskItem] = useState(selectedTask);

  const createTask = async () => {
    await httpPost(URLS.TASKS.CREATE_TASK, {
      ...selectedTaskItem,
    })
      .then((response) => {
        if (response.data == true) {
          dispatch(fireEvent({ eventName: "refresh-task-list" }));
        }
      })
      .catch((error) => {
        //  setFormSubmitError(
        //    error.response.data.Message || "Some error occuered!"
        //  );
      })
      .finally(() => {
        cancelClick();
      });
  };

  const updateTask = async () => {
    // API CAll here
    await httpPut(URLS.TASKS.UPDATE_TASK, {
      ...selectedTaskItem,
    })
      .then((response) => {
        if (response.data == true) {
          dispatch(fireEvent({ eventName: "refresh-task-list" }));
        }
      })
      .catch((error) => {
        //  setFormSubmitError(
        //    error.response.data.Message || "Some error occuered!"
        //  );
      })
      .finally(() => {
        cancelClick();
      });
  };

  const handelDeleteClick = async () => {
    await httpDelete(
      URLS.TASKS.DELETE_TASK.replace(
        "#ID#",
        selectedTask.taskId
      )
    )
      .then((response) => {
        if (response.data == true) {
          dispatch(fireEvent({ eventName: "refresh-task-list" }));
          dispatch(setIsRenderedState(false));
          cancelClick();
        }
      })
      .catch((error) => {
        //  setFormSubmitError(
        //    error.response.data.Message || "Some error occuered!"
        //  );
      });
  };

  const handleMarkTaskCompleted = async () => {
      await httpGet(
      URLS.TASKS.MARK_TASK_COMPLETED.replace(
        "#ID#",
        selectedTask.taskId
      )
    )
      .then((response) => {
        if (response.data == true) {
          dispatch(fireEvent({ eventName: "refresh-task-list" }));
          dispatch(setIsRenderedState(false));
          cancelClick();
        }
      })
      .catch((error) => {
        //  setFormSubmitError(
        //    error.response.data.Message || "Some error occuered!"
        //  );
      });
  }

  const handleSaveClick = () => {
    if (selectedTask.taskId > 0) {
      updateTask();
    } else {
      createTask();
    }
  };

  const handleDeleteButtonClick = () => {
    dispatch(setIsRenderedState(true));
  };

  useEffect(() => {
    setselectedTaskItem(selectedTask);
  }, [selectedTask]);

  return (
    <div className="edit-container w-100">
      <div className="display-flex">
        <Controls.ButtonControl
          text={"Cancel"}
          style={{
            width: "100px",
            margin: "10px 10px",
            backgroundColor: "gray",
          }}
          onClick={() => cancelClick()}
          //color={grey[700]}
        ></Controls.ButtonControl>
        <label className="edit-panel-title">
          {selectedTask.taskId > 0 ? "Edit Task" : "Add Task"}
        </label>
        <Controls.ButtonControl
          onClick={() => handleSaveClick()}
          text={"Save"}
        ></Controls.ButtonControl>
        {selectedTask.taskId > 0 && selectedTask.isActive && (
          <Controls.ButtonControl
            onClick={handleMarkTaskCompleted}
            style={{
              width: "40px",
              margin: "10px 5px",
              backgroundColor: "green",
            }}
            innercomponent={<FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>}
          ></Controls.ButtonControl>
        )}
        {selectedTask.taskId > 0 && (
          <Controls.ButtonControl
            onClick={handleDeleteButtonClick}
            style={{
              width: "40px",
              margin: "10px 5px",
              backgroundColor: "red",
            }}
            innercomponent={<FontAwesomeIcon icon={faTrash}></FontAwesomeIcon>}
          ></Controls.ButtonControl>
        )}
      </div>
      <div style={{ padding: "10px" }}>
        <ModalPopup
          heading={"Are you sure that you want to Delete this Task?"}
          isConfirmButton={true}
          confirmButtonText={"Yes"}
          onConfirmClick={handelDeleteClick}
          isCancelButton={true}
          cancelButtontext={"No"}
          onCancelClick={() => dispatch(setIsRenderedState(false))}
        ></ModalPopup>

        <div className="edit-section-margin">
          <label className="field-title">Task Title</label>
          <Controls.InputControl
            name="title"
            other={{ placeholder: "Enter title for the Task" }}
            style={{ width: "100%", marginTop: "5px" }}
            onChange={(e) =>
              setselectedTaskItem({
                ...selectedTaskItem,
                taskTitle: e.target.value,
              })
            }
            value={selectedTaskItem.taskTitle}
            //error={validation.planName}
          ></Controls.InputControl>
        </div>
        <div className="edit-section-margin">
          <label className="field-title">Task Description</label>
          <Controls.InputControl
            name="description"
            isMultiLine={true}
            rows={3}
            other={{ placeholder: "Enter description for the Task" }}
            style={{ width: "100%", marginTop: "5px" }}
            onChange={(e) =>
              setselectedTaskItem({
                ...selectedTaskItem,
                taskDescription: e.target.value,
              })
            }
            value={selectedTaskItem.taskDescription}
            //error={validation.planName}
          ></Controls.InputControl>
        </div>
      </div>
    </div>
  );
};

export default AddEditTask;
