import {
  SET_ACTIVE_FORM,
  SET_CENTER_IMAGES,
  SET_CREATE_CENTER_DATA,
  SET_FACILITIE_AMENITIES_DTO,
  SET_ALL_POLICY_TYPES,
  SET_GAME_CENTERS_LIST,
  SET_STANDARD_GAMES_DTO,
  SET_ADDRESS_DTO,
  SET_THEME_DTO,
  SET_IS_REQUESTING,
  RESET_CREATE_GAME_CENTER,
  RESET_CENTER_IMAGES_DATA,
  SET_CENTER_POLICY,
} from "./constants";

export const initial_game_center = {
  applicationInstanceName: "",
  applicationId: "",
  templateId: 1,
  timeZone: "Asia/Kolkata",
  gameDetailsDto: {
    gameDetailsId: 0,
    tagLine: "",
    gameDescription: "",
    gameLogoUrl: "",
  },
  themeId: "",
  addressDto: {
    addressId: 0,
    addressLane1: "",
    addressLane2: "",
    city: "",
    state: "",
    country: "India",
    pincode: "",
    latitude: 30.733943,
    longitude: 76.821706,
    googleMapId: "",
    googleMapAddress: "",
  },
  standardGamesDto: [],
  facilityAmenitiesDto: [],
  images: [],
  applicationInstanceCenterPolicies: [
    {
      centerPoliciesTypeId: 0,
      policyDescription: "",
    },
  ],
};

export const initial_center_images = [
  { key: "image-0", image: {} },
  { key: "image-1", image: {} },
  { key: "image-2", image: {} },
  { key: "image-3", image: {} },
  { key: "image-4", image: {} },
];

const initial_state = {
  game_center_list: [],
  standard_games_dto: [],
  facilities_amenities: [],
  all_policies: [],
  themes_dto: [],
  create_center_data: {
    ...initial_game_center,
  },
  active_form: 0,
  images: [...initial_center_images],
  isRequesting: false,
};

function GameCenterReducer(state = { ...initial_state }, action) {
  switch (action.type) {
    case SET_GAME_CENTERS_LIST:
      return { ...state, game_center_list: action.data };

    case SET_STANDARD_GAMES_DTO:
      return { ...state, standard_games_dto: action.data };

    case SET_FACILITIE_AMENITIES_DTO:
      return { ...state, facilities_amenities: action.data };

    case SET_ALL_POLICY_TYPES:
      return { ...state, all_policies: action.data };

    case SET_CREATE_CENTER_DATA:
      return { ...state, create_center_data: action.data };

    case SET_ACTIVE_FORM:
      return { ...state, active_form: action.data };

    case SET_CENTER_IMAGES:
      return { ...state, images: action.data };

    case SET_ADDRESS_DTO:
      return {
        ...state,
        create_center_data: {
          ...state.create_center_data,
          addressDto: {
            ...state.create_center_data.addressDto,
            ...action.data,
          },
        },
      };

    case SET_THEME_DTO:
      return { ...state, themes_dto: action.data };

    case SET_IS_REQUESTING:
      return { ...state, isRequesting: action.data };

    case RESET_CREATE_GAME_CENTER:
      return {
        ...state,
        create_center_data: action.data,
      };

    case SET_CENTER_POLICY:
      return {
        ...state,
        create_center_data: {
          ...state.create_center_data,
          applicationInstanceCenterPolicies: action.data,
        },
      };

    case RESET_CENTER_IMAGES_DATA:
      return {
        ...state,
        images: action.data,
      };

    default:
      return state;
  }
}

export default GameCenterReducer;
