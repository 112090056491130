import React from 'react'
import TextField from "@mui/material/TextField"; 
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import moment from "moment-timezone"
import { FormControl } from '@mui/material';

const TimePickerControl = (props) => 
{
    const { name, label, value, style, disabled, onChange, minTime, maxTime } = props;

    const defautlSx = { padding: "15px 5px 15px" };

    return (
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <FormControl sx={{ width: "100%" }}>
          <TimePicker
            label={label}
            value={moment.utc(value).tz("Asia/Kolkata")}
            disabled={disabled}
            name={name}
            sx={defautlSx || style}
            onChange={onChange}
            renderInput={(params) => (
              <TextField sx={{ width: "100%" }} {...params} />
            )}
            minTime={minTime}
            maxTime={maxTime}
          />
        </FormControl>
      </LocalizationProvider>
    );
}

export default TimePickerControl;