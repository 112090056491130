import React from "react";
import { Box , Drawer} from "@mui/material";
import { useState } from "react";

import ButtonControl from "../../components/Controls/ButtonControl";
import GameSessionList from "./GameSessionList/game-session-list.component";
import AddGameSession from "./AddGameSession/add-game-session.component";
import AllGameSessions from "./AllGameSessions/allgame-session-list.component";

const GameSessionDashboard = () => {
    const [isAddGameSessionDrawOpen, setIsAddGameSessionDrawOpen] = useState(false);
    const [selectedView, setSelectedView] = useState('activesessions')
  return (
    <Box>
      <Box sx={{ textAlign: "right", mb: 1 }}>
        {selectedView === "activesessions" && (
          <ButtonControl
            text={"all sessions"}
            style={{ width: "160px", margin: "0px 5px", background: "gray" }}
            onClick={() => setSelectedView("allgamesession")}
          />
        )}
        {selectedView === "allgamesession" && (
          <ButtonControl
            text={"active sessions"}
            style={{ width: "200px", margin: "0px 5px", background: "gray" }}
            onClick={() => setSelectedView("activesessions")}
          />
        )}
        <ButtonControl
          text={"Create new Session"}
          style={{ width: "220px", margin: "0px 5px" }}
          onClick={() => setIsAddGameSessionDrawOpen(true)}
        />
      </Box>
      <Box
        style={{ background: "white", padding: "15px", borderRadius: "15px" }}
      >
        <React.Fragment key={"right"}>
          <Drawer
            anchor={"right"}
            open={isAddGameSessionDrawOpen}
            onClose={(e) => setIsAddGameSessionDrawOpen(false)}
          >
            <Box
              sx={{
                width: "100%",
                minWidth: 360,
                bgcolor: "background.paper",
                p: 2,
                mt: 5,
              }}
            >
              <AddGameSession
                close={(e) => setIsAddGameSessionDrawOpen(false)}
              />
            </Box>
          </Drawer>
        </React.Fragment>
        {selectedView === "activesessions" && <GameSessionList />}
        {selectedView === "allgamesession" && <AllGameSessions />}
      </Box>
    </Box>
  );
};

export default GameSessionDashboard;
