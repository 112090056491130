import { useContext, useState } from "react";
import { UserContext } from "../../../utility/context";

import ImageProcessor from "../../../components/ImageProcessor/image-processor.component";

import { URLS } from "../../../configs/api_urls";
import Controls from "../../../components/Controls/Controls";

const genderItems = [
  { id: 0, title: "Male", value: "Male" },
  { id: 1, title: "Female", value: "Female" },
];

const MyProfileUpdate = () => {
  const userDetails = useContext(UserContext);
  const [userDetailsItem, setUserDetailsItem] = useState(userDetails);

  return (
    <div>
      <div className="w-100">
        <div style={{ width: "80px" }}>
          <ImageProcessor
            alt="profile_image"
            style={{ borderRadius: "15px", height: "90px" }}
            imageUrl={userDetailsItem.profilePictureUrl}
            uploadUrl={URLS.FILES.PROFILE_IMAGE_UPLOAD}
            setImageData={(e) => {
              setUserDetailsItem({
                ...userDetailsItem,
                profilePictureUrl: e,
              });
            }}
          ></ImageProcessor>
        </div>
        <div className="row mt-20">
          <div className="col-md-4">
            <label className="field-title">First Name</label>
            <Controls.InputControl
              name="title"
              other={{ placeholder: "First Name" }}
              style={{ width: "100%", marginTop: "5px" }}
              onChange={(e) =>
                setUserDetailsItem({
                  ...userDetailsItem,
                  firstName: e.target.value,
                })
              }
              value={userDetailsItem.firstName}
              //error={validation.planName}
            ></Controls.InputControl>
          </div>
          <div className="col-md-4">
            <label className="field-title">Middle Name</label>
            <Controls.InputControl
              name="title"
              other={{ placeholder: "Middle Name" }}
              style={{ width: "100%", marginTop: "5px" }}
              onChange={(e) =>
                setUserDetailsItem({
                  ...userDetailsItem,
                  middleName: e.target.value,
                })
              }
              value={userDetailsItem.middleName}
              //error={validation.planName}
            ></Controls.InputControl>
          </div>
          <div className="col-md-4">
            <label className="field-title">Last Name</label>
            <Controls.InputControl
              name="title"
              other={{ placeholder: "Last Name" }}
              style={{ width: "100%", marginTop: "5px" }}
              onChange={(e) =>
                setUserDetailsItem({
                  ...userDetailsItem,
                  lastName: e.target.value,
                })
              }
              value={userDetailsItem.lastName}
              //error={validation.planName}
            ></Controls.InputControl>
          </div>
        </div>
        <div className="edit-section-margin">
          <div className="row">
            <div className="col-md-6" style={{ display: "grid" }}>
              <label className="field-title">Gender</label>
              <Controls.SelectControl
                name="gender"
                style={{ width: "100%", marginTop: "5px" }}
                options={genderItems}
                value={
                  genderItems.find((a) => a.value == userDetailsItem.gender).id
                }
                onChange={(e) =>
                  setUserDetailsItem({
                    ...userDetailsItem,
                    gender: genderItems.find((a) => a.id == e.target.value)
                      .value,
                  })
                }
              ></Controls.SelectControl>
            </div>
            <div className="col-md-6" style={{ display: "grid" }}>
              <label className="field-title">Date of Birth</label>
              <Controls.DatePickerControl
                id={userDetailsItem.userId}
                name="dateOfBirth"
                style={{ width: "100%", marginTop: "5px" }}
                value={userDetailsItem.dateOfBirth}
                onChange={(value) =>
                  setUserDetailsItem({
                    ...userDetailsItem,
                    dateOfBirth: value,
                  })
                }
              ></Controls.DatePickerControl>
            </div>
          </div>
        </div>
        <div className="edit-section-margin">
          <label className="field-title">Email Id</label>
          <Controls.InputControl
            name="emailId"
            other={{ placeholder: "Email Id" }}
            style={{ width: "100%", marginTop: "5px" }}
            onChange={(e) =>
              setUserDetailsItem({
                ...userDetailsItem,
                emailId: e.target.value,
              })
            }
            value={userDetailsItem.emailId}
            //error={validation.planName}
          ></Controls.InputControl>
        </div>
        <div className="edit-section-margin">
          <div className="row">
            <div className="col-md-6">
              <label className="field-title">Primary Phone</label>
              <Controls.InputControl
                name="title"
                other={{ placeholder: "Primary Phone" }}
                style={{ width: "100%", marginTop: "5px" }}
                onChange={(e) =>
                  setUserDetailsItem({
                    ...userDetailsItem,
                    primaryPhoneNumber: e.target.value,
                  })
                }
                value={userDetailsItem.primaryPhoneNumber}
                //error={validation.planName}
              ></Controls.InputControl>
            </div>
            <div className="col-md-6">
              <label className="field-title">Secondary Phone</label>
              <Controls.InputControl
                name="title"
                other={{ placeholder: "Secondary Phone" }}
                style={{ width: "100%", marginTop: "5px" }}
                onChange={(e) =>
                  setUserDetailsItem({
                    ...userDetailsItem,
                    secondaryPhoneNumber: e.target.value,
                  })
                }
                value={userDetailsItem.secondaryPhoneNumber}
                //error={validation.planName}
              ></Controls.InputControl>
            </div>
            <div className="mt-20 text-align-right">
              <Controls.ButtonControl
                type="submit"
                text="Submit"
                //onClick={handleSubmit}
              ></Controls.ButtonControl>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyProfileUpdate;
