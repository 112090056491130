import { Chip, Checkbox } from "@mui/material";

import "./subscription-plan-item.styles.scss";

const SubscriptionPlanItem = ({ plan, clickSelectPlan, isSelected, isDisabled, addToSelectedList }) => {
  return (
    <div
      className={
        "plan-item cursor-pointer " +
        (isSelected ? "seleted-plan-background" : "")
      }
      onClick={clickSelectPlan}
    >
      <div className="w-100 display-inline-flex">
        <div className="w-10" style={{ margin: "auto" }}>
          <Checkbox
            id={plan.subscriptionPlanId}
            checked={isSelected}
            onChange={addToSelectedList}
          />
        </div>
        <div className="w-80">
          <label>{plan.planName}</label>
          <br />
          <span style={{ color: "dimgray" }}>{plan.planDescription}</span>
          <div className="row">
            <span className="col-md-4">
              Plan Pricing : {plan.planPricingCount}
            </span>
            <span className="col-md-4">
              Offers enabled : {plan.planOfferCount}
            </span>
            <span className="col-md-4">Features : {plan.planFeatureLimit}</span>
          </div>
        </div>
        <div className="w-10" style={{ margin: "auto" }}>
          <Chip label={plan.planSubscriptionCount} />
        </div>
      </div>
    </div>
  );
};

export default SubscriptionPlanItem;
