import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

import ToggleCardSwitch from "../../../components/ToggleCardSwitch";
import Icon from "../../../components/Icons";

import { getStandardGamesDto } from "../../../modules/GameCenter/selector";

const StandardGames = ({ newCenterGames, updateStandardGames }) => {
  const games = useSelector(getStandardGamesDto());
  const [selectedGames, setSelectedGames] = useState(newCenterGames);

  const isGameChecked = (standardGameId) => {
    return selectedGames
      .map((game) => game.standardGameId)
      .includes(standardGameId);
  };

  const selectGame = async (game) => {
    if (selectedGames.includes(game)) {
      var newList = selectedGames.filter(
        (g) => g.standardGameId !== game.standardGameId
      );
      updateStandardGames(newList);
    } else {
      selectedGames.push(game);
      updateStandardGames(selectedGames);
    }
  };

  useEffect(() => {
    setSelectedGames(newCenterGames);
  }, [newCenterGames]);

  return (
    <div className="mt-2">
      <h6 className="font-family-unset">Games</h6>
      <div className="row">
        {games.map((game) => {
          return (
            <div className="col-md-2">
              <ToggleCardSwitch
                key={game.standardGameId}
                id={game.standardGameId}
                isSelected={isGameChecked(game.standardGameId)}
                onClick={() => {
                  selectGame(game);
                }}
                iconOn={
                  <Icon
                    color="white"
                    category="games"
                    icon={game.standardGameName}
                  ></Icon>
                }
                iconOff={
                  <Icon
                    color="black"
                    category="games"
                    icon={game.standardGameName}
                  ></Icon>
                }
                title={game.standardGameName}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default StandardGames;
