import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

import { URLS } from "../../../../configs/api_urls";
import { httpPost } from "../../../../utility/request";

import { selectCartItems } from "../../../../modules/cart/cart.selector";
import Loader from "../../../../components/Loader";

const InstaMojoPaymentGateway = () => {
  const location = useLocation();
  var selectedAddress = location.state;

  const [isPaymentRequestLoading, setIsPaymentRequestLoading] = useState(true);

  const [PaymentResponse, setPaymentResponse] = useState("");

  const cartItems = useSelector(selectCartItems);

  const createPaymentRequest = async () => {
    if (selectedAddress == null || selectedAddress == "") {
      return;
    }
    setIsPaymentRequestLoading(true);
    var adminCartItems = [];
    cartItems.forEach((ci) => {
      adminCartItems.push(ci.adminCartId);
    });

    const { data } = await httpPost(URLS.INSTAMOJOPAYMENT.PAYMENTREQUEST, {
      ...selectedAddress,
      adminCartItems,
    });

    if (data) {
      setPaymentResponse(data);
      setIsPaymentRequestLoading(false);
      window.location.href = data.longurl;
    }
  };

  useEffect(() => {
    if (selectedAddress) createPaymentRequest();
  }, []);

  return (
    <div>
      <Loader isLoading={isPaymentRequestLoading}></Loader>
    </div>
  );
};

export default InstaMojoPaymentGateway;
