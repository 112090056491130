import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fireEvent } from "../../../../modules/Events/eventSlice";

import AssetListItem from "./AssetListItem/asset-list-item.component";
import SelectControl from "../../../../components/Controls/SelectControl";

import { URLS } from "../../../../configs/api_urls";
import { httpGet } from "../../../../utility/request";
import AssetListItemSkeleton from "../../Skeletons/asset-list-item-skeleton.component";

const assetAvailability = [
  { id: 0, title: "Active", isActive: true },
  { id: 1, title: "Archived", isActive: false },
];

const AssetList = ({
  selectedAssetList,
  clickSelectAsset,
  addToSelectedList,
  cancelClick,
}) => {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState(0);
  const [assets, setAssets] = useState([]);
  const [selectedFilterItems, setSelectedFilterItems] = useState([]);

  const event = useSelector((onEvent) => {
    var eventName = onEvent.events.eventName;
    if (eventName === "refresh-asset-list") {
      fetchAssets();
      dispatch(fireEvent({ eventName: "" }));
    }
    //reset event listner
  });

  const handleFilterChange = (filter) => {
    var isActive = assetAvailability.find((x) => x.id == filter).isActive;
    setSelectedFilter(filter);
    var plans = assets.filter((p) => p.isActive === isActive);
    setSelectedFilterItems(plans);
  };

  const fetchAssets = async () => {
    setIsLoading(true);
    const { data } = await httpGet(URLS.ASSET.GET_ASSET_LIST);
    setAssets(data);
    var isActive = assetAvailability.find(
      (x) => x.id == selectedFilter
    ).isActive;
    var assetItems = data.filter((p) => p.isActive === isActive);
    setSelectedFilterItems(assetItems);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchAssets();
  }, []);

  return (
    <div>
      <div className="list-filter-header">
        <SelectControl
          name="asset-availability"
          style={{ width: "120px", height: "35px" }}
          options={assetAvailability}
          value={selectedFilter}
          onChange={(e) => {
            handleFilterChange(e.target.value);
            cancelClick();
          }}
        ></SelectControl>
      </div>
      <div className="list-container scrollbar">
        {isLoading && <AssetListItemSkeleton></AssetListItemSkeleton>}
        {selectedFilterItems.length == 0 &&
          selectedFilter == 0 &&
          !isLoading && (
            <div className="no-item-section">
              <span className="no-item-message">
                You have not created assets yet. Assets are the bookable sports
                turfs, grounds or euipments which players select for booking.
                Select suitable pricing and timing for your assets during
                creation. reach help topics for help and understanding more.
              </span>
            </div>
          )}
        {selectedFilterItems.length == 0 &&
          selectedFilter == 1 &&
          !isLoading && (
            <div className="no-item-section">
              <span className="no-item-message">
                Archived assets are not visible to players during booking.
              </span>
            </div>
          )}
        {!isLoading &&
          selectedFilterItems.map((asset) => {
            return (
              <div key={asset.assetId}>
                <AssetListItem
                  asset={asset}
                  isSelected={selectedAssetList.includes(asset.assetId)}
                  addToSelectedList={addToSelectedList}
                  clickSelectAsset={() => {
                    clickSelectAsset(asset);
                  }}
                ></AssetListItem>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default AssetList;
