import { useSelector, useDispatch } from "react-redux";
import { useEffect, useMemo, useState } from "react";
// import { createPortal } from "react-dom";
import { useNavigate } from "react-router-dom";

import { Card } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import ModalPopup from "../../ModalPopup/modalpopup.component";
import {Select,MenuItem} from "@mui/material";
import Loader from "../../../components/Loader";

import {
  selectCartItems,
  selectCartTotal,
  selectIsPaymentScreenOpen
} from "../../../modules/cart/cart.selector";
import { selectSignalRPayload } from "../../../modules/SignalR/baseSignalR.selector";
import { getIsRenderedState } from "../../../modules/ModalPopup/modalPopupSlice";
import {
  fetchCartItems,
} from "../../../modules/cart/cart.action";
import { setIsRenderedState } from "../../../modules/ModalPopup/modalPopupSlice";
import { httpGet } from "../../../utility/request";
import { URLS } from "../../../configs/api_urls";

import CheckoutItem from "../CheckoutItem/checkout-item.component";

import "./checkout.styles.scss";

// const NewWindow = ({ children, close }) => {

//   const newWindow = useMemo(() =>
//     window.open(
//       "about:blank",
//       "newWin",
//       `width=600,height=600,left=${window.screen.availWidth / 2 - 200},top=${
//         window.screen.availHeight / 2 - 150
//       }`
//     )
//   );

//   setTimeout(() => {
//     if (newWindow.document.getElementById("nonseamless") != null)
//       {
//         newWindow.document.getElementById("nonseamless").submit();
//       }
//   }, 100);
  
//   return createPortal(children, newWindow.document.body);
// };

const Checkout = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();

    const cartItems = useSelector(selectCartItems);
    const cartTotal = useSelector(selectCartTotal);
    const isPaymentScreenOpen = useSelector(selectIsPaymentScreenOpen);
    const signalRPayload = useSelector(selectSignalRPayload);
    const isPopupRendered = useSelector(getIsRenderedState);

    const defaultAddress = {
      addressId: 0,
      addressLane1: "M/S Playnoot Kot Fatuhi",
      addressLane2: "Near Lovely sweet Shop",
      city: "Hoshiarpur",
      state: "Punjab",
      country: "India",
      googleMapAddress: "",
      googleMapId: "",
      latitude: "",
      longitude: "",
      pincode: "144519",
    };

    const [isLoading, setIsLoading] = useState(false);
    const [addresses, setAddresses] = useState([]);
    const [selectedAddress, setSelectedAddress] = useState("");
    const [isAddressSelected, setIsAddressSelected] = useState(true);

    useEffect(() =>{
      dispatch(setIsRenderedState(false));
      setIsLoading(true);
      Promise.all([getCartItems(), getApplicationAddresses()])
      .then((results) =>{
         setAddresses([...results[1].data, {...defaultAddress}]);
         setIsLoading(false);
      });      
    }, []);

const getCartItems = async () => {
  return dispatch(fetchCartItems());
}

const getApplicationAddresses = async () => {
  const  data  = await httpGet(
      URLS.APPLICATION.APPLICATION_ADDRESSES
    );
  return data;
}

const getPaymentToken = async () => {
  if (selectedAddress == null || selectedAddress == "") {
    setIsAddressSelected(false);
    return;
  }
  //navigate("/admin/admin-payment", {state: selectedAddress}); commented cc avenue
  navigate("/admin/phonepe-admin-payment", { state: selectedAddress });

};
  const handleConfirmClick = () =>
  {
     dispatch(setIsRenderedState(false));
     navigate("/admin/admin-orders");
  }

  const handleCancelClick = () => 
  {
     dispatch(setIsRenderedState(false));
  };

  return (
    <div>
      <Card className="mx-2">
        <div>
          <Loader
            isLoading={isLoading}
          ></Loader>
        </div>

        {!isLoading && (
          <div className="step1-container">
            <h6 className="step-container-label">1. Select Address</h6>
            <div className="address-selected-container">
              <FormControl fullWidth required>
                <InputLabel id="address-select-label">
                  Shipping and Billing Address
                </InputLabel>
                <Select
                  className="address-selector"
                  labelId="address-select-label"
                  id="address-select"
                  value={selectedAddress}
                  defaultValue=""
                  label="Shipping and Billing Address"
                  onChange={(event) => {
                    setSelectedAddress(event.target.value);
                    setIsAddressSelected(true);
                  }}
                >
                  {addresses.map((address) => {
                    return (
                      <MenuItem
                        value={address}
                        key={`address-${address.addressId}`}
                      >
                        {address.addressLane1 +
                          " , " +
                          address.addressLane2 +
                          " , " +
                          address.city +
                          " , " +
                          address.state +
                          " , " +
                          address.country +
                          " , (" +
                          address.pincode +
                          ")"}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              {!isAddressSelected && (
                <div className="address-selection-warning">
                  Please select the Address
                </div>
              )}
            </div>
          </div>
        )}

        {!isLoading && cartItems.length > 0 ? (
          <div>
            <h6 className="step-container-label">2. Item list</h6>
            <div className="checkout-container">
              <div className="checkout-header">
                <div className="header-block">
                  <span>Product</span>
                </div>
                <div className="header-block">
                  <span>Description</span>
                </div>
                <div className="header-block">
                  <span>Pricing Option</span>
                </div>
                <div className="header-block">
                  <span>Quantity</span>
                </div>
                <div className="header-block">
                  <span>Price(₹)</span>
                </div>
                <div className="header-block">
                  <span>Remove</span>
                </div>
              </div>
              {cartItems.map((cartItem) => (
                <CheckoutItem key={cartItem.adminCartId} cartItem={cartItem} />
              ))}
              <div className="total">Total: {cartTotal} ₹/-</div>
              <button
                className="theme-btn my-2 p-2"
                onClick={() => {
                  getPaymentToken();
                }}
              >
                Proceed to Payment
              </button>
            </div>
          </div>
        ) : (
          <div className="empty-container">
            <span>Cart is empty!!</span>
          </div>
        )}
        {/* <button onClick={() => {setOpenState(false)}}>Close</button> */}
      </Card>
      <ModalPopup
        heading={signalRPayload.Description}
        isConfirmButton={true}
        confirmButtonText={"Details"}
        onConfirmClick={handleConfirmClick}
        isCancelButton={true}
        cancelButtontext={"Cancel"}
        onCancelClick={handleCancelClick}
      ></ModalPopup>
    </div>
  );
};

export default Checkout;
