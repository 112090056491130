import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as AllIcons from "@fortawesome/free-solid-svg-icons";

import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import DialogTitle from "@mui/material/DialogTitle";


import {iconList} from "./icon-list"
import "./icon-selector.styles.scss";

const IconSelector = ({isOpen, setIconVal, cancelClick }) => {
  var totalIcons = iconList.length;

  const setIconsUi = () => {
    const arr = [];
    for (var i = 0; i < totalIcons; i++) {
      {
        arr.push(
          <div
            key={iconList[i]}
            className="col-md-2 p-20 icon-style"
            id={iconList[i]}
            onClick={setIconVal}
          >
            <FontAwesomeIcon
              style={{ cursor: "pointer" }}
              size="xl"
              icon={AllIcons[iconList[i]]}
            ></FontAwesomeIcon>
          </div>
        );
      }
    }
    return arr;
  };

  return (
    <div className="icon-selector-parent">
      <Dialog maxWidth="sm" open={isOpen}>
        <DialogTitle sx={{ m: 0, p: 2 }} >
          {"Select Icon"}
          {
            <IconButton
              aria-label="close"
              onClick={cancelClick}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          }
        </DialogTitle>
        <div className="icon-list-container">
          <div className="row">{setIconsUi()}</div>
        </div>
      </Dialog>
      {/* <Select label={"fontawsome-select"} name={"fontawsome-select"}>
         <MenuItem value="">None</MenuItem>
         {iconList.map((item) => (
           <MenuItem key={item}>
             <FontAwesomeIcon icon={AllIcons[item]}></FontAwesomeIcon>
           </MenuItem>
         ))}
       </Select> */}
    </div>
  );
};

export default IconSelector;
