import SubscriptionNavigation from "../SubscriptionNavigation/subscription-navigation.component";
import SubscribersList from "./SubscribersList/subscribers-list.component";

const SubscriptionPurchaseOrderDashboard = () => 
{
    return (
      <div>
        <SubscriptionNavigation
          isCreateNewPlanVisible={false}
          isCreateNewOfferVisible={false}
          isPlansDashboardVisible={true}
          isPlanOffersDashboardVisible={true}
          isSubscribersPurchaseDashboardVisible={false}
        ></SubscriptionNavigation>
        <div className="display-inline-flex w-100">
          <SubscribersList></SubscribersList>
        </div>
      </div>
    );
};

export default SubscriptionPurchaseOrderDashboard;