import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

export default function AutocompleteControl({
  name,
  label,
  options,
  loading,
  value,
  defaultValue,
  onInputChange,
  onChange,
  style,
}) {
  var defaultSx = { width: 300 };
  return (
    <Autocomplete
      name={name}
      loading={loading}
      value={value}
      id="name"
      options={options}
      sx={style || defaultSx}
      defaultValue={defaultValue}
      onInputChange={(e) => {
        //if any other event text change has occuered ignore it, So we are ignoring select event
        if (e == null || e.type !== "change") {
          return;
        }
        onInputChange(e.target.value);
      }}
      onChange={(event, newValue) => {
        onChange({ event: event, selectedVal: newValue });
      }}
      renderInput={(params) => <TextField {...params} label={label} />}
    />
  );
}
