import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Checkbox from "@mui/material/Checkbox";
import { Avatar, Chip } from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import { IconButton } from "@mui/material";
import { Menu } from "@mui/material";
import { MenuItem } from "@mui/material";
import FontAwesome from "../../../components/Icons/fontawesome";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import { Pagination } from "@mui/material";
import Controls from "../../../components/Controls/Controls";
import TableSkeleton from "../../../components/Skeletons/table-skeleton.component";
import ModalPopup from "../../../components/ModalPopup/modalpopup.component";
import CircularProgress from "@mui/material/CircularProgress";

import { URLS } from "../../../configs/api_urls";
import { httpPost, httpDelete } from "../../../utility/request";
import { setIsRenderedState } from "../../../modules/ModalPopup/modalPopupSlice";

import moment from "moment-timezone";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "image",
    numeric: false,
    disablePadding: false,
    label: "Image",
  },
  {
    id: "title",
    numeric: false,
    disablePadding: false,
    label: "Title",
  },
  {
    id: "description",
    numeric: false,
    disablePadding: false,
    label: "Description",
  },
  {
    id: "senton",
    numeric: false,
    disablePadding: false,
    label: "Sent on",
  },
  {
    id: "SentCount",
    numeric: false,
    disablePadding: false,
    label: "No. of Receivers",
  },
  {
    id: "action",
    numeric: true,
    disablePadding: false,
    label: "Type",
  },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all payments",
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const AnnouncementList = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const [notificationData, setNotificationData] = useState({});
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [selectedForRemove, setSelectedForRemove] = React.useState(null);
  const [page, setPage] = React.useState(1);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const fetchSentNotification = async () => {
    setIsLoading(true);
    const { data } = await httpPost(URLS.ANNOUNCEMENTS.GET_SENT_NOTIFICATIONS, {
      pageNumber: page,
      pageSize: rowsPerPage,
    });
    setNotificationData(data);
    setRows(data.items);
    setIsLoading(false);
  };

  const removeNotification = async () => {
    await httpDelete(
      URLS.ANNOUNCEMENTS.DELETE_ADMIN_NOTIFICATION.replace(
        "#ID#",
        selectedForRemove
      )
    ).then(({ data }) => {
      if (data == true) {
        fetchSentNotification();
      }
    });
    dispatch(setIsRenderedState(false));
    setSelectedForRemove(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.notificationIdentifierKay);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, notificationIdentifierKay) => {
    const selectedIndex = selected.indexOf(notificationIdentifierKay);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, notificationIdentifierKay);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (rows) => {
    setRowsPerPage(parseInt(rows, 10));
    setPage(1);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (notificationIdentifierKay) =>
    selected.indexOf(notificationIdentifierKay) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = 1;

  const visibleRows = React.useMemo(
    () =>
      stableSort(rows, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [order, orderBy, page, rowsPerPage]
  );

  const handleMenuClick = (event) => {
    setAnchorEl(event);
    CheckOpen(event.split("_")[1]);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const CheckOpen = (notificationIdentifierKay) => {
    if (anchorEl !== undefined && anchorEl !== null) {
      if (anchorEl.split("_")[1] == notificationIdentifierKay) return true;
    }
    return false;
  };

  useEffect(() => {
    fetchSentNotification();
  }, [page, rowsPerPage]);

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2, padding: "20px" }}>
        <div>
          <Controls.ButtonControl
            text={"Refresh"}
           onClick={() => fetchSentNotification()}
            disabled={isLoading}
            innercomponent={
              isLoading && (
                <CircularProgress
                  size={25}
                  style={{ padding: "2px", marginLeft: "10px" }}
                  color="inherit"
                ></CircularProgress>
              )
            }
            style={{
              marginTop: "20px",
              float: "right",
            }}
          ></Controls.ButtonControl>
        </div>
        <div>
          {isLoading && (
            <TableSkeleton
              pageNumber={page}
              pageSize={rowsPerPage}
            ></TableSkeleton>
          )}
          {selectedForRemove != null && (
            <ModalPopup
              heading={
                "Are you sure that you want to Delete this Notification?"
              }
              subHeadhing="Receivers will no longer be able to se this notification on Playnoot app"
              isConfirmButton={true}
              confirmButtonText={"Yes"}
              onConfirmClick={removeNotification}
              isCancelButton={true}
              cancelButtontext={"No"}
              onCancelClick={() => {
                dispatch(setIsRenderedState(false));
                setSelectedForRemove(null);
              }}
            ></ModalPopup>
          )}
          {!isLoading && (
            <TableContainer>
              <Table
                sx={{ minWidth: 750 }}
                aria-labelledby="tableTitle"
                size={dense ? "small" : "medium"}
              >
                <EnhancedTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={rows.length}
                />
                <TableBody>
                  {rows.map((row, index) => {
                    const isItemSelected = isSelected(
                      row.notificationIdentifierKay
                    );
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        hover
                        onClick={(event) =>
                          handleClick(event, row.notificationIdentifierKay)
                        }
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.notificationIdentifierKay}
                        selected={isItemSelected}
                        sx={{ cursor: "pointer" }}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            color="primary"
                            checked={isItemSelected}
                            inputProps={{
                              "aria-labelledby": labelId,
                            }}
                          />
                        </TableCell>
                        <TableCell align="right" padding="none">
                          <Avatar
                            src={row.imageUrl}
                            alt="Avatar"
                            style={{
                              borderRadius: "50%",
                              height: "50px",
                              width: "50px",
                            }}
                          ></Avatar>
                        </TableCell>
                        <TableCell align="left">{row.title}</TableCell>
                        <TableCell align="left">{row.description}</TableCell>
                        <TableCell align="left" sx={{ width: "250px" }}>
                          {moment
                            .utc(row.enuqueueTime)
                            .local()
                            .format("MMM DD, yyyy, hh:mm A")}
                        </TableCell>
                        <TableCell align="left">{row.sentCount}</TableCell>
                        <TableCell align="right">
                          <IconButton
                            id={"button_" + row.notificationIdentifierKay}
                            aria-label={
                              "button_" + row.notificationIdentifierKay
                            }
                            aria-controls={
                              CheckOpen(row.notificationIdentifierKay)
                                ? "menu_" + row.notificationIdentifierKay
                                : undefined
                            }
                            aria-haspopup="true"
                            aria-expanded={
                              CheckOpen(row.notificationIdentifierKay)
                                ? "true"
                                : undefined
                            }
                            onClick={(e) => {
                              handleMenuClick(e.currentTarget.ariaLabel);
                              e.stopPropagation();
                            }}
                          >
                            <FontAwesome
                              icon={faEllipsisV}
                              iconSize={"20px"}
                              className={"info-icon-margin"}
                            ></FontAwesome>
                          </IconButton>
                          <Menu
                            aria-label={"menu_" + row.notificationIdentifierKay}
                            id={"menu_" + row.notificationIdentifierKay}
                            anchorEl={document.getElementById(
                              "button_" + row.notificationIdentifierKay
                            )}
                            open={CheckOpen(row.notificationIdentifierKay)}
                            onClose={handleMenuClose}
                            sx={{ boxShadow: 10, color: "gray" }}
                            MenuListProps={{
                              "aria-labelledby":
                                "button_" + row.notificationIdentifierKay,
                            }}
                          >
                            <MenuItem
                              onClick={(e) => {
                                handleMenuClose(e);
                                setSelectedForRemove(
                                  row.notificationIdentifierKay
                                );
                                dispatch(setIsRenderedState(true));
                              }}
                            >
                              Delete
                            </MenuItem>
                          </Menu>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow
                      style={{
                        height: (dense ? 33 : 53) * emptyRows,
                      }}
                    >
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          )}
          {!isLoading && (
            <div className="display-flex" style={{ padding: "10px" }}>
              <Pagination
                count={notificationData.totalPages}
                page={page}
                variant="outlined"
                shape="rounded"
                showFirstButton={notificationData.hasPreviousPage}
                showLastButton={notificationData.hasNextPage}
                color="secondary"
                style={{ marginLeft: "auto" }}
                onChange={(event, value) => {
                  handleChangePage(value);
                }}
              />
              <Controls.SelectControl
                name="pageSize"
                style={{ width: "100%", marginLeft: "20px" }}
                options={[
                  { id: 5, title: "5" },
                  { id: 10, title: "10" },
                  { id: 50, title: "50" },
                ]}
                value={rowsPerPage}
                onChange={(e) => {
                  handleChangeRowsPerPage(Number(e.target.value));
                }}
              ></Controls.SelectControl>
            </div>
          )}
        </div>
      </Paper>
    </Box>
  );
};

export default AnnouncementList;
