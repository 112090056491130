import React from "react";
import { useEffect } from "react";
import { useDispatch, connect } from "react-redux";
import { Routes, Route, Navigate } from "react-router-dom";

import { APP_ROUTES, LOGIN_PAGE } from "./configs/routes";
import { getAccessTokenV2 } from "./hoc/helper";
import { initiateSignalRConnectionRequest } from "../src/modules/SignalR/baseSignalR.actions";

import styles from "./index.css"
const ProtectedRoute = ({ children }) => {

  const token = getAccessTokenV2();

  const dispatch = useDispatch();
    useEffect(() => {
      dispatch(initiateSignalRConnectionRequest());
    }, []);

  if (!token) {
    return <Navigate to={LOGIN_PAGE} />;
  } else {
    return children;
  }
};

function App() {
  return (
    <Routes>
      {APP_ROUTES.map((route, index) =>
        route.isProtected ? (
          <Route
            path={route.path}
            key={`route_${index}`}
            element={<ProtectedRoute> {route.element} </ProtectedRoute>}
          />
        ) : (
          <Route {...route} key={`route_${index}`} />
        )
      )}
    </Routes>
  );
}

export default App;
