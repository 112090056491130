import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import AdvertisementListSkeleton from "../Skeleton/advertisement-list-skeleton.component";
import AdvertisementItem from "./AdvertisementItem/advertisement-item.component";

import { URLS } from "../../../../configs/api_urls";
import { httpGet } from "../../../../utility/request";
import { fireEvent } from "../../../../modules/Events/eventSlice";

const AdvertisementList = ({
  selectedAdList,
  clickSelectAd,
  addToSelectedList,
}) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [ads, setAds] = useState([]);

  const fetchAllAds = async () => {
    setIsLoading(true);
    const { data } = await httpGet(URLS.ADVERTISEMENT.GET_ALL_ADVERTISEMENT);
    setAds(data);
    setIsLoading(false);
  };

  const event = useSelector((onEvent) => {
    var eventName = onEvent.events.eventName;
    if (eventName === "refresh-advertisement-list") {
      fetchAllAds();
      dispatch(fireEvent({ eventName: "" }));
    }
    //reset event listner
  });

  useEffect(() => {
    fetchAllAds();
  }, []);

  return (
    <div>
      <div className="list-filter-header"></div>
      <div className="list-container scrollbar">
        {isLoading && <AdvertisementListSkeleton />}
        {ads.length == 0 && !isLoading && (
          <div className="no-item-section">
            <span className="no-item-message">
              You have not created any Ads yet. Add Ads to promote facility,
              blog or filters
            </span>
          </div>
        )}
        {ads.map((ad) => {
          return (
            <div key={ad.advertisementId}>
              <AdvertisementItem
                ad={ad}
                isSelected={selectedAdList.includes(ad.advertisementId)}
                addToSelectedList={addToSelectedList}
                clickSelectAd={() => {
                  clickSelectAd(ad);
                }}
              ></AdvertisementItem>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default AdvertisementList;
