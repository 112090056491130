import { useState, useEffect } from "react";
import { GameCenterViewContext } from "../../../utility/context";
import { useDispatch } from "react-redux";

import ImageProcessor from "../../../components/ImageProcessor/image-processor.component";
import placeHolderImage from "../../../static/placeHolderImage.png";

import { URLS } from "../../../configs/api_urls";

const UploadImage = ({images, updateImageList}) => {
  const dispatch = useDispatch();
  const [imagesList, setImagesList] = useState(images);

  const addImageToList = async (imgObject) => {
    imagesList.push(imgObject);
    updateImageList(imagesList);
  };

  const deleteImageFromList = async (imgIndex) => {
     var imageToRemove = imagesList[imgIndex];
     var newList = images.filter(img => img != imageToRemove);
      updateImageList(newList);
  }

  useEffect(() => {
    setImagesList(images);
  }, [images]);

  return (
    <div>
      <div className="row">
        <div className="col-md-4">
          <ImageProcessor
            alt="profile_image_0"
            id="profile_image_0"
            style={{ borderRadius: "15px" }}
            imageUrl={
              imagesList[0] ? imagesList[0].imageFullUrl : placeHolderImage
            }
            showImageUploadButton={imagesList[0] == undefined ? true : false}
            showDeleteButton={true}
            uploadUrl={URLS.FILES.FACILITES_IMAGES}
            setImageData={(e) => addImageToList({ ...e, isThumbnailImage: true })}
            deleteImage={() => {
              deleteImageFromList(0);
            }}
          ></ImageProcessor>
        </div>
        <div className="col-md-4">
          <ImageProcessor
            alt="profile_image_1"
            id="profile_image_1"
            style={{ borderRadius: "15px" }}
            imageUrl={
              imagesList[1] ? imagesList[1].imageFullUrl : placeHolderImage
            }
            showImageUploadButton={imagesList[1] == undefined ? true : false}
            uploadUrl={URLS.FILES.FACILITES_IMAGES}
            showDeleteButton={true}
            setImageData={(e) => addImageToList(e)}
            deleteImage={() => {
              deleteImageFromList(1);
            }}
          ></ImageProcessor>
        </div>
        <div className="col-md-4">
          <ImageProcessor
            alt="profile_image_2"
            id="profile_image_2"
            style={{ borderRadius: "15px" }}
            imageUrl={
              imagesList[2] ? imagesList[2].imageFullUrl : placeHolderImage
            }
            showImageUploadButton={imagesList[2] == undefined ? true : false}
            uploadUrl={URLS.FILES.FACILITES_IMAGES}
            showDeleteButton={true}
            setImageData={(e) => addImageToList(e)}
            deleteImage={() => {
              deleteImageFromList(2);
            }}
          ></ImageProcessor>
        </div>
      </div>
      <div className="row mt-20">
        <div className="col-md-4">
          <ImageProcessor
            alt="profile_image_3"
            id="profile_image_3"
            style={{ borderRadius: "15px" }}
            imageUrl={
              imagesList[3] ? imagesList[3].imageFullUrl : placeHolderImage
            }
            showImageUploadButton={imagesList[3] == undefined ? true : false}
            uploadUrl={URLS.FILES.FACILITES_IMAGES}
            showDeleteButton={true}
            setImageData={(e) => addImageToList(e)}
            deleteImage={() => {
              deleteImageFromList(3);
            }}
          ></ImageProcessor>
        </div>
        <div className="col-md-4">
          <ImageProcessor
            alt="profile_image_4"
            id="profile_image_4"
            style={{ borderRadius: "15px" }}
            imageUrl={
              imagesList[4] ? imagesList[4].imageFullUrl : placeHolderImage
            }
            showImageUploadButton={imagesList[4] == undefined ? true : false}
            uploadUrl={URLS.FILES.FACILITES_IMAGES}
            showDeleteButton={true}
            setImageData={(e) => addImageToList(e)}
            deleteImage={() => {
              deleteImageFromList(4);
            }}
          ></ImageProcessor>
        </div>
      </div>
    </div>
  );
};

export default UploadImage;
