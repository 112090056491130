import rootReducer from "./reducers";
import { configureStore } from "@reduxjs/toolkit";
import  signalRMiddleware  from "./middlewares/signalR.middleware";

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(signalRMiddleware),
});

//export const makeStore = () => store;